import React, { useState } from 'react';
import Button from '../../../../components/ui/Button';
import FormInput from '../../../../components/ui/FormInput';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

const BuyActions = ({ product, onAddToCart }) => {
  const [quantity, setQuantity] = useState(1);

  if (!product || !product.forSell) return null;

  const { price = 0, stock = 0 } = product;
  const isVirtual = product?.productType === 'virtual';

  const handleQuantityChange = (newQuantity) => {
    setQuantity(Math.max(1, Math.min(parseInt(stock), newQuantity)));
  };

  const calculateTotal = () => {
    return quantity * price;
  };

  const total = calculateTotal();

  return (
    <div className="space-y-6 max-w-md">
      {/* Buy Form */}
      <div className="space-y-4">
        {/* Quantity Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Quantity
          </label>
          <div className="flex items-center">
            <div className="flex items-center border border-gray-300 rounded-md">
              <Button
                onClick={() => handleQuantityChange(quantity - 1)}
                disabled={quantity <= 1}
                variant="secondary"
                size="sm"
              >
                -
              </Button>
              <input
                type="text"
                value={quantity}
                onChange={(e) => {
                  const val = parseInt(e.target.value);
                  if (!isNaN(val)) {
                    handleQuantityChange(val);
                  }
                }}
                className="w-12 text-center border-x border-gray-300 py-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              />
              <Button
                onClick={() => handleQuantityChange(quantity + 1)}
                disabled={quantity >= stock}
                variant="secondary"
                size="sm"
              >
                +
              </Button>
            </div>
            <span className="text-sm text-gray-500 ml-2">
              {stock === '0' ? 'Out of stock' : `${stock} available`}
            </span>
          </div>
        </div>

        {/* Display total when quantity > 1 */}
        {quantity > 1 && (
          <div className="text-sm text-gray-500">
            Total: ${total.toFixed(2)} {!isVirtual && '+ shipping'}
          </div>
        )}

        {/* Out of stock message */}
        {stock === '0' && (
          <div className="bg-red-50 p-4 rounded-lg">
            <p className="text-sm text-red-600">
              This item is currently out of stock.
            </p>
          </div>
        )}

        {/* Buy Form Actions */}
        <div className="flex flex-row gap-4 mt-6">
          <Button
            variant="orange"
            disabled={stock === '0'}
            className="w-full"
            onClick={() => {
              onAddToCart?.({
                type: 'buy',
                quantity,
                navigateToCart: true
              });
            }}
          >
            Buy Now
          </Button>
          <Button
            variant="teal"
            disabled={stock === '0'}
            className="w-full"
            onClick={() => {
              onAddToCart?.({
                type: 'buy',
                quantity
              });
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="8" cy="21" r="1" />
              <circle cx="19" cy="21" r="1" />
              <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
              <line x1="12" y1="9" x2="12" y2="15" />
              <line x1="9" y1="12" x2="15" y2="12" />
            </svg>
          </Button>
          <Button
            variant="offer"
            className="w-full"
          >
            Offer
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BuyActions; 