import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchProducts } from '../../utils/algolia';

const initialState = {
  searchTerm: '',
  sortBy: 'products',
  filters: {
    productType: '',
    forRent: false,
    forSell: false,
    openToSwap: false,
    openToShipping: undefined,
    localPickup: {
      enabled: false
    },
    location: null,
    categories: '',
    sizes: '',
    colors: '',
    occasions: '',
    styles: '',
    genders: ''
  },
  loading: false,
  dataLoading: true,
  error: null,
  products: [],
  totalResults: 0,
  offset: 0,
  hasMore: true
};

// Parse URL parameters
const parseUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  const searchTerm = params.get('q') || '';
  const sortBy = params.get('sort') || 'products';
  const filters = {};

  params.forEach((value, key) => {
    if (key !== 'q' && key !== 'sort') {
      try {
        filters[key] = JSON.parse(value);
      } catch {
        filters[key] = value;
      }
    }
  });

  return { searchTerm, sortBy, filters };
};

// Search thunk
export const searchWithAlgolia = createAsyncThunk(
  'filter/searchWithAlgolia',
  async ({ searchTerm, filters, sortBy, offset = 0 }, { rejectWithValue, getState }) => {
    try {
      const state = getState().filter;
      // Extract location data if available
      const location = state.filters.location && state.filters.localPickup?.enabled
        ? state.filters.location
        : null;
      return await searchProducts({ 
        searchTerm, 
        filters, 
        sortBy, 
        offset,
        location // Pass location data to search
      }, getState);
    } catch (error) {
      console.error('Algolia search error:', error);
      return rejectWithValue(error.message);
    }
  }
);

// URL change listener thunk
export const handleUrlChange = createAsyncThunk(
  'filter/handleUrlChange',
  async (_, { dispatch, getState }) => {
    const { searchTerm, sortBy, filters } = parseUrlParams();
    const state = getState().filter;

    // Check if any values have changed
    const hasChanged = 
      searchTerm !== state.searchTerm ||
      sortBy !== state.sortBy ||
      JSON.stringify(filters) !== JSON.stringify(state.filters);

    if (hasChanged) {
      // Update all parameters at once
      dispatch(updateAllParams({ searchTerm, sortBy, filters }));
      console.log('handleUrlChange: searchWithAlgolia', { searchTerm, filters, sortBy, offset: 0 });
      // Trigger search only once
      return dispatch(searchWithAlgolia({ searchTerm, filters, sortBy, offset: 0 }));
    }
    
    return null;
  }
);

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateAllParams: (state, action) => {
      const { searchTerm, sortBy, filters } = action.payload;
      state.searchTerm = searchTerm;
      state.sortBy = sortBy;
      state.filters = {
        ...state.filters,
        ...filters
      };
      state.offset = 0;
      console.log('Updated all parameters:', { searchTerm, sortBy, filters });
    },
    setSearchTerm: (state, action) => {
      console.log('Setting search term:', action.payload);
      state.searchTerm = action.payload;
      state.offset = 0;
    },
    setSortBy: (state, action) => {
      console.log('Setting sort by:', action.payload);
      state.sortBy = action.payload;
      state.offset = 0;
    },
    setFilter: (state, action) => {
      console.log('Setting filter:', action.payload);
      state.filters = {
        ...state.filters,
        ...action.payload
      };
      console.log('Updated filters:', state.filters);
      state.offset = 0;
    },
    clearAll: (state) => {
      console.log('Clearing all filters');
      return initialState;
    },
    loadMore: (state) => {
      if (state.hasMore && !state.loading) {
        state.offset += 20;
        console.log('Loading more, new offset:', state.offset);
      }
    },
    optimisticUpdate: (state, action) => {
      // Immediately update the products array without waiting for Algolia
      state.products = action.payload;
      state.totalResults = action.payload.length;
      state.offset = 0;
      state.hasMore = false; // Disable pagination until we get fresh data
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchWithAlgolia.pending, (state) => {
        state.loading = true;
        state.dataLoading = true;
        state.error = null;
      })
      .addCase(searchWithAlgolia.fulfilled, (state, action) => {
        state.loading = false;
        const offset = action.meta.arg.offset || 0;
        if (offset === 0) {
          state.products = action.payload.products;
          state.offset = 0;
        } else {
          state.products = [...state.products, ...action.payload.products];
          state.offset = offset;
        }
        state.totalResults = action.payload.totalResults;
        state.hasMore = state.products.length < action.payload.totalResults;
        state.dataLoading = false;
      })
      .addCase(searchWithAlgolia.rejected, (state, action) => {
        state.loading = false;
        state.dataLoading = false;
        state.error = action.payload;
      });
  }
});

export const { 
  updateAllParams,
  setSearchTerm, 
  setSortBy, 
  setFilter, 
  clearAll, 
  loadMore,
  optimisticUpdate
} = filterSlice.actions;

export default filterSlice.reducer; 