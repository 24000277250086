import React, { forwardRef } from 'react';
import Checkbox from './Checkbox';

const FormCheckbox = forwardRef(({ 
  label,
  name,
  checked,
  onChange,
  error,
  className = '',
  helpText,
  required = false,
  ...props
}, ref) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <Checkbox
        ref={ref}
        label={required ? `${label} *` : label}
        name={name}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      
      {helpText && (
        <p className="mt-1 text-sm text-gray-500">{helpText}</p>
      )}
    </div>
  );
});

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox; 