import React, { useMemo } from 'react';
import Dropdown from '../../../../ui/Dropdown';
import useFilter from '../../useFilter';

const FilterDropdown = ({ type, className = '' }) => {
  const { filters, handleFilterChange, handleProductTypeChange, handleTransactionTypeChange } = useFilter();

  const options = useMemo(() => {
    switch (type) {
      case 'productType':
        return [
          { value: '', label: 'All Products' },
          { value: 'designer', label: 'Designer' },
          { value: 'sharing', label: 'Closet Share' },
          { value: 'virtual', label: 'Virtual' },
          { value: 'others', label: 'Others' }
        ];
      case 'transactionType':
        return [
          { value: '', label: 'All Transactions' },
          { value: 'forRent', label: 'For Rent' },
          { value: 'forSell', label: 'For Sale' },
          { value: 'openToSwap', label: 'Open to Swap' }
        ];
      default:
        return [];
    }
  }, [type]);

  const handleChange = (value) => {
    if (type === 'transactionType') {
      if (value === '') {
        // Reset all transaction flags
        handleFilterChange({
          forRent: false,
          forSell: false,
          openToSwap: false
        });
      } else {
        // Set only the selected transaction flag
        handleFilterChange({
          forRent: value === 'forRent',
          forSell: value === 'forSell',
          openToSwap: value === 'openToSwap'
        });
      }
    } else if (type === 'productType') {
      handleProductTypeChange(value);
    }
  };

  // For transaction type, find which one is true
  const currentTransactionType = type === 'transactionType' 
    ? Object.keys(filters).find(key => 
        ['forRent', 'forSell', 'openToSwap'].includes(key) && filters[key]
      ) || ''
    : filters[type];

  const getTitle = () => {
    switch (type) {
      case 'productType':
        return 'Product Type';
      case 'transactionType':
        return 'Transaction Type';
      default:
        return '';
    }
  };

  // Get the default option (the first one with empty value)
  const getDefaultOption = () => {
    return options.find(opt => opt.value === '') || { label: `All ${getTitle()}` };
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium text-gray-700">{getTitle()}</span>
      </div>
      <Dropdown
        value={type === 'transactionType' ? currentTransactionType : filters[type] || ''}
        onChange={handleChange}
        options={options}
        placeholder={getDefaultOption().label}
        maxWidth="none"
        className={className}
      />
    </div>
  );
};

export default FilterDropdown; 