import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import {
  fetchCartItems,
  updateCartItems,
  checkoutCart,
  selectBuyProducts,
  selectRentProducts,
  selectCartLoading,
  selectCartError,
  selectAddCartSuccess,
  selectGroupedBuyProducts,
  selectGroupedRentProducts,
  selectCartUpdating,
} from '../../redux/slices/cartSlice';

const CartPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Local state
  const [activeTab, setActiveTab] = useState('Buy');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [stockModalOpen, setStockModalOpen] = useState(false);
  const [targetItem, setTargetItem] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  // Get cart state from Redux
  const buyProducts = useSelector(selectBuyProducts);
  const rentProducts = useSelector(selectRentProducts);
  const groupedBuyProducts = useSelector(selectGroupedBuyProducts);
  const groupedRentProducts = useSelector(selectGroupedRentProducts);
  const loading = useSelector(selectCartLoading);
  const error = useSelector(selectCartError);
  const addCartSuccess = useSelector(selectAddCartSuccess);
  const updating = useSelector(selectCartUpdating);

  // Check authentication and fetch cart items
  useEffect(() => {
    const token = localStorage.getItem('token');
    const isLoggedIn = localStorage.getItem('login') === 'true';

    console.log(buyProducts)

    if (!token || !isLoggedIn) {
      history.push('/login');
      return;
    }

    dispatch(fetchCartItems());
  }, [dispatch, history]);

  // Calculate total amount when products change
  useEffect(() => {
    let newAmount = 0;
    if (activeTab === 'Buy') {
      buyProducts.forEach(product => {
        if (product.stock !== '0') {
          newAmount += product.price * product.quantity;
        }
      });
      localStorage.setItem('fee', newAmount);
    } else {
      rentProducts.forEach(product => {
        if (product.stock !== '0' && product.rentTimeSelect) {
          newAmount += product.rentTimeSelect.price * product.quantity;
        }
      });
      localStorage.setItem('rentFee', newAmount);
    }
    setTotalAmount(newAmount);
  }, [buyProducts, rentProducts, activeTab]);

  const handleQuantityChange = async (product, action) => {
    if (action === 'decrease' && product.quantity === 1) {
      setTargetItem(product);
      setDeleteModalOpen(true);
      return;
    }

    if (action === 'increase' && product.quantity >= product.stock) {
      setTargetItem(product);
      setStockModalOpen(true);
      return;
    }

    try {
      await dispatch(updateCartItems({
        type: activeTab.toLowerCase(),
        id: product.id,
        type: product.type,
        category: product.is,
        action,
        isRent: activeTab === 'Rent'
      }));
    } catch (error) {
      console.error('Error updating quantity:', error);
      if (error.message === 'Authentication required') {
        history.push('/login');
      }
    }
  };

  const handleDelete = (product) => {
    setTargetItem(product);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!targetItem) return;
    
    try {
      await dispatch(updateCartItems({
        type: activeTab.toLowerCase(),
        id: targetItem.id,
        type: targetItem.type,
        category: targetItem.is,
        action: 'delete',
        isRent: activeTab === 'Rent'
      }));
      setDeleteModalOpen(false);
      setTargetItem(null);
    } catch (error) {
      console.error('Error deleting item:', error);
      if (error.message === 'Authentication required') {
        history.push('/login');
      }
    }
  };

  const handleCheckout = async () => {
    try {
      const userToken = localStorage.getItem('token');
      if (!userToken) {
        history.push('/login');
        return;
      }
      
      // Determine order type based on active tab
      const orderType = activeTab.toLowerCase();
      
      // Prepare checkout data
      const checkoutData = {
        orderType,
        // You'll need to update this to include the selected address
        // This would typically come from a form or state
        shippingAddressId: null, // Replace with actual address ID
        billingAddressId: null,  // Replace with actual address ID
        paymentMethodId: null,   // Replace with actual payment method
        // Optional fields
        couponCode: null,
        notes: null
      };
      
      // Show loading indicator
      setIsCheckingOut(true);
      
      // Call the checkout endpoint
      const response = await dispatch(checkoutCart(checkoutData)).unwrap();
      
      // Store order ID in localStorage and navigate to confirmation
      if (response && response.order) {
        localStorage.setItem('orderId', response.order.id);
        history.push('/checkout?orderid=' + response.order.id);
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      // You can set an error state here to display to the user
      setCheckoutError(error.message || 'An error occurred during checkout');
    } finally {
      setIsCheckingOut(false);
    }
  };

  if (loading) {
    return (
     <LoadingSpinner/>
    );
  }

  if (!localStorage.getItem('token') || localStorage.getItem('login') !== 'true') {
    return (
      <div className="min-h-screen flex flex-col gradient_limeade items-center justify-center bg-cover bg-center text-center">
        <h2 className="text-2xl font-bold mb-4">You Should Login First</h2>
        <button 
          onClick={() => history.push('/login')}
          className="px-6 py-2 bg-brand-teal text-white rounded-md hover:bg-brand-teal-dark transition-colors"
        >
          Login
        </button>
      </div>
    );
  }

  const productsLength = buyProducts.length + rentProducts.length;

  if (!productsLength) {
    return (
      <div className="min-h-screen flex flex-col gradient_limeade items-center justify-center bg-cover bg-center text-center">
        <h2 className="text-2xl font-bold mb-4">You Don't Have Anything in Your Cart</h2>
        <button 
          onClick={() => history.push('/browse')}
          className="px-6 py-2 bg-brand-teal text-white rounded-md hover:bg-brand-teal-dark transition-colors"
        >
          Start Shopping
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {!addCartSuccess && (
        <div className="mb-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          <h4 className="font-bold">Failure</h4>
          <p>Something Went Wrong</p>
        </div>
      )}

      <div className="mb-8">
        <div className="flex items-center mb-6">
          <svg className="h-6 w-6 text-brand-teal mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
          </svg>
          <h2 className="text-2xl font-bold">Shopping Cart</h2>
        </div>

        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              className={`${
                activeTab === 'Buy'
                  ? 'border-brand-teal text-brand-teal'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium`}
              onClick={() => setActiveTab('Buy')}
            >
              Buy
              <span className="ml-2 py-0.5 px-2.5 text-xs font-medium bg-brand-teal/10 text-brand-teal rounded-full">
                {buyProducts.length}
              </span>
            </button>

            <button
              className={`${
                activeTab === 'Rent'
                  ? 'border-brand-teal text-brand-teal'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium`}
              onClick={() => setActiveTab('Rent')}
            >
              Rent
              <span className="ml-2 py-0.5 px-2.5 text-xs font-medium bg-brand-teal/10 text-brand-teal rounded-full">
                {rentProducts.length}
              </span>
            </button>
          </nav>
        </div>

        <div className="mt-8">
          {((activeTab === 'Buy' && buyProducts.length === 0) || 
            (activeTab === 'Rent' && rentProducts.length === 0)) ? (
            <div className="min-h-[50vh] flex flex-col items-center justify-center text-center">
              <h3 className="text-xl font-medium mb-4">
                You Don't Have Anything to {activeTab}
              </h3>
              <button 
                onClick={() => history.push('/browse')}
                className="px-6 py-2 bg-brand-teal text-white rounded-md hover:bg-brand-teal-dark transition-colors"
              >
                Start Shopping
              </button>
            </div>
          ) : (
            <>
              {Object.entries(activeTab === 'Buy' ? groupedBuyProducts : groupedRentProducts)
                .map(([merchantId, group]) => {
                  const merchant = group.products[0]?.users_permissions_user;
                  const merchantName = merchant?.username || 'Unknown';
                  const merchantInfo = merchant?.merchantInfo || {};
                  const storeName = merchantInfo.storeName || merchantName;
                  const initials = storeName.split(' ').map(word => word[0]).join('').toUpperCase();

                  return (
                    <div key={merchantId} className="bg-white rounded-lg shadow-sm mb-8 overflow-hidden">
                      {/* Merchant Header */}
                      <div className="p-6 border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center bg-brand-teal text-white">
                            {merchant?.headPortrait?.[0]?.url ? (
                              <img 
                                src={merchant.headPortrait[0].url}
                                alt={storeName}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <span className="text-lg font-bold">{initials}</span>
                            )}
                          </div>
                          <div className="ml-4">
                            <h3 className="text-lg font-medium">{storeName}</h3>
                            {merchantInfo.storeDetail?.description && (
                              <p className="text-sm text-gray-500">
                                {merchantInfo.storeDetail.description}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Products */}
                      <div className="divide-y divide-gray-200">
                        {group.products.map(product => {
                          const isOutOfStock = product.stock === '0';
                          return (
                            <div 
                              key={product.id} 
                              className={`p-6 relative ${isOutOfStock ? 'opacity-50' : ''}`}
                            >
                              {isOutOfStock && (
                                <div className="absolute inset-0 bg-white/80 flex flex-col items-center justify-center">
                                  <p className="text-gray-500 font-medium mb-2">
                                    Sorry, this item is out of stock
                                  </p>
                                  <button
                                    onClick={() => handleDelete(product)}
                                    className="text-gray-400 hover:text-gray-500"
                                  >
                                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                  </button>
                                </div>
                              )}

                              <div className="flex items-start space-x-6">
                                <div className="flex-shrink-0 w-24 h-24">
                                  <img
                                    src={product.photos?.[0]?.url}
                                    alt={product.name}
                                    className="w-full h-full object-cover rounded"
                                  />
                                </div>

                                <div className="flex-1 min-w-0">
                                  <h4 className="text-lg font-medium">{product.name}</h4>
                                  
                                  {/* Display product options */}
                                  {product.productOptions && product.productOptions.map((option, idx) => (
                                    <p key={idx} className="mt-1 text-sm text-gray-500">
                                      {option.customOptionName || option.optionType}: {option.optionValue}
                                      {option.priceModifier > 0 && ` (+$${option.priceModifier})`}
                                      {option.priceModifier < 0 && ` (-$${Math.abs(option.priceModifier)})`}
                                    </p>
                                  ))}
                                  
                                  {/* Fallback for old structure */}
                                  {!product.productOptions && (
                                    <>
                                      <p className="mt-1 text-sm text-gray-500">
                                        Color: {Array.isArray(product.colors) ? product.colors[0]?.name : product.color}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        Size: {Array.isArray(product.sizes) ? product.sizes[0]?.name : product.size}
                                      </p>
                                    </>
                                  )}
                                  
                                  {/* Display custom note if available */}
                                  {product.customNote && (
                                    <p className="mt-1 text-sm italic text-gray-500">
                                      Note: {product.customNote}
                                    </p>
                                  )}
                                </div>

                                <div className="flex items-center space-x-4">
                                  <div className="flex items-center space-x-2">
                                    <button
                                      onClick={() => handleQuantityChange(product, 'decrease')}
                                      className="p-1 rounded-full hover:bg-gray-100"
                                    >
                                      <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                      </svg>
                                    </button>
                                    <span className="text-gray-700">{product.quantity}</span>
                                    <button
                                      onClick={() => handleQuantityChange(product, 'increase')}
                                      className="p-1 rounded-full hover:bg-gray-100"
                                    >
                                      <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                      </svg>
                                    </button>
                                  </div>

                                  <div className="text-right">
                                    {activeTab === 'Buy' ? (
                                      <p className="text-lg font-medium">${product.price}</p>
                                    ) : (
                                      <div>
                                        <p className="text-lg font-medium">
                                          ${(product.rentalPeriod?.price || 0)}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {product.rentalPeriod ? (
                                            <>
                                              {product.rentalPeriod.number || 1} {product.rentalPeriod.option || 'day'}
                                              {(product.rentalPeriod.number || 1) > 1 ? 's' : ''}
                                            </>
                                          ) : (
                                            'Rental period not specified'
                                          )}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* Merchant Subtotal */}
                      <div className="p-6 bg-gray-50">
                        <div className="flex justify-between">
                          <span className="font-medium">Subtotal</span>
                          <span className="font-medium">
                            ${group.products.reduce((sum, item) => 
                              item.stock !== '0' ? 
                                sum + (activeTab === 'Buy' ? 
                                  item.price * item.quantity : 
                                  (item.rentTimeSelect?.price || 0) * item.quantity
                                ) : sum, 0
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* Cart Total */}
              <div className="mt-8">
                <div className="flex justify-between items-center mb-6">
                  <span className="text-2xl font-bold">Total</span>
                  <span className="text-2xl font-bold">${totalAmount.toFixed(2)}</span>
                </div>

                {checkoutError && (
                  <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                    <h4 className="font-bold">Checkout Error</h4>
                    <p>{checkoutError}</p>
                  </div>
                )}

                <div className="flex justify-center">
                  <button
                    onClick={handleCheckout}
                    disabled={isCheckingOut || updating}
                    className={`px-8 py-3 bg-brand-teal text-white rounded-md transition-colors ${
                      isCheckingOut || updating 
                        ? 'opacity-70 cursor-not-allowed' 
                        : 'hover:bg-brand-teal-dark'
                    }`}
                  >
                    {isCheckingOut ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </span>
                    ) : (
                      'Check Out'
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium mb-2">Delete Item</h3>
                <p>Are you sure you want to remove this item from your cart?</p>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleConfirmDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setDeleteModalOpen(false);
                    setTargetItem(null);
                  }}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Stock Limit Modal */}
      {stockModalOpen && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg font-medium mb-2">Stock Limit Reached</h3>
                <p>Sorry, there is not enough stock available for this item.</p>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6">
                <button
                  onClick={() => {
                    setStockModalOpen(false);
                    setTargetItem(null);
                  }}
                  className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal sm:w-auto sm:text-sm"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage; 