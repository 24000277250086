/**
 * Utility functions for address validation
 */
import { 
  getCountryNameByCode,
  isStateRequired, 
  usesPostalCode, 
  getPostalCodeFormat 
} from './countryUtils';

/**
 * Basic address validation
 * @param {Object} address - Address object to validate
 * @returns {Object} Validation result with errors
 */
export const validateAddress = (address) => {
  const errors = {};
  
  // Always require street and city
  if (!address.street) errors.street = 'Street address is required';
  if (!address.city) errors.city = 'City is required';
  
  // Country must be selected
  if (!address.country) {
    errors.country = 'Country is required';
  } else {
    // State/Region validation based on country
    if (isStateRequired(address.country) && !address.state) {
      errors.state = 'State/Region is required';
    }
    
    // Postal code validation based on country
    if (usesPostalCode(address.country)) {
      if (!address.postalCode) {
        errors.postalCode = 'Postal code is required';
      } else {
        // Additional format validation for certain countries
        const postalFormat = getPostalCodeFormat(address.country);
        if (postalFormat && !postalFormat.pattern.test(address.postalCode)) {
          errors.postalCode = postalFormat.message;
        }
      }
    }
  }
  
  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};

/**
 * Validate US postal code format
 * @param {string} postalCode - Postal code to validate
 * @returns {boolean} Whether the postal code is valid
 */
export const validateUSPostalCode = (postalCode) => {
  const regex = /^\d{5}(-\d{4})?$/;
  return regex.test(postalCode);
};

/**
 * Check if an address has all required fields
 * @param {Object} address - Address to check
 * @returns {boolean} Whether the address is complete
 */
export const isAddressComplete = (address) => {
  if (!address) return false;
  
  // Basic required fields
  const hasBasicFields = address.street && address.city && address.country;
  if (!hasBasicFields) return false;
  
  // Check additional fields based on country
  if (isStateRequired(address.country) && !address.state) {
    return false;
  }
  
  if (usesPostalCode(address.country) && !address.postalCode) {
    return false;
  }
  
  return true;
};

/**
 * Check if an address has geolocation data
 * @param {Object} address - Address to check
 * @returns {boolean} Whether the address has coordinates
 */
export const hasGeolocation = (address) => {
  return (
    address &&
    typeof address.latitude === 'number' &&
    typeof address.longitude === 'number'
  );
};

/**
 * Compare two addresses to determine if they are the same
 * @param {Object} address1 - First address
 * @param {Object} address2 - Second address
 * @returns {boolean} Whether the addresses are the same
 */
export const areAddressesSame = (address1, address2) => {
  if (!address1 || !address2) return false;
  
  // If both have IDs, compare IDs
  if (address1.id && address2.id) {
    return address1.id === address2.id;
  }
  
  // Otherwise compare core fields
  return (
    address1.street === address2.street &&
    address1.city === address2.city &&
    address1.country === address2.country &&
    (address1.state || '') === (address2.state || '') &&
    (address1.postalCode || '') === (address2.postalCode || '')
  );
}; 