import React, { useRef, useState } from 'react';
import { useUpdateAvatarMutation } from '../../redux/apis/endpoints/profileApi';
import { PencilIcon } from '@heroicons/react/24/outline';

const ProfilePhoto = ({ user, isCurrentUser = true }) => {
  const fileInputRef = useRef(null);
  const [updateAvatar, { isLoading }] = useUpdateAvatarMutation();
  const [uploadError, setUploadError] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploadError(null);
      
      // Upload and update the profile photo
      const result = await updateAvatar(file);
      
      if (result.error) {
        throw new Error(result.error.message || 'Failed to update profile picture');
      }
    } catch (error) {
      setUploadError(error.message || 'Failed to update profile picture');
    }
  };

  return (
    <div className="absolute -top-16 left-6">
      <div className="relative">
        {user?.profilePic?.length > 0 && user.profilePic[0]?.url ? (
          <img
            src={user.profilePic[0].url}
            alt={user.username}
            className="w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover"
          />
        ) : (
          <div className="w-32 h-32 rounded-full border-4 border-white shadow-lg bg-brand-teal flex items-center justify-center text-white text-4xl font-bold">
            {user?.username?.[0]?.toUpperCase() || 'U'}
          </div>
        )}
        
        {/* Edit button - only shown if it's the current user's profile */}
        {isCurrentUser && (
          <button 
            className="absolute bottom-1 right-1 rounded-full bg-brand-orange p-1.5 shadow-md hover:bg-brand-orange/90 focus:outline-none focus:ring-2 focus:ring-brand-orange transition-colors"
            onClick={() => fileInputRef.current?.click()}
            disabled={isLoading}
            title="Change profile picture"
          >
            {isLoading ? (
              <div className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
            ) : (
              <PencilIcon className="h-4 w-4 text-white" />
            )}
          </button>
        )}
        
        {/* Error message */}
        {uploadError && (
          <div className="absolute -bottom-10 right-0 bg-red-100 text-red-700 px-3 py-1 rounded-md text-sm whitespace-nowrap max-w-md">
            {uploadError}
          </div>
        )}
        
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default ProfilePhoto; 