import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../../redux/slices/listingSlice';
import FormInput from '../../ui/FormInput';
import Dropdown from '../../ui/Dropdown';

const BasicInfoSection = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);

  // Debug what's coming from Redux
  useEffect(() => {
    console.log('BasicInfoSection - formData:', formData);
    console.log('BasicInfoSection - name field:', formData.name);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    dispatch(updateFormData({
      [name]: type === 'number' ? Number(value) : value
    }));
  };

  return (
    <div className="space-y-4 max-w-[1400px] mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-heading font-semibold text-gray-800 mb-4">Basic Information</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormInput
          label="Name"
          name="name"
          type="text"
          value={formData?.name || ''}
          onChange={handleInputChange}
          error={fieldErrors?.name}
          required
          placeholder="Enter product name"
        />

        <Dropdown
          title="Product Type"
          value={formData?.productType || ''}
          onChange={(value) => handleInputChange({ target: { name: 'productType', value }})}
          options={[
            { value: 'designer', label: 'Original Design' },
            { value: 'sharing', label: 'Closet Sharing' },
            { value: 'virtual', label: 'Virtual Fashion' },
            { value: 'others', label: 'Others' }
          ]}
          error={fieldErrors?.productType}
          required
          placeholder="Select product type"
          className="w-full"
        />
      </div>

      <FormInput
        label="Description"
        name="description"
        value={formData?.description || ''}
        onChange={handleInputChange}
        error={fieldErrors?.description}
        required
        as="textarea"
        rows={4}
        placeholder="Enter product description"
      />
    </div>
  );
};

export default BasicInfoSection; 