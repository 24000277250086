import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDeleteModalOpen,
  clearSelectedProducts
} from '../../../redux/slices/listingSlice'
import { useBulkDeleteProductsMutation } from '../../../redux/apis/endpoints/listingApi'
import { handleResponse } from '../../../redux/slices/responseSlice'
import Modal from '../../ui/Modal'
import Button from '../../ui/Button'

const DeleteModal = ({ isOpen, selectedProducts, itemCount = 1 }) => {
  const dispatch = useDispatch()
  const { products } = useSelector(state => state.filter)
  const [bulkDelete, { isLoading }] = useBulkDeleteProductsMutation()

  const handleClose = () => {
    if (isLoading) return
    dispatch(setDeleteModalOpen(false))
  }

  const handleDelete = async () => {
    if (isLoading || itemCount === 0) return

    try {
      dispatch(setDeleteModalOpen(false))
      // Optimistically update UI first
      const updatedProducts = products.filter(
        product => !selectedProducts.includes(product.id)
      )
      dispatch({ type: 'filter/optimisticUpdate', payload: updatedProducts })

      // Ensure selectedProducts is always an array before passing to bulkDelete
      const productIdsToDelete = Array.isArray(selectedProducts) ? selectedProducts : [selectedProducts];
      
      const result = await bulkDelete(productIdsToDelete).unwrap()
      
      dispatch(
        handleResponse(result, {
          successMessage: 'Successfully deleted selected products',
          errorMessage: 'Failed to delete products',
          onSuccess: () => {
            dispatch(clearSelectedProducts())
          }
        })
      )
    } catch (error) {
      // Revert optimistic update on error
      dispatch(
        handleResponse(error, {
          errorMessage: 'Failed to delete products'
        })
      )
    }
  }

  const actions = (
    <>
      <Button
        onClick={handleDelete}
        disabled={isLoading}
        isLoading={isLoading}
        variant='danger'
        className="w-full sm:w-auto mb-2 sm:mb-0"
      >
        Delete
      </Button>
      <Button
        onClick={handleClose}
        disabled={isLoading}
        variant='secondary'
        className="w-full sm:w-auto"
      >
        Cancel
      </Button>
    </>
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title='Delete Confirmation'
      actions={actions}
    >
      <p className='text-sm text-gray-500'>
        Are you sure you want to delete{' '}
        {itemCount === 1 ? 'this listing' : `these ${itemCount} listings`}? This
        action cannot be undone.
      </p>
    </Modal>
  )
}

export default DeleteModal
