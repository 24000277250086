import React, { useState } from 'react';
import Badge from '../../../components/ui/Badge';
import Like from '../../../components/ui/Like';
import TabButton from '../../../components/ui/TabButton';
import BuyActions from './tabs/BuyActions';
import RentActions from './tabs/RentActions';
import SwapActions from './tabs/SwapActions';

const ProductActions = ({ 
  product,
  onAddToCart,
  className = ''
}) => {
  const [activeTab, setActiveTab] = useState(() => {
    if (product?.forSell) return 'buy';
    if (product?.forRent) return 'rent';
    if (product?.openToSwap) return 'swap';
    return 'buy';
  });

  if (!product) return null;

  const {
    name,
    forSell,
    forRent,
    openToSwap,
    price = 0,
    stock = 0,
    productType
  } = product;

  const isVirtual = productType === 'virtual';

  // Group badges by type
  const typeBadges = [
    productType === 'designer' && 'designer',
    productType === 'virtual' && 'virtual'
  ].filter(Boolean);

  return (
    <div className={`space-y-8 ${className}`}>
      {/* Name, Badges, and Like Button */}
      <div className="space-y-3">
        <div className="flex items-start justify-between">
          <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">{name}</h1>
          <Like 
            productId={product.id}
            size="md"
            showCount={true}
          />
        </div>
        {typeBadges.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {typeBadges.map(type => (
              <Badge key={type} type={type} />
            ))}
          </div>
        )}
      </div>

      {/* Price Display */}
      <div className="space-y-2">
        {forSell && activeTab === 'buy' && (
          <div className="flex items-baseline justify-between">
            <div className="flex items-baseline gap-2">
              <span className="text-2xl font-bold text-gray-900">
                ${price.toFixed(2)}
              </span>
              {!isVirtual && <span className="text-sm text-gray-500">+ shipping</span>}
            </div>
          </div>
        )}
      </div>

      {/* Transaction Type Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          {forSell && (
            <TabButton
              onClick={() => setActiveTab('buy')}
              isActive={activeTab === 'buy'}
            >
              Buy
            </TabButton>
          )}
          {forRent && (
            <TabButton
              onClick={() => setActiveTab('rent')}
              isActive={activeTab === 'rent'}
            >
              Rent
            </TabButton>
          )}
          {openToSwap && (
            <TabButton
              onClick={() => setActiveTab('swap')}
              isActive={activeTab === 'swap'}
            >
              Swap
            </TabButton>
          )}
        </nav>
      </div>

      {/* Tab Content */}
      <div>
        {activeTab === 'buy' && forSell && (
          <BuyActions 
            product={product} 
            onAddToCart={onAddToCart} 
          />
        )}

        {activeTab === 'rent' && forRent && (
          <RentActions 
            product={product} 
            onAddToCart={onAddToCart} 
          />
        )}

        {activeTab === 'swap' && openToSwap && (
          <SwapActions 
            product={product} 
            onAddToCart={onAddToCart} 
          />
        )}
      </div>
    </div>
  );
};

export default ProductActions; 