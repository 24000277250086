import { useState } from 'react';
import FormInput from '../../components/ui/FormInput';
import Button from '../../components/ui/Button';
import { useUpdateProfileMutation } from '../../redux/apis/endpoints/profileApi';

const Contact = ({ user, formData, setFormData, userLang, refetchUser }) => {
  const [updateProfile, { isLoading: isSubmitting }] = useUpdateProfileMutation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle account information update
  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const userData = {
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phonenumber: formData.phonenumber
      };

      await updateProfile(userData).unwrap();
      setSuccessMessage(userLang === 'zh-CN' ? '联系信息已更新' : 'Contact information successfully updated');
      refetchUser();
    } catch (error) {
      console.error("Contact update error:", error);
      setErrorMessage(
        error.data?.message || error.data?.error?.message || 
        (userLang === 'zh-CN' ? '更新失败' : 'Failed to update contact information')
      );
    }
  };

  return (
    <>
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
          {errorMessage}
        </div>
      )}

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {userLang === 'zh-CN' ? '联系信息' : 'Contact Information'}
        </h3>
        <form onSubmit={handleAccountUpdate} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <FormInput
              label={userLang === 'zh-CN' ? '名' : 'First Name'}
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
            />
            <FormInput
              label={userLang === 'zh-CN' ? '姓' : 'Last Name'}
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
            />
            <FormInput
              type="email"
              label={userLang === 'zh-CN' ? '邮箱' : 'Email'}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <FormInput
              type="tel"
              label={userLang === 'zh-CN' ? '电话' : 'Phone'}
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              fullWidth={false}
              variant="teal"
              className="px-6"
            >
              {userLang === 'zh-CN' ? '保存' : 'Save Changes'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact; 