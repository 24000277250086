import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import authReducer from './slices/authSlice'
import filterReducer from './slices/filterSlice'
import listingReducer from './slices/listingSlice'
import responseReducer from './slices/responseSlice'
import reviewReducer from './slices/reviewSlice'
import likesReducer from './slices/likeSlice'
import cartReducer from './slices/cartSlice'
import addressReducer from './slices/addressSlice'
import locationReducer from './slices/locationSlice'

import {
  enhancedAuthApi,
  enhancedProductApi,
  enhancedListingApi,
  enhancedReviewApi,
  enhancedProfileApi,
  enhancedAddressApi,
  enhancedCartApi
} from './apis'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    filter: filterReducer,
    listing: listingReducer,
    response: responseReducer,
    review: reviewReducer,
    likes: likesReducer,
    cart: cartReducer,
    address: addressReducer,
    location: locationReducer,
    [enhancedAuthApi.reducerPath]: enhancedAuthApi.reducer,
    [enhancedProductApi.reducerPath]: enhancedProductApi.reducer,
    [enhancedListingApi.reducerPath]: enhancedListingApi.reducer,
    [enhancedReviewApi.reducerPath]: enhancedReviewApi.reducer,
    [enhancedProfileApi.reducerPath]: enhancedProfileApi.reducer,
    [enhancedAddressApi.reducerPath]: enhancedAddressApi.reducer,
    [enhancedCartApi.reducerPath]: enhancedCartApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        ignoredActionPaths: ['meta.baseQueryMeta.request', 'meta.baseQueryMeta.response'],
        ignoredPaths: [
          'auth.user',
          `${enhancedAuthApi.reducerPath}.queries`,
          `${enhancedProductApi.reducerPath}.queries`,
          `${enhancedListingApi.reducerPath}.queries`,
          `${enhancedReviewApi.reducerPath}.queries`,
          `${enhancedProfileApi.reducerPath}.queries`,
          `${enhancedAddressApi.reducerPath}.queries`,
          `${enhancedCartApi.reducerPath}.queries`,
        ],
      },
    }).concat(
      enhancedAuthApi.middleware,
      enhancedProductApi.middleware,
      enhancedListingApi.middleware,
      enhancedReviewApi.middleware,
      enhancedProfileApi.middleware,
      enhancedAddressApi.middleware,
      enhancedCartApi.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

export default store