// Common sizes for product listing
export const COMMON_SIZES = [
  // Letter Sizes
  { value: 'XXS', label: 'XXS', group: 'Letter Sizes' },
  { value: 'XS', label: 'XS', group: 'Letter Sizes' },
  { value: 'S', label: 'S', group: 'Letter Sizes' },
  { value: 'M', label: 'M', group: 'Letter Sizes' },
  { value: 'L', label: 'L', group: 'Letter Sizes' },
  { value: 'XL', label: 'XL', group: 'Letter Sizes' },
  { value: 'XXL', label: 'XXL', group: 'Letter Sizes' },
  // Numerical Sizes
  { value: '2', label: 'US 2 / EU 32', group: 'Numerical Sizes' },
  { value: '4', label: 'US 4 / EU 34', group: 'Numerical Sizes' },
  { value: '6', label: 'US 6 / EU 36', group: 'Numerical Sizes' },
  { value: '8', label: 'US 8 / EU 38', group: 'Numerical Sizes' },
  { value: '10', label: 'US 10 / EU 40', group: 'Numerical Sizes' },
  { value: '12', label: 'US 12 / EU 42', group: 'Numerical Sizes' },
  { value: '14', label: 'US 14 / EU 44', group: 'Numerical Sizes' },
  { value: '16', label: 'US 16 / EU 46', group: 'Numerical Sizes' },
  // Special Sizes
  { value: 'onesize', label: 'One Size', group: 'Special Sizes' },
  { value: 'custom', label: 'Custom Size', group: 'Special Sizes' },
  { value: 'other', label: 'Other Size', group: 'Special Sizes' }
]; 