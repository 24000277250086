import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFilter from '../../useFilter';
import TriStateCheckbox from '../../../../ui/TriStateCheckbox';
import { 
  detectUserLocationWithPlaceName,
  selectUserLocation, 
  selectLocationError,
  clearLocationError,
  locationDetectionFailed
} from '../../../../../redux/slices/locationSlice';

/**
 * Local pickup checkbox component for filtering products
 * - UI behavior: Not checked by default
 * - When checked: Shows indeterminate state until location is obtained
 * - URL sync: Sets localPickup={enabled:true/false} in URL
 * - Location: Uses URL location data if available before requesting new location
 */
const PickUpCheckbox = ({ className = '' }) => {
  const dispatch = useDispatch();
  const { filters, handleFilterChange, handleLocalPickupChange } = useFilter();
  const userLocation = useSelector(selectUserLocation);
  const locationError = useSelector(selectLocationError);
  const [isRequestingLocation, setIsRequestingLocation] = useState(false);
  
  // Use refs to track previous values and avoid unnecessary updates
  const prevLocationRef = useRef(null);
  const locationUpdateAppliedRef = useRef(false);

  // Check if we have a valid location for filtering
  const hasValidLocation = Boolean(
    (filters.location?.latitude && filters.location?.longitude) ||
    (userLocation?.coordinates?.latitude && userLocation?.coordinates?.longitude)
  );

  // Determine checkbox state
  // Three states:
  // 1. Checked: Local pickup is enabled AND we have a valid location
  // 2. Indeterminate: Either requesting location OR enabled but no location yet
  // 3. Unchecked: Local pickup is not enabled
  const isChecked = filters.localPickup?.enabled === true && hasValidLocation && !locationError;
  const isIndeterminate = isRequestingLocation || 
    (filters.localPickup?.enabled === true && !hasValidLocation) ||
    (filters.localPickup?.enabled === true && locationError);

  // Effect to handle location detection completion
  useEffect(() => {
    // Only proceed if we're in requesting state
    if (!isRequestingLocation) return;
    
    // Compare with previous location to avoid circular updates
    const locationKey = userLocation?.coordinates ? 
      `${userLocation.coordinates.latitude},${userLocation.coordinates.longitude}` : 
      null;
    
    const prevLocationKey = prevLocationRef.current;
    
    // Only update if we have a new valid location that's different from previous
    if (hasValidLocation && 
        !locationError && 
        locationKey !== prevLocationKey && 
        !locationUpdateAppliedRef.current) {
      
      // Mark that we're applying the update to avoid multiple updates
      locationUpdateAppliedRef.current = true;
      
      // Store current location to compare later
      prevLocationRef.current = locationKey;
      
      // Update only the location data
      handleFilterChange({
        location: userLocation?.coordinates ? {
          latitude: userLocation.coordinates.latitude,
          longitude: userLocation.coordinates.longitude,
        } : filters.location
      });
      
      // Clear requesting state
      setIsRequestingLocation(false);
    }
  }, [userLocation, locationError, hasValidLocation]);

  // Reset the update flag when requesting state changes
  useEffect(() => {
    if (!isRequestingLocation) {
      locationUpdateAppliedRef.current = false;
    }
  }, [isRequestingLocation]);

  // Handle pickup checkbox change
  const handlePickupChange = async (checked) => {
    if (checked) {
      // First, immediately show the indeterminate state
      setIsRequestingLocation(true);
      
      // Update the pickup filter state using the specialized handler
      handleLocalPickupChange(true, filters.localPickup?.distance || 5);
      
      // Check if we already have location data in the URL or Redux
      if (hasValidLocation) {
        console.log('Using existing location data:', userLocation?.coordinates || filters.location);
        
        // Update only the location if needed
        if (!filters.location?.latitude || !filters.location?.longitude) {
          handleFilterChange({
            location: userLocation?.coordinates ? {
              latitude: userLocation.coordinates.latitude,
              longitude: userLocation.coordinates.longitude,
            } : filters.location
          });
        }
        
        // Clear requesting state since we already have location
        setIsRequestingLocation(false);
      } else {
        // Clear any previous location errors
        dispatch(clearLocationError());
        
        try {
          // Request location - this will update Redux state
          const locationAction = await dispatch(detectUserLocationWithPlaceName());
          
          if (locationAction?.payload) {
            const locationData = locationAction.payload;
            prevLocationRef.current = `${locationData.coordinates.latitude},${locationData.coordinates.longitude}`;
            
            // Update only the location data
            handleFilterChange({
              location: {
                latitude: locationData.coordinates.latitude,
                longitude: locationData.coordinates.longitude,
              }
            });
            
            // Don't set isRequestingLocation to false directly here
            // The effect will handle this
          } else if (locationAction?.error) {
            // Properly handle error from redux action
            console.error('Error in location detection:', locationAction.error.message);
            dispatch(locationDetectionFailed(locationAction.error.message));
            setIsRequestingLocation(false);
          }
        } catch (error) {
          // Log the error details and pass it to the Redux store
          console.error('Error getting location:', error);
          dispatch(locationDetectionFailed(error.message || 'Unknown location error'));
          setIsRequestingLocation(false);
        }
      } 
    } else {
      // Disable the filter using the specialized handler
      handleLocalPickupChange(false);
    }
  };

  return (
    <TriStateCheckbox
      label="Local Pickup"
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={handlePickupChange}
      disabled={isRequestingLocation}
      className={className}
    />
  );
};

export default PickUpCheckbox; 