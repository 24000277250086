import React from 'react';
import { Parallax } from 'react-parallax';
import { 
  ArrowPathIcon, 
  ShoppingBagIcon, 
  TruckIcon 
} from '@heroicons/react/24/outline';

const HowItWorksSection = ({ userLang, onBrowseClick }) => {
  // Translation content
  const content = {
    title: userLang === 'zh-CN' ? '共享衣橱是怎么运作的' : 'How it Works for Users',
    steps: [
      {
        icon: <ShoppingBagIcon className="h-16 w-16 text-brand-teal mx-auto" />,
        title: userLang === 'zh-CN' ? '浏览' : 'Browse',
        description: userLang === 'zh-CN' 
          ? '选择购买租借或者交换喜欢的衣物' 
          : 'Choose clothing to buy, rent, or trade'
      },
      {
        icon: <TruckIcon className="h-16 w-16 text-brand-teal mx-auto" />,
        title: userLang === 'zh-CN' ? '穿衣' : 'Wear',
        description: userLang === 'zh-CN' 
          ? '从附近取衣或者送货上门' 
          : 'Pick up items in person or get delivered from owners/designers'
      },
      {
        icon: <ArrowPathIcon className="h-16 w-16 text-brand-teal mx-auto" />,
        title: userLang === 'zh-CN' ? '重复' : 'Repeat',
        description: userLang === 'zh-CN' 
          ? '循环这一过程，祝您愉快！' 
          : 'Enjoying Repeating this process'
      }
    ],
    cta: userLang === 'zh-CN' ? '寻找新风格' : 'Look for new style!'
  };

  return (
    <div className="relative">
      <Parallax
        bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727638068/redhead_b0b08dae63.svg"
        bgImageAlt="Group of diverse women in fashionable outfits"
        strength={100}
        bgImageStyle={{
          objectFit: 'cover',
          width: '100%',
          height: '100%'
        }}
        className="bg-brand-teal/20"
      >
        <div className="py-24 md:py-32 bg-black/30">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Section Header */}
            <h2 className="text-3xl md:text-4xl font-bold text-center text-white mb-12">
              {content.title}
            </h2>

            {/* Steps Card */}
            <div className="bg-white rounded-xl shadow-xl p-6 md:p-10 max-w-5xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {content.steps.map((step, index) => (
                  <div key={index} className="flex flex-col items-center text-center">
                    <div className="mb-4">
                      {step.icon}
                    </div>
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">
                      {step.title}
                    </h3>
                    <p className="text-gray-600">
                      {step.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* CTA Button */}
            <div className="flex justify-center mt-12">
              <button
                onClick={onBrowseClick}
                className="px-8 py-3 bg-brand-orange hover:bg-brand-orange-light text-white text-lg font-medium rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                {content.cta}
              </button>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default HowItWorksSection; 