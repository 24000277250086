import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './ProductGallery.css'; // Import custom styles

const ProductGallery = ({ 
  images = [], 
  isLoading = false,
  className = ''
}) => {
  // Transform images to format required by react-image-gallery
  const galleryImages = images.map(image => ({
    original: image.url,
    thumbnail: image.formats?.thumbnail?.url || image.url
  }));

  if (isLoading) {
    return (
      <div className={`animate-pulse ${className}`}>
        <div className="aspect-w-1 aspect-h-1 w-full">
          <div className="w-full h-full bg-gray-200 rounded-lg"></div>
        </div>
        <div className="mt-4 flex gap-2">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="w-20 h-20 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  if (!images?.length) {
    return (
      <div className={`${className} aspect-w-1 aspect-h-1 w-full bg-gray-100 rounded-lg flex items-center justify-center`}>
        <span className="text-gray-400">No images available</span>
      </div>
    );
  }

  return (
    <div className={`${className} preserve-aspect-ratio`}>
      <ImageGallery
        items={galleryImages}
        showPlayButton={false}
        showFullscreenButton={true}
        showNav={true}
        thumbnailPosition="bottom"
        useBrowserFullscreen={true}
        additionalClass="product-gallery"
      />
    </div>
  );
};

export default ProductGallery; 