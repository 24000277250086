import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ListingManagement from '../../../components/listings/ListingManagement';
import ListingFilter from '../../../components/cards/filter/ListingFilter';
import CardsContainer from '../../../components/cards/CardsContainer';
import ListingCard from '../../../components/cards/card/ListingCard';
import { useUrlChangeHandler } from '../../../hooks/useUrlChangeHandler';
import Button from '../../../components/ui/Button';
import { PlusIcon } from '@heroicons/react/24/outline';

const userLang = navigator.language || navigator.userLanguage;

const Listings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { 
    filters, 
    searchTerm, 
    sortBy, 
    offset,
    products,
    loading,
    error,
    totalResults,
    hasMore
  } = useSelector(state => state.filter);
  const { selectedProducts } = useSelector(state => state.listing);

  // Initial search
  // useEffect(() => {
  //   dispatch(searchWithAlgolia({ searchTerm, filters, sortBy, offset }));
  // }, [offset, location.pathname]);

  // Use the shared URL change handler with isChild=true since this is a child component
  // useUrlChangeHandler(true);

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {userLang === 'zh-CN' ? '商品列表' : 'My Listings'}
        </h2>
        <Button
          onClick={() => history.push('/listing/upload')}
          variant="primary"
          icon={<PlusIcon className="w-5 h-5" />}
        >
          <span className="hidden sm:inline">Upload Listing</span>
          <span className="sm:hidden">Upload</span>
        </Button>
      </div>

      {/* Management Section */}
      <div className="bg-white shadow-sm rounded-lg mb-6">
        <ListingManagement />
      </div>

      {/* Filter Section */}
      <div className="bg-white shadow-sm rounded-lg mb-6">
        <ListingFilter />
      </div>

      {/* Cards Section */}
      <div className="bg-white shadow-sm rounded-lg">
        <CardsContainer
          loading={loading}
          error={error}
          hasMore={hasMore}
          totalResults={totalResults}
          searchTerm={searchTerm}
        >
          {products.map(product => (
            <ListingCard 
              key={product.id} 
              product={product}
              isSelected={selectedProducts.includes(product.id)}
            />
          ))}
        </CardsContainer>
      </div>
    </div>
  );
};

export default Listings; 