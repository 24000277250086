import React from 'react';
import Section from '../Section';

const MapSection = () => {
  const userLang = navigator.language || navigator.userLanguage;

  return (
    <div className="relative">
      {/* Text Section */}
      <Section
        fullWidth
        minHeight="min-h-[40vh]"
        padding="py-16"
        bgColor="bg-[#F5F5DD]"
        title={userLang === 'zh-CN' ? '跳过二手店' : 'Skip Thrift Stores'}
        description={userLang === 'zh-CN' 
          ? '直接从时尚爱好者本地提货或送到你家' 
          : 'Pickup clothes in person, or get goods shipped to you'}
        alignment="center"
        className="text-gray-900"
      >
        <div className="mt-8 max-w-2xl mx-auto text-center">
          <p className="text-lg text-gray-600">
            {userLang === 'zh-CN' 
              ? '我们的平台让你可以直接与附近的时尚爱好者联系，省去中间环节' 
              : 'Our platform connects you directly with fashion enthusiasts nearby, eliminating the middleman'}
          </p>
        </div>
      </Section>
      
      {/* Map Section */}
      <Section
        fullWidth
        minHeight="h-screen"
        bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727638024/Locall_fa347948aa.svg"
        bgPosition="bg-center"
        bgOverlay="rgba(255, 255, 255, 0.1)"
        className="transform hover:scale-105 transition-transform duration-700"
      />
    </div>
  );
};

export default MapSection; 