import { useState, useEffect } from 'react';
import { Route, Switch, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MerchantHome from './MerchantHome';
import MerchantStore from './MerchantStore';
// import MerchantProduct from './MerchantProduct';
// import NewProduct from './NewProduct';
import EditProduct from './EditProduct';

const MerchantPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { userInfo } = useSelector(state => state.user);

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  const NavigationMenu = () => (
    <nav className="space-y-3">
      <Link 
        to="/merchantpage" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantpage' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  Home
      </Link>
      <Link 
        to="/merchantpage/store" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantpage/store' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Store
      </Link>
      <Link 
        to="/merchantpage/products" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantpage/products' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                  </svg>
                  Products
      </Link>

      <div className="py-6">
        <h3 className="px-6 text-sm font-semibold text-gray-500 uppercase tracking-wider">
                    Orders
                  </h3>
                </div>

      <Link 
        to="/merchantOrder" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantOrder' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                  All Orders
      </Link>
      <Link 
        to="/merchantOrder/ongoing" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantOrder/ongoing' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Ongoing
      </Link>
      <Link 
        to="/merchantOrder/past" 
        className={`flex items-center px-6 py-4 text-lg rounded-md ${
          location.pathname === '/merchantOrder/past' 
                      ? 'bg-brand-teal text-white' 
            : 'text-gray-700 hover:bg-gray-100'
                  }`}
                >
        <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                  </svg>
                  Past Orders
      </Link>
              </nav>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Desktop/Tablet Menu - Only visible on md and up */}
      <div className="hidden md:flex min-h-screen">
        <div className="w-80 bg-white shadow-lg sticky top-0 h-screen">
          <div className="h-full flex flex-col">
            <div className="p-6 border-b">
              <h2 className="text-2xl font-medium text-gray-900">Merchant Dashboard</h2>
            </div>
            <div className="flex-1 p-6 overflow-y-auto">
              <NavigationMenu />
            </div>
          </div>
        </div>

        {/* Main Content for Desktop/Tablet */}
        <div className="flex-1">
          <Switch>
            <Route exact path="/merchantpage" component={MerchantHome} />
            <Route exact path="/merchantpage/store" component={MerchantStore} />
            {/* <Route exact path="/merchantpage/products" component={MerchantProduct} /> */}
            {/* <Route exact path="/merchantpage/product/new" component={NewProduct} /> */}
            <Route exact path="/merchantpage/product/edit/:id" component={EditProduct} />
          </Switch>
        </div>
      </div>

      {/* Mobile View - Only visible below md */}
      <div className="md:hidden">
        {/* Mobile Menu Header */}
        <div className="bg-white shadow-sm sticky top-0 z-50">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="w-full px-6 py-4 flex items-center justify-between"
          >
            <h2 className="text-xl font-medium text-gray-900">Merchant Dashboard</h2>
            <svg 
              className={`w-6 h-6 text-gray-600 transform transition-transform duration-200 ${mobileMenuOpen ? 'rotate-180' : ''}`}
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Mobile Menu Dropdown */}
          <div 
            className={`border-t border-gray-200 overflow-hidden transition-all duration-300 ease-in-out ${
              mobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <div className="p-4 bg-white">
              <NavigationMenu />
            </div>
          </div>
        </div>

        {/* Mobile Main Content */}
        <div className="flex-1">
          <Switch>
            <Route exact path="/merchantpage" component={MerchantHome} />
            <Route exact path="/merchantpage/store" component={MerchantStore} />
            {/* <Route exact path="/merchantpage/products" component={MerchantProduct} /> */}
            {/* <Route exact path="/merchantpage/product/new" component={NewProduct} /> */}
            <Route exact path="/merchantpage/product/edit/:id" component={EditProduct} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MerchantPage;