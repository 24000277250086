import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formatAddress, isValidAddress } from './utils/addressUtils';
import { 
  useCreateAddressMutation, 
  useUpdateAddressMutation,
  useValidateAddressMutation
} from '../../redux/apis';
import { validateAddress } from './utils/validateAddress';
import { selectUserCountry, selectIsDetectingCountry, detectUserCountry } from '../../redux/slices/locationSlice';
import AddressInput from './components/AddressInput';
// import AddressPreview from './components/AddressPreview';

/**
 * AddressForm component for creating or editing addresses
 * 
 * @param {Object} props Component props
 * @param {Object} props.initialAddress Optional initial address data
 * @param {Function} props.onSuccess Callback function after successful save
 * @param {Function} props.onSave Optional save handler for backward compatibility
 * @param {Function} props.onCancel Optional cancel handler
 * @param {boolean} props.disableValidation Disable backend address validation
 * @param {string} props.userLang User language for localization
 * @param {boolean} props.isInline Whether this form is embedded in another component
 */
const AddressForm = ({
  initialAddress = null,
  onSuccess = () => {},
  onSave = null,
  onCancel = null,
  disableValidation = false,
  userLang = navigator.language || navigator.userLanguage,
  isInline = false
}) => {
  // State for form data
  const [address, setAddress] = useState(formatAddress(initialAddress || {}));
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  
  const dispatch = useDispatch();
  
  // Get country detection state from Redux
  const userCountry = useSelector(selectUserCountry);
  const isDetectingCountry = useSelector(selectIsDetectingCountry);
  
  const isEditMode = Boolean(initialAddress?.id);
  
  // API mutations
  const [createAddressMutation] = useCreateAddressMutation();
  const [updateAddressMutation] = useUpdateAddressMutation();
  const [validateAddressMutation] = useValidateAddressMutation();
  
  // Create a reference to store the actual callback
  const onSuccessRef = useRef(onSave || onSuccess);
  
  // Update the callback ref when props change
  useEffect(() => {
    onSuccessRef.current = onSave || onSuccess;
  }, [onSave, onSuccess]);
  
  // Ensure country detection has been triggered
  useEffect(() => {
    // Always trigger country detection when the component mounts
    // This ensures we have country data available across the app
    dispatch(detectUserCountry());
  }, [dispatch]);
  
  // Set default country from Redux when creating a new address
  useEffect(() => {
    // Only apply country from Redux for new addresses and when 
    // country is not already set in the form and a country has been detected
    if (!isEditMode && !address.country && userCountry?.code) {
      // No need to log this in production
      if (process.env.NODE_ENV !== 'production') {
        console.log(`Setting default country: ${userCountry.code}`);
      }
      handleAddressChange({ country: userCountry.code });
    }
  }, [userCountry, isEditMode, address.country]);
  
  // Update address if initialAddress changes
  useEffect(() => {
    if (initialAddress) {
      setAddress(formatAddress(initialAddress));
    }
  }, [initialAddress]);
  
  // Handle address change from AddressInput component
  const handleAddressChange = (newAddressData) => {
    setAddress(prev => {
      const updated = {
        ...prev,
        ...newAddressData
      };
      return updated;
    });
    
    // Clear any field-specific errors for the fields that changed
    const updatedErrors = { ...errors };
    Object.keys(newAddressData).forEach(key => {
      if (updatedErrors[key]) {
        delete updatedErrors[key];
      }
    });
    
    // Clear general error message if any field changes
    if (Object.keys(updatedErrors).length !== Object.keys(errors).length) {
      setErrors(updatedErrors);
      setErrorMessage('');
    }
  };
  
  // Set default address toggle
  const handleSetDefaultChange = (e) => {
    setAddress({ ...address, isDefault: e.target.checked });
  };
  
  // Form validation
  const validateForm = async () => {
    // Use validateAddress from validateAddress.js for consistent validation
    const validationResult = validateAddress(address);
    
    // Custom validations for additional fields
    const newErrors = {...validationResult.errors};
    
    if (!address.phoneNumber?.trim()) {
      newErrors.phoneNumber = userLang === 'zh-CN' ? '请输入电话号码' : 'Phone number is required';
    }
    
    const hasErrors = Object.keys(newErrors).length > 0;
    setErrors(newErrors);
    
    // If there are form validation errors, return early
    if (hasErrors) {
      return false;
    }
    
    // If validation is disabled or we already have coordinates, skip backend validation
    if (disableValidation || (address.latitude && address.longitude)) {
      return true;
    }
    
    // Backend address validation (geocoding)
    try {
      setIsValidating(true);
      
      // Format address data to ensure it has the correct structure
      const addressForValidation = {
        street: address.street || '',
        city: address.city || '',
        state: address.state || '',
        postalCode: address.postalCode || '',
        country: address.country || ''
      };
      
      // Check for empty required fields
      if (!addressForValidation.street || !addressForValidation.city) {
        setErrorMessage(userLang === 'zh-CN' ? '地址和城市为必填项' : 'Street and city are required');
        return false;
      }
      
      try {
        const response = await validateAddressMutation(addressForValidation).unwrap();
        
        if (response && response.isValid) {
          // Update the address with coordinates from validation
          setAddress({
            ...address,
            latitude: response.coordinates?.latitude || address.latitude,
            longitude: response.coordinates?.longitude || address.longitude,
            formattedAddress: response.formattedAddress
          });
          return true;
        } else {
          setErrorMessage(response.message || (userLang === 'zh-CN' ? '无法验证地址' : 'Could not validate address'));
          return false;
        }
      } catch (error) {
        console.error('Address validation error:', error);
        setErrorMessage(
          error.data?.message || 
          (userLang === 'zh-CN' ? '地址验证失败' : 'Address validation failed')
        );
        return false;
      }
    } catch (error) {
      console.error('Address validation error:', error);
      setErrorMessage(
        error.data?.message || 
        (userLang === 'zh-CN' ? '地址验证失败' : 'Address validation failed')
      );
      return false;
    } finally {
      setIsValidating(false);
    }
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    
    // Validate form
    const isValid = await validateForm();
    if (!isValid) return;
    
    // Prepare address data for API
    const addressData = {
      name: address.name || '',
      street: address.street || '',
      apartment: address.apartment || '',
      city: address.city || '',
      state: address.state || '',
      postalCode: address.postalCode || '',
      country: address.country || '',
      phoneNumber: address.phoneNumber || '',
      isDefault: Boolean(address.isDefault),
      // Include coordinates if we have them
      ...(address.latitude && address.longitude ? {
        latitude: address.latitude,
        longitude: address.longitude
      } : {})
    };
    
    setIsSaving(true);
    
    try {
      let savedAddress;
      
      if (isEditMode) {
        // Update existing address
        savedAddress = await updateAddressMutation({
          id: address.id,
          ...addressData // Spread addressData directly to avoid nesting
        }).unwrap();
        
        setSuccessMessage(userLang === 'zh-CN' ? '地址已更新' : 'Address updated successfully');
      } else {
        // Create new address - directly pass the formatted data
        savedAddress = await createAddressMutation(addressData).unwrap();
        
        setSuccessMessage(userLang === 'zh-CN' ? '地址已添加' : 'Address added successfully');
      }
      
      // Reset form or redirect
      onSuccessRef.current(savedAddress);
    } catch (error) {
      console.error('Save address error:', error);
      setErrorMessage(
        error.data?.message || 
        (userLang === 'zh-CN' ? '保存地址时出错' : 'Error saving address')
      );
    } finally {
      setIsSaving(false);
    }
  };
  
  // Manual save function for inline use
  const handleSave = async () => {
    const isValid = await validateForm();
    if (!isValid) return null;
    
    setIsSaving(true);
    
    try {
      // Format address data properly, similar to handleSubmit
      const addressData = {
        name: address.name || '',
        street: address.street || '',
        apartment: address.apartment || '',
        city: address.city || '',
        state: address.state || '',
        postalCode: address.postalCode || '',
        country: address.country || '',
        phoneNumber: address.phoneNumber || '',
        isDefault: Boolean(address.isDefault),
        // Include coordinates if we have them
        ...(address.latitude && address.longitude ? {
          latitude: address.latitude,
          longitude: address.longitude
        } : {})
      };
      
      let savedAddress;
      
      if (isEditMode) {
        savedAddress = await updateAddressMutation({
          id: address.id,
          ...addressData // Spread directly to avoid nesting
        }).unwrap();
      } else {
        savedAddress = await createAddressMutation(addressData).unwrap();
      }
      
      // Set success message
      setSuccessMessage(
        isEditMode 
          ? (userLang === 'zh-CN' ? '地址已更新' : 'Address updated') 
          : (userLang === 'zh-CN' ? '地址已添加' : 'Address added')
      );
      
      // Call the onSuccess callback with the saved address
      if (onSuccessRef.current) {
        onSuccessRef.current(savedAddress);
      }
      
      return savedAddress;
    } catch (error) {
      console.error('Error saving address:', error);
      setErrorMessage(
        error.data?.message || 
        (userLang === 'zh-CN' ? '保存地址失败' : 'Failed to save address')
      );
      return null;
    } finally {
      setIsSaving(false);
    }
  };
  
  return (
    <form 
      onSubmit={isInline ? (e) => e.preventDefault() : handleSubmit} 
      className="space-y-6"
    >
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="p-3 bg-green-50 text-green-700 rounded-md">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="p-3 bg-red-50 text-red-700 rounded-md">
          {errorMessage}
        </div>
      )}
      
   
      
      {/* Debug info - hidden in production and only visible in development with DEBUG param */}
      {process.env.NODE_ENV !== 'production' && new URLSearchParams(window.location.search).has('DEBUG') && (
        <div className="text-xs text-gray-400 mt-2 p-2 bg-gray-50 rounded">
          <div>Country detection: {isDetectingCountry ? 'Loading...' : 'Complete'}</div>
          <div>Detected country: {userCountry?.code || 'None'}</div>
          <div>Form country: {address.country || 'None'}</div>
        </div>
      )}
      
      {/* Address Input Component */}
      <AddressInput
        initialAddress={address}
        onAddressChange={handleAddressChange}
        required={true}
        error={errors}
        description={userLang === 'zh-CN' 
          ? '输入您的地址信息。带*号的为必填项。' 
          : 'Enter your address information. Fields marked with * are required.'}
       
      />
      
      {/* Default Address Checkbox */}
      <div className="flex items-center">
        <input
          id="isDefault"
          name="isDefault"
          type="checkbox"
          checked={address.isDefault || false}
          onChange={handleSetDefaultChange}
          className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
        />
        <label htmlFor="isDefault" className="ml-2 block text-sm text-gray-700">
          {userLang === 'zh-CN' ? '设为默认地址' : 'Set as default address'}
        </label>
      </div>
      
      {/* Form Buttons */}
      <div className={`flex ${isInline ? 'justify-end' : 'justify-between'} space-x-3 mt-6`}>
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className={`px-4 py-2 text-sm font-medium rounded-md ${
              isInline 
                ? 'text-gray-700 hover:text-gray-900' 
                : 'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
            }`}
            disabled={isSaving}
          >
            {userLang === 'zh-CN' ? '取消' : 'Cancel'}
          </button>
        )}
        
        {/* Single button that works for both inline and regular forms */}
        <button
          type={isInline ? "button" : "submit"}
          onClick={isInline ? handleSave : undefined}
          className="px-4 py-2 text-sm font-medium rounded-md bg-brand-teal text-white hover:bg-brand-teal-dark"
          disabled={isSaving || isValidating}
        >
          {isSaving || isValidating ? (
            <span className="animate-pulse">{userLang === 'zh-CN' ? '保存中...' : 'Saving...'}</span>
          ) : isEditMode ? (
            userLang === 'zh-CN' ? '更新地址' : 'Update Address'
          ) : (
            userLang === 'zh-CN' ? '添加地址' : 'Add Address'
          )}
        </button>
      </div>
    </form>
  );
};

export default AddressForm; 