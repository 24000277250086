import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { loadMore } from '../../redux/slices/filterSlice';
import LoadingSpinner from '../ui/LoadingSpinner';
// Status Components


export const ErrorMessage = ({ message }) => (
  <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded mb-20">
    <p className="text-red-700 font-medium">Error Loading Products</p>
    <p className="text-red-600">{message || 'Please try again later.'}</p>
  </div>
);

export const NoResultsMessage = ({ searchTerm }) => (
  <div className="bg-brand-beige/10 border-l-4 border-brand-beige p-4 rounded mb-20">
    <p className="text-brand-orange font-medium">No Products Found</p>
    <p className="text-brand-orange-dark">
      {searchTerm 
        ? `No results found for "${searchTerm}". Try different search terms.`
        : 'Try adjusting your filters to see more results.'}
    </p>
  </div>
);

export const EndOfResultsMessage = () => (
  <div className="mt-8 mb-20 bg-brand-beige/10 border-l-4 border-brand-beige p-4 rounded">
    <p className="text-brand-orange font-medium">End of Results</p>
    <p className="text-brand-orange-dark">You've seen all the products that match your criteria.</p>
  </div>
);

const CardsContainer = ({ 
  children,
  className = '',
  loading,
  error,
  hasMore,
  totalResults,
  searchTerm
}) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const isLoadingRef = useRef(false);
  const { dataLoading } = useSelector(state => state.filter);

  // Setup scroll container and handlers
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const scrollPosition = Math.ceil(container.scrollTop + container.clientHeight);
      const scrollThreshold = container.scrollHeight - 100;
      
      if (scrollPosition >= scrollThreshold && !loading && hasMore && !isLoadingRef.current) {
        isLoadingRef.current = true;
        dispatch(loadMore());
        isLoadingRef.current = false;
      }
    };

    const debouncedScroll = debounce(handleScroll, 150);
    container.addEventListener('scroll', debouncedScroll);
    
    return () => {
      container.removeEventListener('scroll', debouncedScroll);
      debouncedScroll.cancel();
    };
  }, [hasMore, loading, dispatch]);

  const renderContent = () => {
    // Initial loading state (no products yet)
    if (dataLoading && !children?.length) {
      return <LoadingSpinner />;
    }

    // Error state
    if (error && !loading && !children?.length) {
      return <ErrorMessage message={error} />;
    }

    // No results state
    if (!dataLoading && !children?.length) {
      return <NoResultsMessage searchTerm={searchTerm} />;
    }

    return (
      <div className="px-4 py-3">
        {searchTerm && totalResults > 0 && (
          <div className="mb-3 text-sm text-gray-600">
            Found {totalResults} results for "{searchTerm}"
          </div>
        )}
        
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {children}
        </div>
        
        {/* Show loading spinner at bottom only during pagination */}
        {loading && children?.length > 0 && <LoadingSpinner />}
        {!hasMore && !loading && children?.length > 0 && <EndOfResultsMessage />}
      </div>
    );
  };

  return (
    <div 
      ref={scrollContainerRef}
      className={`overflow-y-auto ${className}`}
      style={{ maxHeight: 'calc(100vh - 100px)' }}
    >
      {renderContent()}
    </div>
  );
};

export default CardsContainer; 