import React from 'react';
import Checkbox from '../../../../ui/Checkbox';
import useFilter from '../../useFilter';

/**
 * Shipping checkbox component for filtering products
 * - UI behavior: Not checked by default
 * - Filter behavior: Only applies the filter when checked (explicit user interaction)
 * - URL sync: Sets openToShipping=true or openToShipping=false in URL when changed
 */
const ShippingCheckbox = ({ className = '' }) => {
  const { filters, handleFilterChange } = useFilter();
  
  // Handle user checking/unchecking the shipping checkbox
  const handleShippingChange = (e) => {
    // Explicitly set the openToShipping value based on checkbox state
    // This will be sent to Algolia as a filter when checked
    // It will also update the URL parameter
    handleFilterChange({ openToShipping: e.target.checked });
  };

  return (
    <Checkbox
      label="Shipping"
      // Only checked when explicitly set to true (not by default)
      checked={filters.openToShipping === true}
      onChange={handleShippingChange}
      className={className}
    />
  );
};

export default ShippingCheckbox; 