import { createSlice } from '@reduxjs/toolkit';
import { enhancedCartApi as cartApi, mapCartItems } from '../apis/endpoints/cartApi';

// Initial state
const initialState = {
  buyProducts: [],
  rentProducts: [],
  loading: false,
  error: null,
  addCartSuccess: true,
  addRentSuccess: true,
  groupedBuyProducts: {},
  groupedRentProducts: {},
  cartUpdating: false
};

// Transaction types
export const TRANSACTION_TYPES = {
  BUY: 'buy',
  RENT: 'rent',
  SWAP: 'swap'
};


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCartUpdating: (state, action) => {
      state.cartUpdating = action.payload;
    },
    setCartError: (state, action) => {
      state.loading = false;
      state.cartUpdating = false;
      state.error = action.payload;
      state.addCartSuccess = false;
      state.addRentSuccess = false;
    },
    setBuyProducts: (state, action) => {
      state.loading = false;
      state.error = null;
      state.buyProducts = action.payload;
      
      // Group products by merchant
      state.groupedBuyProducts = action.payload.reduce((groups, product) => {
        const merchantId = product.merchant?.id;
        if (!merchantId) return groups;
        
        if (!groups[merchantId]) {
          groups[merchantId] = {
            merchantId: merchantId,
            merchantName: product.merchant?.username || 'Unknown Merchant',
            products: []
          };
        }
        groups[merchantId].products.push(product);
        return groups;
      }, {});
    },
    setRentProducts: (state, action) => {
      state.loading = false;
      state.error = null;
      state.rentProducts = action.payload;

      // Group rent products by merchant
      state.groupedRentProducts = action.payload.reduce((groups, product) => {
        const merchantId = product.merchant?.id;
        if (!merchantId) return groups;
        
        if (!groups[merchantId]) {
          groups[merchantId] = {
            merchantId: merchantId,
            merchantName: product.merchant?.username || 'Unknown Merchant',
            products: []
          };
        }
        groups[merchantId].products.push(product);
        return groups;
      }, {});
    },
    setAddCartSuccess: (state, action) => {
      state.addCartSuccess = action.payload;
      state.cartUpdating = false;
    },
    setAddRentSuccess: (state, action) => {
      state.addRentSuccess = action.payload;
      state.cartUpdating = false;
    },
    clearCart: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle cart data from API
      .addMatcher(
        cartApi.endpoints.getCart.matchFulfilled,
        (state, { payload }) => {
          const { buyItems, rentItems } = mapCartItems(payload);
          
          // Calculate total for buy items
          const buyTotal = buyItems.reduce((sum, item) => {
            let itemPrice = item.price || 0;
            
            // Apply price modifiers from product options
            if (item.productOptions && item.productOptions.length > 0) {
              item.productOptions.forEach(option => {
                if (option.priceModifier) {
                  itemPrice += parseFloat(option.priceModifier);
                }
              });
            }
            
            return sum + (itemPrice * item.quantity);
          }, 0);
          
          // Calculate total for rent items
          const rentTotal = rentItems.reduce((sum, item) => {
            if (!item.rentalPeriod) return sum;
            
            // Find the rental price based on the period
            let rentalPrice = 0;
            if (item.rentPriceAndTime && item.rentPriceAndTime.length > 0) {
              // Try to match the rental period to an option from rentPriceAndTime
              const rentalOptions = item.rentPriceAndTime;
              let matchedOption = null;
              
              // Find the option that matches the rental period
              if (item.rentalPeriod.duration) {
                for (const option of rentalOptions) {
                  // Convert to days based on option type
                  let optionDays = option.number;
                  if (option.option === 'week') optionDays *= 7;
                  else if (option.option === 'month') optionDays *= 30;
                  else if (option.option === 'year') optionDays *= 365;
                  
                  if (optionDays === item.rentalPeriod.duration) {
                    matchedOption = option;
                    break;
                  }
                }
              }
              
              // If no exact match, find the closest option
              if (!matchedOption && rentalOptions.length > 0) {
                // Sort by number of days
                const sortedOptions = [...rentalOptions].sort((a, b) => {
                  const aDays = a.option === 'day' ? a.number : 
                              a.option === 'week' ? a.number * 7 :
                              a.option === 'month' ? a.number * 30 : a.number * 365;
                  
                  const bDays = b.option === 'day' ? b.number : 
                              b.option === 'week' ? b.number * 7 :
                              b.option === 'month' ? b.number * 30 : b.number * 365;
                  
                  return aDays - bDays;
                });
                
                // Find the closest option that covers the rental period
                for (const option of sortedOptions) {
                  const optionDays = option.option === 'day' ? option.number : 
                                    option.option === 'week' ? option.number * 7 :
                                    option.option === 'month' ? option.number * 30 : option.number * 365;
                  
                  if (optionDays >= item.rentalPeriod.duration) {
                    matchedOption = option;
                    break;
                  }
                }
                
                // If no option covers it, use the longest one
                if (!matchedOption) {
                  matchedOption = sortedOptions[sortedOptions.length - 1];
                }
              }
              
              if (matchedOption) {
                rentalPrice = matchedOption.price;
              } else if (item.price) {
                // Fallback to regular price if no rental option found
                rentalPrice = item.price;
              }
            } else {
              // Fallback if no rental options are defined
              rentalPrice = item.price || 0;
            }
            
            // Apply price modifiers from product options
            if (item.productOptions && item.productOptions.length > 0) {
              item.productOptions.forEach(option => {
                if (option.priceModifier) {
                  rentalPrice += parseFloat(option.priceModifier);
                }
              });
            }
            
            return sum + (rentalPrice * item.quantity);
          }, 0);
          
          // Update state with mapped data
          state.buyProducts = buyItems;
          state.rentProducts = rentItems;
          state.buyTotal = buyTotal;
          state.rentTotal = rentTotal;
          state.loading = false;
          state.error = null;
          
          // Group buy products by merchant
          state.groupedBuyProducts = buyItems.reduce((groups, product) => {
            const merchantId = product.merchant?.id;
            if (!merchantId) return groups;
            
            if (!groups[merchantId]) {
              groups[merchantId] = {
                merchantId: merchantId,
                merchantName: product.merchant?.username || 'Unknown Merchant',
                products: []
              };
            }
            groups[merchantId].products.push(product);
            return groups;
          }, {});
          
          // Group rent products by merchant
          state.groupedRentProducts = rentItems.reduce((groups, product) => {
            const merchantId = product.merchant?.id;
            if (!merchantId) return groups;
            
            if (!groups[merchantId]) {
              groups[merchantId] = {
                merchantId: merchantId,
                merchantName: product.merchant?.username || 'Unknown Merchant',
                products: []
              };
            }
            groups[merchantId].products.push(product);
            return groups;
          }, {});
        }
      )
      // Handle loading and error states
      .addMatcher(
        cartApi.endpoints.getCart.matchPending,
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        cartApi.endpoints.getCart.matchRejected,
        (state, { error }) => {
          state.loading = false;
          state.error = error.message;
        }
      )
      // Set updating state during cart modifications
      .addMatcher(
        action => [
          'cartApi/executeMutation/pending'
        ].some(type => action.type.includes(type)),
        (state) => {
          state.cartUpdating = true;
        }
      )
      .addMatcher(
        action => [
          'cartApi/executeMutation/fulfilled'
        ].some(type => action.type.includes(type)),
        (state) => {
          state.cartUpdating = false;
          // Reset error state on successful mutations
          state.error = null;
        }
      )
      .addMatcher(
        action => [
          'cartApi/executeMutation/rejected'
        ].some(type => action.type.includes(type)),
        (state, { error }) => {
          state.cartUpdating = false;
          state.error = error.message;
        }
      );
  }
});

export const {
  setCartLoading,
  setCartError,
  setBuyProducts,
  setRentProducts,
  setAddCartSuccess,
  setAddRentSuccess,
  clearCart,
  setCartUpdating
} = cartSlice.actions;

// Selectors
export const selectBuyProducts = state => state.cart?.buyProducts || [];
export const selectRentProducts = state => state.cart?.rentProducts || [];
export const selectBuyTotal = state => state.cart?.buyTotal || 0;
export const selectRentTotal = state => state.cart?.rentTotal || 0;
export const selectCartLoading = state => state.cart?.loading || false;
export const selectCartUpdating = state => state.cart?.cartUpdating || false;
export const selectCartError = state => state.cart?.error || null;
export const selectAddCartSuccess = state => state.cart?.addCartSuccess || true;
export const selectAddRentSuccess = state => state.cart?.addRentSuccess || true;
export const selectGroupedBuyProducts = state => state.cart?.groupedBuyProducts || {};
export const selectGroupedRentProducts = state => state.cart?.groupedRentProducts || {};

// Helper function to create cart item
export const createCartItem = ({
  productId,
  quantity = 1,
  type = TRANSACTION_TYPES.BUY,
  productOptions = [],
  customNote = '',
  merchant = null,
  rentalPeriod = null
}) => {
  const cartItem = {
    product: productId,
    quantity,
    type,
    productOptions,
    customNote
  };
  
  if (merchant) {
    cartItem.merchant = merchant;
  }
  
  if (type === TRANSACTION_TYPES.RENT && rentalPeriod) {
    cartItem.rentalPeriod = rentalPeriod;
  }
  
  return cartItem;
};

// Thunk to fetch cart items using the new API
export const fetchCartItems = () => async (dispatch) => {
  try {
    dispatch(setCartLoading());
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to view cart'));
      return;
    }
    
    // Use the RTK Query endpoint directly
    const result = await dispatch(cartApi.endpoints.getCart.initiate());
    
    if (result.error) {
      console.error('Cart fetch error:', result.error);
      dispatch(setCartError(result.error.message || 'Failed to load cart'));
      return;
    }
    
    // Cart data is automatically processed in extraReducers
    
  } catch (error) {
    console.error('Cart fetch error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to add item to cart
export const addToCart = (cartItemData) => async (dispatch) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to add to cart'));
      return;
    }
    
    // Format and create the cart item
    const cartItem = createCartItem(cartItemData);
    
    // Use the addCartItem API endpoint
    await dispatch(cartApi.endpoints.addCartItem.initiate(cartItem));
    
    // Set success state based on item type
    if (cartItem.type === TRANSACTION_TYPES.RENT) {
      dispatch(setAddRentSuccess(true));
    } else {
      dispatch(setAddCartSuccess(true));
    }
    
  } catch (error) {
    console.error('Add to cart error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to update cart item
export const updateCartItem = (itemIndex, updates) => async (dispatch) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to update cart'));
      return;
    }
    
    // Use the updateCartItem API endpoint
    await dispatch(cartApi.endpoints.updateCartItem.initiate({
      itemIndex,
      cartItem: updates
    }));
    
  } catch (error) {
    console.error('Update cart error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to remove item from cart
export const removeFromCart = (itemIndex) => async (dispatch) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to remove from cart'));
      return;
    }
    
    // Use the removeCartItem API endpoint
    await dispatch(cartApi.endpoints.removeCartItem.initiate(itemIndex));
    
  } catch (error) {
    console.error('Remove from cart error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to clear cart
export const emptyCart = () => async (dispatch) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to clear cart'));
      return;
    }
    
    // Use the clearCart API endpoint
    await dispatch(cartApi.endpoints.clearCart.initiate());
    dispatch(clearCart());
    
  } catch (error) {
    console.error('Clear cart error:', error);
    dispatch(setCartError(error.message));
  }
};

// Thunk to checkout
export const checkoutCart = (checkoutData) => async (dispatch) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to checkout'));
      return;
    }
    
    // Use the checkout API endpoint
    const result = await dispatch(cartApi.endpoints.checkout.initiate(checkoutData)).unwrap();
    
    // Clear local cart state after successful checkout
    dispatch(clearCart());
    
    return result;
    
  } catch (error) {
    console.error('Checkout error:', error);
    dispatch(setCartError(error.message));
    throw error;
  }
};

// Backward-compatible updateCartItems function for existing components
export const updateCartItems = ({ id, action, type, category, isRent, color, size, merchant, rentTimeSelect, startDate, endDate }) => async (dispatch, getState) => {
  try {
    dispatch(setCartUpdating(true));
    
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(setCartError('Please sign in to update cart'));
      return;
    }
    
    // Get current cart state
    const currentState = getState().cart;
    const currentItems = isRent ? currentState.rentProducts : currentState.buyProducts;
    
    // Find the item in the current cart
    const itemToUpdate = currentItems.find(item => item.id === id);
    if (!itemToUpdate) {
      dispatch(setCartError('Item not found in cart'));
      return;
    }
    
    // Get the cart item index
    const itemIndex = itemToUpdate.cartItemIndex;
    
    if (action === 'delete') {
      // Delete item
      await dispatch(cartApi.endpoints.removeCartItem.initiate(itemIndex));
    } else if (action === 'increase' || action === 'decrease') {
      // Update quantity
      const newQuantity = action === 'increase' 
        ? itemToUpdate.quantity + 1 
        : Math.max(1, itemToUpdate.quantity - 1);
      
      await dispatch(cartApi.endpoints.updateCartItem.initiate({
        itemIndex,
        cartItem: { quantity: newQuantity }
      }));
    } else if (action === 'add') {
      // Add new item
      const cartItem = {
        product: id,
        quantity: 1,
        type: isRent ? TRANSACTION_TYPES.RENT : TRANSACTION_TYPES.BUY,
        productOptions: []
      };
      
      // Add color option if provided
      if (color) {
        cartItem.productOptions.push({
          optionType: 'color',
          optionValue: color
        });
      }
      
      // Add size option if provided
      if (size) {
        cartItem.productOptions.push({
          optionType: 'size',
          optionValue: size
        });
      }
      
      // Add merchant if provided
      if (merchant) {
        cartItem.merchant = merchant;
      }
      
      // Add rental period if this is a rental
      if (isRent && rentTimeSelect) {
        cartItem.rentalPeriod = {
          startDate,
          endDate,
          duration: calculateDurationInDays(startDate, endDate),
          rentalOption: rentTimeSelect
        };
      }
      
      await dispatch(cartApi.endpoints.addCartItem.initiate(cartItem));
    }
    
    // Refresh the cart
    dispatch(fetchCartItems());
    
    // Set success flags
    if (isRent) {
      dispatch(setAddRentSuccess(true));
    } else {
      dispatch(setAddCartSuccess(true));
    }
    
  } catch (error) {
    console.error('Cart update error:', error);
    dispatch(setCartError(error.message));
  }
};

// Helper function to calculate duration in days
function calculateDurationInDays(startDate, endDate) {
  if (!startDate || !endDate) return null;
  
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end - start);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export default cartSlice.reducer; 