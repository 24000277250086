import React from 'react';
import { Parallax } from 'react-parallax';

const QuoteSection = ({ userLang }) => {
  // Translation content
  const content = {
    quote: userLang === 'zh-CN' ? '没有衣服穿' : 'I Have Nothing To Wear',
    statistics: userLang === 'zh-CN'
      ? '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'
      : 'Research shows 80% of women wear the clothing in their closets an average of 5 times.',
    benefits: userLang === 'zh-CN'
      ? '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'
      : 'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'
  };

  return (
    <div className="relative">
      <Parallax
        bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727700908/environment_befe0dab6d.svg"
        bgImageAlt="Three diverse women in fashionable outfits"
        strength={100}
        bgImageStyle={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        
        }}
        className="bg-brand-teal/20"
      >
        <div className="py-32 md:py-40" style={{backgroundColor:'rgba(0,0,0,0.5)'}}>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Big quote */}
            <div className="mb-12 md:mb-20">
              <span className="block text-7xl md:text-9xl font-serif text-white opacity-90 mb-2">"</span>
              <h2 className="text-4xl md:text-6xl font-bold text-white tracking-tight mb-6">
                {content.quote}
              </h2>
            </div>

            {/* Statistics and benefits */}
            <div className="md:w-3/4">
              <p className="text-xl md:text-2xl text-white font-medium mb-8">
                {content.statistics}
              </p>
              <p className="text-lg md:text-xl text-white font-light">
                {content.benefits}
              </p>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export default QuoteSection; 