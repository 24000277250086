import { io } from 'socket.io-client';
import { BACKEND_URL, SOCKET_CONFIG } from '../utils/config';
import store from '../redux/store';
import { addMessage, fetchChatList, setTypingStatus } from '../redux/slices/chatSlice';
import { handleWebhookUpdate as handleUserWebhookUpdate } from '../redux/apis';
import { fetchPaymentMethods } from '../redux/slices/paymentSlice';
import { clearShippingState } from '../redux/slices/shippingSlice';

const MAX_RETRIES = 3;
const RETRY_INTERVAL = 3000; // 3 seconds

class WebSocketService {
  constructor() {
    if (WebSocketService.instance) {
      return WebSocketService.instance;
    }
    
    this.socket = null;
    this.currentRoom = null;
    this.connected = false;
    this.messageHandlers = new Set();
    this.typingHandlers = new Set();
    this.userUpdateHandlers = new Set();
    this.reconnectAttempts = 0;
    this.typingTimeout = null;

    WebSocketService.instance = this;
  }

  async connect(token) {
    if (this.socket?.connected) {
      console.log('WebSocket already connected');
      return;
    }

    try {
      console.log('Connecting to WebSocket at:', BACKEND_URL);
      this.socket = io(BACKEND_URL, {
        ...SOCKET_CONFIG,
        auth: { token },
        transports: ['websocket']
      });

      this.setupEventHandlers();

      await new Promise((resolve, reject) => {
        this.socket.on('connect', () => {
          console.log('WebSocket connected successfully');
          this.connected = true;
          this.reconnectAttempts = 0;
          resolve();
        });

        this.socket.on('connect_error', (error) => {
          console.error('WebSocket connection error:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error connecting to WebSocket:', error);
      this.handleReconnect();
      throw error;
    }
  }

  setupEventHandlers() {
    if (!this.socket) return;

    const currentUserId = store.getState().user?.userInfo?.id;
    const isCurrentUser = (data) => data.entry?.id === currentUserId;

    // Handle product updates
    this.socket.on('products.create', (data) => {
      console.log('Received product creation:', data);
      // Refresh Algolia index
      if (data.entry?.users_permissions_user?.id === currentUserId) {
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('products.update', (data) => {
      console.log('Received product update:', data);
      // Refresh Algolia index
      if (data.entry?.users_permissions_user?.id === currentUserId) {
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('products.delete', (data) => {
      console.log('Received product deletion:', data);
      // Refresh Algolia index
      if (data.entry?.users_permissions_user?.id === currentUserId) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle user profile updates
    this.socket.on('users-permissions.user.update', (data) => {
      console.log('Received user update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle cart and favorites updates
    this.socket.on('users-permissions.user.cart', (data) => {
      console.log('Received cart update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('users-permissions.user.heart', (data) => {
      console.log('Received favorites update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('users-permissions.user.liked_products', (data) => {
      console.log('Received liked products update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle merchant related updates
    this.socket.on('users-permissions.user.merchantInfo', (data) => {
      console.log('Received merchant info update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('users-permissions.user.stripeAccount', (data) => {
      console.log('Received stripe account update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
        store.dispatch(fetchPaymentMethods());
      }
    });

    // Handle chat updates
    this.socket.on('users-permissions.user.chatList', (data) => {
      console.log('Received chat list update:', data);
      if (isCurrentUser(data)) {
        store.dispatch(fetchChatList());
      }
    });

    this.socket.on('messages.create', (data) => {
      console.log('Received new message:', data);
      const message = data.entry;
      if (message.messageSender[0]?.id === currentUserId || 
          message.messageReceiver[0]?.id === currentUserId) {
        store.dispatch(addMessage({
          ...message,
          isSender: message.messageSender[0]?.id === currentUserId
        }));
        store.dispatch(fetchChatList());
      }
    });

    // Handle order updates
    this.socket.on('orders.update', (data) => {
      console.log('Received order update:', data);
      if (data.entry?.users_permissions_user?.id === currentUserId || 
          data.entry?.merchants?.some(m => m.id === currentUserId)) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle shipping and billing updates
    this.socket.on('users-permissions.user.shippingaddress', (data) => {
      console.log('Received shipping address update:', data);
      if (isCurrentUser(data)) {
        store.dispatch(clearShippingState());
        handleUserWebhookUpdate(store);
      }
    });

    this.socket.on('users-permissions.user.billinginformation', (data) => {
      console.log('Received billing info update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle membership updates
    this.socket.on('users-permissions.user.membership', (data) => {
      console.log('Received membership update:', data);
      if (isCurrentUser(data)) {
        handleUserWebhookUpdate(store);
      }
    });

    // Handle typing status
    this.socket.on('typing', (data) => {
      console.log('Received typing status:', data);
      store.dispatch(setTypingStatus({ userId: data.senderId, isTyping: data.isTyping }));
      this.typingHandlers.forEach(handler => handler(data));
    });

    // Handle errors
    this.socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    // Handle disconnection
    this.socket.on('disconnect', (reason) => {
      console.log('WebSocket disconnected:', reason);
      this.connected = false;
      this.currentRoom = null;

      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        // Don't attempt to reconnect for intentional disconnections
        return;
      }

      this.handleReconnect();
    });
  }

  handleReconnect() {
    if (this.reconnectAttempts >= MAX_RETRIES) {
      console.error('Max reconnection attempts reached');
      return;
    }

    this.reconnectAttempts++;
    console.log(`Attempting to reconnect (${this.reconnectAttempts}/${MAX_RETRIES})...`);

    setTimeout(() => {
      const token = localStorage.getItem('token');
      if (token) {
        this.connect(token).catch(error => {
          console.error('Reconnection attempt failed:', error);
        });
      }
    }, RETRY_INTERVAL * this.reconnectAttempts);
  }

  disconnect() {
    if (this.socket) {
      if (this.currentRoom) {
        this.leaveChat(this.currentRoom);
      }
      this.socket.disconnect();
      this.socket = null;
      this.connected = false;
      this.currentRoom = null;
      this.reconnectAttempts = 0;
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
        this.typingTimeout = null;
      }
    }
  }

  joinChat(chatId) {
    if (!this.socket?.connected || !chatId) {
      console.warn('WebSocket: Cannot join chat - not connected or missing chatId');
      return;
    }

    // Leave current room if any
    if (this.currentRoom) {
      this.leaveChat(this.currentRoom);
    }

    console.log('Joining chat room:', chatId);
    this.socket.emit('join', { room: chatId });
    this.currentRoom = chatId;
  }

  leaveChat(chatId) {
    if (!this.socket?.connected || !chatId) {
      console.warn('WebSocket: Cannot leave chat - not connected or missing chatId');
      return;
    }

    console.log('Leaving chat room:', chatId);
    this.socket.emit('leave-chat', chatId);
    if (this.currentRoom === chatId) {
      this.currentRoom = null;
    }
  }

  sendMessage(messageData) {
    if (!this.socket?.connected) {
      console.warn('WebSocket: Cannot send message - not connected');
      return false;
    }

    console.log('Sending message via WebSocket:', messageData);
    this.socket.emit('send-message', messageData);

    // Clear typing status after sending message
    this.emitTyping(messageData.senderId, messageData.receiverId, false);

    // Also update Redux store immediately for better UX
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const senderId = userInfo[0]?.id;

    if (senderId) {
      const optimisticMessage = {
        messageText: messageData.text,
        messageSender: [{ id: senderId }],
        messageReceiver: [{ id: messageData.receiverId }],
        created_at: new Date().toISOString(),
        isSender: true,
        id: `temp-${Date.now()}` // Temporary ID for optimistic update
      };

      store.dispatch(addMessage(optimisticMessage));
    }

    return true;
  }

  emitTyping(senderId, receiverId, isTyping) {
    if (!this.socket?.connected || !senderId || !receiverId) {
      console.warn('WebSocket: Cannot emit typing - not connected or missing IDs');
      return;
    }

    // Clear existing timeout
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
      this.typingTimeout = null;
    }

    // Emit typing status
    console.log('Emitting typing status:', { senderId, receiverId, isTyping });
    this.socket.emit('typing', { senderId, receiverId, isTyping });

    // Automatically clear typing status after 3 seconds of no updates
    if (isTyping) {
      this.typingTimeout = setTimeout(() => {
        this.emitTyping(senderId, receiverId, false);
      }, 3000);
    }

    // Update Redux store
    store.dispatch(setTypingStatus({ userId: senderId, isTyping }));
  }

  onNewMessage(handler) {
    if (!this.socket) return;
    this.messageHandlers.add(handler);
  }

  onTyping(handler) {
    if (!this.socket) return;
    this.typingHandlers.add(handler);
  }

  removeMessageHandler(handler) {
    if (!this.socket) return;
    this.messageHandlers.delete(handler);
  }

  removeTypingHandler(handler) {
    if (!this.socket) return;
    this.typingHandlers.delete(handler);
  }

  isConnected() {
    return this.socket?.connected || false;
  }

  getCurrentRoom() {
    return this.currentRoom;
  }
}

// Create a singleton instance
const instance = new WebSocketService();

export default instance; 