// Common categories for product listing
export const COMMON_CATEGORIES = [
  { value: 'dress', label: 'Dress' },
  { value: 'top', label: 'Top' },
  { value: 'sweater', label: 'Sweater' },
  { value: 'jacket', label: 'Jacket' },
  { value: 'coat', label: 'Coat' },
  { value: 'pants', label: 'Pants' },
  { value: 'jeans', label: 'Jeans' },
  { value: 'skirt', label: 'Skirt' },
  { value: 'shorts', label: 'Shorts' },
  { value: 'jumpsuit', label: 'Jumpsuit' },
  { value: 'suit', label: 'Suit' },
  { value: 'activewear', label: 'Activewear' },
  { value: 'swimwear', label: 'Swimwear' },
  { value: 'lingerie', label: 'Lingerie' },
  { value: 'accessories', label: 'Accessories' },
  { value: 'shoes', label: 'Shoes' },
  { value: 'other', label: 'Other' }
]; 