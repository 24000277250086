/**
 * Utility functions for country detection
 */

/**
 * Get user's country from IP address
 * Uses ipapi.co free tier API - note that this has rate limits
 * For production, consider using a paid API or implementing server-side detection
 * 
 * @returns {Promise<{countryCode: string, countryName: string}>} Country information
 */
export const getUserCountryFromIP = async () => {
  try {
    // Default fallback
    const defaultCountry = { countryCode: 'US', countryName: 'United States' };
    
    // Try to get country from IP
    const response = await fetch('https://ipapi.co/json/');
    
    // If response fails, return default
    if (!response.ok) return defaultCountry;
    
    const data = await response.json();
    
    // Return country code and name
    return {
      countryCode: data.country_code || 'US',
      countryName: data.country_name || 'United States'
    };
  } catch (error) {
    console.warn('Failed to detect country from IP:', error);
    // Return US as fallback
    return { countryCode: 'US', countryName: 'United States' };
  }
};

/**
 * Get browser language and region
 * This isn't as reliable as IP detection but can be used as a fallback
 * 
 * @returns {string} Country code based on browser language
 */
export const getCountryFromBrowserLocale = () => {
  try {
    const locale = navigator.language || navigator.userLanguage || 'en-US';
    // If locale includes a region code (e.g., en-US, zh-CN)
    if (locale.includes('-')) {
      return locale.split('-')[1];
    }
    // Default to US if no region code
    return 'US';
  } catch (error) {
    return 'US';
  }
}; 