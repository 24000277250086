import React from 'react';

const LoadingState = () => {
  return (
    <div className="p-6">
      <div className="animate-pulse space-y-4">
        <div className="h-48 bg-gray-200 rounded-lg w-full"></div>
        <div className="h-32 bg-gray-200 rounded-full w-32"></div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingState; 