import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const ROUTES = {
  LOGIN: '/login',
  CART: '/cart',
  CART_RENT: '/cart?rent=true',
  PAYMENT: '/payment',
  MESSAGE: '/message',
  HOME: '/',
  MERCHANT_PRODUCT_EDIT: '/listings/edit'
};

export const navigate = (route, options = {}) => {
  const { state, replace = false } = options;
  
  // Remove the hash if it exists in the route
  const cleanRoute = route.replace('#', '');
  
  if (state) {
    window.location.href = cleanRoute;
  } else if (replace) {
    window.location.replace(cleanRoute);
  } else {
    window.location.href = cleanRoute;
  }
};

export const redirectToLogin = () => {
  navigate(ROUTES.LOGIN, { replace: true });
};

export const redirectToCart = (isRent = false) => {
  navigate(isRent ? ROUTES.CART_RENT : ROUTES.CART);
};

export const redirectToPayment = () => {
  navigate(ROUTES.PAYMENT);
};

export const redirectToMessage = (productInfo, merchantId) => {
  navigate(ROUTES.MESSAGE, {
    state: {
      product: productInfo,
      merchantId
    }
  });
};

export const redirectToProductEdit = (productId) => {
  const editUrl = `${ROUTES.MERCHANT_PRODUCT_EDIT}/${productId}`;
  navigate(editUrl);
};

export default {
  history,
  ROUTES,
  navigate,
  redirectToLogin,
  redirectToCart,
  redirectToPayment,
  redirectToMessage,
  redirectToProductEdit
}; 