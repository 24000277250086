import { createBaseApi } from '../baseApi';
import { uploadFiles } from '../../../utils/uploadUtils';

const profileApi = createBaseApi('profileApi');

export const enhancedProfileApi = profileApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => '/profile/me',
      providesTags: ['User', 'Profile']
    }),
    updateProfile: builder.mutation({
      query: (profileData) => ({
        url: '/profile/me',
        method: 'PUT',
        body: profileData
      }),
      invalidatesTags: ['User', 'Profile']
    }),
    /**
     * Change password using our custom secure endpoint
     * This uses a dedicated endpoint that properly verifies the current password
     * before updating to the new password
     */
    changePassword: builder.mutation({
      query: (passwordData) => ({
        url: '/password-change',
        method: 'POST',
        body: {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword
        }
      }),
      invalidatesTags: ['User', 'Profile']
    }),
    /**
     * Update the user's avatar (profile picture)
     * Handles file upload and profile update in one operation
     */
    updateAvatar: builder.mutation({
      queryFn: async (fileData, { dispatch }, _extraOptions, baseQuery) => {
        try {
          if (!fileData) {
            return { error: { message: 'No file provided' } };
          }
          
          // Step 1: Upload the file
          const uploadedFiles = await uploadFiles([fileData]);
          
          if (!uploadedFiles || uploadedFiles.length === 0) {
            return { error: { message: 'File upload failed' } };
          }
          
          console.log('File uploaded successfully:', uploadedFiles[0]);
          
          // Step 2: Update the profile with the file ID
          try {
            const result = await baseQuery({
              url: '/profile/me',
              method: 'PUT',
              body: { profilePic: uploadedFiles[0].id }
            });
            
            if (result.error) {
              console.error('Profile update error:', result.error);
              return { error: result.error };
            }
            
            // Invalidate the profile cache to refresh data
            dispatch(profileApi.util.invalidateTags(['User', 'Profile']));
            
            return { data: result.data };
          } catch (apiError) {
            console.error('API request error:', apiError);
            return { error: { message: apiError.message || 'API request failed' } };
          }
        } catch (error) {
          console.error('Avatar update error:', error);
          return { error: { message: error.message || 'Failed to update avatar' } };
        }
      },
      invalidatesTags: ['User', 'Profile']
    }),
    /**
     * Update the user's background image
     * Handles file upload and profile update in one operation
     */
    updateBackground: builder.mutation({
      queryFn: async (fileData, { dispatch }, _extraOptions, baseQuery) => {
        try {
          if (!fileData) {
            return { error: { message: 'No file provided' } };
          }
          
          // Step 1: Upload the file
          const uploadedFiles = await uploadFiles([fileData]);
          
          if (!uploadedFiles || uploadedFiles.length === 0) {
            return { error: { message: 'File upload failed' } };
          }
          
          console.log('File uploaded successfully:', uploadedFiles[0]);
          
          // Step 2: Update the profile with the file ID
          try {
            const result = await baseQuery({
              url: '/profile/me',
              method: 'PUT',
              body: { backgroundPic: uploadedFiles[0].id }
            });
            
            if (result.error) {
              console.error('Profile update error:', result.error);
              return { error: result.error };
            }
            
            // Invalidate the profile cache to refresh data
            dispatch(profileApi.util.invalidateTags(['User', 'Profile']));
            
            return { data: result.data };
          } catch (apiError) {
            console.error('API request error:', apiError);
            return { error: { message: apiError.message || 'API request failed' } };
          }
        } catch (error) {
          console.error('Background update error:', error);
          return { error: { message: error.message || 'Failed to update background image' } };
        }
      },
      invalidatesTags: ['User', 'Profile']
    }),
    /**
     * Delete the user's avatar (profile picture)
     */
    deleteAvatar: builder.mutation({
      query: () => ({
        url: '/profile/me',
        method: 'PUT',
        body: { profilePic: null }
      }),
      invalidatesTags: ['User', 'Profile']
    }),
    /**
     * Delete the user's background image
     */
    deleteBackground: builder.mutation({
      query: () => ({
        url: '/profile/me',
        method: 'PUT',
        body: { backgroundPic: null }
      }),
      invalidatesTags: ['User', 'Profile']
    })
  })
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useChangePasswordMutation,
  useUpdateAvatarMutation,
  useDeleteAvatarMutation,
  useUpdateBackgroundMutation,
  useDeleteBackgroundMutation
} = enhancedProfileApi; 