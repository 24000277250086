import React from 'react';
import Section from '../Section';

const SharingSection = () => {
  const userLang = navigator.language || navigator.userLanguage;

  return (
    <Section
      fullWidth
      minHeight="min-h-[80vh]"
      bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727638013/exchange_8ed19f8200.svg"
      padding="py-24 px-4 sm:px-6 lg:px-8"
    >
      <div className="flex items-center justify-center">
        <div className="backdrop-blur-sm bg-beige-50/40 rounded-full w-[400px] h-[400px] flex flex-col items-center justify-center p-8 border-none">
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-4 drop-shadow-[2px_2px_#e4bf8f]">
            {userLang === 'zh-CN' ? '欢迎来到SHEYOU' : 'Welcome to SHEYOU'}
          </h1>
          
          <h2 className="text-2xl text-gray-800 text-center mb-8 drop-shadow-[2px_2px_#e4bf8f]">
            {userLang === 'zh-CN' 
              ? '新时代时尚租赁租赁平台' 
              : 'Next Generation Fashion Renting Platform'}
          </h2>
          
          <a 
            href="/signin" 
            className="inline-flex items-center justify-center px-12 py-5 text-lg font-medium rounded-full bg-[#e4bf8f] text-[#493d2b] hover:bg-[#d4af7f] transition-colors duration-200"
          >
            {userLang === 'zh-CN' ? '如何运作' : 'Get Started'}
          </a>
        </div>
      </div>
    </Section>
  );
};

export default SharingSection; 