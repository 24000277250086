import { useState } from 'react';
import { useGetCurrentUserQuery } from '../../../redux/apis';
import { BACKEND_URL } from '../../../utils/config';
import axios from 'axios';

const userLang = navigator.language || navigator.userLanguage;

const ProfileTransactions = () => {
  const { data: user } = useGetCurrentUserQuery();
  const [activeTab, setActiveTab] = useState('all');
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useState(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${BACKEND_URL}/orders?users_permissions_user.username=${user?.username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setOrders(response.data.reverse());
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.username) {
      fetchOrders();
    }
  }, [user?.username]);

  const tabs = [
    {
      id: 'all',
      label: userLang === 'zh-CN' ? '全部' : 'All'
    },
    {
      id: 'ongoing',
      label: userLang === 'zh-CN' ? '进行中' : 'Ongoing'
    },
    {
      id: 'completed',
      label: userLang === 'zh-CN' ? '已完成' : 'Completed'
    }
  ];

  const filteredOrders = orders.filter(order => {
    if (activeTab === 'all') return true;
    if (activeTab === 'ongoing') return !order.isCompleted;
    if (activeTab === 'completed') return order.isCompleted;
    return true;
  });

  const getOrderStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'processing':
        return 'bg-blue-100 text-blue-800';
      case 'shipped':
        return 'bg-purple-100 text-purple-800';
      case 'delivered':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getOrderStatusText = (status) => {
    if (userLang === 'zh-CN') {
      switch (status) {
        case 'pending':
          return '待处理';
        case 'processing':
          return '处理中';
        case 'shipped':
          return '已发货';
        case 'delivered':
          return '已送达';
        case 'cancelled':
          return '已取消';
        default:
          return '未知状态';
      }
    }
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          <div className="space-y-3">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="h-24 bg-gray-200 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {userLang === 'zh-CN' ? '交易记录' : 'Transactions'}
        </h2>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-brand-teal text-brand-teal'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>

      {/* Orders List */}
      {filteredOrders.length === 0 ? (
        <div className="text-center py-12">
          <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            {userLang === 'zh-CN' ? '暂无订单' : 'No orders'}
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            {userLang === 'zh-CN' ? '开始购物以查看订单' : 'Start shopping to see your orders'}
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div key={order.id} className="bg-white shadow-sm rounded-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <p className="text-sm text-gray-500">
                    {userLang === 'zh-CN' ? '订单号：' : 'Order ID: '}
                    <span className="font-medium text-gray-900">{order.id}</span>
                  </p>
                  <p className="text-sm text-gray-500">
                    {userLang === 'zh-CN' ? '下单时间：' : 'Ordered on: '}
                    <span className="font-medium text-gray-900">
                      {new Date(order.created_at).toLocaleDateString()}
                    </span>
                  </p>
                </div>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getOrderStatusColor(order.status)}`}>
                  {getOrderStatusText(order.status)}
                </span>
              </div>

              {/* Order Items */}
              <div className="border-t border-gray-200 pt-4">
                {order.products?.map((product) => (
                  <div key={product.id} className="flex items-center py-2">
                    <div className="flex-shrink-0 w-16 h-16">
                      {product.images?.[0]?.url && (
                        <img
                          src={`${BACKEND_URL}${product.images[0].url}`}
                          alt={product.name}
                          className="w-full h-full object-cover rounded"
                        />
                      )}
                    </div>
                    <div className="ml-4 flex-1">
                      <h4 className="text-sm font-medium text-gray-900">
                        {product.name}
                      </h4>
                      <p className="text-sm text-gray-500">
                        {userLang === 'zh-CN' ? '数量：' : 'Quantity: '}
                        {product.quantity}
                      </p>
                    </div>
                    <div className="text-sm font-medium text-gray-900">
                      ${product.price}
                    </div>
                  </div>
                ))}
              </div>

              {/* Order Total */}
              <div className="border-t border-gray-200 pt-4 mt-4">
                <div className="flex justify-between text-sm">
                  <span className="font-medium text-gray-900">
                    {userLang === 'zh-CN' ? '总计' : 'Total'}
                  </span>
                  <span className="font-medium text-gray-900">
                    ${order.total}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfileTransactions; 