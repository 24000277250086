import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addresses: [],
  defaultAddressId: null,
  loading: false,
  error: null,
  selectedAddressId: null,
  currentAddress: null // Store for temporary address data
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddresses: (state, { payload }) => {
      state.addresses = payload;
      
      // Find default address
      const defaultAddress = payload.find(addr => addr.isDefault);
      state.defaultAddressId = defaultAddress ? defaultAddress.id : null;
      
      // If no address is selected, select the default or first one
      if (!state.selectedAddressId && payload.length > 0) {
        state.selectedAddressId = state.defaultAddressId || payload[0].id;
      }
    },
    
    setUserAddresses: (state, { payload: { address, action } }) => {
      switch (action) {
        case 'add':
          // Normalize country to ensure it's a country code
          if (address.country && address.country.length > 2) {
            // If it's a country name rather than a code, try to convert it
            // For now, just ensure we have something
            address.country = address.country.substring(0, 2).toUpperCase();
          }
          
          state.addresses.push(address);
          // If this is the first address or it's marked as default, update default
          if (state.addresses.length === 1 || address.isDefault) {
            state.defaultAddressId = address.id;
          }
          break;
          
        case 'update':
          // Find index and update
          const index = state.addresses.findIndex(addr => addr.id === address.id);
          if (index !== -1) {
            // Normalize country code
            if (address.country && address.country.length > 2) {
              address.country = address.country.substring(0, 2).toUpperCase();
            }
            
            state.addresses[index] = address;
          }
          
          // Update default status
          if (address.isDefault) {
            state.defaultAddressId = address.id;
            // Update other addresses to not be default
            state.addresses = state.addresses.map(addr => 
              addr.id !== address.id ? { ...addr, isDefault: false } : addr
            );
          }
          break;
          
        case 'remove':
          state.addresses = state.addresses.filter(addr => addr.id !== address.id);
          
          // Update default address if we removed the default
          if (state.defaultAddressId === address.id) {
            const newDefault = state.addresses.length > 0 ? state.addresses[0] : null;
            state.defaultAddressId = newDefault ? newDefault.id : null;
          }
          
          // Update selected address if we removed the selected one
          if (state.selectedAddressId === address.id) {
            state.selectedAddressId = state.defaultAddressId || 
              (state.addresses.length > 0 ? state.addresses[0].id : null);
          }
          break;
          
        case 'current':
          // Store temporary address data without persisting to the server
          // Normalize country to ensure it's a country code
          if (address.country && address.country.length > 2) {
            address.country = address.country.substring(0, 2).toUpperCase();
          }
          state.currentAddress = address;
          break;
          
        default:
          break;
      }
    },
    
    selectAddress: (state, { payload }) => {
      state.selectedAddressId = payload;
    },
    
    setDefaultAddress: (state, { payload }) => {
      state.defaultAddressId = payload;
      
      // Update addresses array to reflect the new default
      state.addresses = state.addresses.map(addr => ({
        ...addr,
        isDefault: addr.id === payload
      }));
    },
    
    setAddressLoading: (state, { payload }) => {
      state.loading = payload;
    },
    
    setAddressError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    
    clearAddressError: (state) => {
      state.error = null;
    },
    
    clearCurrentAddress: (state) => {
      state.currentAddress = null;
    }
  }
});

export const {
  setAddresses,
  setUserAddresses,
  selectAddress,
  setDefaultAddress,
  setAddressLoading,
  setAddressError,
  clearAddressError,
  clearCurrentAddress
} = addressSlice.actions;

// Selectors
export const selectAllAddresses = (state) => state.address.addresses;
export const selectDefaultAddress = (state) => {
  const { addresses, defaultAddressId } = state.address;
  return addresses.find(addr => addr.id === defaultAddressId);
};
export const selectSelectedAddress = (state) => {
  const { addresses, selectedAddressId } = state.address;
  return addresses.find(addr => addr.id === selectedAddressId);
};
export const selectCurrentAddress = (state) => state.address.currentAddress;
export const selectAddressLoading = (state) => state.address.loading;
export const selectAddressError = (state) => state.address.error;

export default addressSlice.reducer; 