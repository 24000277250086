import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../../redux/slices/listingSlice';
import FormInput from '../../ui/FormInput';
import Dropdown from '../../ui/Dropdown';

// Import constants from dedicated files
import { COMMON_CATEGORIES } from '../../../constants/categories';
import { COMMON_COLORS } from '../../../constants/colors';
import { COMMON_SIZES } from '../../../constants/sizes';
import { COMMON_MATERIALS } from '../../../constants/materials';
import { CONDITION_OPTIONS } from '../../../constants/conditions';

const DetailsSection = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);
  
  // Debug what's coming from Redux
  useEffect(() => {
    console.log('DetailsSection - formData:', formData);
    console.log('DetailsSection - category field:', formData.category);
    console.log('DetailsSection - condition field:', formData.condition);
    console.log('DetailsSection - productType field:', formData.productType);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    dispatch(updateFormData({
      [name]: type === 'number' ? Number(value) : value
    }));
  };
  
  // Determine which fields to show based on productType
  const isClosetSharing = formData?.productType === 'sharing';
  const isOriginalDesign = formData?.productType === 'designer';
  const showMaterial = isClosetSharing || isOriginalDesign;
  const showCondition = isClosetSharing;
  const showBrand = isClosetSharing;

  return (
    <div className="space-y-4 max-w-[1400px] mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-heading font-semibold text-gray-800 mb-4">Details</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Dropdown
          label="Category"
          title="Category"
          value={formData?.category || ''}
          onChange={(value) => handleInputChange({ target: { name: 'category', value }})}
          options={COMMON_CATEGORIES}
          error={fieldErrors?.category}
          required
          placeholder="Select category"
          className="w-full"
        />

        <div>
          <Dropdown
            title="Size"
            value={formData?.size || ''}
            onChange={(value) => handleInputChange({ target: { name: 'size', value }})}
            options={COMMON_SIZES}
            error={fieldErrors?.size}
            required
            placeholder="Select size"
            className="w-full"
          />
          {formData?.size === 'custom' && (
            <div className="mt-2">
              <FormInput
                label="Custom Size Details"
                name="customSizeDetails"
                type="text"
                value={formData?.customSizeDetails || ''}
                onChange={handleInputChange}
                placeholder="Enter measurements or size details"
                required
              />
            </div>
          )}
        </div>

        <Dropdown
          title="Color"
          value={formData?.color || ''}
          onChange={(value) => handleInputChange({ target: { name: 'color', value }})}
          options={COMMON_COLORS}
          error={fieldErrors?.color}
          required
          placeholder="Select color"
          className="w-full"
          renderOption={(option) => (
            option.value ? (
              <div className="flex items-center">
                <span 
                  className="inline-block w-4 h-4 mr-2 rounded-full" 
                  style={option.bgClass || {}}
                ></span>
                {option.label}
              </div>
            ) : option.label
          )}
        />
      </div>

      {/* Conditional Fields Section */}
      {(showCondition || showMaterial || showBrand) && (
        <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          {showCondition && (
            <Dropdown
              title="Condition"
              value={formData?.condition || ''}
              onChange={(value) => handleInputChange({ target: { name: 'condition', value }})}
              options={CONDITION_OPTIONS}
              error={fieldErrors?.condition}
              required={isClosetSharing}
              placeholder="Select condition"
              className="w-full"
            />
          )}

          {showMaterial && (
            <Dropdown
              title="Material"
              value={formData?.material || ''}
              onChange={(value) => handleInputChange({ target: { name: 'material', value }})}
              options={COMMON_MATERIALS}
              error={fieldErrors?.material}
              placeholder="Select material"
              className="w-full"
            />
          )}

          {showBrand && (
            <FormInput
              label="Brand"
              name="brand"
              type="text"
              value={formData?.brand || ''}
              onChange={handleInputChange}
              error={fieldErrors?.brand}
              placeholder="Enter brand name"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsSection; 