import SearchBox from '../../../ui/SearchBox';
import useFilter from '../useFilter';
import { useDispatch } from 'react-redux';
import { setSearchTerm } from '../../../../redux/slices/filterSlice';

const FilterSearchBox = ({ className = '' }) => {
  const { searchTerm, handleSearchChange } = useFilter();
  const dispatch = useDispatch();

  const handleChange = (value) => {
    dispatch(setSearchTerm(value));
  };

  return (
    <SearchBox
      title="Search"
      value={searchTerm}
      onChange={handleChange}
      // onSearch={handleSearchChange}
      placeholder="Search products..."
      maxWidth="none"
      className={`text-gray-500 ${className}`}
    />
  );
};

export default FilterSearchBox; 