import React from 'react';
import { useSelector } from 'react-redux';
import DeleteButton from './manage_components/DeleteButton';
import ActivationButton from './manage_components/ActivationButton';
import SelectionCheckbox from './manage_components/SelectionCheckbox';

const ListingManagement = () => {
  const { products } = useSelector(state => state.filter);
  const { selectedProducts } = useSelector(state => state.listing);
  
  const totalCount = products?.length || 0;
  const selectedCount = selectedProducts?.length || 0;

  if (selectedCount === 0) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 transform transition-transform duration-300 ease-in-out">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-6">
        <div className="bg-white shadow-lg rounded-t-lg border-t border-x border-gray-200">
          <div className="p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              {/* Selection Counter */}
              <SelectionCheckbox
                products={products}
                selectedCount={selectedCount}
                totalCount={totalCount}
              />

              {/* Action Buttons */}
              <div className="flex items-center gap-2">
                <ActivationButton
                  selectedProducts={selectedProducts}
                  selectedCount={selectedCount}
                />

                <DeleteButton
                  selectedCount={selectedCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingManagement;  