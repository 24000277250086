import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAddressesQuery } from '../../redux/apis';
import AddressList from '../../components/address/AddressList';
import AddressForm from '../../components/address/AddressForm';
import { selectCurrentAddress, clearCurrentAddress } from '../../redux/slices/addressSlice';
import Button from '../../components/ui/Button';

const Address = ({ userLang }) => {
  const dispatch = useDispatch();
  const { data: addresses = [], refetch: refetchAddresses } = useGetAddressesQuery();
  
  // Get current address from Redux store (populated by AddressAutocomplete)
  const currentAddressFromRedux = useSelector(selectCurrentAddress);
  
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editingAddress, setEditingAddress] = useState(null);
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  // Default empty address form
  const emptyAddressForm = {
    name: '',
    street: '',
    apartment: '',
    city: '',
    state: '',
    postalCode: '',
    country: '', // Let AddressForm set the default country
    phoneNumber: '',
    isDefault: false
  };

  const [formData, setFormData] = useState({
    addressForm: { ...emptyAddressForm }
  });

  // Handle cancel address edit
  const handleCancelAddressEdit = () => {
    setIsAddingAddress(false);
    setEditingAddress(null);
    setFormData(prev => ({
      ...prev,
      addressForm: { ...emptyAddressForm }
    }));
    // Clear the current address from Redux when canceling
    dispatch(clearCurrentAddress());
  };

  return (
    <>
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
          {errorMessage}
        </div>
      )}

      <div className="bg-white shadow-sm rounded-lg p-6 space-y-6">
        {/* Address List */}
        {!isAddingAddress && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">
                {userLang === 'zh-CN' ? '我的地址' : 'My Addresses'}
              </h3>
              <Button
                onClick={() => setIsAddingAddress(true)}
                variant="teal"
                size="sm"
              >
                {userLang === 'zh-CN' ? '添加新地址' : 'Add New Address'}
              </Button>
            </div>

            {/* Use our smart AddressList component */}
            <AddressList
              onEdit={(address) => {
                setEditingAddress(address);
                setFormData(prev => ({
                  ...prev,
                  addressForm: { ...address }
                }));
                // Clear any current address from Redux to avoid conflicts
                dispatch(clearCurrentAddress());
                setIsAddingAddress(true);
              }}
              emptyMessage={userLang === 'zh-CN' ? '你还没有保存的地址' : 'You have no saved addresses'}
              userLang={userLang}
            />
          </>
        )}

        {/* Address Form */}
        {isAddingAddress && (
          <>
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">
                {editingAddress 
                  ? (userLang === 'zh-CN' ? '编辑地址' : 'Edit Address') 
                  : (userLang === 'zh-CN' ? '添加新地址' : 'Add New Address')}
              </h3>
              <Button
                onClick={handleCancelAddressEdit}
                variant="secondary"
                size="sm"
              >
                {userLang === 'zh-CN' ? '返回' : 'Back to List'}
              </Button>
            </div>

            {/* Use our smart AddressForm component */}
            <AddressForm
              initialAddress={editingAddress ? formData.addressForm : currentAddressFromRedux || formData.addressForm}
              onSuccess={(savedAddress) => {
                setSuccessMessage(
                  editingAddress
                    ? (userLang === 'zh-CN' ? '地址已更新' : 'Address successfully updated')
                    : (userLang === 'zh-CN' ? '地址已添加' : 'Address successfully added')
                );
                setEditingAddress(null);
                setIsAddingAddress(false);
                // Clear the current address from Redux after saving
                dispatch(clearCurrentAddress());
                refetchAddresses();
              }}
              onCancel={handleCancelAddressEdit}
              userLang={userLang}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Address; 