import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Parallax } from 'react-parallax';

// Components to be implemented
import HowItWorksSection from './components/HowItWorksSection';
import SustainabilitySection from './components/SustainabilitySection';
import QuoteSection from './components/QuoteSection';
import MerchantSection from './components/MerchantSection';

// Note: Make sure the following CSS is included in your project for proper aspect ratios
// For the video section we use aspect-w-16 aspect-h-9, which requires the @tailwindcss/aspect-ratio plugin
// This should be included in your tailwind.config.js

// Detect user language
const userLang = navigator.language || navigator.userLanguage;

const AboutPage = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  // Handle navigation to other pages
  const handleNavigation = (path) => {
    history.push(path);
  };

  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);

    // Set loading state
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    // Handle window load event
    const handleLoad = () => {
      setLoading(false);
    };

    window.addEventListener('load', handleLoad);

    // Clean up
    return () => {
      clearTimeout(timer);
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="animate-spin h-12 w-12 mb-4 text-brand-teal">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <p className="text-xl font-medium text-gray-700">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section with Parallax */}
      <HowItWorksSection 
        userLang={userLang} 
        onBrowseClick={() => handleNavigation('/browse')} 
      />

      {/* Sustainability Section */}
      <div className="py-16 bg-gray-50">
        <SustainabilitySection userLang={userLang} />
      </div>

      {/* Quote Section with Parallax */}
      <QuoteSection userLang={userLang} />

      {/* Merchant Section */}
      <MerchantSection userLang={userLang} />
    </div>
  );
};

export default AboutPage; 