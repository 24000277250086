import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const MerchantSection = ({ userLang }) => {
  const history = useHistory();

  // Translation content
  const content = {
    title: userLang === 'zh-CN' ? '商户怎样使用平台' : 'How it Works For Business',
    description: [
      userLang === 'zh-CN' 
        ? '我们为个人、品牌、设计师和商家提供一个平台，用于出租、销售或交换他们的产品。'
        : 'We provide individuals, brands, designers and merchants with a platform to rent out, sell, or trade their products.',
      userLang === 'zh-CN'
        ? '一切都是高度可定制的。您可以选择您的设计价格以及想要出租的时长。'
        : 'Everything is highly customizable. You can choose how much your design is and how long you want to rent it out.',
      userLang === 'zh-CN'
        ? '您可以对客户进行评分，并决定他们是否需要支付押金。'
        : 'You can rate your customers and decide if they need to pay deposit.'
    ],
    benefits: [
      {
        title: userLang === 'zh-CN' ? '扩展您的受众' : 'Expand Your Audience',
        description: userLang === 'zh-CN'
          ? '接触您可能无法通过传统方式接触到的潜在客户。'
          : 'Reach potential customers you might not reach through traditional methods.'
      },
      {
        title: userLang === 'zh-CN' ? '增加收入' : 'Increase Revenue',
        description: userLang === 'zh-CN'
          ? '通过出租同一件商品多次来提高每件商品的收入。'
          : 'Boost revenue per item by renting the same piece multiple times.'
      },
      {
        title: userLang === 'zh-CN' ? '减少浪费' : 'Reduce Waste',
        description: userLang === 'zh-CN'
          ? '通过延长产品生命周期，为可持续时尚做出贡献。'
          : 'Contribute to sustainable fashion by extending product lifecycles.'
      },
      {
        title: userLang === 'zh-CN' ? '完全控制' : 'Full Control',
        description: userLang === 'zh-CN'
          ? '设置您自己的条款、价格和可用性。'
          : 'Set your own terms, pricing, and availability.'
      }
    ],
    cta: userLang === 'zh-CN' ? '成为商户' : 'Start in SHEYOU'
  };

  return (
    <div className="relative overflow-hidden bg-white">
      {/* Background image section */}
      <div className="h-80 w-full bg-cover bg-center" style={{ 
        backgroundImage: 'url(https://res.cloudinary.com/sheyou/image/upload/v1654418878/smiling_asian_girl_with_tablet_looking_at_camera_2022_01_19_00_09_04_utc_23ba1dbfe9.jpg)'
      }}>
        <div className="h-full w-full bg-brand-teal/70 flex items-center justify-center">
          <h2 className="text-4xl md:text-5xl font-bold text-white">
            {content.title}
          </h2>
        </div>
      </div>

      {/* Content section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-16">
        <div className="bg-white rounded-xl shadow-xl p-6 md:p-10">
          {/* Description */}
          <div className="max-w-3xl mx-auto mb-12">
            {content.description.map((paragraph, index) => (
              <p key={index} className="text-lg text-gray-700 mb-4">
                {paragraph}
              </p>
            ))}
          </div>

          {/* Benefits grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
            {content.benefits.map((benefit, index) => (
              <div key={index} className="flex">
                <CheckCircleIcon className="h-6 w-6 text-brand-teal flex-shrink-0 mt-1" />
                <div className="ml-4">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600">
                    {benefit.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* CTA Button */}
          <div className="flex justify-center">
            <button
              onClick={() => history.push('/register/merchant')}
              className="px-8 py-3 bg-brand-teal hover:bg-brand-teal-light text-white text-lg font-medium rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            >
              {content.cta}
            </button>
          </div>
        </div>
      </div>

      {/* Feature Image */}
      <div className="mt-16 h-80 w-full bg-cover bg-center" style={{ 
        backgroundImage: 'url(https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg)'
      }}>
      </div>
    </div>
  );
};

export default MerchantSection; 