import React from 'react';
import clsx from 'clsx';

const VARIANTS = {
  green: {
    base: 'text-green-700 bg-green-100 hover:bg-green-200 focus:ring-green-500',
    disabled: 'text-green-500 bg-green-50'
  },
  yellow: {
    base: 'text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500',
    disabled: 'text-yellow-500 bg-yellow-50'
  },
  red: {
    base: 'text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500',
    disabled: 'text-red-500 bg-red-50'
  },
  blue: {
    base: 'text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500',
    disabled: 'text-blue-500 bg-blue-50'
  }
};

const SIZES = {
  icon: 'w-9 h-9',
  small: 'px-2 py-1 text-sm',
  medium: 'px-3 py-1.5 text-sm',
  large: 'px-4 py-2 text-base'
};

const CardButton = ({
  children,
  variant = 'blue',
  size = 'medium',
  isLoading = false,
  disabled = false,
  icon,
  className,
  ...props
}) => {
  const variantStyles = VARIANTS[variant];
  const sizeStyles = SIZES[size];

  return (
    <button
      disabled={disabled || isLoading}
      className={clsx(
        'inline-flex items-center justify-center font-medium rounded-md',
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        'disabled:opacity-50 disabled:cursor-not-allowed',
        sizeStyles,
        disabled ? variantStyles.disabled : variantStyles.base,
        className
      )}
      {...props}
    >
      {isLoading ? (
        <div className="h-4 w-4 border-2 border-current border-t-transparent rounded-full animate-spin" />
      ) : icon ? (
        <div className="w-4 h-4">
          {icon}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default CardButton; 