import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import FormInput from '../../components/ui/FormInput';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const ContactPage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phonenumber: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  // Translation content
  const content = {
    title: userLang === 'zh-CN' ? '联系我们' : 'Contact Us',
    subtitle: userLang === 'zh-CN' 
      ? '有关于我们平台的问题？我们随时为您解答。' 
      : 'Have questions about our platform? We\'re here to help.',
    form: {
      firstName: userLang === 'zh-CN' ? '名字' : 'First Name',
      lastName: userLang === 'zh-CN' ? '姓氏' : 'Last Name',
      phoneNumber: userLang === 'zh-CN' ? '电话号码' : 'Phone Number',
      email: userLang === 'zh-CN' ? '邮箱地址' : 'Email',
      message: userLang === 'zh-CN' ? '留言' : 'Message',
      send: userLang === 'zh-CN' ? '发送消息' : 'Send Message',
      sending: userLang === 'zh-CN' ? '发送中...' : 'Sending...',
    },
    status: {
      error: userLang === 'zh-CN' ? '错误' : 'Error',
      success: userLang === 'zh-CN' ? '成功' : 'Success',
      successMessage: userLang === 'zh-CN' 
        ? '消息发送成功。我们会尽快回复您！' 
        : 'Message sent successfully. We\'ll get back to you soon!',
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: null });

    const { firstname, lastname, phonenumber, email, message } = formData;
    const verificationCode = `${message}, with phone number: ${phonenumber}, and email: ${email}`;
    const name = `${firstname} ${lastname}`;

    try {
      await emailjs.send(
        'service_orld5qb',
        'template_l5xa2ig',
        {
          email: 'support@sheyou.com',
          name,
          verificationCode,
          reply_to: 'support@sheyou.com'
        },
        'user_4rMSnDYBigUutDlR5RSib'
      );
      setStatus({ loading: false, success: true, error: null });
    } catch (error) {
      setStatus({ 
        loading: false, 
        success: false, 
        error: `Failed to send message: ${error.message}` 
      });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Contact Card */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Header */}
          <div className="text-center py-12 px-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">{content.title}</h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              {content.subtitle}
            </p>
          </div>

          {/* Contact Form */}
          <form onSubmit={sendMessage} className="px-6 py-8 space-y-6">
            {/* Name Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormInput
                label={content.form.firstName}
                type="text"
                name="firstname"
                id="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
              <FormInput
                label={content.form.lastName}
                type="text"
                name="lastname"
                id="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
              />
            </div>

            {/* Contact Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormInput
                label={content.form.phoneNumber}
                type="tel"
                name="phonenumber"
                id="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
                required
              />
              <FormInput
                label={content.form.email}
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            {/* Message Field */}
            <FormInput
              label={content.form.message}
              as="textarea"
              name="message"
              id="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
            />

            {/* Status Messages */}
            {status.error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{content.status.error}</h3>
                    <div className="mt-2 text-sm text-red-700">
                      <p>{status.error}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {status.success && (
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">{content.status.success}</h3>
                    <div className="mt-2 text-sm text-green-700">
                      <p>{content.status.successMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={status.loading}
                className={`
                  inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md shadow-sm
                  ${status.loading 
                    ? 'bg-brand-orange/70 cursor-not-allowed' 
                    : 'bg-brand-orange hover:bg-brand-orange/90'
                  }
                  text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-orange
                `}
              >
                {status.loading ? content.form.sending : content.form.send}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage; 