import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetProfileQuery } from '../../../redux/apis/endpoints/profileApi';
import Contact from '../../../components/settings/Contact';
import Address from '../../../components/settings/Address';
import Security from '../../../components/settings/Security';

const userLang = navigator.language || navigator.userLanguage;

const Settings = () => {
  const { data: user, refetch: refetchUser } = useGetProfileQuery();
  const [activeTab, setActiveTab] = useState('contact');

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
    height: '',
    weight: '',
    bodytype: '',
    bustsize: '',
    clothingsize: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  // Update form data when user data is loaded
  useEffect(() => {
    if (user) {
      setFormData(prev => ({
        ...prev,
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        email: user.email || '',
        phonenumber: user.phonenumber || '',
        height: user.height || '',
        weight: user.weight || '',
        bodytype: user.bodytype || '',
        bustsize: user.bustsize || '',
        clothingsize: user.clothingsize || ''
      }));
    }
  }, [user]);

  const tabs = [
    {
      id: 'contact',
      label: userLang === 'zh-CN' ? '联系信息' : 'Contact'
    },
    {
      id: 'address',
      label: userLang === 'zh-CN' ? '地址信息' : 'Address'
    },
    {
      id: 'security',
      label: userLang === 'zh-CN' ? '账户安全' : 'Security'
    }
  ];

  return (
    <div className="p-6">
      {/* Header */}
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {userLang === 'zh-CN' ? '设置' : 'Settings'}
        </h2>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab.id
                  ? 'border-brand-teal text-brand-teal'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>

      {/* Contact (First Tab) */}
      {activeTab === 'contact' && (
        <Contact 
          user={user} 
          formData={formData} 
          setFormData={setFormData} 
          userLang={userLang} 
          refetchUser={refetchUser}
        />
      )}

      {/* Address Information (Second Tab) */}
      {activeTab === 'address' && (
        <Address userLang={userLang} />
      )}

      {/* Security (Third Tab) */}
      {activeTab === 'security' && (
        <Security 
          user={user} 
          formData={formData} 
          setFormData={setFormData} 
                userLang={userLang}
              />
      )}
    </div>
  );
};

export default Settings; 