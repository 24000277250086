import { searchClient } from './config';

// Helper to get base filters based on route and state
export const getBaseFilters = (state) => {
  const pathname = window.location.pathname;
  
  if (pathname.includes('/listing')) {
    return { userFilter: `users_permissions_user.id:${state.auth?.user?.id}` };
  } else if (pathname.includes('/favorites')) {
    console.log('Favorites filter - User ID:', state.auth?.user?.id);
    return { 
      userFilter: `being_liked_by.id = ${state.auth?.user?.id} AND activeListing:true AND NOT stock:"0"`
    };
  } else { 
    return { baseFilter: 'activeListing:true AND NOT stock:"0"' };
  }
};

// Helper to convert filters to Algolia format
export const getAlgoliaFilters = (filters, state) => {
  if (!filters) return '';
  
  const filterStrings = [];
  const baseFilters = getBaseFilters(state);
  
  // Add base filters first
  if (baseFilters.userFilter) {
    filterStrings.push(baseFilters.userFilter);
  }
  if (baseFilters.baseFilter) {
    filterStrings.push(baseFilters.baseFilter);
  }

  // Product type filter
  if (filters.productType) {
    filterStrings.push(`productType:"${filters.productType}"`);
  }

  // Transaction type filters - only add if true
  if (filters.forSell === true) filterStrings.push('forSell:true');
  if (filters.forRent === true) filterStrings.push('forRent:true');
  if (filters.openToSwap === true) filterStrings.push('openToSwap:true');

  // For browse page, handle delivery method filters
  if (!window.location.pathname.includes('/favorites') && !window.location.pathname.includes('/listing')) {
    const hasShippingFilter = filters.openToShipping === true;
    
    // Check if location is available
    const hasValidLocation = filters.location && 
                            filters.location.latitude && 
                            filters.location.longitude && 
                            !state.location?.error;
                            
    const hasPickupFilter = filters.localPickup?.enabled === true;
    
    // -------------------------
    // LOCATION IS NOT AVAILABLE
    // -------------------------
    if (!hasValidLocation) {
      // Case 1: Shipping unchecked (default)
      if (!hasShippingFilter) {
        // Show all products except pickup-only products
        filterStrings.push('(NOT openToShipping:false OR NOT openToPickUp:true)');
        console.log('Adding filter to exclude pickup-only products (no location)');
      } 
      // Case 2: Shipping checked
      else {
        // Show only shipping products
        filterStrings.push('openToShipping:true');
        console.log('Adding shipping-only filter');
      }
    }
    // -------------------------
    // LOCATION IS AVAILABLE
    // -------------------------
    else {
      // Get coordinates for geo filter
      const hasPickupDistance = filters.localPickup?.distance !== undefined;
      
      // Case 1: Both shipping and pickup selected
      if (hasShippingFilter && hasPickupFilter) {
        // Show products with either shipping OR pickup
        filterStrings.push('(openToShipping:true OR openToPickUp:true)');
        console.log('Added filter for shipping OR pickup');
      }
      // Case 2: Only shipping selected
      else if (hasShippingFilter) {
        // Show only shipping products
        filterStrings.push('openToShipping:true');
        console.log('Added shipping-only filter');
      }
      // Case 3: Only pickup selected
      else if (hasPickupFilter) {
        // Show only pickup products
        filterStrings.push('openToPickUp:true');
        console.log('Added pickup-only filter');
      }
      // Case 4: Neither selected (default)
      // else {
      //   // Show all products but exclude pickup-only products
      //   filterStrings.push('(NOT openToShipping:false OR NOT openToPickUp:true)');
      //   console.log('Added default filter to exclude pickup-only products');
      // }
    }
  }

  // Continue with other filters...
  // Direct enumeration filters
  if (filters.category) filterStrings.push(`category:"${filters.category}"`);
  if (filters.size) filterStrings.push(`size:"${filters.size}"`);
  if (filters.color) filterStrings.push(`color:"${filters.color}"`);
  if (filters.condition) filterStrings.push(`condition:"${filters.condition}"`);
  if (filters.material) filterStrings.push(`material:"${filters.material}"`);
  if (filters.brand) filterStrings.push(`brand:"${filters.brand}"`);
  
  // Price range filter
  if (filters.price?.min !== undefined && filters.price?.max !== undefined) {
    filterStrings.push(`price:${filters.price.min} TO ${filters.price.max}`);
  } else if (filters.price?.min !== undefined) {
    filterStrings.push(`price >= ${filters.price.min}`);
  } else if (filters.price?.max !== undefined) {
    filterStrings.push(`price <= ${filters.price.max}`);
  }

  // Rental price filter
  if (filters.rentPrice?.min !== undefined || filters.rentPrice?.max !== undefined) {
    // For rental prices, we'll need a more complex filter since rentPriceAndTime is an array
    // This assumes we're filtering on the lowest rental price option for now
    if (filters.rentPrice.min !== undefined && filters.rentPrice.max !== undefined) {
      filterStrings.push(`rentPriceAndTime.price:${filters.rentPrice.min} TO ${filters.rentPrice.max}`);
    } else if (filters.rentPrice.min !== undefined) {
      filterStrings.push(`rentPriceAndTime.price >= ${filters.rentPrice.min}`);
    } else if (filters.rentPrice.max !== undefined) {
      filterStrings.push(`rentPriceAndTime.price <= ${filters.rentPrice.max}`);
    }
  }

  const finalFilter = filterStrings.join(' AND ');
  console.log('Final Algolia filter:', finalFilter);
  return finalFilter;
};

// Main search function
export const searchProducts = async ({ 
  searchTerm, 
  filters, 
  sortBy, 
  offset = 0,
  location // Location parameter for geolocation search
}, getState) => {
  try {
    // Get the correct index based on sort
    const indexName = sortBy || 'products';
    const index = searchClient.initIndex(indexName);

    // Get location status
    const state = getState();
    const hasLocationError = state.location?.error;
    const hasValidLocation = location?.latitude && 
                           location?.longitude && 
                           !hasLocationError;

    // Get base filter string without delivery method filters
    const baseFilterStrings = [];
    const baseFilters = getBaseFilters(state);
    
    // Add base filters first
    if (baseFilters.userFilter) {
      baseFilterStrings.push(baseFilters.userFilter);
    }
    if (baseFilters.baseFilter) {
      baseFilterStrings.push(baseFilters.baseFilter);
    }

    // Add other non-delivery filters
    if (filters.productType) {
      baseFilterStrings.push(`productType:"${filters.productType}"`);
    }
    if (filters.forSell === true) baseFilterStrings.push('forSell:true');
    if (filters.forRent === true) baseFilterStrings.push('forRent:true');
    if (filters.openToSwap === true) baseFilterStrings.push('openToSwap:true');
    if (filters.category) baseFilterStrings.push(`category:"${filters.category}"`);
    if (filters.size) baseFilterStrings.push(`size:"${filters.size}"`);
    if (filters.color) baseFilterStrings.push(`color:"${filters.color}"`);
    if (filters.condition) baseFilterStrings.push(`condition:"${filters.condition}"`);
    if (filters.material) baseFilterStrings.push(`material:"${filters.material}"`);
    if (filters.brand) baseFilterStrings.push(`brand:"${filters.brand}"`);

    // Combine base filters
    const baseFilter = baseFilterStrings.join(' AND ');
    
    // Now build the delivery method filter according to the filter matrix
    const hasShippingFilter = filters.openToShipping === true;
    const hasPickupFilter = filters.localPickup?.enabled === true;
    let deliveryFilter = '';
    let useGeoSearch = false;
    
    // Check current path - only apply delivery filters to browse and favorites, not listings
    const pathname = window.location.pathname;
    const isListingsPage = pathname.includes('/listing');
    const isFavoritesPage = pathname.includes('/favorites');
    const isBrowsePage = !isListingsPage && !isFavoritesPage;
    
    // Skip delivery method filtering for listings page
    if (isListingsPage) {
      console.log('Skipping delivery method filtering for listings page');
    }
    // Apply delivery method filtering for browse and favorites pages
    else {
      // -------------------------
      // LOCATION IS NOT AVAILABLE
      // -------------------------
      if (!hasValidLocation) {
        // Case 1: Shipping unchecked (default)
        if (!hasShippingFilter) {
          // Show all products except pickup-only products
          deliveryFilter = '(NOT openToShipping:false OR NOT openToPickUp:true)';
          console.log('Adding filter to exclude pickup-only products (no location)');
        } 
        // Case 2: Shipping checked
        else {
          // Show only shipping products
          deliveryFilter = 'openToShipping:true';
          console.log('Adding shipping-only filter');
        }
      }
      // -------------------------
      // LOCATION IS AVAILABLE
      // -------------------------
      else {
        // Case 1: Both shipping and pickup selected
        if (hasShippingFilter && hasPickupFilter) {
          // Show products with either shipping OR pickup 
          deliveryFilter = '(openToShipping:true OR openToPickUp:true)';
          useGeoSearch = true; // We'll apply geo search to affect ranking
        }
        // Case 2: Only shipping selected
        else if (hasShippingFilter) {
          // Show only shipping products
          deliveryFilter = 'openToShipping:true';
        }
        // Case 3: Only pickup selected
        else if (hasPickupFilter) {
          // Show only pickup products
          deliveryFilter = 'openToPickUp:true';
          useGeoSearch = true; // Apply geo search for distance filtering
        }
        // Case 4: Neither selected (default)
        else {
          // Show all products but exclude pickup-only products
          deliveryFilter = '(NOT openToShipping:false OR NOT openToPickUp:true)';
          useGeoSearch = true; // Apply geo search to filter distant pickup-only products
        }
      }
    }
    
    // Combine all filters
    let finalFilter = baseFilter;
    if (deliveryFilter && finalFilter) {
      finalFilter += ` AND (${deliveryFilter})`;
    } else if (deliveryFilter) {
      finalFilter = deliveryFilter;
    }
    
    console.log('Final filter string:', finalFilter);

    // Prepare search parameters
    const searchParams = {
      query: searchTerm || '',
      filters: finalFilter,
      page: Math.floor(offset / 20),
      hitsPerPage: 20,
      attributesToRetrieve: [
        // Essential info
        'objectID', 'id', 'name', 'description', 'price',
        'productType', 'stock', 'activeListing', 'brand',
        'created_at', 'updated_at', 'published_at',
        
        // Transaction types
        'forSell', 'forRent', 'openToSwap',
        'openToPickUp', 'openToShipping',
        
        // Product attributes
        'category', 'size', 'color', 'condition', 'material', 
        'customSizeDetails',
        
        // Images
        'photos', 'coverImageIndex', 'photo',
        
        // Rental info
        'rentPriceAndTime', 'rentDeposit', 'minRentalDays', 'maxRentalDays',
        
        // Related metadata
        'users_permissions_user', 'pickupAddress',
        'likes_count', 'being_liked_by',
        
        // Location data
        '_geoloc',
        
        // Tags
        '_tags'
      ],
      attributesToHighlight: ['name', 'description']
    };
    
    // Apply geo search only when needed (for pickup products)
    // But only for browse and favorites pages, never for listings page
    if (useGeoSearch && hasValidLocation && !isListingsPage) {
      // Special case for both filters checked
      if (hasShippingFilter && hasPickupFilter) {
        // Don't use geo search at all for this case - will filter client-side
        // This ensures shipping products aren't affected by geo filtering
        console.log('Skipping geo search - will filter pickup products client-side');
      }
      // All other cases - use standard geo search
      else {
        // Use Algolia's geo-filtering capabilities
        searchParams.aroundLatLng = `${location.latitude}, ${location.longitude}`;
        
        // Apply appropriate radius based on case
        if (hasPickupFilter) {
          // Use user-selected distance for pickup filter
          const radius = filters.localPickup?.distance || 5;
          searchParams.aroundRadius = radius * 1000; // Convert km to meters
          console.log(`Using radius of ${radius}km for pickup filter`);
        } else {
          // Use default distance (5km) for excluding distant pickup-only products
          searchParams.aroundRadius = 5000; // 5km
          console.log('Using default radius of 5km for pickup-only products');
        }
      }
    }

    console.log('Search parameters:', searchParams);

    const { hits, nbHits, page, nbPages } = await index.search(searchTerm || '', searchParams);
    console.log('Search results:', { hits: hits.length, nbHits, page, nbPages });

    // Filter out distant pickup-only products client-side when we can't
    // use Algolia filtering effectively (for case 1: both filters checked)
    // But only for browse and favorites pages, never for listings page
    if (hasValidLocation && hasShippingFilter && hasPickupFilter && !isListingsPage) {
      const userRadius = (filters.localPickup?.distance || 5) * 1000; // in meters
      const filteredHits = hits.filter(hit => {
        // Keep all shipping products
        if (hit.openToShipping) return true;
        
        // Only keep pickup products if they're within range
        if (hit.openToPickUp && hit._geoloc) {
          const productLat = hit._geoloc.lat;
          const productLng = hit._geoloc.lng;
          
          // Skip if missing location data
          if (!productLat || !productLng) return false;
          
          // Calculate distance
          const distance = calculateDistance(
            location.latitude, location.longitude,
            productLat, productLng
          );
          
          return distance <= userRadius;
        }
        
        return false;
      });
      
      console.log(`Filtered ${hits.length - filteredHits.length} products by distance client-side`);
      
      return {
        products: filteredHits.map(hit => ({
          ...hit,
          id: hit.objectID,
          likes_count: hit.likes_count || 0
        })),
        totalResults: filteredHits.length,
        currentPage: page,
        totalPages: nbPages
      };
    }

    return {
      products: hits.map(hit => ({
        ...hit,
        id: hit.objectID,
        likes_count: hit.likes_count || 0
      })),
      totalResults: nbHits,
      currentPage: page,
      totalPages: nbPages
    };
  } catch (error) {
    console.error('Algolia search error:', error);
    throw error;
  }
}; 

// Helper function to calculate distance between two geo points in meters
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371000; // Earth radius in meters
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c; // Distance in meters
} 