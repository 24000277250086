import React from 'react';
import Section from '../Section';

const HeroSection = () => {
  const userLang = navigator.language || navigator.userLanguage;

  return (
    <Section
      fullWidth
      minHeight="min-h-screen"
      bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727638013/exchange_8ed19f8200.svg"
      bgOverlay="rgba(255, 255, 255, 0.5)"
      padding="py-0"
      className="text-gray-900 flex items-center justify-center"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 text-center relative z-10">
        {/* Main Title Section - Always Visible */}
        <div className="mb-8 sm:mb-12">
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-4 sm:mb-6 leading-tight">
            {userLang === 'zh-CN' ? '发现，穿搭，分享' : 'Discover, Wear, Share'}
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto">
            {userLang === 'zh-CN' 
              ? '加入我们的时尚社区，探索无限可能' 
              : 'Join our fashion community and explore endless possibilities'}
          </p>
        </div>

        {/* CTA Button - Prominent Placement */}
        <a 
          href="/browse"
          className="inline-flex items-center px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-semibold rounded-md bg-brand-orange text-white hover:bg-brand-orange/90 transition-colors duration-200 mb-8 sm:mb-16 shadow-lg hover:shadow-xl"
        >
          {userLang === 'zh-CN' ? '开始探索' : 'Start Exploring'}
        </a>

        {/* Feature Cards - Collapsible on Mobile */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 max-w-6xl mx-auto">
          {/* Discover Card */}
          <div className="group">
            <div className="text-center p-4 sm:p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 text-brand-orange">
                {userLang === 'zh-CN' ? '发现' : 'Discover'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700 transition-all duration-300 overflow-hidden">
                {userLang === 'zh-CN' 
                  ? '在线或本地探索独特时尚单品，找到专属于你的风格' 
                  : 'Find unique pieces locally or online that speak to your personal style'}
              </p>
            </div>
          </div>

          {/* Wear Card */}
          <div className="group">
            <div className="text-center p-4 sm:p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 text-brand-orange">
                {userLang === 'zh-CN' ? '穿搭' : 'Wear'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700 transition-all duration-300 overflow-hidden">
                {userLang === 'zh-CN' 
                  ? '以最具创意的方式演绎时尚，租赁或购买心仪单品' 
                  : 'Experience fashion in the most imaginative way whether renting or virtual items'}
              </p>
            </div>
          </div>

          {/* Share Card */}
          <div className="group">
            <div className="text-center p-4 sm:p-6 bg-white/80 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 text-brand-orange">
                {userLang === 'zh-CN' ? '分享' : 'Share'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700 transition-all duration-300 overflow-hidden">
                {userLang === 'zh-CN' 
                  ? '分享你的设计灵感或轻松上架闲置衣物' 
                  : 'Share your designs or easily list your closet items for others to discover'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HeroSection; 