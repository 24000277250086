import { useGetCurrentUserQuery } from '../../../redux/apis';
import { useHistory } from 'react-router-dom';

const userLang = navigator.language || navigator.userLanguage;

const ProfileWallet = () => {
  const history = useHistory();
  const { data: user } = useGetCurrentUserQuery();

  const handleStripeVerification = () => {
    history.push('/merchant/verify');
  };

  return (
    <div className="p-6 space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-bold text-gray-900">
          {userLang === 'zh-CN' ? '钱包' : 'Wallet'}
        </h2>
      </div>

      {/* Balance Card */}
      <div className="bg-gradient-to-r from-brand-teal to-brand-teal-light rounded-lg shadow-lg p-6 text-white">
        <div className="space-y-1">
          <p className="text-sm opacity-90">
            {userLang === 'zh-CN' ? '当前余额' : 'Available Balance'}
          </p>
          <p className="text-3xl font-bold">
            ${user?.stripeAccountDetail?.available?.[0]?.amount || '0.00'}
          </p>
        </div>
        <div className="mt-4">
          <p className="text-sm opacity-90">
            {userLang === 'zh-CN' ? '待结算' : 'Pending'}
          </p>
          <p className="text-xl font-semibold">
            ${user?.stripeAccountDetail?.pending?.[0]?.amount || '0.00'}
          </p>
        </div>
      </div>

      {/* Merchant Verification Status */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {userLang === 'zh-CN' ? '商家认证' : 'Merchant Verification'}
        </h3>
        
        {user?.isMerchantOnboard ? (
          <div className="flex items-center text-green-600">
            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span className="font-medium">
              {userLang === 'zh-CN' ? '已认证' : 'Verified'}
            </span>
          </div>
        ) : (
          <div>
            <div className="flex items-center text-yellow-600 mb-4">
              <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span className="font-medium">
                {userLang === 'zh-CN' ? '未认证' : 'Not Verified'}
              </span>
            </div>
            <button
              onClick={handleStripeVerification}
              className="w-full bg-brand-teal hover:bg-brand-teal-dark text-white font-medium py-2 px-4 rounded-md transition-colors"
            >
              {userLang === 'zh-CN' ? '完成认证' : 'Complete Verification'}
            </button>
          </div>
        )}
      </div>

      {/* Transaction History */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {userLang === 'zh-CN' ? '最近交易' : 'Recent Transactions'}
        </h3>
        {user?.stripeAccountDetail?.transactions?.length > 0 ? (
          <div className="space-y-4">
            {user.stripeAccountDetail.transactions.map((transaction, index) => (
              <div key={index} className="flex items-center justify-between py-3 border-b last:border-0">
                <div>
                  <p className="font-medium text-gray-900">{transaction.description}</p>
                  <p className="text-sm text-gray-500">{new Date(transaction.created * 1000).toLocaleDateString()}</p>
                </div>
                <div className={`font-medium ${transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {transaction.amount > 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center py-4">
            {userLang === 'zh-CN' ? '暂无交易记录' : 'No transactions yet'}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProfileWallet; 