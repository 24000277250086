import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoginMutation } from '../../redux/apis';
import { setCredentials } from '../../redux/slices/authSlice';
import { setUserInfo, setToken, setMerchantLogin } from '../../redux/slices/userSlice';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import newOTP from 'otp-generators';
import emailjs from 'emailjs-com';
import jwt from 'jsonwebtoken';

const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();

  // Get login type from URL params
  const searchParams = new URLSearchParams(location.search);
  const isMerchant = searchParams.get('type') === 'merchant';

  const [formState, setFormState] = useState({
    username: '',
    password: '',
    rememberMe: false,
    error: null
  });

  useEffect(() => {
    // Check if there's a stored token and redirect if already logged in
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('userInfo');
    if (storedToken && storedUser) {
      try {
        const user = JSON.parse(storedUser);
        dispatch(setCredentials({ user, token: storedToken }));
        dispatch(setUserInfo(user));
        dispatch(setToken(storedToken));
        if (user.merchantInfo?.isMerchant) {
          dispatch(setMerchantLogin(true));
        }
        history.push('/dashboard');
      } catch (error) {
        // If there's an error parsing stored data, clear it
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
      }
    }

    // Handle remember me
    const remember = localStorage.getItem('rememberMe');
    const savedUsername = localStorage.getItem('identifier');
    if (remember === 'true' && savedUsername) {
      setFormState(prev => ({
        ...prev,
        rememberMe: true,
        username: savedUsername
      }));
    }
  }, [dispatch, history, isMerchant]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, error: null }));

    try {
      const result = await login({
        identifier: formState.username,
        password: formState.password
      }).unwrap();

      const { user, jwt: token } = result;
      const { merchantInfo, merchantConfirmed, username, email } = user;

      // Check if merchant trying to access regular login or vice versa
      if (isMerchant && !merchantInfo?.isMerchant) {
        setFormState(prev => ({
          ...prev,
          error: 'This account is not registered as a merchant'
        }));
        return;
      }

      // Store in Redux - both in authSlice and userSlice
      dispatch(setCredentials({ user, token }));
      dispatch(setUserInfo(user));
      dispatch(setToken(token));

      // Set merchant login state if applicable
      if (merchantInfo?.isMerchant && merchantConfirmed) {
        dispatch(setMerchantLogin(true));
      }

      // Handle remember me and store credentials
      if (formState.rememberMe) {
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('identifier', formState.username);
      } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('identifier');
      }

      // Store auth data
      localStorage.setItem('token', token);
      localStorage.setItem('userInfo', JSON.stringify(user));
      localStorage.setItem('login', 'true');
      localStorage.setItem('loginTime', Date.now().toString());

      // Handle email verification if needed
      if (!user.confirmed) {
        const verificationCode = newOTP.generate(6, {
          alphabets: false,
          upperCase: false,
          specialChar: false
        });

        const verificationToken = jwt.sign(
          { data: verificationCode },
          'sheyoufashion',
          { expiresIn: '5min' }
        );

        try {
          await emailjs.send(
            'service_orld5qb',
            'template_c1h9qsr',
            {
              email,
              reply_to: 'susanna.xinshu@gmail.com',
              name: username,
              verificationCode
            },
            'user_4rMSnDYBigUutDlR5RSib'
          );
          
          localStorage.setItem('verificationCode', verificationToken);
          history.push('/dashboard');
        } catch (error) {
          setFormState(prev => ({
            ...prev,
            error: 'Email confirmation failed. Please contact support.'
          }));
        }
        return;
      }

      // Always redirect to dashboard after successful login
      history.push('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setFormState(prev => ({
        ...prev,
        error: error.status === 400
          ? 'Wrong username & password combination'
          : 'An error occurred. Please try again.'
      }));

      // Clear localStorage on error
      localStorage.removeItem('identifier');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-brand-teal to-brand-orange/50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="bg-white py-8 px-4 shadow-xl rounded-lg sm:px-10">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
            {isMerchant ? 'Merchant Login' : 'Welcome Back'}
          </h2>

          {/* Error message */}
          {formState.error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Login Failed</h3>
                  <p className="mt-2 text-sm text-red-700">{formState.error}</p>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Username / Email
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  value={formState.username}
                  onChange={(e) => setFormState(prev => ({ ...prev, username: e.target.value }))}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-teal focus:border-brand-teal sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formState.password}
                  onChange={(e) => setFormState(prev => ({ ...prev, password: e.target.value }))}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brand-teal focus:border-brand-teal sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={formState.rememberMe}
                  onChange={(e) => setFormState(prev => ({ ...prev, rememberMe: e.target.checked }))}
                  className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="/forgot-password" className="font-medium text-brand-teal hover:text-brand-teal-dark">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-teal hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Signing in...
                  </span>
                ) : 'Sign in'}
              </button>
            </div>

            <div className="text-center text-sm">
              <span className="text-gray-600">Don't have an account?</span>{' '}
              <a 
                href={isMerchant ? "/merchant/open-shop" : "/signup"} 
                className="font-medium text-brand-teal hover:text-brand-teal-dark"
              >
                {isMerchant ? 'Open a shop' : 'Sign up'}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage; 