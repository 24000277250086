/**
 * Validates a product listing form data
 * @param {Object} formData - The form data to validate
 * @returns {Object} An object with field names as keys and error messages as values
 */
export const validateListingForm = (formData) => {
  const errors = {};
  
  console.log("Validating form data:", formData);
  
  // Required fields
  if (!formData.name) errors.name = 'Name is required';
  if (!formData.description) errors.description = 'Description is required';
  if (!formData.category) errors.category = 'Category is required';
  if (!formData.size) errors.size = 'Size is required';
  if (!formData.color) errors.color = 'Color is required';
  
  // Product Type validation
  if (!formData.productType) errors.productType = 'Product Type is required';
  
  // Condition is only required for closet sharing items
  if (formData.productType === 'sharing' && !formData.condition) {
    errors.condition = 'Condition is required for closet sharing items';
  }
  
  // Photos validation
  if (!formData.photos || formData.photos.length === 0) {
    errors.photos = 'At least one photo is required';
  }
  
  // Stock validation - must be greater than 0
  if (formData.stock === 0 || formData.stock === '0' || formData.stock === '' || !formData.stock) {
    errors.stock = 'Stock quantity must be at least 1';
  }
  
  // Transaction type validation
  if (!formData.forSell && !formData.forRent && !formData.openToSwap) {
    errors.transactionType = 'Select at least one transaction type';
  }
  
  // Delivery options validation - Skip for virtual products
  if (formData.productType !== 'virtual' && !formData.openToShipping && !formData.openToPickUp) {
    errors.deliveryOptions = 'Select at least one delivery option (shipping or pickup)';
  }
  
  // Sale price validation - ONLY if forSell is true
  if (formData.forSell && (!formData.price || formData.price === '' || isNaN(parseFloat(formData.price)) || parseFloat(formData.price) <= 0)) {
    errors.price = 'Please enter a valid sale price greater than 0';
  }
  
  // Rent prices validation - ONLY if forRent is true
  if (formData.forRent) {
    const rentPrices = formData.rentPriceAndTime || [];
    if (rentPrices.length === 0) {
      errors.rentPriceAndTime = 'Please add at least one rental period and price';
    } else {
      const hasInvalidPrices = rentPrices.some(
        rental => !rental.option || !rental.price ||!rental.number || isNaN(parseFloat(rental.price)) || parseFloat(rental.price) <= 0
      );
      if (hasInvalidPrices) {
        errors.rentPriceAndTime = 'Please complete all rental periods and prices with values greater than 0';
      }
    }
  }
  
  // Pickup address validation - ONLY if openToPickUp is true
  if (formData.openToPickUp && (!formData.pickUpAddress || !formData.selectedAddressId)) {
    errors.pickUpAddress = 'Please select or add a pickup address';
  }
  
  console.log("Validation errors:", errors);
  
  return errors;
};

/**
 * Check if an object is empty
 * @param {Object} obj - The object to check
 * @returns {boolean} True if the object is empty, false otherwise
 */
export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

/**
 * Validate a number value
 * @param {any} value - The value to check
 * @returns {boolean} True if the value is a valid number, false otherwise
 */
export const isNumber = (value) => {
  if (value === '' || value === null || value === undefined) return false;
  const num = Number(value);
  return !isNaN(num) && typeof num === 'number';
}; 