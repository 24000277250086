import React, { useEffect, useState, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCurrentUserQuery, useGetCartQuery } from '../../redux/apis';
import { logout } from '../../redux/slices/authSlice';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { 
  ShoppingCartIcon, 
  HeartIcon,
  UserIcon,
  Bars3Icon,
  XMarkIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline';

const userLang = navigator.language || navigator.userLanguage;

// Cart icon component with badge
const CartIcon = ({ cartCount, className = "h-5 w-5" }) => (
  <div className="relative">
    <ShoppingCartIcon className={className} />
    {cartCount > 0 && (
      <span className="absolute -top-3 -right-3 bg-yellow-400 text-black text-xs rounded-full h-5 w-5 flex items-center justify-center">
        {cartCount}
      </span>
    )}
  </div>
);

const Nav = () => {
  const [activeItem, setActiveItem] = useState('');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const { isLoggedIn, userInfo } = useSelector((state) => {
    return {
      isLoggedIn: state.auth?.isAuthenticated ?? false,
      userInfo: state.auth?.user ?? null
    };
  }, (prev, next) => prev.isLoggedIn === next.isLoggedIn && prev.userInfo === next.userInfo);
  
  const { data: user, refetch } = useGetCurrentUserQuery(undefined, {
    skip: !isLoggedIn,
  });
  
  // Add cart query to get real-time cart data
  const { data: cartData } = useGetCartQuery(undefined, {
    skip: !isLoggedIn,
  });

  const identifier = userInfo?.username || userInfo?.email;

  useEffect(() => {
    const menus = [
      { include: 'browse', activeItem: 'Browse' },
      { include: 'about', activeItem: 'About' },
      { include: 'contact', activeItem: 'Contact' }
    ];

    const pathname = location.pathname.toLowerCase();
    menus.forEach((each) => {
      if (pathname.includes(each.include.toLowerCase())) {
        setActiveItem(each.activeItem);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn, refetch]);

  const cartNum = React.useMemo(() => {
    if (!isLoggedIn) return 0;
    
    // Use cartData from the query if available
    if (cartData && cartData.cartItems) {
      return cartData.cartItems.length;
    }
    
    // Fallback to user.cart if cartData is not available
    if (user && user.cart) {
      return user.cart.length || 0;
    }
    
    return 0;
  }, [isLoggedIn, cartData, user]);

  const cartLink = React.useMemo(() => {
    return '/cart';
  }, []);

  const handleItemClick = (name, link) => {
    setActiveItem(name);
    history.push(link);
  };

  const handleSignOut = () => {
    // First dispatch logout to clear auth state
    dispatch(logout());
    
    // Then close the modal
    setShowLogoutConfirm(false);
    
    // Finally redirect to home page
    history.push('/');
    
    // Force a page reload to ensure clean state
    window.location.reload();
  };

  // Navigation items
  const navItems = [
    { name: 'Browse', link: '/browse' },
    { name: 'About', link: '/about' },
    { name: 'Contact', link: '/contact' }
  ];

  // Action items that will be shown in both desktop and mobile menu when logged in
  const actionItems = isLoggedIn ? [
    { 
      name: 'Search',
      icon: <MagnifyingGlassIcon className="h-5 w-5" />,
      onClick: () => setShowSearch(true)
    },
    {
      name: 'Cart',
      icon: <CartIcon cartCount={cartNum} />,
      link: '/cart'
    },
    {
      name: 'Favorites',
      icon: <HeartIcon className="h-5 w-5" />,
      link: '/favorites'
    },
    {
      name: 'Dashboard',
      icon: <UserIcon className="h-5 w-5" />,
      link: '/dashboard'
    }
  ] : [];

  // User menu items
  const userMenuItems = isLoggedIn ? [
    { name: 'Dashboard', link: '/dashboard' },
    { name: 'Profile', link: '/profile' },
    { name: 'Settings', link: '/settings' },
    { name: 'Sign Out', onClick: () => setShowLogoutConfirm(true) }
  ] : [
    { name: 'Sign In', link: '/login' },
    { name: 'Register', link: '/register' }
  ];

  return (
    <>
      <nav className="bg-brand-teal">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo and main nav */}
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/" className="text-white font-bold text-xl">
                  SHEYOU
                </Link>
              </div>
              
              {/* Mobile menu button */}
              <div className="md:hidden ml-4">
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="p-2 rounded-full text-white hover:bg-brand-teal-light transition-colors"
                  aria-label="Open menu"
                >
                  {isMobileMenuOpen ? (
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>

              {/* Desktop navigation */}
              <div className="hidden md:block ml-10">
                <div className="flex items-center space-x-4">
                  {navItems.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => handleItemClick(item.name, item.link)}
                      className={`
                        px-3 py-2 rounded-full text-label font-medium transition-colors
                        ${activeItem === item.name 
                          ? 'bg-brand-teal-dark text-white' 
                          : 'text-white hover:bg-brand-teal-light hover:text-white'
                        }
                      `}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Desktop action items */}
            <div className="hidden md:flex items-center space-x-4">
              {isLoggedIn ? (
                <>
                  {actionItems.map((item) => (
                    item.onClick ? (
                      <button
                        key={item.name}
                        onClick={item.onClick}
                        className="p-2 rounded-full text-white hover:bg-brand-teal-light transition-colors"
                        aria-label={item.name}
                      >
                        {item.icon}
                      </button>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.link}
                        className="p-2 rounded-full text-white hover:bg-brand-teal-light transition-colors"
                        aria-label={item.name}
                      >
                        {item.icon}
                      </Link>
                    )
                  ))}
                  <button
                    onClick={() => setShowLogoutConfirm(true)}
                    className="ml-2 px-4 py-2 text-sm font-medium text-white bg-brand-orange hover:bg-brand-orange-dark rounded-md transition-colors"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-orange hover:bg-brand-orange-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-orange transition-colors"
                >
                  Sign In / Register
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Mobile menu dropdown */}
        {isMobileMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Navigation Items */}
              {navItems.map((item) => (
                <button
                  key={item.name}
                  onClick={() => {
                    handleItemClick(item.name, item.link);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`
                    w-full text-left px-4 py-3 rounded-md text-sm font-medium transition-colors
                    ${activeItem === item.name 
                      ? 'bg-brand-teal-light text-white' 
                      : 'text-white hover:bg-brand-teal-light hover:text-white'
                    }
                  `}
                >
                  {item.name}
                </button>
              ))}

              {/* Action Items */}
              {isLoggedIn && (
                <div className="pt-4 pb-3 border-t border-brand-teal">
                  <div className="px-4 py-2">
                    <div className="text-base font-medium text-white">{identifier}</div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {actionItems.map((item) => (
                      item.onClick ? (
                        <button
                          key={item.name}
                          onClick={() => {
                            item.onClick();
                            setIsMobileMenuOpen(false);
                          }}
                          className="flex items-center w-full px-4 py-2 text-sm text-white hover:bg-brand-teal-light rounded-md"
                        >
                          <span className="mr-3">{item.icon}</span>
                          {item.name}
                        </button>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.link}
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center w-full px-4 py-2 text-sm text-white hover:bg-brand-teal-light rounded-md"
                        >
                          <span className="mr-3">{item.icon}</span>
                          {item.name}
                        </Link>
                      )
                    ))}
                    <button
                      onClick={() => {
                        setShowLogoutConfirm(true);
                        setIsMobileMenuOpen(false);
                      }}
                      className="flex items-center w-full px-4 py-2 text-sm text-white hover:bg-brand-teal-light rounded-md"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              )}

              {/* Sign In button for mobile when not logged in */}
              {!isLoggedIn && (
                <div className="px-5 py-4">
                  <Link
                    to="/login"
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-brand-orange hover:bg-brand-orange-light"
                  >
                    Sign In / Register
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Search overlay */}
      <Transition
        show={showSearch}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 z-20 bg-black bg-opacity-50">
          <div className="max-w-3xl mx-auto px-4 pt-20 pb-6">
            <div className="relative">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search products..."
                  className="w-full h-11 px-4 bg-gray-50 border border-gray-300 rounded-lg text-sm text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal hover:border-gray-400 transition-colors"
                />
                <button
                  onClick={() => setShowSearch(false)}
                  className="absolute top-0 right-0 mt-3 mr-3 p-1 rounded-full text-white bg-brand-teal-dark hover:bg-brand-teal-light"
                  aria-label="Close search"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      {/* Logout confirmation modal */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4 text-center">
            {/* Backdrop */}
            <div 
              className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
              aria-hidden="true"
              onClick={() => setShowLogoutConfirm(false)}
            />

            {/* Modal */}
            <div className="relative inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-brand-teal p-6 text-left align-middle shadow-xl transition-all">
              <h3 className="text-lg font-medium leading-6 text-white">
                Sign out
              </h3>
              
              <div className="mt-2">
                <p className="text-sm text-white/80">
                  Are you sure you want to sign out?
                </p>
              </div>

              <div className="mt-4 flex space-x-3">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-brand-orange px-4 py-2 text-sm font-medium text-white hover:bg-brand-orange-light focus:outline-none focus:ring-2 focus:ring-brand-orange focus:ring-offset-2"
                  onClick={handleSignOut}
                >
                  Yes, sign out
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-white bg-transparent px-4 py-2 text-sm font-medium text-white hover:bg-brand-teal-dark focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  onClick={() => setShowLogoutConfirm(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Nav; 