import React, { useMemo } from 'react';
import Dropdown from '../../../../ui/Dropdown';
import useFilter from '../../useFilter';

const MoreFilterDropdown = ({ type, className = '' }) => {
  const { filters, handleFilterChange } = useFilter();

  const options = useMemo(() => {
    switch (type) {
      case 'category':
        return [
          { value: 'dress', label: 'Dress' },
          { value: 'top', label: 'Top' },
          { value: 'sweater', label: 'Sweater' },
          { value: 'jacket', label: 'Jacket' },
          { value: 'coat', label: 'Coat' },
          { value: 'pants', label: 'Pants' },
          { value: 'jeans', label: 'Jeans' },
          { value: 'skirt', label: 'Skirt' },
          { value: 'shorts', label: 'Shorts' },
          { value: 'jumpsuit', label: 'Jumpsuit' },
          { value: 'suit', label: 'Suit' },
          { value: 'activewear', label: 'Activewear' },
          { value: 'swimwear', label: 'Swimwear' },
          { value: 'lingerie', label: 'Lingerie' },
          { value: 'accessories', label: 'Accessories' },
          { value: 'shoes', label: 'Shoes' },
          { value: 'other', label: 'Other' }
        ];
      case 'size':
        return [
          // Letter Sizes
          { value: 'XXS', label: 'XXS', group: 'Letter Sizes' },
          { value: 'XS', label: 'XS', group: 'Letter Sizes' },
          { value: 'S', label: 'S', group: 'Letter Sizes' },
          { value: 'M', label: 'M', group: 'Letter Sizes' },
          { value: 'L', label: 'L', group: 'Letter Sizes' },
          { value: 'XL', label: 'XL', group: 'Letter Sizes' },
          { value: 'XXL', label: 'XXL', group: 'Letter Sizes' },
          // Numerical Sizes
          { value: '2', label: 'US 2 / EU 32', group: 'Numerical Sizes' },
          { value: '4', label: 'US 4 / EU 34', group: 'Numerical Sizes' },
          { value: '6', label: 'US 6 / EU 36', group: 'Numerical Sizes' },
          { value: '8', label: 'US 8 / EU 38', group: 'Numerical Sizes' },
          { value: '10', label: 'US 10 / EU 40', group: 'Numerical Sizes' },
          { value: '12', label: 'US 12 / EU 42', group: 'Numerical Sizes' },
          { value: '14', label: 'US 14 / EU 44', group: 'Numerical Sizes' },
          { value: '16', label: 'US 16 / EU 46', group: 'Numerical Sizes' },
          // Special Sizes
          { value: 'onesize', label: 'One Size', group: 'Special Sizes' },
          { value: 'custom', label: 'Custom Size', group: 'Special Sizes' },
          { value: 'other', label: 'Other Size', group: 'Special Sizes' }
        ];
      case 'color':
        return [
          { value: 'black', label: 'Black', bgClass: { backgroundColor: '#000000', color: 'white' } },
          { value: 'white', label: 'White', bgClass: { backgroundColor: '#FFFFFF', border: '2px solid #E5E7EB' } },
          { value: 'gray', label: 'Gray', bgClass: { backgroundColor: '#6B7280', color: 'white' } },
          { value: 'beige', label: 'Beige', bgClass: { backgroundColor: '#F5F5DC', border: '1px solid #E5E7EB' } },
          { value: 'brown', label: 'Brown', bgClass: { backgroundColor: '#964B00', color: 'white' } },
          { value: 'navy', label: 'Navy', bgClass: { backgroundColor: '#000080', color: 'white' } },
          { value: 'blue', label: 'Blue', bgClass: { backgroundColor: '#3B82F6', color: 'white' } },
          { value: 'red', label: 'Red', bgClass: { backgroundColor: '#EF4444', color: 'white' } },
          { value: 'pink', label: 'Pink', bgClass: { backgroundColor: '#EC4899', color: 'white' } },
          { value: 'purple', label: 'Purple', bgClass: { backgroundColor: '#8B5CF6', color: 'white' } },
          { value: 'green', label: 'Green', bgClass: { backgroundColor: '#10B981', color: 'white' } },
          { value: 'yellow', label: 'Yellow', bgClass: { backgroundColor: '#FBBF24', border: '1px solid #E5E7EB' } },
          { value: 'orange', label: 'Orange', bgClass: { backgroundColor: '#F97316', color: 'white' } },
          { value: 'gold', label: 'Gold', bgClass: { backgroundColor: '#FFD700', border: '1px solid #E5E7EB' } },
          { value: 'silver', label: 'Silver', bgClass: { backgroundColor: '#C0C0C0', border: '1px solid #E5E7EB' } },
          { value: 'multicolor', label: 'Multicolor', bgClass: { background: 'linear-gradient(to right, #EC4899, #FBBF24, #3B82F6)', color: 'white' } }
        ];
      default:
        return [];
    }
  }, [type]);

  const handleChange = (value) => {
    handleFilterChange({ [type]: value });
  };

  const getTitle = () => {
    switch (type) {
      case 'category':
        return 'Category';
      case 'size':
        return 'Size';
      case 'color':
        return 'Color';
      default:
    return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };

  // Get the default "All" option
  const getDefaultOption = () => {
    return { label: `All ${getTitle()}` };
  };

  // Only use custom rendering for color swatches
  const isColorType = type === 'color';

  // Custom render functions only for color type to show swatches
  const renderColorOption = isColorType ? (option, active, selected) => (
    <div className="flex items-center w-full py-1">
      <div className="flex items-center">
        {option.value && option.bgClass && (
          <span className="inline-block w-5 h-5 rounded-full mr-2" style={option.bgClass} />
        )}
        <span className="block truncate">
          {option.label}
        </span>
      </div>
    </div>
  ) : undefined;

  const renderColorSelected = isColorType ? (option) => (
    <div className="flex items-center h-full">
      {option?.value ? (
        <>
          {option.bgClass && (
            <span className="inline-block w-5 h-5 rounded-full mr-2" style={option.bgClass} />
          )}
          <span className="text-gray-900 font-medium">{option.label}</span>
        </>
      ) : (
        <span className="text-gray-500">{getDefaultOption().label}</span>
      )}
    </div>
  ) : undefined;

  return (
    <div className={`flex flex-col gap-2 w-full ${className}`}>
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium text-gray-700">{getTitle()}</span>
      </div>
      <Dropdown
        value={filters[type] || ''}
        onChange={handleChange}
        options={[
          { value: '', label: `All ${getTitle()}` },
          ...options
        ]}
        placeholder={getDefaultOption().label}
        maxWidth="none"
        renderOption={renderColorOption}
        renderSelected={renderColorSelected}
      />
    </div>
  );
};

export default MoreFilterDropdown; 