import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Section from '../Section';

gsap.registerPlugin(ScrollTrigger);

const MerchantSection = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const containerRef = useRef(null);
  const sectionRefs = useRef([]);
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated ?? false);

  useEffect(() => {
    const container = containerRef.current;
    const sections = sectionRefs.current;

    if (container && sections.length > 0) {
      // Set up horizontal scroll
      const totalWidth = sections.length * window.innerWidth;
      container.style.width = `${totalWidth}px`;

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          end: () => `+=${totalWidth}`,
          invalidateOnRefresh: true
        }
      });
    }

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill());
    };
  }, []);

  const images = [
    "https://res.cloudinary.com/sheyou/image/upload/v1727123216/landing_first_page_76276a5712.svg",
    "https://res.cloudinary.com/sheyou/image/upload/v1740477247/beige_background_cfe53f5db3.png",
    "https://res.cloudinary.com/sheyou/image/upload/v1727123216/landing_second_page_c01c1023ba.svg"
  ];

  const handleGetStartedClick = () => {
    if (isAuthenticated) {
      history.push('/dashboard/listings');
    } else {
      history.push('/login', { returnUrl: '/dashboard/listings' });
    }
  };

  return (
    <div className="relative">
      {/* Sliding Section */}
      <div className="relative overflow-hidden">
        <div ref={containerRef} className="relative h-screen flex">
          {images.map((img, index) => (
            <div
              key={index}
              ref={el => sectionRefs.current[index] = el}
              className="w-screen h-screen flex-shrink-0 relative"
            >
              <div 
                className={`absolute inset-0 bg-cover ${index === 0 ? 'bg-left' : index === 2 ? 'bg-right' : 'bg-center'}`}
                style={{ backgroundImage: `url(${img})` }}
              >
                {index === 1 && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-2 gap-8">
                      {/* First Column */}
                      <div className="space-y-6">
                        <div className="p-4 sm:p-6 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
                          <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-orange">
                            {userLang === 'zh-CN' ? '快速启动' : 'Quick Start'}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-700">
                            {userLang === 'zh-CN' 
                              ? '几分钟内完成设置，开始你的生意' 
                              : 'Set up your business in minutes and start earning'}
                          </p>
                        </div>
                        <div className="p-4 sm:p-6 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
                          <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-teal">
                            {userLang === 'zh-CN' ? '灵活定制' : 'Custom Terms'}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-700">
                            {userLang === 'zh-CN' 
                              ? '自由设置租赁、出售、交换的条件和价格' 
                              : 'Set your own terms for rentals, sales, and swaps'}
                          </p>
                        </div>
                      </div>
                      {/* Second Column */}
                      <div className="space-y-6">
                        <div className="p-4 sm:p-6 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
                          <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-orange">
                            {userLang === 'zh-CN' ? '设计师中心' : 'Designer Centered'}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-700">
                            {userLang === 'zh-CN' 
                              ? '为年轻设计师提供展示平台和社区支持' 
                              : 'Platform and community support for emerging designers'}
                          </p>
                        </div>
                        <div className="p-4 sm:p-6 bg-white/95 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
                          <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-teal">
                            {userLang === 'zh-CN' ? '创意管理' : 'Creative Management'}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-700">
                            {userLang === 'zh-CN' 
                              ? '数字化管理你的衣橱和时尚生意' 
                              : 'Digitalize your closet and fashion business'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Text Section with Background */}
      <div className="relative bg-[#F5F5DD] py-16 sm:py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-gray-900">
            {userLang === 'zh-CN' ? '开启你的时尚事业' : 'Start Your Business'}
          </h2>
          <p className="text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto mb-6 sm:mb-8">
            {userLang === 'zh-CN' 
              ? '在这里开启你的时尚创业之旅' 
              : 'Turn your fashion passion into a thriving business'}
          </p>
          <button 
            onClick={handleGetStartedClick}
            className="inline-flex items-center px-6 sm:px-8 py-2.5 sm:py-3 text-base sm:text-lg font-semibold rounded-md bg-brand-orange text-white hover:bg-brand-orange/90 transition-colors duration-200"
          >
            {userLang === 'zh-CN' ? '立即开始' : 'Get Started'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchantSection; 