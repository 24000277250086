import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const BackButton = ({ className = '' }) => {
  const history = useHistory();

  return (
    <button
      onClick={() => history.goBack()}
      className={`inline-flex items-center text-gray-500 hover:text-gray-700 ${className}`}
      aria-label="Go back"
    >
      <ArrowLeftIcon className="h-5 w-5 mr-1" />
      <span className="text-sm font-medium">Back</span>
    </button>
  );
};

export default BackButton; 