import React from 'react';
import { 
  ShoppingBagIcon, 
  ClockIcon, 
  ArrowsRightLeftIcon, 
  SparklesIcon,
  TagIcon,
  UserIcon,
  CubeTransparentIcon
} from '@heroicons/react/24/outline';

const BADGE_TYPES = {
  sale: {
    icon: ShoppingBagIcon,
    text: 'For Sale',
    className: 'bg-brand-teal/10 text-brand-teal'
  },
  rent: {
    icon: ClockIcon,
    text: 'For Rent',
    className: 'bg-brand-orange/10 text-brand-orange'
  },
  swap: {
    icon: ArrowsRightLeftIcon,
    text: 'Open to Swap',
    className: 'bg-purple-100 text-purple-600'
  },
  designer: {
    icon: SparklesIcon,
    text: 'Designer',
    className: 'bg-pink-100 text-pink-600'
  },
  virtual: {
    icon: CubeTransparentIcon,
    text: 'Virtual',
    className: 'bg-indigo-100 text-indigo-600'
  },
  sharing: {
    icon: UserIcon,
    text: 'Closet',
    className: 'bg-gray-100 text-gray-600'
  }
};

const Badge = ({ 
  type, 
  text: customText,
  className: customClassName = '',
  size = 'base' // 'sm', 'base', or 'lg'
}) => {
  const badge = BADGE_TYPES[type] || {};
  const Icon = badge.icon;
  const text = customText || badge.text;
  
  const sizeClasses = {
    sm: 'text-xs px-2 py-0.5',
    base: 'text-sm px-2.5 py-1',
    lg: 'text-base px-3 py-1.5'
  };

  const iconSizes = {
    sm: 'h-3.5 w-3.5',
    base: 'h-4 w-4',
    lg: 'h-5 w-5'
  };

  return (
    <span
      className={`
        inline-flex items-center gap-1.5 rounded-full font-medium
        ${badge.className || 'bg-gray-100 text-gray-600'}
        ${sizeClasses[size]}
        ${customClassName}
      `}
    >
      {Icon && <Icon className={iconSizes[size]} />}
      {text}
    </span>
  );
};

export default Badge; 