import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const MoreFilterToggle = ({ enabled, onChange, className = '' }) => {
  return (
    <button
      onClick={() => onChange(!enabled)}
      className={`flex items-center gap-2 text-sm font-medium text-brand-teal hover:text-brand-teal-dark focus:outline-none transition-colors ${className}`}
    >
      {enabled ? (
        <>
          <ChevronUpIcon className="h-5 w-5" />
          <span>Less Filters</span>
        </>
      ) : (
        <>
          <ChevronDownIcon className="h-5 w-5" />
          <span>More Filters</span>
        </>
      )}
    </button>
  );
};

export default MoreFilterToggle; 