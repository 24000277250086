import { createSlice } from '@reduxjs/toolkit';
import { getCountryFromBrowserLocale } from '../../utils/getCountry';
import { getCurrentPosition, getLocationNameFromCoordinates } from '../../utils/locationUtils';

// Initial state with browser locale as fallback
const initialState = {
  country: {
    code: getCountryFromBrowserLocale(),
    name: null
  },
  location: {
    coordinates: null, // {latitude, longitude}
    displayName: 'Current location',
    city: null,
    state: null
  },
  isDetecting: false,
  detectionComplete: false,
  locationError: null // New field to track location errors
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    startCountryDetection: (state) => {
      state.isDetecting = true;
      state.detectionComplete = false;
    },
    setUserCountry: (state, action) => {
      state.country = {
        code: action.payload.countryCode,
        name: action.payload.countryName
      };
      state.isDetecting = false;
      state.detectionComplete = true;
    },
    countryDetectionFailed: (state) => {
      // Keep the current country, just update status
      state.isDetecting = false;
      state.detectionComplete = true;
    },
    // New action to manually update country when user selects one
    updateUserCountry: (state, action) => {
      state.country = {
        code: action.payload.countryCode,
        name: action.payload.countryName
      };
      // No need to change detection status since this is a manual selection
    },
    // New actions for location
    startLocationDetection: (state) => {
      state.isDetecting = true;
      state.locationError = null;
    },
    setUserLocation: (state, action) => {
      state.location = action.payload;
      state.isDetecting = false;
      state.locationError = null;
    },
    locationDetectionFailed: (state, action) => {
      state.isDetecting = false;
      state.locationError = action.payload || 'Failed to detect location';
    },
    clearLocationError: (state) => {
      state.locationError = null;
    }
  }
});

export const { 
  startCountryDetection, 
  setUserCountry, 
  countryDetectionFailed,
  updateUserCountry,
  startLocationDetection,
  setUserLocation,
  locationDetectionFailed,
  clearLocationError
} = locationSlice.actions;

// Selectors
export const selectUserCountry = (state) => state.location.country;
export const selectUserLocation = (state) => state.location.location;
export const selectIsDetectingCountry = (state) => state.location.isDetecting;
export const selectLocationError = (state) => state.location.locationError;

// Async action to detect user's country
export const detectUserCountry = () => async (dispatch) => {
  dispatch(startCountryDetection());
  
  try {
    // Import dynamically to avoid circular dependencies
    const { getUserCountryFromIP } = await import('../../utils/getCountry');
    const countryInfo = await getUserCountryFromIP();
    
    dispatch(setUserCountry(countryInfo));
    return countryInfo;
  } catch (error) {
    console.warn('Country detection failed:', error);
    dispatch(countryDetectionFailed());
    return null;
  }
};

// Async action to detect user's location with place name
export const detectUserLocationWithPlaceName = () => async (dispatch) => {
  dispatch(startLocationDetection());
  
  try {
    // First get coordinates
    const position = await getCurrentPosition();
    
    // Then get place name from coordinates
    const locationData = await getLocationNameFromCoordinates(
      position.latitude, 
      position.longitude
    );
    
    // Update Redux with full location data
    dispatch(setUserLocation(locationData));
    
    return locationData;
  } catch (error) {
    console.warn('Location detection failed:', error.message);
    dispatch(locationDetectionFailed(error.message));
    return null;
  }
};

// Simple location detection (coordinates only)
export const detectUserLocation = () => async (dispatch) => {
  dispatch(startLocationDetection());
  
  try {
    const position = await getCurrentPosition();
    
    dispatch(setUserLocation({
      coordinates: {
        latitude: position.latitude,
        longitude: position.longitude
      },
      displayName: 'Current location'
    }));
    
    return position;
  } catch (error) {
    console.warn('Location detection failed:', error.message);
    dispatch(locationDetectionFailed(error.message));
    return null;
  }
};

export default locationSlice.reducer; 