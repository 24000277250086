/**
 * Mapbox configuration
 */

import { store } from '../redux/store';

export const MAPBOX_CONFIG = {
  apiKey: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'your-mapbox-token',
  defaultRadius: 10, // Default search radius in km
  maxRadius: 50, // Maximum search radius in km
  minRadius: 1, // Minimum search radius in km
  
  // Mapbox geocoding API
  geocodingEndpoint: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  
  // Autocomplete settings
  country: 'us',
  limit: 5,
  types: 'address',
  autocompleteDebounce: 300,
  
  // Map display settings
  mapDefaults: {
    zoom: 12,
    style: 'mapbox://styles/mapbox/streets-v11'
  },
  
  // Default map style for display
  defaultMapStyle: 'mapbox://styles/mapbox/streets-v11',
  
  // Default zoom level
  defaultZoom: 12,
  
  // Marker color
  markerColor: '#0694A2' // brand-teal color for markers
};

/**
 * Get current user country code for Mapbox
 * @returns {string} Lowercase country code (e.g., 'us', 'ca', 'gb')
 */
export const getCurrentCountryCode = () => {
  const state = store.getState();
  const countryCode = state?.location?.country?.code || 'US';
  
  // If it's a two-letter code, return it lowercase
  if (countryCode && countryCode.length === 2) {
    return countryCode.toLowerCase();
  }
  
  // Handle country names by returning default 'us'
  return 'us';
};

/**
 * Get Mapbox API URL for geocoding
 * 
 * @param {string} query - Address or location to geocode
 * @returns {string} URL for geocoding request
 */
export const getGeocodingUrl = (query) => {
  // Always get the latest country code from Redux
  const countryCode = getCurrentCountryCode();
  
  // Use the country code for filtering results
  return `${MAPBOX_CONFIG.geocodingEndpoint}${encodeURIComponent(query)}.json?access_token=${MAPBOX_CONFIG.accessToken}&country=${countryCode}&limit=1`;
};

/**
 * Convert address object to display string
 * 
 * @param {Object} address - Address object
 * @returns {string} Formatted address string
 */
export const formatAddress = (address) => {
  if (!address) return '';
  
  const parts = [];
  
  if (address.street) parts.push(address.street);
  if (address.street2) parts.push(address.street2);
  
  const cityParts = [];
  if (address.city) cityParts.push(address.city);
  if (address.state) cityParts.push(address.state);
  if (address.zip) cityParts.push(address.zip);
  
  if (cityParts.length > 0) {
    parts.push(cityParts.join(', '));
  }
  
  return parts.join('\n');
};

/**
 * Format a response from Mapbox geocoding API
 * 
 * @param {Object} feature - Mapbox geocoding API feature
 * @returns {Object} Formatted address
 */
export const formatMapboxResponse = (feature) => {
  if (!feature) return null;
  
  // Get country from Redux store
  const state = store.getState();
  const userCountry = state?.location?.country?.code || 'US';
  
  // Extract address components from context array
  const components = {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: userCountry // Use country code from Redux store
  };
  
  // Parse context for address components
  if (feature.context) {
    feature.context.forEach(item => {
      const id = item.id.split('.')[0];
      const text = item.text;
      const shortCode = item.short_code;
      
      if (id === 'place') components.city = text;
      if (id === 'region') components.state = text;
      if (id === 'postcode') components.postalCode = text;
      if (id === 'country') {
        // If we have a short_code (like us, ca, gb), use that as the country code
        if (shortCode) {
          components.country = shortCode.toUpperCase();
        } else {
          // Otherwise use the country name
          components.country = text;
        }
      }
    });
  }
  
  // Handle different address types
  if (feature.place_type) {
    if (feature.place_type.includes('address')) {
      // Full address with number
      components.street = feature.text || '';
      
      // If there's an address line, check if it has the street number already
      if (feature.address && !components.street.startsWith(feature.address)) {
        components.street = `${feature.address} ${components.street}`;
      }
    } else if (feature.place_type.includes('poi')) {
      // Point of interest
      components.street = feature.text || '';
    }
  }
  
  // For address line format, try to get a proper street address
  // Sometimes Mapbox returns the full street in the place_name before the city
  if (!components.street && feature.place_name) {
    const addressParts = feature.place_name.split(',');
    if (addressParts.length > 1) {
      components.street = addressParts[0].trim();
    }
  }
  
  return {
    fullAddress: feature.place_name,
    components,
    coordinates: {
      latitude: feature.center[1], // Mapbox returns [lng, lat]
      longitude: feature.center[0]
    },
    mapboxId: feature.id,
    placeType: feature.place_type?.[0],
    confidence: feature.relevance
  };
};

/**
 * Format an address object into a query string for Mapbox
 * @param {Object} address - Address data
 * @returns {string} Formatted address string
 */
export const formatAddressForQuery = (address) => {
  const parts = [
    address.street,
    address.city,
    address.state,
    address.postalCode,
    address.country || 'United States'
  ];
  
  return parts.filter(Boolean).join(', ');
};

export default MAPBOX_CONFIG; 