import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../utils/config';

// Create a custom base query with error handling
const baseQueryWithReauth = fetchBaseQuery({
  baseUrl: BACKEND_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState, endpoint, extraOptions }) => {
    // If the request specifically sets headers.Authorization = '',
    // this is a public endpoint and should not include auth headers
    if (headers.get('Authorization') === '') {
      headers.delete('Authorization');
      return headers;
    }
    
    const token = getState().auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    
    // For non-FormData requests, set the Content-Type to application/json
    // FormData requests automatically get the correct multipart/form-data Content-Type with boundary
    if (!headers.has('Content-Type')) {
      // We don't set Content-Type for FormData requests - the browser will set it automatically
      headers.set('Content-Type', 'application/json');
    }
    
    return headers;
  }
});

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  // For FormData requests, we need to ensure Content-Type is not set
  if (args.formData) {
    if (args.headers) {
      delete args.headers['Content-Type'];
    } else {
      args.headers = {};
    }
  }

  const result = await baseQueryWithReauth(args, api, extraOptions);
  
  if (result.error) {
    // Check if this is a public endpoint (has empty Authorization header)
    const isPublicEndpoint = args.headers && args.headers.Authorization === '';
    
    // Handle 401 Unauthorized errors for protected endpoints only
    if (result.error.status === 401 && !isPublicEndpoint) {
      // Handle unauthorized access for protected endpoints
      api.dispatch({ type: 'auth/logout' });
    }
    
    // Log other errors in development
    if (process.env.NODE_ENV === 'development') {
      console.error('API Error:', {
        endpoint: args.url,
        error: result.error,
        isPublicEndpoint
      });
    }
  }
  return result;
};

export const createBaseApi = (reducerPath) => {
  return createApi({
    reducerPath,
    baseQuery: baseQueryWithErrorHandling,
    tagTypes: ['Auth', 'User', 'Product', 'Listing', 'Review', 'Profile', 'Address', 'Upload', 'Cart'],
    endpoints: () => ({}),
    keepUnusedDataFor: 5 * 60, // Keep unused data in cache for 5 minutes
    refetchOnMountOrArgChange: 30, // Refetch after 30 seconds
    refetchOnFocus: false,
    refetchOnReconnect: true
  });
};

export const createProtectedEndpoint = (builder, endpoint) => {
  return builder.query({
    ...endpoint,
    transformErrorResponse: (response) => {
      if (response.status === 401) {
        // Handle unauthorized access
        return { error: 'Unauthorized access' };
      }
      return response;
    }
  });
};

export const handleWebhookUpdate = (store, tags = ['Product']) => {
  tags.forEach(tag => {
    Object.values(store.dispatch)
      .filter(value => typeof value === 'object' && value.util)
      .forEach(api => {
        store.dispatch(api.util.invalidateTags([tag]));
      });
  });
};
