import { BACKEND_URL } from './config';

/**
 * Upload files directly to the Strapi API without using Redux
 * @param {FileList|Array<File>} files - The files to upload
 * @returns {Promise<Array>} - Array of uploaded file objects
 */
export const uploadFiles = async (files) => {
  // Get API token from localStorage
  const token = localStorage.getItem('token');
  
  if (!files || files.length === 0) {
    throw new Error('No files provided');
  }
  
  // Create FormData and append all files
  const formData = new FormData();
  Array.from(files).forEach(file => {
    formData.append('files', file);
  });

  try {
    const response = await fetch(`${BACKEND_URL}/upload`, {
      method: 'POST',
      headers: {
        // Don't set Content-Type header, let the browser set it with the boundary
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      },
      body: formData
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || `Upload failed with status: ${response.status}`);
    }

    const data = await response.json();
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Upload error:', error);
    throw error;
  }
}; 