import React, { useRef, useEffect } from 'react';

/**
 * A checkbox component that supports three states:
 * - checked: fully checked (true)
 * - indeterminate: half-checked state, visually represented by a dot
 * - unchecked: not checked (false)
 * 
 * @param {Object} props Component props
 * @param {boolean} props.checked Whether the checkbox is checked
 * @param {boolean} props.indeterminate Whether the checkbox is in indeterminate state
 * @param {Function} props.onChange Callback when the checkbox is clicked
 * @param {string} props.label Label text for the checkbox
 * @param {string} props.className Additional CSS classes
 * @param {boolean} props.disabled Whether the checkbox is disabled
 */
const TriStateCheckbox = ({ 
  checked = false, 
  indeterminate = false, 
  onChange, 
  label,
  className = '',
  disabled = false
}) => {
  const checkboxRef = useRef(null);
  
  // Set the indeterminate property on the input element
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const handleChange = (e) => {
    if (disabled) return;
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <label className={`inline-flex items-center cursor-pointer whitespace-nowrap ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}>
      <input
        ref={checkboxRef}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        className={`
          h-4 w-4 rounded border-gray-300 text-brand-teal focus:ring-brand-teal
        `}
      />
      {label && <span className={`ml-2 text-sm ${disabled ? 'text-gray-400' : 'text-gray-700'} flex-shrink-0`}>{label}</span>}
    </label>
  );
};

export default TriStateCheckbox; 