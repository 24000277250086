import { createSlice } from '@reduxjs/toolkit';

// Initialize state from localStorage
const getInitialState = () => {
  try {
    const token = localStorage.getItem('token');
    const userInfo = localStorage.getItem('userInfo');
    const user = userInfo ? JSON.parse(userInfo) : null;

    return {
      token,
      isAuthenticated: !!token,
      user,
      loading: false,
      error: null,
      registrationSuccess: false,
      passwordResetRequested: false,
      passwordResetSuccess: false
    };
  } catch (error) {
    // If there's an error reading from localStorage, return default state
    return {
      token: null,
      isAuthenticated: false,
      user: null,
      loading: false,
      error: null,
      registrationSuccess: false,
      passwordResetRequested: false,
      passwordResetSuccess: false
    };
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
      state.error = null;
      
      // Persist auth state
      localStorage.setItem('token', token);
      localStorage.setItem('userInfo', JSON.stringify(user));
      localStorage.setItem('login', 'true');
      localStorage.setItem('loginTime', Date.now().toString());
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      state.error = null;
      
      // Clear all auth-related localStorage items
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('login');
      localStorage.removeItem('loginTime');
      localStorage.removeItem('merchantLogin');
      localStorage.removeItem('identifier');
      localStorage.removeItem('password');
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      localStorage.setItem('userInfo', JSON.stringify(payload));
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    setRegistrationSuccess: (state, { payload }) => {
      state.registrationSuccess = payload;
    },
    setPasswordResetRequested: (state, { payload }) => {
      state.passwordResetRequested = payload;
    },
    setPasswordResetSuccess: (state, { payload }) => {
      state.passwordResetSuccess = payload;
    },
    checkAuth: (state) => {
      const token = localStorage.getItem('token');
      const userInfo = localStorage.getItem('userInfo');
      
      if (!token || !userInfo) {
        // If either token or user info is missing, clear everything
        state.token = null;
        state.user = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('login');
        localStorage.removeItem('loginTime');
        return;
      }

      try {
        // Check if login time is within 24 hours
        const loginTime = parseInt(localStorage.getItem('loginTime'));
        const now = Date.now();
        const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

        if (now - loginTime > SESSION_DURATION) {
          // Session expired
          state.token = null;
          state.user = null;
          state.isAuthenticated = false;
          localStorage.removeItem('token');
          localStorage.removeItem('userInfo');
          localStorage.removeItem('login');
          localStorage.removeItem('loginTime');
          return;
        }

        // Valid session
        state.token = token;
        state.user = JSON.parse(userInfo);
        state.isAuthenticated = true;
        
        // Update login time
        localStorage.setItem('loginTime', now.toString());
      } catch (error) {
        // If there's any error parsing the stored data, clear everything
        state.token = null;
        state.user = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        localStorage.removeItem('login');
        localStorage.removeItem('loginTime');
      }
    }
  }
});

export const {
  setCredentials,
  logout,
  setUser,
  setLoading,
  setError,
  clearError,
  setRegistrationSuccess,
  setPasswordResetRequested,
  setPasswordResetSuccess,
  checkAuth
} = authSlice.actions;

// Selectors
export const selectCurrentUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectAuthToken = (state) => state.auth.token;
export const selectAuthLoading = (state) => state.auth.loading;
export const selectAuthError = (state) => state.auth.error;
export const selectRegistrationSuccess = (state) => state.auth.registrationSuccess;
export const selectPasswordResetRequested = (state) => state.auth.passwordResetRequested;
export const selectPasswordResetSuccess = (state) => state.auth.passwordResetSuccess;

export default authSlice.reducer; 