import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Navigation Links */}
          <div className="flex flex-col items-center md:items-center space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Navigation</h3>
            <ul className="space-y-2 text-center md:text-center">
              <li><Link to="/browse" className="text-gray-600 hover:text-brand-teal">Browse</Link></li>
              <li><Link to="/about" className="text-gray-600 hover:text-brand-teal">About</Link></li>
              <li><Link to="/contactUs" className="text-gray-600 hover:text-brand-teal">Contact</Link></li>
            </ul>
          </div>

          {/* Company Info */}
          <div className="flex flex-col items-center md:items-center space-y-4">
            <div className="space-y-2 text-center">
              <span className="text-brand-teal text-2xl font-bold block">SHEYOU</span>
              <p className="text-gray-500 text-sm">Next Generation Fashion Platform</p>
            </div>
            <div className="space-y-1 text-center">
              <p className="text-gray-500 text-sm">Sichuan Sheyu Renyu Trading Co., LTD</p>
              <p className="text-gray-500 text-sm">© {new Date().getFullYear()} All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 