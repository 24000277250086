import { createBaseApi } from '../baseApi';

const listingApi = createBaseApi('listingApi');

// Helper function to handle validation errors
const transformErrorResponse = (response) => {
  // Try to extract structured errors from response
  if (response.data && response.data.data && response.data.data.errors) {
    return { errors: response.data.data.errors };
  } else if (response.data && response.data.errors) {
    return { errors: response.data.errors };
  }
  return response;
};

export const enhancedListingApi = listingApi.injectEndpoints({
  endpoints: (builder) => ({
   
    // Product queries
    getProduct: builder.query({
      query: (id) => ({
        url: `/products/${id}`,
        credentials: 'omit',  // Don't send cookies or authorization headers
        headers: { 
          // Explicitly clear Authorization header for this endpoint
          Authorization: ''
        }
      }),
      providesTags: (result, error, id) => [{ type: 'Product', id }]
    }),

    // Product mutations
    createProduct: builder.mutation({
      query: (productData) => ({
        url: '/products',
        method: 'POST',
        body: productData
      }),
      transformErrorResponse,
      invalidatesTags: [{ type: 'Product', id: 'LIST' }]
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/products/${id}`,
        method: 'PUT',
        body: data
      }),
      transformErrorResponse,
      invalidatesTags: (result, error, { id }) => [
        { type: 'Product', id },
        { type: 'Product', id: 'LIST' }
      ]
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Product', id },
        { type: 'Product', id: 'LIST' }
      ]
    }),

    // Bulk operations
    bulkDeleteProducts: builder.mutation({
      query: (ids) => {
        // Ensure ids is an array even if a single id is passed
        const idsArray = Array.isArray(ids) ? ids : [ids];
        
        return {
          url: '/products/bulk-delete',
          method: 'POST',
          body: { ids: idsArray }
        };
      },
      invalidatesTags: (result, error, ids) => [
        { type: 'Product', id: 'LIST' },
        ...(Array.isArray(ids) ? ids : [ids]).map(id => ({ type: 'Product', id }))
      ]
    }),
    bulkToggleActivation: builder.mutation({
      query: ({ ids, active }) => ({
        url: '/products/bulk-toggle-activation',
        method: 'POST',
        body: { ids, active }
      }),
      invalidatesTags: (result, error, { ids }) => [
        { type: 'Product', id: 'LIST' },
        ...ids.map(id => ({ type: 'Product', id }))
      ]
    }),
    toggleProductActivation: builder.mutation({
      query: ({ productId, active }) => ({
        url: `/products/${productId}/toggle-activation`,
        method: 'POST',
        body: { active }
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: 'Product', id: productId },
        { type: 'Product', id: 'LIST' }
      ]
    }),

    
  })
});

export const {
  // Product queries
  useGetProductQuery,
  // Product mutations
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  // Bulk operations
  useBulkDeleteProductsMutation,
  useBulkToggleActivationMutation,
  useToggleProductActivationMutation
} = enhancedListingApi;
