import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const SearchBox = ({ 
  value, 
  onChange, 
  onSearch,
  placeholder = 'Search...', 
  title,
  className = '',
  maxWidth = 'max-w-xs'
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch && onSearch(value);
  };

  return (
    <div className={`flex flex-col ${maxWidth} ${className}`}>
      {title && (
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {title}
        </label>
      )}
      <form className="relative flex items-center z-30">
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className="w-full h-10 px-3 text-gray-500 border border-gray-300 rounded-md
                   shadow-sm placeholder-gray-500
                   focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal
                   hover:border-gray-400 transition-colors"
        />
        <button
          type="submit"
          onClick={handleSubmit}
          className="absolute right-2 p-1.5 text-gray-400 hover:text-gray-600 focus:outline-none"
          aria-label="Search"
        >
          <MagnifyingGlassIcon className="h-5 w-5" />
        </button>
      </form>
    </div>
  );
};

export default SearchBox; 