import React from 'react';
import { useHistory } from 'react-router-dom';
import Rating from '../ui/Rating';

const userLang = navigator.language || navigator.userLanguage;

const ProfileStats = ({ user }) => {
  const history = useHistory();

  const goToListings = () => {
    history.push('/dashboard/listings');
  };

  const goToTransactions = () => {
    history.push('/dashboard/transactions');
  };

  // Calculate average rating from comments
  const averageRating = user?.commentsFromCustomers?.length 
    ? user.commentsFromCustomers.reduce((sum, comment) => sum + (comment.rating || 0), 0) / user.commentsFromCustomers.length
    : 0;
  
  // Get total number of ratings
  const totalRatings = user?.commentsFromCustomers?.length || 0;

  // Determine if we should show 5 cards (with ratings) or 4 cards
  const shouldShowRatings = user?.isMerchant || totalRatings > 0;
  const gridCols = shouldShowRatings ? 'md:grid-cols-5' : 'md:grid-cols-4';

  return (
    <div className={`grid grid-cols-1 ${gridCols} gap-6 px-6 mt-6`}>
      <div 
        className="bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-shadow duration-200"
        onClick={goToListings}
      >
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {userLang === 'zh-CN' ? '商品' : 'Products'}
        </h3>
        <p className="text-3xl font-bold text-brand-teal">
          {user?.productsCount || user?.products?.length || 0}
        </p>
      </div>

      <div 
        className="bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-shadow duration-200"
        onClick={goToTransactions}
      >
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {userLang === 'zh-CN' ? '交易' : 'Transactions'}
        </h3>
        <p className="text-3xl font-bold text-brand-teal">
          {user?.ordersCount || user?.order?.length || 0}
        </p>
      </div>

      {shouldShowRatings && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            {userLang === 'zh-CN' ? '评分' : 'Rating'}
          </h3>
          <div className="flex items-center">
            <span className="text-3xl font-bold text-brand-teal mr-2">
              {averageRating.toFixed(1)}
            </span>
            <Rating rating={averageRating} showCount={false} size="sm" />
          </div>
          <p className="text-sm text-gray-500 mt-1">
            {userLang === 'zh-CN' ? `${totalRatings} 评价` : `${totalRatings} reviews`}
          </p>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {userLang === 'zh-CN' ? '关注者' : 'Followers'}
        </h3>
        <p className="text-3xl font-bold text-brand-teal">
          {user?.followersCount || user?.followers?.length || 0}
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {userLang === 'zh-CN' ? '关注中' : 'Following'}
        </h3>
        <p className="text-3xl font-bold text-brand-teal">
          {user?.followingCount || user?.following?.length || 0}
        </p>
      </div>
    </div>
  );
};

export default ProfileStats; 