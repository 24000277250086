import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import AddressPreview from './components/AddressPreview';
import { 
  selectAllAddresses, 
  selectDefaultAddress, 
  selectAddressLoading,
  setAddresses
} from '../../redux/slices/addressSlice';
import { 
  useGetAddressesQuery,
  useDeleteAddressMutation,
  useSetDefaultAddressMutation
} from '../../redux/apis';
import Modal from '../ui/Modal';

/**
 * AddressList component for displaying and managing user addresses
 * This is a smart component that handles its own data operations
 */
const AddressList = ({
  addresses = null, // Optional - if null, will use Redux state
  onSelect = null,
  onEdit = null, // Optional - if provided, will be called when edit is clicked
  onDelete = null, // Optional callback after successful deletion
  onSetDefault = null, // Optional callback after setting default
  compact = false,
  showMap = true,
  className = '',
  emptyMessage = 'No addresses found',
  userLang = navigator.language || navigator.userLanguage
}) => {
  // Local state for operation states
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSettingDefault, setIsSettingDefault] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();

  // Get addresses from Redux state if not provided
  const storeAddresses = useSelector(selectAllAddresses);
  const defaultAddress = useSelector(selectDefaultAddress);
  const reduxLoading = useSelector(selectAddressLoading);
  
  // API mutations
  const { data: apiAddresses = [], isLoading: isFetching, refetch } = useGetAddressesQuery();
  const [deleteAddressMutation] = useDeleteAddressMutation();
  const [setDefaultAddressMutation] = useSetDefaultAddressMutation();
  
  // If we get addresses from API but Redux store is empty, synchronize them
  useEffect(() => {
    if (apiAddresses?.length > 0 && (!storeAddresses || storeAddresses.length === 0)) {
      console.log('Synchronizing addresses from API to Redux store:', apiAddresses);
      dispatch(setAddresses(apiAddresses));
    }
  }, [apiAddresses, storeAddresses, dispatch]);
  
  // Use provided addresses or fall back to Redux state or API data
  const displayAddresses = addresses || storeAddresses || apiAddresses || [];
  const loading = reduxLoading || isFetching || isDeleting || isSettingDefault;
  
  // Debug info to help track down issues
  console.log('AddressList - Addresses Sources:', {
    providedAddresses: addresses?.length || 0,
    storeAddresses: storeAddresses?.length || 0,
    apiAddresses: apiAddresses?.length || 0,
    displayAddresses: displayAddresses?.length || 0
  });
  
  // Prepare for address deletion
  const promptDeleteAddress = (address) => {
    setAddressToDelete(address);
    setShowDeleteModal(true);
  };
  
  // Handle address deletion
  const handleDeleteAddress = async () => {
    if (!addressToDelete || !addressToDelete.id) return;
    
    setIsDeleting(true);
    setErrorMessage('');
    setShowDeleteModal(false);
    
    try {
      await deleteAddressMutation(addressToDelete.id).unwrap();
      setSuccessMessage(
        userLang === 'zh-CN' ? '地址已删除' : 'Address successfully deleted'
      );
      
      // Call optional callback if provided
      if (onDelete) {
        onDelete(addressToDelete.id);
      }
      
      // Clear the address to delete
      setAddressToDelete(null);
      
      // Refresh data
      refetch();
    } catch (error) {
      setErrorMessage(
        error.data?.message || 
        (userLang === 'zh-CN' ? '删除地址失败' : 'Failed to delete address')
      );
    } finally {
      setIsDeleting(false);
      
      // Clear success message after 3 seconds
      if (successMessage) {
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    }
  };
  
  // Cancel delete operation
  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setAddressToDelete(null);
  };
  
  // Handle setting address as default
  const handleSetDefaultAddress = async (id) => {
    setIsSettingDefault(true);
    setErrorMessage('');
    
    try {
      await setDefaultAddressMutation(id).unwrap();
      setSuccessMessage(
        userLang === 'zh-CN' ? '默认地址已更新' : 'Default address updated'
      );
      
      // Call optional callback if provided
      if (onSetDefault) {
        onSetDefault(id);
      }
      
      // Refresh data
      refetch();
    } catch (error) {
      setErrorMessage(
        error.data?.message || 
        (userLang === 'zh-CN' ? '更新默认地址失败' : 'Failed to update default address')
      );
    } finally {
      setIsSettingDefault(false);
      
      // Clear success message after 3 seconds
      if (successMessage) {
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    }
  };
  
  // Render confirmation modal
  const renderDeleteModal = () => {
    if (!showDeleteModal || !addressToDelete) return null;
    
    return (
      <Modal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        title={userLang === 'zh-CN' ? '确认删除地址' : 'Confirm Delete Address'}
        size="sm"
        actions={
          <>
            <button
              type="button"
              onClick={handleDeleteAddress}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              disabled={isDeleting}
            >
              {isDeleting ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {userLang === 'zh-CN' ? '删除中...' : 'Deleting...'}
                </span>
              ) : (
                userLang === 'zh-CN' ? '删除' : 'Delete'
              )}
            </button>
            <button
              type="button"
              onClick={handleCancelDelete}
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-teal focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              disabled={isDeleting}
            >
              {userLang === 'zh-CN' ? '取消' : 'Cancel'}
            </button>
          </>
        }
      >
        <p className="text-sm text-gray-500">
          {userLang === 'zh-CN' 
            ? '您确定要删除此地址吗？此操作无法撤销。' 
            : 'Are you sure you want to delete this address? This action cannot be undone.'}
        </p>
        
        {/* Show address preview */}
        <div className="mt-4 border-t pt-4">
          <div className="text-sm text-gray-700">
            {addressToDelete?.name && <p className="font-medium">{addressToDelete.name}</p>}
            <p>
              {addressToDelete?.street}{addressToDelete?.apartment ? `, ${addressToDelete.apartment}` : ''}<br />
              {addressToDelete?.city}, {addressToDelete?.state} {addressToDelete?.postalCode}<br />
              {addressToDelete?.country}
            </p>
            {addressToDelete?.phoneNumber && (
              <p className="mt-1">
                Phone: {addressToDelete.phoneNumber}
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  };
  
  if (loading) {
    return (
      <div className={`py-4 text-center ${className}`}>
        <svg className="animate-spin h-5 w-5 text-gray-400 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <p className="mt-2 text-sm text-gray-500">Loading addresses...</p>
      </div>
    );
  }
  
  if (displayAddresses.length === 0) {
    return (
      <div className={`py-4 text-center border rounded-md border-gray-300 ${className}`}>
        <p className="text-sm text-gray-500">{emptyMessage}</p>
      </div>
    );
  }
  
  return (
    <div className={`space-y-4 ${className}`}>
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="p-3 bg-green-50 text-green-700 rounded-md">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="p-3 bg-red-50 text-red-700 rounded-md">
          {errorMessage}
        </div>
      )}
      
      {/* Address list */}
      {displayAddresses.map(address => {
        // Check if this is the default address
        const isDefault = defaultAddress ? 
          defaultAddress.id === address.id : 
          address.isDefault;
        
        return (
          <div 
            key={address.id} 
            className={`${onSelect ? 'cursor-pointer' : ''} border p-4 rounded-md mb-4 ${isDefault ? 'border-brand-teal bg-teal-50' : 'border-gray-200'}`}
            onClick={onSelect ? () => onSelect(address) : undefined}
          >
            <div className="space-y-2">
              {address.name && (
                <div className="flex justify-between">
                  <span className="font-medium">{address.name}</span>
                  {isDefault && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-brand-teal text-white">
                      Default
                    </span>
                  )}
                </div>
              )}
              
              <p className="text-sm text-gray-700">
                {address.street}{address.apartment ? `, ${address.apartment}` : ''}<br />
                {address.city}, {address.state} {address.postalCode}<br />
                {address.country}
              </p>
              
              {address.phoneNumber && (
                <p className="text-sm text-gray-600">
                  Phone: {address.phoneNumber}
                </p>
              )}
              
              <div className="flex space-x-4 pt-2 mt-2 border-t border-gray-200">
                {onEdit && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit(address);
                    }}
                    className="text-sm text-gray-600 hover:text-brand-teal"
                  >
                    {userLang === 'zh-CN' ? '编辑' : 'Edit'}
                  </button>
                )}
                
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    promptDeleteAddress(address);
                  }}
                  className="text-sm text-gray-600 hover:text-red-600"
                >
                  {userLang === 'zh-CN' ? '删除' : 'Delete'}
                </button>
                
                {onSetDefault && !isDefault && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetDefaultAddress(address.id);
                    }}
                    className="text-sm text-gray-600 hover:text-gray-900"
                  >
                    {userLang === 'zh-CN' ? '设为默认' : 'Set as default'}
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      })}
      
      {/* Delete confirmation modal */}
      {renderDeleteModal()}
    </div>
  );
};

export default AddressList; 