import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  severity: 'info', // 'error' | 'warning' | 'info' | 'success'
  isVisible: false,
  duration: 5000, // default duration in ms
  details: null, // for additional error details or success data
};

const responseSlice = createSlice({
  name: 'response',
  initialState,
  reducers: {
    showResponse: (state, action) => {
      // Handle different message formats
      let message = action.payload.message;
      if (typeof message === 'object') {
        message = message.message || message.error || JSON.stringify(message);
      }
      
      state.message = message;
      state.severity = action.payload.severity || 'info';
      state.isVisible = true;
      state.duration = action.payload.duration || 5000;
      state.details = action.payload.details || null;
    },
    hideResponse: (state) => {
      state.message = null;
      state.isVisible = false;
      state.details = null;
    },
  },
});

export const { showResponse, hideResponse } = responseSlice.actions;

// Helper functions for common response types
export const showSuccess = (message, duration = 3000) => 
  showResponse({ message, severity: 'success', duration });

export const showError = (message, duration = 5000) => 
  showResponse({ message, severity: 'error', duration });

export const showInfo = (message, duration = 4000) => 
  showResponse({ message, severity: 'info', duration });

export const showWarning = (message, duration = 4000) => 
  showResponse({ message, severity: 'warning', duration });

// New function to handle API responses
export const handleResponse = (response, { 
  successMessage, 
  errorMessage = 'Operation failed', 
  duration,
  onSuccess = () => {} 
}) => (dispatch) => {
  try {
    // If response is an error object
    if (response instanceof Error) {
      throw response;
    }

    // Check for success based on response structure
    const isSuccess = response?.success || // Bulk operations return success flag
                     response?.id;         // Single operations return updated entity

    if (isSuccess) {
      dispatch(showSuccess(successMessage, duration));
      onSuccess(response);
    } else {
      // Get error message from various possible locations in the response
      const message = response?.message || 
                     response?.error ||
                     errorMessage;
      throw new Error(message);
    }
  } catch (error) {
    // Handle different error types
    const finalErrorMessage = error.status === 403 
      ? 'You do not have permission to perform this action'
      : error.status === 404
      ? 'Resource not found'
      : error.message || errorMessage;

    dispatch(showError(finalErrorMessage, duration));
    
    // Log errors in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Response error:', error);
    }
  }
};

// Thunk action creator for showing response with auto-hide
export const showResponseWithTimeout = (message, severity = 'info', duration = 5000, details = null) => (dispatch) => {
  dispatch(showResponse({ message, severity, duration, details }));
  setTimeout(() => {
    dispatch(hideResponse());
  }, duration);
};

export default responseSlice.reducer; 