import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const AccordionItem = ({ title, children }) => {
  return (
    <Disclosure as="div" className="mt-2">
      {({ open }) => (
        <>
          <Disclosure.Button
            className="flex justify-between w-full px-4 py-3 text-lg font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-brand-teal focus-visible:ring-opacity-75"
          >
            <span>{title}</span>
            <ChevronDownIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 text-brand-teal transition-transform duration-200`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="overflow-hidden">
            {({ close }) => (
              <div className="px-4 pt-4 pb-6 text-gray-700">
                {children}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AccordionItem; 