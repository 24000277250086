import React, { useRef, useState } from 'react';
import { useUpdateBackgroundMutation } from '../../redux/apis/endpoints/profileApi';
import { PencilIcon } from '@heroicons/react/24/outline';

const ProfileBackground = ({ user, isCurrentUser = true }) => {
  const fileInputRef = useRef(null);
  const [updateBackground, { isLoading }] = useUpdateBackgroundMutation();
  const [uploadError, setUploadError] = useState(null);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploadError(null);
      
      // Upload and update the background image
      const result = await updateBackground(file);
      
      if (result.error) {
        throw new Error(result.error.message || 'Failed to update background image');
      }
    } catch (error) {
      setUploadError(error.message || 'Failed to update background image');
    }
  };

  return (
    <div className="relative h-48">
      {user?.backgroundPic?.length > 0 && user.backgroundPic[0]?.url ? (
        <img
          src={user.backgroundPic[0].url}
          alt="Profile background"
          className="w-full h-full object-cover rounded-lg"
        />
      ) : (
        <div className="w-full h-full bg-gradient-to-r from-brand-teal to-brand-teal-light rounded-lg" />
      )}
      
      {/* Edit button - only shown if it's the current user's profile */}
      {isCurrentUser && (
        <button 
          className="absolute bottom-3 right-3 rounded-full bg-brand-orange p-2 shadow-md hover:bg-brand-orange/90 focus:outline-none focus:ring-2 focus:ring-brand-orange transition-colors"
          onClick={() => fileInputRef.current?.click()}
          disabled={isLoading}
          title="Change background image"
        >
          {isLoading ? (
            <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
          ) : (
            <PencilIcon className="h-5 w-5 text-white" />
          )}
        </button>
      )}
      
      {/* Error message */}
      {uploadError && (
        <div className="absolute bottom-14 right-3 bg-red-100 text-red-700 px-3 py-1 rounded-md text-sm max-w-md">
          {uploadError}
        </div>
      )}
      
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfileBackground; 