import { createBaseApi } from '../baseApi';
import { setAddresses, setUserAddresses } from '../../slices/addressSlice';

const addressApi = createBaseApi('addressApi');

export const enhancedAddressApi = addressApi.injectEndpoints({
  endpoints: (builder) => ({
    getAddresses: builder.query({
      query: () => '/addresses',
      providesTags: ['Addresses'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAddresses(data));
        } catch (error) {
          // Handle error if needed
        }
      }
    }),
    
    getAddress: builder.query({
      query: (id) => `/addresses/${id}`,
      providesTags: (result, error, id) => [{ type: 'Addresses', id }]
    }),
    
    createAddress: builder.mutation({
      query: (address) => {
        // Check if data is nested incorrectly
        const unwrappedData = address.data ? address.data : address;
        
        return {
          url: '/addresses',
          method: 'POST',
          body: unwrappedData
        };
      },
      invalidatesTags: ['Addresses', 'User'],
      // Update the user's addresses in the store
      async onQueryStarted(address, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserAddresses({ address: data, action: 'add' }));
        } catch (error) {
          console.error('Error creating address:', error);
        }
      }
    }),
    
    updateAddress: builder.mutation({
      query: ({ id, ...address }) => {
        // Check if data is nested incorrectly (inside a data property)
        const unwrappedData = address.data ? address.data : address;
        
        return {
          url: `/addresses/${id}`,
          method: 'PUT',
          body: unwrappedData
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Addresses', id },
        'Addresses',
        'User'
      ],
      // Update the address in the store
      async onQueryStarted({ id, ...address }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserAddresses({ address: data, action: 'update' }));
        } catch (error) {
          console.error('Error updating address:', error);
        }
      }
    }),
    
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/addresses/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Addresses', id },
        'Addresses',
        'User'
      ],
      // Remove the address from the store
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setUserAddresses({ address: { id }, action: 'remove' }));
        } catch (error) {
          // Handle error if needed
        }
      }
    }),
    
    setDefaultAddress: builder.mutation({
      query: (id) => ({
        url: `/addresses/${id}`,
        method: 'PUT',
        body: { isDefault: true }
      }),
      invalidatesTags: ['Addresses', 'User']
    }),
    
    // Address validation endpoint
    validateAddress: builder.mutation({
      query: (addressData) => {
        // Check if data is nested incorrectly (might be wrapped in a data property)
        const unwrappedData = addressData.data ? addressData.data : addressData;
        
        return {
          url: '/addresses/validate',
          method: 'POST',
          body: unwrappedData
        };
      },
      // Add tracking for API request lifecycle
      async onQueryStarted(addressData, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error('Address validation failed:', error);
        }
      }
    }),
    
    // Legacy mutation to support existing components
    updateUserAddress: builder.mutation({
      query: ({ userId, addresses }) => ({
        url: `/users/${userId}`,
        method: 'PUT',
        body: {
          shippingaddress: addresses
        }
      }),
      invalidatesTags: ['Addresses', 'User']
    })
  })
});

export const {
  useGetAddressesQuery,
  useGetAddressQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useSetDefaultAddressMutation,
  useValidateAddressMutation,
  useUpdateUserAddressMutation
} = enhancedAddressApi; 