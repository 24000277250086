import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CardsContainer from '../cards/CardsContainer';
import BrowseCard from '../cards/card/BrowseCard';
import BrowseFilter from '../cards/filter/BrowseFilter';
import { useUrlChangeHandler } from '../../hooks/useUrlChangeHandler';

const Favorites = () => {
  const history = useHistory();
  const { products, loading, error, hasMore, totalResults, searchTerm } = useSelector(state => state.filter);
  
  // Use the shared URL change handler
  useUrlChangeHandler();


  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900">My Favorites</h1>
          <p className="mt-2 text-sm text-gray-500">
            Items you've liked will appear here
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Filter Section - responsive width */}
          <div className="w-full lg:w-1/4 xl:w-1/5">
            <div className="bg-white shadow-sm rounded-lg">
              <BrowseFilter />
            </div>
          </div>
          
          {/* Cards Section */}
          <div className="w-full lg:w-3/4 xl:w-4/5">
            <div className="bg-white shadow-sm rounded-lg p-6">
              <CardsContainer
                loading={loading}
                error={error}
                hasMore={hasMore}
                totalResults={totalResults}
                searchTerm={searchTerm}
              >
                {products.map(product => (
                  <BrowseCard 
                    key={product.id} 
                    product={product}
                  />
                ))}
              </CardsContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites; 