import { createBaseApi } from '../baseApi';

// Create a base cart API
const cartApi = createBaseApi('cartApi');

// Create the enhanced cart API with endpoints
export const enhancedCartApi = cartApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get the current user's cart
    getCart: builder.query({
      query: () => '/carts/me',
      providesTags: ['Cart'],
    }),
    
    // Create a new cart (usually not needed as getCart creates one if needed)
    createCart: builder.mutation({
      query: () => ({
        url: '/carts',
        method: 'POST',
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Add an item to the cart
    addCartItem: builder.mutation({
      query: (cartItem) => ({
        url: '/carts/items',
        method: 'POST',
        body: cartItem,
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Replace a rental period for an existing item
    replaceRentalPeriod: builder.mutation({
      query: ({ itemIndex, rentalPeriod }) => ({
        url: '/carts/rental-period',
        method: 'PUT',
        body: {
          itemIndex,
          rentalPeriod,
        },
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Update a cart item
    updateCartItem: builder.mutation({
      query: ({ itemIndex, cartItem }) => ({
        url: '/carts/items',
        method: 'PUT',
        body: {
          itemIndex,
          cartItem,
        },
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Remove an item from the cart
    removeCartItem: builder.mutation({
      query: (itemIndex) => ({
        url: `/carts/items/${itemIndex}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Clear all items from the cart
    clearCart: builder.mutation({
      query: () => ({
        url: '/carts/clear',
        method: 'PUT',
      }),
      invalidatesTags: ['Cart'],
    }),
    
    // Process cart checkout
    checkout: builder.mutation({
      query: (checkoutData) => ({
        url: '/carts/checkout',
        method: 'POST',
        body: checkoutData,
      }),
      invalidatesTags: ['Cart'],
    }),
  }),
});

// Export hooks for using the API
export const {
  useGetCartQuery,
  useCreateCartMutation,
  useAddCartItemMutation,
  useUpdateCartItemMutation,
  useRemoveCartItemMutation,
  useClearCartMutation,
  useCheckoutMutation,
  useReplaceRentalPeriodMutation,
} = enhancedCartApi;

// Helper function to map cart items to the format expected by the frontend
export const mapCartItems = (cart) => {
  if (!cart || !cart.cartItems) return { buyItems: [], rentItems: [] };
  
  const buyItems = [];
  const rentItems = [];
  
  cart.cartItems.forEach(item => {
    const product = item.product;
    if (!product) return;
    
    const mappedItem = {
      ...product,
      quantity: item.quantity,
      cartItemIndex: cart.cartItems.indexOf(item),
      productOptions: item.productOptions || [],
      customNote: item.customNote,
      merchant: item.merchant
    };
    
    if (item.type === 'rent' && item.rentalPeriod) {
      mappedItem.rentalPeriod = item.rentalPeriod;
      rentItems.push(mappedItem);
    } else {
      buyItems.push(mappedItem);
    }
  });
  
  return { buyItems, rentItems };
};

export default enhancedCartApi;
