/**
 * Country-specific utilities for address handling
 */
import { getNames, getCodes, getName } from 'country-list';

// Cache the country options to avoid recalculating
let countryOptionsCache = null;

/**
 * Get a list of countries formatted for dropdown selection
 * Returns array of objects with value (country code) and text (country name)
 * 
 * @returns {Array<{value: string, text: string}>} Array of country options
 */
export const getCountryOptions = () => {
  // Return cached options if available
  if (countryOptionsCache) {
    return countryOptionsCache;
  }

  const countries = getNames();
  const countryCodes = getCodes();
  
  // Create options array with value (code) and text (name)
  const countryOptions = countries.map((name, index) => ({
    value: countryCodes[index],
    text: name
  }));
  
  // Cache the result
  countryOptionsCache = countryOptions;
  
  return countryOptions;
};

/**
 * Get country name from country code
 * 
 * @param {string} code - Country code (e.g., 'US')
 * @returns {string|undefined} Country name or undefined if not found
 */
export const getCountryNameByCode = (code) => {
  if (!code) return undefined;
  
  // Use the direct lookup function from country-list
  const name = getName(code);
  if (name) return name;
  
  // Fallback to our cached options if direct lookup fails
  const options = getCountryOptions();
  const country = options.find(option => option.value === code);
  return country ? country.text : undefined;
};

/**
 * Get country code from country name
 * 
 * @param {string} name - Country name (e.g., 'United States')
 * @returns {string|undefined} Country code or undefined if not found
 */
export const getCountryCodeByName = (name) => {
  if (!name) return undefined;
  
  const options = getCountryOptions();
  const country = options.find(option => option.text === name);
  return country ? country.value : undefined;
};

/**
 * Get the appropriate label for administrative division (state/province/region) based on country
 * 
 * @param {string} countryCode - Two-letter country code (e.g., 'US', 'CA', 'GB')
 * @param {string} defaultLabel - Default label if no specific one is found
 * @returns {string} The appropriate label for the administrative division
 */
export const getAdminDivisionLabel = (countryCode, defaultLabel = 'Region') => {
  if (!countryCode) return defaultLabel;
  
  const upperCode = countryCode.toUpperCase();
  
  // Map of country codes to their administrative division labels
  const labelMap = {
    'US': 'State',
    'CA': 'Province',
    'AU': 'State',
    'GB': 'County',
    'UK': 'County',
    'IN': 'State',
    'CN': 'Province',
    'JP': 'Prefecture',
    'DE': 'State',
    'FR': 'Department',
    'IT': 'Province',
    'ES': 'Province',
    'MX': 'State'
  };
  
  return labelMap[upperCode] || defaultLabel;
};

/**
 * Check if a country requires a state/region field
 * 
 * @param {string} countryCode - Two-letter country code
 * @returns {boolean} Whether the state/region field is required
 */
export const isStateRequired = (countryCode) => {
  if (!countryCode) return false;
  
  const upperCode = countryCode.toUpperCase();
  
  // Countries where state/region is typically required
  const requiredStateCountries = [
    'US', 'CA', 'AU', 'IN', 'CN', 'JP', 'MX', 'BR'
  ];
  
  return requiredStateCountries.includes(upperCode);
};

/**
 * Check if a country uses postal codes
 * 
 * @param {string} countryCode - Two-letter country code
 * @returns {boolean} Whether the country uses postal codes
 */
export const usesPostalCode = (countryCode) => {
  if (!countryCode) return true; // Default to true
  
  const upperCode = countryCode.toUpperCase();
  
  // Countries that don't use postal codes or where they're optional
  const noPostalCodeCountries = [
    'AO', 'AG', 'BS', 'BZ', 'BJ', 'BW', 'BF', 'BI', 'CM', 'CF', 'KM', 
    'CG', 'CD', 'CK', 'CI', 'DJ', 'DM', 'GQ', 'ER', 'FJ', 'GM', 'GH'
  ];
  
  return !noPostalCodeCountries.includes(upperCode);
};

/**
 * Get postal code format for validation based on country
 * 
 * @param {string} countryCode - Two-letter country code
 * @returns {Object} Validation info including regex pattern and error message
 */
export const getPostalCodeFormat = (countryCode) => {
  if (!countryCode) {
    return { 
      pattern: /^.+$/, 
      message: 'Please enter a valid postal code'
    };
  }
  
  const upperCode = countryCode.toUpperCase();
  
  // Map of country codes to postal code validation regex
  const formatMap = {
    'US': {
      pattern: /^\d{5}(-\d{4})?$/,
      message: 'US ZIP codes must be 5 digits or 5+4 digits'
    },
    'CA': {
      pattern: /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
      message: 'Canadian postal codes must be in format "A1A 1A1"'
    },
    'UK': {
      pattern: /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i,
      message: 'UK postcodes must be in a valid format'
    },
    'GB': {
      pattern: /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i,
      message: 'UK postcodes must be in a valid format'
    },
    'DE': {
      pattern: /^\d{5}$/,
      message: 'German postal codes must be 5 digits'
    },
    'FR': {
      pattern: /^\d{5}$/,
      message: 'French postal codes must be 5 digits'
    },
    'JP': {
      pattern: /^\d{3}-\d{4}$/,
      message: 'Japanese postal codes must be in format "123-4567"'
    }
  };
  
  return formatMap[upperCode] || { 
    pattern: /^.+$/, 
    message: 'Please enter a valid postal code'
  };
}; 