// Common materials for product listing
export const COMMON_MATERIALS = [
  { value: 'cotton', label: 'Cotton' },
  { value: 'polyester', label: 'Polyester' },
  { value: 'wool', label: 'Wool' },
  { value: 'silk', label: 'Silk' },
  { value: 'linen', label: 'Linen' },
  { value: 'leather', label: 'Leather' },
  { value: 'denim', label: 'Denim' },
  { value: 'cashmere', label: 'Cashmere' },
  { value: 'velvet', label: 'Velvet' },
  { value: 'satin', label: 'Satin' },
  { value: 'spandex', label: 'Spandex' },
  { value: 'nylon', label: 'Nylon' },
  { value: 'rayon', label: 'Rayon' },
  { value: 'acrylic', label: 'Acrylic' },
  { value: 'blend', label: 'Blend' },
  { value: 'other', label: 'Other' }
]; 