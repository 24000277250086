import React from 'react';

const Button = ({ 
  onClick,
  disabled = false,
  isLoading = false,
  variant = 'primary', // primary, secondary, danger, success, warning, orange, teal, yellow, offer
  size = 'md',         // sm, md, lg
  icon,                // optional icon component
  children,
  className = '',
  type = 'button',
  fullWidth = false
}) => {
  const baseClasses = 'inline-flex items-center justify-center border border-transparent font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed';
  
  const sizeClasses = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-sm',
    lg: 'px-6 py-3 text-base'
  };

  const variantClasses = {
    primary: 'text-white bg-brand-teal hover:bg-brand-teal-dark focus:ring-brand-teal',
    secondary: 'text-gray-700 bg-white border-gray-300 hover:bg-gray-50 focus:ring-brand-teal',
    danger: 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500',
    success: 'text-white bg-green-500 hover:bg-green-600 focus:ring-green-500',
    warning: 'text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-500',
    orange: 'text-white bg-brand-orange hover:bg-brand-orange/90 focus:ring-brand-orange',
    teal: 'text-white bg-brand-teal hover:bg-brand-teal/90 focus:ring-brand-teal',
    yellow: 'text-gray-800 bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-400',
    offer: 'text-gray-800 bg-brand-beige-light hover:bg-brand-beige focus:ring-brand-beige'
  };

  const widthClass = fullWidth ? 'w-full' : '';

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${baseClasses} ${sizeClasses[size]} ${variantClasses[variant]} ${widthClass} ${className}`}
    >
      {isLoading ? (
        <div className="h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
      ) : (
        <>
          {icon && <span className="mr-1.5">{icon}</span>}
          {children}
        </>
      )}
    </button>
  );
};

export default Button; 