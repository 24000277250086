import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Section from '../Section';

gsap.registerPlugin(ScrollTrigger);

const OccasionSection = () => {
  const userLang = navigator.language || navigator.userLanguage;
  const containerRef = useRef(null);
  const sectionRefs = useRef([]);

  useEffect(() => {
    const container = containerRef.current;
    const sections = sectionRefs.current;

    if (container && sections.length > 0) {
      // Set up horizontal scroll
      const totalWidth = sections.length * window.innerWidth;
      container.style.width = `${totalWidth}px`;

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          end: () => `+=${totalWidth}`,
          invalidateOnRefresh: true
        }
      });
    }

    return () => {
      ScrollTrigger.getAll().forEach(t => t.kill());
    };
  }, []);

  const images = [
    "https://res.cloudinary.com/sheyou/image/upload/v1727638068/couture_f2bfe95973.svg",
    "https://res.cloudinary.com/sheyou/image/upload/v1727638068/metalic_541fb1a11a.svg",
    "https://res.cloudinary.com/sheyou/image/upload/v1727638068/tennis_5d41863bed.svg",
    "https://res.cloudinary.com/sheyou/image/upload/v1727638234/suit_ef6ed1de24.svg"
  ];

  return (
    <div className="relative">
        {/* Text Section */}
      <Section
        fullWidth
        minHeight="min-h-[60vh]"
        padding="py-16"
        bgColor="bg-[#00BAB0]"
        title={userLang === 'zh-CN' ? '让时尚循环起来' : 'Keep fashion circular with styles'}
        description={userLang === 'zh-CN' 
          ? '通过租赁、购买或交换，庆祝个性风格' 
          : 'Celebrate individuality through various ways of rent, buy, or trade.'}
        buttonText={userLang === 'zh-CN' ? '浏览' : 'Browse'}
        buttonLink="/clothing"
        buttonVariant="primary"
        buttonSize="lg"
        className="text-white"
      >
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-8 max-w-6xl mx-auto">
          
        </div>
      </Section>
      {/* First Sliding Section */}
      <div className="relative overflow-hidden">
        <div ref={containerRef} className="relative h-screen flex">
          {images.map((img, index) => (
            <div
              key={index}
              ref={el => sectionRefs.current[index] = el}
              className="w-screen h-screen flex-shrink-0 relative"
            >
              <div 
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${img})` }}
              />
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
};

export default OccasionSection; 