import React from 'react';
import useFilter from '../useFilter';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ClearAll = ({ className = '' }) => {
  const { handleClearAll } = useFilter();

  return (
    <button
      onClick={handleClearAll}
      className={`flex items-center gap-1.5 text-brand-orange hover:text-brand-orange-light focus:outline-none text-sm font-medium transition-colors ${className}`}
    >
      {/* <XMarkIcon className="h-5 w-5" aria-hidden="true" /> */}
      <span>Reset Filters</span>
    </button>
  );
};

export default ClearAll; 