import React from 'react';
import Checkbox from '../../../../ui/Checkbox';
import useFilter from '../../useFilter';

const ActiveListingCheckbox = ({ className = '' }) => {
  const { filters, handleFilterChange } = useFilter();

  return (
    <Checkbox
      checked={filters.activeListing === true}
      onChange={(e) => handleFilterChange({ activeListing: e.target.checked })}
      label="Active Listings"
      className={className}
    />
  );
};

export default ActiveListingCheckbox; 