import React from 'react';

const FormInput = ({ 
  label, 
  error, 
  as = 'input', 
  required, 
  children, 
  type, 
  renderCustomInput,
  ...props 
}) => {
  const getInputClasses = (error) => 
    `form-${as} w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;

  const handleKeyPress = (e) => {
    // Allow only numbers, decimal point, backspace, delete, and arrow keys for number inputs
    if (type === 'number') {
      const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
      // Allow only one decimal point
      if (e.key === '.' && e.target.value.includes('.')) {
        e.preventDefault();
        return;
      }
      // Prevent other non-numeric characters
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleChange = (e) => {
    // If there's an onChange handler passed in props, call it
    if (props.onChange) {
      if (type === 'number') {
        // For number inputs, set the type as 'number' in the event
        const modifiedEvent = {
          ...e,
          target: {
            ...e.target,
            type: 'number'
          }
        };
        props.onChange(modifiedEvent);
      } else {
        props.onChange(e);
      }
    }
  };

  // Create an error node with support for multi-line errors
  const formatErrorMessage = (errorMessage) => {
    if (!errorMessage) return null;
    
    // If the error message contains new lines, split it and render with line breaks
    if (errorMessage.includes('\n')) {
      return (
        <div className="mt-1 text-sm text-red-600 whitespace-pre-line">
          {errorMessage}
        </div>
      );
    }
    
    // Otherwise, render as normal
    return (
      <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
    );
  };

  // Remove the original onChange to use our custom one
  const { onChange, ...restProps } = props;
  
  // Create label node
  const labelNode = label ? (
    <label className="block text-label font-medium text-gray-700 mb-2">
      {label} {required && <span className="text-red-500 ml-1">*</span>}
    </label>
  ) : null;
  
  // Create error node
  const errorNode = error ? formatErrorMessage(error) : null;
  
  // If a custom render function is provided, use it
  if (renderCustomInput) {
    const inputProps = {
      ...restProps,
      onChange: handleChange,
      onKeyDown: type === 'number' ? handleKeyPress : undefined,
      type: type === 'number' ? 'text' : type
    };
    
    return renderCustomInput({
      inputProps,
      labelNode,
      errorNode
    });
  }

  // Default rendering
  return (
    <div className="max-w-form-input">
      {labelNode}
      {as === 'select' ? (
        <select className={getInputClasses(error)} onChange={handleChange} {...restProps}>
          {children}
        </select>
      ) : as === 'textarea' ? (
        <textarea className={getInputClasses(error)} onChange={handleChange} {...restProps} />
      ) : (
        <input 
          className={getInputClasses(error)} 
          onKeyDown={type === 'number' ? handleKeyPress : undefined}
          type={type === 'number' ? 'text' : type}
          onChange={handleChange}
          {...restProps} 
        />
      )}
      {errorNode}
    </div>
  );
};

export default FormInput; 