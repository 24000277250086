import { useDispatch, useSelector } from 'react-redux'
import { setSearchTerm, setSortBy, setFilter, clearAll } from '../../../redux/slices/filterSlice'
import { useEffect, useMemo } from 'react'

export const useFilter = () => {
  const dispatch = useDispatch()
  const { searchTerm, sortBy, filters } = useSelector((state) => state.filter)

  // Memoized options
  const sortOptions = useMemo(() => [
    { value: 'products', label: 'Newest First' },
    { value: 'products_by_price_asc', label: 'Price: Low to High' },
    { value: 'products_by_price_desc', label: 'Price: High to Low' },
    { value: 'products_by_likes_desc', label: 'Most Liked' }
  ], [])

  const productTypes = useMemo(() => [
    { value: 'designer', label: 'Original Design' },
    { value: 'sharing', label: 'Closet Sharing' },
    { value: 'virtual', label: 'Virtual Fashion' },
    { value: 'others', label: 'Others' }
  ], [])

  const transactionTypes = useMemo(() => [
    { value: 'forSell', label: 'For Sale' },
    { value: 'forRent', label: 'For Rent' },
    { value: 'openToSwap', label: 'Open to Swap' }
  ], [])

  // Update URL helper
  const updateURL = (params) => {
    const url = new URL(window.location.href)
    
    // Get existing params
    const currentParams = new URLSearchParams(window.location.search)
    
    // Update with new params
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        currentParams.delete(key)
      } else if (typeof value === 'object') {
        currentParams.set(key, JSON.stringify(value))
      } else {
        currentParams.set(key, value)
      }
    })
    
    // Replace URL
    const newUrl = `${window.location.pathname}${currentParams.toString() ? '?' + currentParams.toString() : ''}`
    window.history.pushState({}, '', newUrl)
  }

  // Core handlers
  const handleSearchChange = (value) => {
    dispatch(setSearchTerm(value))
    updateURL({ q: value || null })
  }

  const handleSortChange = (value) => {
    dispatch(setSortBy(value))
    updateURL({ sort: value })
  }

  const handleFilterChange = (newFilters) => {
    dispatch(setFilter(newFilters))
    // Update URL with all filters at once
    updateURL(newFilters)
  }

  const handleProductTypeChange = (type) => {
    handleFilterChange({ productType: type })
  }

  const handleTransactionTypeChange = (type, checked) => {
    handleFilterChange({ [type]: checked })
  }

  const handleLocalPickupChange = (checked, distance) => {
    const localPickupValue = checked
      ? {
          enabled: true,
          ...(distance !== undefined && { distance })
        }
      : { enabled: false, distance: null }
    
    handleFilterChange({ localPickup: localPickupValue })
  }

  const handleClearAll = () => {
    dispatch(clearAll())
    // Clear URL params
    window.history.pushState({}, '', window.location.pathname)
  }

  // Initialize from URL params
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const urlSearchTerm = params.get('q') || ''
    const urlSortBy = params.get('sort') || 'products'
    const urlFilters = {}

    // Convert URL params to filter object
    params.forEach((value, key) => {
      if (key !== 'q' && key !== 'sort') {
        try {
          urlFilters[key] = JSON.parse(value)
        } catch {
          urlFilters[key] = value
        }
      }
    })

    // Only update if values are different
    // if (urlSearchTerm !== searchTerm) {
    //   dispatch(setSearchTerm(urlSearchTerm))
    // }
    // if (urlSortBy !== sortBy) {
    //   dispatch(setSortBy(urlSortBy))
    // }
    // if (Object.keys(urlFilters).length > 0) {
    //   dispatch(setFilter(urlFilters))
    // }
  }, [])

  return {
    // State
    searchTerm,
    sortBy,
    filters,

    // Options
    sortOptions,
    productTypes,
    transactionTypes,

    // Handlers
    handleSearchChange,
    handleSortChange,
    handleFilterChange,
    handleProductTypeChange,
    handleTransactionTypeChange,
    handleLocalPickupChange,
    handleClearAll
  }
}

export default useFilter
