import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import Section from '../Section';

const SustainSection = () => {
  const userLang = navigator.language || navigator.userLanguage;

  return (
    <Parallax speed={-20}>
      <Section
        fullWidth
        minHeight="min-h-screen"
        bgImage="https://res.cloudinary.com/sheyou/image/upload/v1727700908/environment_befe0dab6d.svg"
        bgOverlay="rgba(255, 255, 255, 0.7)"
        className="text-gray-900 relative"
      >
        {/* Main Content Container */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 relative z-10">
          {/* Title Section */}
          <div className="text-center mb-8 sm:mb-12">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6">
              {userLang === 'zh-CN' ? '可持续时尚' : 'Sustainable Fashion'}
            </h2>
            <p className="text-base sm:text-lg md:text-xl text-gray-600 max-w-3xl mx-auto mb-6 sm:mb-8">
              {userLang === 'zh-CN' 
                ? '通过分享和租赁减少浪费，为环保尽一份力' 
                : 'Reduce waste through sharing and renting, contribute to environmental protection'}
            </p>
            <a 
              href="/about"
              className="inline-flex items-center px-6 sm:px-8 py-2.5 sm:py-3 text-base sm:text-lg font-semibold rounded-md border-2 border-brand-orange text-brand-orange hover:bg-brand-orange hover:text-white transition-colors duration-200 mb-8 sm:mb-12"
            >
              {userLang === 'zh-CN' ? '了解更多' : 'Learn More'}
            </a>
          </div>

          {/* Feature Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 max-w-5xl mx-auto">
            {/* Reduce Waste Card */}
            <div className="p-4 sm:p-6 bg-white/90 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-orange">
                {userLang === 'zh-CN' ? '减少浪费' : 'Reduce Waste'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700">
                {userLang === 'zh-CN' 
                  ? '通过共享衣物减少不必要的购买' 
                  : 'Reduce unnecessary purchases through clothing sharing'}
              </p>
            </div>

            {/* Circular Fashion Card */}
            <div className="p-4 sm:p-6 bg-white/90 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-orange">
                {userLang === 'zh-CN' ? '循环利用' : 'Circular Fashion'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700">
                {userLang === 'zh-CN' 
                  ? '让每件衣服都能发挥最大价值' 
                  : 'Maximize the value of every piece of clothing'}
              </p>
            </div>

            {/* Eco-friendly Card */}
            <div className="p-4 sm:p-6 bg-white/90 backdrop-blur-sm rounded-lg shadow-lg transform hover:scale-105 transition-all duration-300">
              <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3 text-brand-orange">
                {userLang === 'zh-CN' ? '环保时尚' : 'Eco-friendly'}
              </h3>
              <p className="text-sm sm:text-base text-gray-700">
                {userLang === 'zh-CN' 
                  ? '为可持续时尚贡献一份力量' 
                  : 'Contribute to sustainable fashion'}
              </p>
            </div>
          </div>
        </div>
      </Section>
    </Parallax>
  );
};

export default SustainSection; 