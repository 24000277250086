import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/outline';
import { showError, showSuccess } from '../../redux/slices/responseSlice';
import { 
  useGetCurrentUserQuery
} from '../../redux/apis';
import {
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductQuery
} from '../../redux/apis/endpoints/listingApi';
import {
  updateFormData,
  updateFieldErrors,
  setEditingId,
  resetForm,
  initializeFormWithProduct,
  prepareFormDataForSubmit
} from '../../redux/slices/listingSlice';
import LoadingSpinner from '../common/LoadingSpinner';
import { validateListingForm } from './listingValidation';
import { navigate } from '../../utils/navigation';

// Import modular section components
import ProductPhotoSection from './form_sections/ProductPhotoSection';
import BasicInfoSection from './form_sections/BasicInfoSection';
import DetailsSection from './form_sections/DetailsSection';
import AvailabilitySection from './form_sections/AvailabilitySection';
import PricingSection from './form_sections/PricingSection';

const ListingForm = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);
  
  const dispatch = useDispatch();
  const history = useHistory();
  
  // Get form data from Redux store
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);
  
  const { data: currentUser } = useGetCurrentUserQuery();
  const [createProduct, { isLoading: isCreating }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdating }] = useUpdateProductMutation();
  
  // Get product data if editing
  const {
    data: productData,
    isLoading: isLoadingProduct,
    isError: productError,
    refetch: refetchProduct
  } = useGetProductQuery(id, { skip: !isEditing });

  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Initialize form data
  useEffect(() => {
    // Set editing ID for reference
    if (isEditing && id) {
      dispatch(setEditingId(id));
    } else {
      dispatch(setEditingId(null));
    }
    
    // Reset form when component mounts or ID changes
    if (!isEditing) {
      dispatch(resetForm());
    }
    
    return () => {
      // Reset form when component unmounts
      dispatch(resetForm());
    };
  }, [dispatch, isEditing, id]);
  
  // Initialize form with product data when editing
  useEffect(() => {
    if (isEditing && productData) {
      dispatch(initializeFormWithProduct(productData));
    }
  }, [dispatch, isEditing, productData]);

  // Helper functions
  const getInputClasses = (error) => `form-input block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;
  const getSelectClasses = (error) => `form-select block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;
  const getTextareaClasses = (error) => `form-textarea block w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${error ? 'border-red-300' : 'border-gray-300'}`;

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form using the imported validation function
    const errors = validateListingForm(formData);
    
    if (Object.keys(errors).length > 0) {
      // Update Redux state with field errors
      dispatch(updateFieldErrors(errors));
      
      // Show general error message to user
      dispatch(showError('Please fill in all required fields'));
      
      // Scroll to the first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    // Clear errors
    dispatch(updateFieldErrors({}));
    
    // Prepare form data for submission
    const productData = prepareFormDataForSubmit(formData, currentUser?.id);
    
    setIsSubmitting(true);
    
    try {
      let response;
      
      if (isEditing) {
        response = await updateProduct({ id: formData.id, data: productData }).unwrap();
        dispatch(showSuccess('Product updated successfully!'));
      } else {
        response = await createProduct(productData).unwrap();
        dispatch(showSuccess('Product created successfully!'));
      }
      
      navigate('/dashboard/listings');
      
    } catch (error) {
      console.error('Error saving product:', error);
      
      // Handle backend validation errors
      if (error.data?.errors) {
        dispatch(updateFieldErrors(error.data.errors));
        
        // Scroll to the first error
        const firstErrorElement = document.querySelector('.text-red-600');
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else {
        // Show general error
        const errorMessage = error.data?.message || error.message || 'An error occurred while saving the product';
        dispatch(showError(errorMessage));
      }
      
      setIsSubmitting(false);
    }
  };

  // Show loading state while fetching product data
  if (isEditing && isLoadingProduct) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <LoadingSpinner size="lg" />
      </div>
    );
  }
  
  // Show error if product data fetch fails
  if (isEditing && productError) {
    return (
      <div className="text-center py-12">
        <h2 className="text-xl font-medium text-red-600 mb-2">Error Loading Product</h2>
        <p className="text-gray-600 mb-4">Unable to load the product data. Please try again later.</p>
        <button 
          onClick={() => refetchProduct()} 
          className="px-4 py-2 bg-brand-teal text-white rounded-md hover:bg-brand-teal-dark"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="w-full bg-brand-segment-bg min-h-screen">
      <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="relative flex justify-center mb-8">
          <Link 
            to="/dashboard/listings"
            className="absolute left-0 flex items-center text-gray-600 hover:text-gray-900 transition-colors"
            title="Back to Listings"
          >
            <ArrowLeftIcon className="w-5 h-5" />
            <span className="hidden sm:inline ml-1 text-sm font-medium">Back to Listings</span>
          </Link>
          <h1 className="text-3xl font-bold text-gray-900">
            {isEditing ? 'Edit Product' : 'Create New Product'}
          </h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Photo Section */}
          <ProductPhotoSection />
          
          {/* Basic Info Section */}
          <BasicInfoSection />
          
          {/* Details Section */}
          <DetailsSection />
          
          {/* Availability Section */}
          <AvailabilitySection />
          
          {/* Pricing Section */}
          <PricingSection />
          
          {/* Submit Button */}
          <div className="flex justify-end mt-8">
            <button
              type="submit"
              disabled={isSubmitting || isCreating || isUpdating}
              className="bg-brand-teal hover:bg-brand-teal-dark text-white flex items-center justify-center px-6 py-2 min-w-[180px] rounded-md"
            >
              {isSubmitting || isCreating || isUpdating ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {isEditing ? 'Updating...' : 'Creating...'}
                </span>
              ) : (
                <span className="flex items-center">
                  <CheckIcon className="w-5 h-5 mr-2" />
                  {isEditing ? 'Update Product' : 'Create Product'}
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ListingForm;
