import React, { useState, useEffect } from 'react'
import FilterSearchBox from './filter_components/FilterSearchBox'
import SortDropdown from './filter_components/dropdowns/SortDropdown'
import FilterDropdown from './filter_components/dropdowns/FilterDropdown'
import MoreFilterDropdown from './filter_components/dropdowns/MoreFilterDropdown'
import MoreFilterToggle from './filter_components/MoreFilterToggle'
import ClearAll from './filter_components/ClearAll'
import MenuGrid from '../../ui/MenuGrid'
import MenuItem from '../../ui/MenuItem'

const BaseFilter = ({ children, layout = 'vertical' }) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  // Detect if screen is small (less than lg breakpoint in Tailwind = 1024px)
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1024)
    }

    // Initial check
    checkScreenSize()

    // Listen for window resize
    window.addEventListener('resize', checkScreenSize)

    // Clean up
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  // Determine if we should use horizontal layout
  // True if explicitly set to horizontal OR if vertical but on small screen
  const useHorizontalLayout =
    layout !== 'vertical' || (layout === 'vertical' && isSmallScreen)

  // Search and Sort Section
  const topSection = (
    <>
      <MenuItem>
        <FilterSearchBox maxWidth='none' />
      </MenuItem>
      <MenuItem>
        <SortDropdown maxWidth='none' />
      </MenuItem>
    </>
  )

  // Filter Dropdowns Section - MoreFilterToggle only in vertical mode on large screens
  const filterSection = (
    <>
      <MenuItem>
        <FilterDropdown type='productType' maxWidth='none' />
      </MenuItem>
      <MenuItem>
        <FilterDropdown type='transactionType' maxWidth='none' />
      </MenuItem>
      {!useHorizontalLayout && (
        <MenuItem>
          <MoreFilterToggle
            enabled={showMoreFilters}
            onChange={setShowMoreFilters}
          />
        </MenuItem>
      )}
      {!useHorizontalLayout && showMoreFilters && (
        <>
          <MenuItem>
            <MoreFilterDropdown type='category' />
          </MenuItem>
          <MenuItem>
            <MoreFilterDropdown type='size' />
          </MenuItem>
          <MenuItem>
            <MoreFilterDropdown type='color' />
          </MenuItem>
        </>
      )}
    </>
  )

  // Expanded filter section for horizontal mode
  const expandedFilterSection =
    useHorizontalLayout && showMoreFilters ? (
      <>
        <MenuItem>
          <MoreFilterDropdown type='category' />
        </MenuItem>
        <MenuItem>
          <MoreFilterDropdown type='size' />
        </MenuItem>
        <MenuItem>
          <MoreFilterDropdown type='color' />
        </MenuItem>
      </>
    ) : null

  // Checkboxes and Clear All Section - MoreFilterToggle in horizontal mode
  const bottomSection = (
    <>
      <div className='w-full'>{children}</div>
      {useHorizontalLayout && (
        <div className='flex items-center justify-end space-x-4 w-full flex-wrap'>
          <MoreFilterToggle
            enabled={showMoreFilters}
            onChange={setShowMoreFilters}
            className='mt-2 mb-2'
          />
          <ClearAll className='mt-2 mb-2' />
        </div>
      )}
      {!useHorizontalLayout && <ClearAll />}
    </>
  )

  // Add LocationFilter to vertical layout when more filters are shown
  if (!useHorizontalLayout && showMoreFilters) {
    return (
      <MenuGrid
        layout={layout}
        topSection={topSection}
        filterSection={<>{filterSection}</>}
        expandedFilterSection={expandedFilterSection}
        bottomSection={bottomSection}
      />
    )
  }

  return (
    <MenuGrid
      layout={layout}
      topSection={topSection}
      filterSection={filterSection}
      expandedFilterSection={expandedFilterSection}
      bottomSection={bottomSection}
    />
  )
}

export default BaseFilter
