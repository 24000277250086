import React from 'react';

const TabButton = ({ 
  isActive = false, 
  onClick, 
  children, 
  className = '',
  isDisabled = false
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      className={`
        ${
          isActive
            ? 'border-brand-orange text-brand-orange'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
        } 
        ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
        whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm
        ${className}
      `}
    >
      {children}
    </button>
  );
};

export default TabButton; 