import React from 'react';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { PLACEHOLDER_IMAGE } from '../../utils/config';

const BaseCard = ({ 
  product,
  onClick,
  className = '',
  renderTopRightContent,
  renderTopLeftContent,
  renderBottomContent,
  renderActionButtons
}) => {
  if (!product) return null;

  return (
    <div 
      className={`relative bg-white rounded-lg shadow-card hover:shadow-card-hover transition-shadow duration-200 overflow-hidden ${className}`}
    >
      {/* Top Left Content (Checkbox, etc.) */}
      <div className="absolute top-1.5 left-1.5 z-10">
        {renderTopLeftContent?.(product)}
      </div>

      {/* Top Right Content (Like button or Status badges) */}
      <div className="absolute top-1.5 right-1.5 z-10">
        {renderTopRightContent?.(product)}
      </div>

      {/* Clickable Content Area */}
      <div 
        onClick={() => onClick(product)}
        className="cursor-pointer"
      >
        {/* Product Image */}
        <div className="relative h-48 w-full group">
          <img
            src={product.photos?.[product.coverImageIndex]?.url || PLACEHOLDER_IMAGE}
            alt={product.name}
            className="object-contain w-full h-full transition-transform duration-300 group-hover:scale-105"
          />
          {/* Scale Icon Overlay */}
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-20 transition-opacity duration-300">
            <ArrowsPointingOutIcon className="w-12 h-12 text-gray-900" />
          </div>
        </div>

        {/* Product Info */}
        <div className="p-3">
          {/* Title */}
          <h3 className="text-sm font-medium text-gray-900 truncate">
            {product.name}
          </h3>

          {/* Custom Bottom Content (Tags, Actions, etc.) */}
          {renderBottomContent?.(product)}
        </div>
      </div>

      {/* Action Buttons */}
      {renderActionButtons && (
        <div className="px-3 pb-3 pt-0 border-t border-gray-100">
          {renderActionButtons(product)}
        </div>
      )}
    </div>
  );
};

export default BaseCard; 