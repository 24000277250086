import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../../redux/slices/listingSlice';
import UploadProductPhoto from '../form_components/UploadProductPhoto';

const ProductPhotoSection = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);
  const isEditing = Boolean(formData?.id);
  
  // Debug what's coming from Redux
  useEffect(() => {
    console.log('ProductPhotoSection - formData:', formData);
    console.log('ProductPhotoSection - photos field:', formData.photos);
    console.log('ProductPhotoSection - isEditing:', isEditing);
  }, [formData, isEditing]);

  return (
    <div className="space-y-4 max-w-[1400px] mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">
        Product Photos {fieldErrors?.photos && <span className="text-red-500 ml-1">*</span>}
      </h2>
      
      <div className="max-w-3xl mx-auto">
        <UploadProductPhoto
          onUploadPic={({ photos, coverIndex }) => 
            dispatch(updateFormData({ 
              photos, 
              coverImageIndex: coverIndex 
            }))}
          error={fieldErrors?.photos}
          initialPhotos={isEditing && formData?.photos ? formData.photos : []}
          initialCoverIndex={isEditing && formData?.coverImageIndex ? formData.coverImageIndex : 0}
        />
      </div>
    </div>
  );
};

export default ProductPhotoSection; 