import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  selectedProducts: [],
  deleteModalOpen: false,
  form: {
    formData: {
      name: '',
      description: '',
      productType: '',
      stock: '',
      price: '',
      photos: [],
      coverImageIndex: 0,
      forSell: false,
      forRent: false,
      openToSwap: false,
      openToPickUp: false,
      openToShipping: false,
      pickUpAddress: null,
      selectedAddressId: '',
      rentPriceAndTime: [],
      requireDeposit: false,
      rentDeposit: '',
      category: '',
      size: '',
      customSizeDetails: '',
      color: '',
      condition: '',
      material: '',
      brand: ''
    },
    editingId: null,
    fieldErrors: {
      name: '',
      description: '',
      productType: '',
      stock: '',
      price: '',
      photos: '',
      category: '',
      size: '',
      color: '',
      condition: '',
      pickUpAddress: '',
      deliveryOptions: '',
      rentPriceAndTime: '',
      rentDeposit: '',
      material: '',
      brand: ''
    }
  }
};

// Action to use outside of slice (used by product API)
export const resetSelectedProducts = createAction('listing/resetSelectedProducts');

// Helper function to prepare form data for submission to API
export const prepareFormDataForSubmit = (formData, userId) => {
  // Create the base data object
  const data = {
    name: formData.name,
    description: formData.description,
    productType: formData.productType,
    category: formData.category,
    size: formData.size,
    customSizeDetails: formData.size === 'custom' ? formData.customSizeDetails : '',
    color: formData.color,
    
    // Handle condition: required for sharing, optional otherwise
    ...(formData.productType === 'sharing' || (formData.condition && formData.condition !== '') ? { 
      condition: formData.condition 
    } : {}),
    
    // Material is optional - only include if it's not an empty string or null
    ...(formData.material && formData.material !== '' ? { 
      material: formData.material 
    } : {}),
    
    // Brand is optional - only include if it's not an empty string or null
    ...(formData.brand && formData.brand !== '' ? { 
      brand: formData.brand 
    } : {}),
    
    stock: parseInt(formData.stock) || 1, // Ensure stock is at least 1
    
    // Only include price if the item is for sale
    ...(formData.forSell ? { 
      price: parseInt(formData.price) || 0
    } : { price: 0 }), // Set price to 0 if not for sale
    
    photos: formData.photos,
    coverImageIndex: formData.coverImageIndex,
    forSell: formData.forSell,
    forRent: formData.forRent,
    openToSwap: formData.openToSwap,
    openToPickUp: formData.openToPickUp,
    openToShipping: formData.openToShipping,
    
    // Handle pickup address relation correctly
    ...(formData.openToPickUp && (formData.pickUpAddress || formData.selectedAddressId) ? { 
      pickupAddress: formData.pickUpAddress ? formData.pickUpAddress.id : formData.selectedAddressId 
    } : {}),
    
    // Only include rental info if item is for rent
    ...(formData.forRent ? {
      rentPriceAndTime: formData.rentPriceAndTime,
      rentDeposit: formData.requireDeposit ? (parseInt(formData.rentDeposit) || 0) : null
    } : {
      rentPriceAndTime: [],
      rentDeposit: null
    }),
    
    users_permissions_user: userId
  };
  
  // Log the data being sent to the API for debugging
  console.log('Submitting product data:', data);
  
  return data;
};

const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    toggleProductSelection: (state, action) => {
      const productId = action.payload;
      const index = state.selectedProducts.indexOf(productId);
      if (index === -1) {
        state.selectedProducts.push(productId);
      } else {
        state.selectedProducts.splice(index, 1);
      }
    },
    toggleSelectAll: (state, action) => {
      const allProducts = action.payload;
      if (state.selectedProducts.length === allProducts.length) {
        state.selectedProducts = [];
      } else {
        state.selectedProducts = allProducts.map(p => p.id);
      }
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
    setDeleteModalOpen: (state, action) => {
      state.deleteModalOpen = action.payload;
    },
    updateFormData: (state, action) => {
      state.form.formData = {
        ...state.form.formData,
        ...action.payload
      };
    },
    updateFieldErrors: (state, action) => {
      state.form.fieldErrors = {
        ...state.form.fieldErrors,
        ...action.payload
      };
    },
    setEditingId: (state, action) => {
      state.form.editingId = action.payload;
    },
    resetForm: (state) => {
      state.form = initialState.form;
    },
    initializeFormWithProduct: (state, action) => {
      const product = action.payload;
      
      // Update form data with product details
      state.form.formData = {
        ...state.form.formData,
        id: product.id,
        name: product.name || '',
        description: product.description || '',
        productType: product.productType || '',
        category: product.category || '',
        size: product.size || '',
        customSizeDetails: product.customSizeDetails || '',
        color: product.color || '',
        condition: product.condition || '',
        material: product.material || '',
        brand: product.brand || '',
        price: product.price || '',
        stock: product.stock || '',
        photos: product.photos || [],
        coverImageIndex: product.coverImageIndex || 0,
        forSell: !!product.forSell,
        forRent: !!product.forRent,
        openToSwap: !!product.openToSwap,
        openToPickUp: !!product.openToPickUp,
        rentPriceAndTime: product.rentPriceAndTime || [],
        requireDeposit: !!product.rentDeposit,
        rentDeposit: product.rentDeposit || '',
        
        // Handle pickup address relation correctly
        pickUpAddress: product.pickupAddress || null,
        selectedAddressId: product.pickupAddress?.id || null
      };
      
      // Log the pickup address for debugging
      console.log('Initializing form with pickup address:', product.pickupAddress);
      
      // Clear any field errors
      state.form.fieldErrors = initialState.form.fieldErrors;
    },
    normalizeFormData: (state) => {
      // Convert empty strings to 0 for rental-related fields
      state.form.formData = {
        ...state.form.formData,
        rentDeposit: state.form.formData.rentDeposit === '' ? 0 : state.form.formData.rentDeposit,
    };
    }
  },
  extraReducers: (builder) => {
    // Handle external actions
    builder
      .addCase(resetSelectedProducts, (state) => {
        state.selectedProducts = [];
      });
  }
});

export const { 
  toggleProductSelection, 
  toggleSelectAll,
  clearSelectedProducts,
  setDeleteModalOpen,
  updateFormData,
  updateFieldErrors,
  setEditingId,
  resetForm,
  initializeFormWithProduct,
  normalizeFormData
} = listingSlice.actions;

export default listingSlice.reducer; 