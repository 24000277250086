import { useState, useRef } from 'react';
import FormInput from '../../components/ui/FormInput';
import Button from '../../components/ui/Button';
import { useChangePasswordMutation } from '../../redux/apis/endpoints/profileApi';
import PasswordInput from './components/PasswordInput';
import passwordValidator from 'password-validator';

// Create a schema for password validation
const createPasswordSchema = () => {
  const schema = new passwordValidator();
  
  // Add properties to the schema
  schema
    .is().min(6)                                   // Minimum length 6
    .is().max(100)                                 // Maximum length 100
    .has().uppercase()                             // Must have uppercase letters
    .has().lowercase()                             // Must have lowercase letters
    .has().digits(1)                               // Must have at least 1 digit
    .has().not().spaces()                          // Should not have spaces
    .is().not().oneOf(['password', 'Password']);   // Blacklist these values
  
  return schema;
};

const schema = createPasswordSchema();

const Security = ({ user, formData, setFormData, userLang }) => {
  const [changePassword, { isLoading: isSubmitting }] = useChangePasswordMutation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formErrors, setFormErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  // Reference to the password validator component to access its validation state
  const newPasswordValidationRef = useRef({
    validationErrors: []
  });

  // Keep track of validation errors from the password validator
  const updateValidationErrors = (errors) => {
    newPasswordValidationRef.current.validationErrors = errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Generate specific error message based on validation errors
  const getPasswordStrengthHints = (validationErrors) => {
    if (!validationErrors || validationErrors.length === 0) return '';
    
    const errorMessages = {
      min: userLang === 'zh-CN' ? '• 密码长度至少为6位' : '• Password must be at least 6 characters',
      max: userLang === 'zh-CN' ? '• 密码长度不能超过100位' : '• Password must be less than 100 characters',
      uppercase: userLang === 'zh-CN' ? '• 密码必须包含大写字母' : '• Password must include uppercase letters',
      lowercase: userLang === 'zh-CN' ? '• 密码必须包含小写字母' : '• Password must include lowercase letters',
      digits: userLang === 'zh-CN' ? '• 密码必须包含数字' : '• Password must include digits',
      spaces: userLang === 'zh-CN' ? '• 密码不能包含空格' : '• Password cannot contain spaces',
      oneOf: userLang === 'zh-CN' ? '• 密码太简单' : '• Password is too common'
    };
    
    const hints = validationErrors.map(error => errorMessages[error] || `• ${error}`);
    return hints.join('\n');
  };

  // Validate the password form fields
  const validateForm = () => {
    let isValid = true;
    const errors = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    };

    // Check if current password is provided
    if (!formData.currentPassword.trim()) {
      errors.currentPassword = userLang === 'zh-CN' ? '请输入当前密码' : 'Current password is required';
      isValid = false;
    }

    // Check if new password is provided
    if (!formData.newPassword.trim()) {
      errors.newPassword = userLang === 'zh-CN' ? '请输入新密码' : 'New password is required';
      isValid = false;
    }

    // Check if confirm password is provided
    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = userLang === 'zh-CN' ? '请确认新密码' : 'Please confirm your new password';
      isValid = false;
    }

    // Check if new password and confirm password match
    if (formData.newPassword && formData.confirmPassword && 
        formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = userLang === 'zh-CN' ? '密码不匹配' : 'Passwords do not match';
      isValid = false;
    }

    // Check if new password is the same as current password
    if (formData.currentPassword && formData.newPassword && 
        formData.currentPassword === formData.newPassword) {
      errors.newPassword = userLang === 'zh-CN' 
        ? '新密码不能与当前密码相同' 
        : 'New password must be different from current password';
      isValid = false;
    }

    // Check password strength only if there's a password entered
    if (formData.newPassword.trim()) {
      const validationErrors = newPasswordValidationRef.current.validationErrors;
      if (validationErrors && validationErrors.length > 0) {
        errors.newPassword = userLang === 'zh-CN' 
          ? '密码强度不足，请参考以下提示：\n' + getPasswordStrengthHints(validationErrors)
          : 'Password is not strong enough. Please address the following:\n' + getPasswordStrengthHints(validationErrors);
        isValid = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  // Reset the form and validation state
  const resetForm = () => {
    setFormData(prev => ({
      ...prev,
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }));
    setFormErrors({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    // Reset the validation state
    updateValidationErrors([]);
  };

  // Handle password change using backend validation
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    // Clear any previous messages
    setErrorMessage('');
    setSuccessMessage('');

    // Validate form
    if (!validateForm()) {
      return;
    }
    
    try {
      // Use our custom API endpoint for changing password
      // This will handle the verification on the backend
      await changePassword({
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword
      }).unwrap();
      
      // Show success message
      setSuccessMessage(
        userLang === 'zh-CN' ? '密码已更新' : 'Password successfully updated'
      );
      
      // Reset form and validation state
      resetForm();
      
    } catch (error) {
      console.error("Password update error:", error);
      
      // Handle different error types
      if (error.status === 400) {
        if (error.data?.message?.includes('Current password is incorrect')) {
          // Current password is incorrect
          setFormErrors(prev => ({
            ...prev,
            currentPassword: userLang === 'zh-CN' ? '当前密码不正确' : 'Current password is incorrect'
          }));
        } else if (error.data?.message?.includes('New password must be different')) {
          // New password is the same as current password
          setFormErrors(prev => ({
            ...prev,
            newPassword: userLang === 'zh-CN' ? '新密码不能与当前密码相同' : 'New password must be different from current password'
          }));
        } else {
          // Other 400 error
          const errorMsg = error.data?.message || (userLang === 'zh-CN' ? '密码更新失败' : 'Failed to update password');
          setErrorMessage(errorMsg);
        }
      } else if (error.status === 500) {
        // Server error
        const errorDetail = error.data?.error || '';
        setErrorMessage(
          userLang === 'zh-CN' 
            ? `服务器内部错误，请联系技术支持。错误: ${errorDetail}` 
            : `Internal server error. Please contact support. Error: ${errorDetail}`
        );
      } else {
        // Generic error
        const errorMsg = error.data?.message || (userLang === 'zh-CN' ? '密码更新失败' : 'Failed to update password');
        setErrorMessage(`${errorMsg}`);
      }
    }
  };

  return (
    <>
      {/* Success/Error Messages */}
      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
          {errorMessage}
        </div>
      )}

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          {userLang === 'zh-CN' ? '更改密码' : 'Change Password'}
        </h3>
        <form onSubmit={handlePasswordChange} className="space-y-6">
          <PasswordInput
            label={userLang === 'zh-CN' ? '当前密码' : 'Current Password'}
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleInputChange}
            required
            error={formErrors.currentPassword}
            userLang={userLang}
            showValidation={false}
          />
          <PasswordInput
            label={userLang === 'zh-CN' ? '新密码' : 'New Password'}
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            required
            error={formErrors.newPassword}
            userLang={userLang}
            showValidation={true}
            onValidationChange={updateValidationErrors}
          />
          <PasswordInput
            label={userLang === 'zh-CN' ? '确认新密码' : 'Confirm New Password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
            error={formErrors.confirmPassword}
            userLang={userLang}
            showValidation={false}
          />
          <div className="flex justify-end">
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              fullWidth={false}
              variant="teal"
              className="px-6"
            >
              {userLang === 'zh-CN' ? '更新密码' : 'Update Password'}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Security; 