import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from '../../../redux/slices/responseSlice';
import { uploadFiles } from '../../../utils/uploadUtils';

const UploadProductPhoto = ({ onUploadPic, error, initialPhotos = [], initialCoverIndex = 0 }) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const [coverIndex, setCoverIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Initialize with existing photos in edit mode
  useEffect(() => {
    if (initialPhotos?.length > 0) {
      setPhotos(initialPhotos);
      setCoverIndex(initialCoverIndex);
    }
  }, [initialPhotos, initialCoverIndex]);

  const handlePhotoChange = async (files) => {
    if (!files || files.length === 0) {
      dispatch(showError('Please select at least one image file', 'error'));
      return;
    }

    const validFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
    console.log('validFiles', validFiles);

    if (validFiles.length !== files.length) {
      dispatch(showError('Please upload only image files', 'error'));
      return;
    }

    // Set loading state
    setIsUploading(true);

    try {
      // Use the direct upload utility instead of Redux
      const uploadedPhotos = await uploadFiles(validFiles);
      
      console.log('uploadedPhotos response:', uploadedPhotos);
      const newPhotos = [...photos, ...uploadedPhotos];
      setPhotos(newPhotos);
      onUploadPic({ photos: newPhotos, coverIndex: coverIndex || 0 });
    } catch (error) {
      console.error('Failed to upload photos:', error);
      const errorMessage = error.message || 'Failed to upload photos';
      dispatch(showError(errorMessage, 'error'));
    } finally {
      // Always set loading state back to false
      setIsUploading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    handlePhotoChange(files);
  };

  const handleDelete = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
    
    // Adjust coverIndex if necessary
    let newCoverIndex = coverIndex;
    if (index === coverIndex) {
      newCoverIndex = newPhotos.length > 0 ? 0 : -1;
    } else if (index < coverIndex) {
      newCoverIndex--;
    }
    
    setCoverIndex(newCoverIndex);
    onUploadPic({ photos: newPhotos, coverIndex: newCoverIndex !== -1 ? newCoverIndex : 0 });
  };

  const handleSetCover = (index) => {
    setCoverIndex(index);
    onUploadPic({ photos, coverIndex: index });
  };

  return (
    <div className="space-y-4">
      <div 
        className={`border-2 border-dashed p-8 text-center ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'} ${error ? 'border-red-500' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          id="photo-upload"
          multiple
          className="hidden"
          accept="image/*"
          onChange={(e) => handlePhotoChange(e.target.files)}
          disabled={isUploading}
        />
        <label
          htmlFor="photo-upload"
          className={`cursor-pointer block ${isUploading ? 'opacity-50' : ''}`}
        >
          {isUploading ? (
            <div className="text-center">
              <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
              <p className="mt-2 text-sm text-gray-600">Uploading...</p>
            </div>
          ) : (
            <>
              <div className="text-gray-500 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <p className="text-sm text-gray-600">Drag photos here or click to upload</p>
              <p className="text-xs text-gray-500 mt-1">PNG, JPG up to 5MB</p>
            </>
          )}
        </label>
      </div>
      
      {error && <p className="text-red-500 text-sm">{error}</p>}

      {photos.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {photos.map((photo, index) => (
            <div 
              key={photo.id || index} 
              className={`relative group rounded-lg overflow-hidden border ${coverIndex === index ? 'ring-2 ring-blue-500' : ''}`}
            >
              <img 
                src={photo.url || photo.formats?.thumbnail?.url || photo.formats?.small?.url || photo.formats?.medium?.url}
                alt={`Uploaded ${index + 1}`}
                className="w-full h-32 object-contain bg-gray-100"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100">
                <button
                  onClick={() => handleDelete(index)}
                  className="bg-red-600 text-white p-1 rounded-full mr-2"
                  title="Delete photo"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </button>
                {coverIndex !== index && (
                  <button
                    onClick={() => handleSetCover(index)}
                    className="bg-blue-600 text-white p-1 rounded-full"
                    title="Set as cover"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                    </svg>
                  </button>
                )}
              </div>
              {coverIndex === index && (
                <div className="absolute top-1 left-1 bg-blue-500 text-white text-xs px-2 py-1 rounded">
                  Cover
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadProductPhoto;
