import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleProductSelection, setDeleteModalOpen } from '../../../redux/slices/listingSlice';
import { 
  useToggleProductActivationMutation,
  useDeleteProductMutation
} from '../../../redux/apis/endpoints/listingApi';
import { handleResponse } from '../../../redux/slices/responseSlice';
import { TrashIcon, HeartIcon } from '@heroicons/react/24/outline';
import BaseCard from '../../ui/BaseCard';
import CardButton from '../../ui/CardButton';

const ListingCard = ({ product, isSelected }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { products } = useSelector(state => state.filter);
  const { deleteModalOpen } = useSelector(state => state.listing);
  const [toggleActivation, { isLoading: isToggling }] = useToggleProductActivationMutation();
  const [deleteProduct, { isLoading: isDeleting }] = useDeleteProductMutation();
  const isLoading = isToggling || isDeleting;

  const handleCardClick = useCallback((product) => {
    history.push(`/listing/edit/${product.id}`);
  }, [history]);

  const handleSelect = useCallback((e) => {
    e.stopPropagation();
    dispatch(toggleProductSelection(product.id));
  }, [dispatch, product.id, isSelected]);

  const handleToggleActive = useCallback(async (e) => {
    e.stopPropagation();
    if (isLoading) return;

    try {
      // Optimistically update UI first
      const updatedProducts = products.map(p => {
        if (p.id === product.id) {
          return { ...p, activeListing: !p.activeListing };
        }
        return p;
      });
      dispatch({ type: 'filter/optimisticUpdate', payload: updatedProducts });

      const result = await toggleActivation({ 
        productId: product.id, 
        active: !product.activeListing 
      }).unwrap();
      
      dispatch(handleResponse(result, {
        successMessage: `Product ${!product.activeListing ? 'activated' : 'deactivated'} successfully`,
        errorMessage: 'Failed to update product status'
      }));
    } catch (error) {
      // Revert optimistic update on error
      const revertedProducts = products.map(p => {
        if (p.id === product.id) {
          return { ...p, activeListing: product.activeListing };
        }
        return p;
      });
      dispatch({ type: 'filter/optimisticUpdate', payload: revertedProducts });
      dispatch(handleResponse(error, {
        errorMessage: 'Failed to update product status'
      }));
    }
  }, [dispatch, product, products, isLoading, toggleActivation]);

  const handleDelete = useCallback((e) => {
    e.stopPropagation();
    // First select the product if it's not already selected
    if (!isSelected) {
      dispatch(toggleProductSelection(product.id));
    }
    // Then open the delete modal
    dispatch(setDeleteModalOpen(true));
  }, [dispatch, product.id, isSelected]);

  if (!product) return null;

  return (
    <>
      <BaseCard
        product={product}
        onClick={handleCardClick}
        className="h-full"
        renderTopLeftContent={(product) => (
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleSelect}
            onClick={e => e.stopPropagation()}
            className="h-4 w-4 text-brand-teal focus:ring-brand-teal border-gray-300 rounded"
          />
        )}
        renderTopRightContent={(product) => (
          <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
            product.activeListing 
              ? 'bg-green-500 text-white' 
              : 'bg-gray-500 text-white'
          }`}>
            {product.activeListing ? 'Active' : 'Inactive'}
          </span>
        )}
        renderBottomContent={(product) => (
          <div className="mt-2 flex items-center gap-3">
            <span className="text-sm text-gray-600">Stock: {product.stock}</span>
            <span className="text-gray-300">|</span>
            <div className="flex items-center gap-1">
              <HeartIcon className="w-4 h-4 text-brand-teal" />
              <span className="text-sm text-gray-600">{product.likes_count || 0}</span>
            </div>
          </div>
        )}
        renderActionButtons={(product) => (
          <div className="flex items-center gap-2">
            <CardButton
              onClick={handleToggleActive}
              disabled={isLoading}
              variant={product.activeListing ? 'yellow' : 'green'}
              isLoading={isToggling}
            >
              {product.activeListing ? 'Deactivate' : 'Activate'}
            </CardButton>

            <CardButton
              onClick={handleDelete}
              disabled={isLoading}
              variant="red"
              size="icon"
              icon={<TrashIcon />}
              isLoading={isDeleting}
              title="Delete"
            />
          </div>
        )}
      />
    </>
  );
};

export default ListingCard; 