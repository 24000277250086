import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { handleUrlChange } from '../redux/slices/filterSlice';

// Shared reference to track if a handler is already installed
let isHandlerInstalled = false;
let lastDispatchTime = 0;

export const useUrlChangeHandler = (isChild = false) => {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  // Debounced dispatch function to prevent rapid successive calls
  const debouncedDispatch = () => {
    const now = Date.now();
    if (now - lastDispatchTime > 100) { // 100ms debounce
      dispatch(handleUrlChange());
      lastDispatchTime = now;
    }
  };

  useEffect(() => {
    // Skip if this is a child component and a handler is already installed
    if (isChild && isHandlerInstalled) {
      return;
    }

    // Only handle URL on first mount for parent or standalone components
    if (isInitialMount.current && !isChild) {
      debouncedDispatch();
      isInitialMount.current = false;
    }

    // Only install handlers if not already installed
    if (!isHandlerInstalled) {
      isHandlerInstalled = true;

      // Handle browser back/forward
      const handleUrlChangeEvent = () => {
        debouncedDispatch();
      };

      window.addEventListener('popstate', handleUrlChangeEvent);

      // Override pushState to handle programmatic navigation
      const originalPushState = window.history.pushState;
      window.history.pushState = function() {
        originalPushState.apply(this, arguments);
        debouncedDispatch();
      };

      return () => {
        window.removeEventListener('popstate', handleUrlChangeEvent);
        window.history.pushState = originalPushState;
        isHandlerInstalled = false;
      };
    }
  }, [dispatch, isChild]);
}; 