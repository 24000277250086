import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Section = ({
  // Layout
  fullWidth = false,
  minHeight = 'min-h-screen',
  padding = 'py-16 px-4 sm:px-6 lg:px-8',
  
  // Background
  bgColor = 'bg-white',
  bgImage,
  bgOverlay,
  bgPosition = 'bg-center',
  
  // Content
  title,
  subtitle,
  description,
  alignment = 'center',
  
  // Button
  buttonText,
  buttonLink,
  buttonVariant = 'primary',
  buttonSize = 'md',
  
  // Custom
  className,
  children,
}) => {
  const sectionClasses = classNames(
    'relative',
    minHeight,
    padding,
    bgColor,
    bgPosition,
    {
      'container mx-auto': !fullWidth,
      'text-left': alignment === 'left',
      'text-center': alignment === 'center',
      'text-right': alignment === 'right',
    },
    className
  );

  const buttonClasses = classNames(
    'inline-flex items-center rounded-md shadow-sm font-medium',
    {
      'bg-brand-orange text-white hover:bg-brand-orange/90': buttonVariant === 'primary',
      'bg-white text-gray-700 border border-gray-300 hover:bg-gray-50': buttonVariant === 'secondary',
      'border-2 border-brand-orange text-brand-orange hover:bg-brand-orange/10': buttonVariant === 'outline',
      'px-4 py-2 text-sm': buttonSize === 'sm',
      'px-6 py-3 text-base': buttonSize === 'md',
      'px-8 py-4 text-lg': buttonSize === 'lg',
    }
  );

  return (
    <section className={sectionClasses}>
      {/* Background Image and Overlay */}
      {bgImage && (
        <div className="absolute inset-0 z-0">
          <div 
            className={classNames('w-full h-full bg-cover bg-center', bgPosition)}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
          {bgOverlay && (
            <div 
              className="absolute inset-0" 
              style={{ backgroundColor: bgOverlay }}
            />
          )}
        </div>
      )}

      {/* Content Container */}
      <div className="relative z-10 max-w-7xl mx-auto">
        {/* Text Content */}
        <div className="space-y-6">
          {subtitle && (
            <h3 className="text-brand-orange text-lg font-semibold">
              {subtitle}
            </h3>
          )}
          {title && (
            <h2 className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-900">
              {title}
            </h2>
          )}
          {description && (
            <p className="mt-4 text-xl text-gray-500 max-w-3xl mx-auto">
              {description}
            </p>
          )}
          {buttonText && buttonLink && (
            <div className="mt-8">
              <Link to={buttonLink} className={buttonClasses}>
                {buttonText}
              </Link>
            </div>
          )}
        </div>

        {/* Custom Content */}
        {children}
      </div>
    </section>
  );
};

export default Section; 