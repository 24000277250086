import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { clearSelectedProducts, toggleSelectAll } from '../../../redux/slices/listingSlice';
import Checkbox from '../../ui/Checkbox';

const SelectionCheckbox = ({ 
  products,
  selectedCount,
  totalCount
}) => {
  const dispatch = useDispatch();
  const checkboxRef = useRef(null);

  const handleSelectAll = () => {
    dispatch(toggleSelectAll(products));
  };

  const handleClear = () => {
    dispatch(clearSelectedProducts());
  };

  if (totalCount === 0) return null;

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        ref={checkboxRef}
        checked={selectedCount === totalCount}
        indeterminate={selectedCount > 0 && selectedCount < totalCount}
        onChange={handleSelectAll}
        label={
          selectedCount === 0 
            ? `${totalCount} listings`
            : `${selectedCount} of ${totalCount} listings selected`
        }
      />
    </div>
  );
};

export default SelectionCheckbox; 