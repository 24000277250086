import React from 'react';
import BaseFilter from './BaseFilter';
import useFilter from './useFilter';
import ActiveListingCheckbox from './filter_components/checkboxes/ActiveListingCheckbox';

const ListingFilter = () => {
  const { filters, handleFilterChange } = useFilter();

 

  return (
    <div className="w-full overflow-hidden">
      <BaseFilter layout="horizontal">
        <ActiveListingCheckbox />
      </BaseFilter>
    </div>
  );
};

export default ListingFilter; 