import React from 'react';
import AccordionItem from './AccordionItem';
import { PlayIcon } from '@heroicons/react/24/solid';

const SustainabilitySection = ({ userLang }) => {
  // Translation content
  const content = {
    title: {
      why: userLang === 'zh-CN' ? '为什么我们支持' : 'Why We Support',
      sustainable: userLang === 'zh-CN' ? '可持续时尚' : 'Sustainable Fashion',
      instead: userLang === 'zh-CN' ? '而不是快时尚' : 'instead of Fast Fashion',
    },
    videoLabel: userLang === 'zh-CN' ? '先看一个三分钟的视频' : 'Let\'s watch a 3-minute-video first',
    learnMoreLabel: userLang === 'zh-CN' ? '了解更多' : 'Learn More',
    accordions: [
      {
        title: userLang === 'zh-CN' ? '水污染' : 'Water Contamination',
        content: (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <img 
                src="http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUojKmOHjCIBTiIBQ.png" 
                alt="Cotton field" 
                className="rounded-lg w-full h-auto mb-4"
              />
              <h4 className="text-lg font-semibold mb-2">
                {userLang === 'zh-CN' 
                  ? '棉花是制作服装最常用的天然纤维，约占所有纺织品纤维的33%。' 
                  : 'Cotton is the most common natural fiber used to make clothing, accounting for about 33 percent of all fibers found in textiles.'}
              </h4>
            </div>
            <div className="flex flex-col justify-center">
              <img 
                src="http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUogKTuvAYwwAI4kAI.png" 
                alt="Aral Sea" 
                className="rounded-lg w-full h-auto mb-4"
              />
              <h4 className="text-lg font-semibold mb-2">
                {userLang === 'zh-CN' 
                  ? '例如在中亚，由于棉花种植者过度汲取阿姆河和锡尔河的水，咸海几乎已经消失。' 
                  : 'In Central Asia, for instance, the Aral Sea has nearly disappeared because cotton farmers draw excessively from the Amu Darya and Syr Darya rivers.'}
              </h4>
              <p className="text-gray-700">
                {userLang === 'zh-CN' 
                  ? '服装生产过程中也会使用和污染水资源。约20%的工业水污染来自于服装制造。' 
                  : 'Water use and pollution also take place during clothing production. About 20 percent of industrial water pollution is due to garment manufacturing.'}
              </p>
              <p className="text-gray-700 mt-2">
                {userLang === 'zh-CN' 
                  ? '在世界工厂中国，据估计70%的河流和湖泊被纺织业产生的25亿加仑废水污染。' 
                  : 'In China, the factory of the world, it is estimated that 70 percent of the rivers and lakes are contaminated by the 2.5 billion gallons of wastewater produced by the textile industry.'}
              </p>
            </div>
          </div>
        )
      },
      {
        title: userLang === 'zh-CN' ? '其他污染' : 'Other Pollution',
        content: (
          <div className="space-y-4">
            <p className="text-gray-700">
              {userLang === 'zh-CN' 
                ? '棉花种植使用许多有害的农药和化肥，最终导致土壤污染。此外，纺织过程会将毒素排放到附近的土地中。' 
                : 'The plantation of cotton uses many harmful pesticides and chemical fertilizers, which eventually results in soil. In addition, the textile process puts toxins into nearby lands.'}
            </p>
            <p className="text-gray-700">
              {userLang === 'zh-CN' 
                ? '17%至20%的工业废物来自纺织品染色。每年该行业都会产生20吨有毒水，其中含有硝酸盐、铜、砷、铅、镉、汞、镍等。' 
                : '17% to 20% of industrial wastes come from textile dyeing. Every year the industry has 20 tons of toxic water, which contains nitrate, copper, arsenic, lead, cadmium, mercury, nickel...'}
            </p>
            <p className="text-gray-700">
              {userLang === 'zh-CN' 
                ? '尼龙的生产会产生一氧化二氮，这是一种比二氧化碳强300倍的温室气体。聚酯和尼龙也会在洗衣机中分解，导致我们的水系统中微塑料的堆积。' 
                : 'Production of nylon produces nitrous oxide which is a greenhouse gas 300 times more potent than carbon dioxide. Both polyester and nylon also break down in washing machines leading to the build up of microplastics in our water systems.'}
            </p>
            <p className="text-gray-700">
              {userLang === 'zh-CN' 
                ? '纺织厂需要电力来运行缝纫机和空气泵等机器。洗涤、干燥和染色布料需要大量的热量。' 
                : 'Electricity is needed to run machinery such as sewing machines and air pumps in textile factories. Huge amounts of heat is needed for washing, drying and dying the cloth.'}
            </p>
            <p className="text-gray-700">
              {userLang === 'zh-CN' 
                ? '运输服装产生的额外成本产生了额外的二氧化碳，因为绝大多数服装都是通过船舶运输的。船舶燃烧的船用燃油含硫量是美国国内车辆燃油的1800倍，使航运成为一个重要的污染部门。' 
                : 'The further costs of transporting the garments produces additional CO2 as the vast majority of garments have travelled by ship. Ships burn bunker fuel which contains 1800 times more sulfur than US domestic vehicle fuel, making shipping a significant polluting sector.'}
            </p>
          </div>
        )
      },
      {
        title: userLang === 'zh-CN' ? '让我们一起改变！' : 'Let\'s make a difference!',
        content: (
          <div className="space-y-4">
            <div className="flex items-start">
              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-brand-teal text-white font-medium mr-3">1</span>
              <p className="text-gray-700">
                {userLang === 'zh-CN' 
                  ? '减少消费新的、传统的时尚产品。' 
                  : 'Consume less new, conventional fashion.'}
              </p>
            </div>
            <div className="flex items-start">
              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-brand-teal text-white font-medium mr-3">2</span>
              <p className="text-gray-700">
                {userLang === 'zh-CN' 
                  ? '尽可能购买二手商品。' 
                  : 'Try to buy second hand if you can.'}
              </p>
            </div>
            <div className="flex items-start">
              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-brand-teal text-white font-medium mr-3">3</span>
              <p className="text-gray-700">
                {userLang === 'zh-CN' 
                  ? '当你购买新衣服时，从使用有机、天然或回收纤维和制造的更可持续品牌购买。' 
                  : 'When you buy new clothes, buy it from a more sustainable label that uses organic, natural, or recycled fibers and manufactures.'}
              </p>
            </div>
          </div>
        )
      }
    ]
  };

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Section Header */}
      <div className="text-center mb-12">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">
          {content.title.why} <span className="text-brand-orange">{content.title.sustainable}</span>
        </h2>
        <h3 className="text-xl md:text-2xl font-semibold text-gray-700">
          {content.title.instead}
        </h3>
      </div>

      {/* Video Section */}
      <div className="mb-12">
        <div className="relative bg-brand-orange text-white px-4 py-2 text-lg font-medium rounded-t-lg">
          {content.videoLabel}
        </div>
        <div className="relative rounded-b-lg overflow-hidden shadow-lg aspect-w-16 aspect-h-9 bg-gray-900">
          {/* Fallback for browsers that don't support aspect-ratio */}
          <div className="w-full" style={{ paddingBottom: "56.25%" }}></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <img 
              src="https://i.loli.net/2020/12/15/DchTU5FgR9zpAeu.jpg" 
              alt="Video thumbnail" 
              className="absolute inset-0 w-full h-full object-cover"
            />
            <button 
              className="relative z-10 flex items-center justify-center h-16 w-16 rounded-full bg-brand-orange/90 hover:bg-brand-orange transition-colors duration-300 group"
              aria-label="Play video"
            >
              <PlayIcon className="h-10 w-10 text-white group-hover:scale-110 transition-transform duration-300" />
            </button>
          </div>
          {/* This would be a real video embed in production */}
        </div>
      </div>

      {/* Accordion Section */}
      <div>
        <div className="relative bg-brand-orange text-white px-4 py-2 text-lg font-medium rounded-t-lg">
          {content.learnMoreLabel}
        </div>
        <div className="border border-gray-200 rounded-b-lg shadow-lg p-6 space-y-4 bg-white">
          {content.accordions.map((accordion, index) => (
            <AccordionItem key={index} title={accordion.title}>
              {accordion.content}
            </AccordionItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SustainabilitySection; 