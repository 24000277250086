/**
 * Comprehensive address utilities
 * All address-related formatting functions
 */
import { getCountryNameByCode } from './countryUtils';

/*
 * ==========================================
 * ADDRESS FORMATTING FUNCTIONS
 * ==========================================
 */

/**
 * Format an address object to match the backend schema
 * @param {Object} address - The address object to format
 * @returns {Object} Formatted address object
 */
export const formatAddress = (address) => {
  if (!address) return {};
  
  return {
    name: address.name || '',
    street: address.street || '',
    apartment: address.apartment || '',
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || '',
    country: address.country || '',
    phoneNumber: address.phoneNumber || '',
    isDefault: address.isDefault || address.default || false,
    latitude: address.latitude || null,
    longitude: address.longitude || null,
    id: address.id || String(Date.now()) // Generate an ID if none exists
  };
};

/**
 * Validates if an address object has all required fields
 * @param {Object} address - The address object to validate
 * @returns {boolean} Whether the address is valid
 */
export const isValidAddress = (address) => {
  if (!address) return false;
  
  // Basic required fields
  const requiredFields = ['street', 'city', 'country'];
  
  // Check if all required fields exist and are not empty
  return requiredFields.every(field => !!address[field]);
};

/**
 * Format address for display purposes
 * @param {Object} address - The address object to format
 * @returns {string} Formatted address string
 */
export const formatAddressDisplay = (address) => {
  if (!address) return '';
  
  // Convert country code to name if needed
  const countryName = address.country && address.country.length <= 3 
    ? getCountryNameByCode(address.country) 
    : address.country;
  
  const parts = [
    address.name,
    address.street,
    address.apartment,
    `${address.city}${address.state ? `, ${address.state}` : ''}`,
    address.postalCode,
    countryName
  ];
  
  return parts.filter(Boolean).join(', ');
};

/**
 * Format address as a single line
 * @param {Object} address - Address object
 * @returns {string} Formatted address string
 */
export const formatAddressOneLine = (address) => {
  if (!address) return '';
  
  const parts = [
    address.street,
    address.apartment,
    address.city,
    address.state,
    address.postalCode,
    address.country
  ];
  
  // Filter out empty values and join with commas
  return parts.filter(Boolean).join(', ');
};

/**
 * Format address in multiple lines
 * @param {Object} address - Address object
 * @returns {string[]} Array of address lines
 */
export const formatAddressMultiLine = (address) => {
  if (!address) return [];
  
  const lines = [
    address.name,
    address.street,
    address.apartment,
    [address.city, address.state, address.postalCode].filter(Boolean).join(', '),
    address.country
  ];
  
  // Filter out empty lines
  return lines.filter(Boolean);
};

/**
 * Format pickup address (simpler format for product listings)
 * @param {Object} address - Address object
 * @returns {string} Formatted pickup address string
 */
export const formatPickupAddress = (address) => {
  if (!address) return '';
  
  return `${address.city}, ${address.state || ''} ${address.postalCode || ''}`.trim();
};

/**
 * Parse raw address text into address object
 * @param {string} rawAddress - Raw address string
 * @returns {Object} Parsed address object
 */
export const parseAddress = (rawAddress) => {
  if (!rawAddress) return {};
  
  // Very simple parsing - in a real app you'd use a more sophisticated parser
  const parts = rawAddress.split(',').map(part => part.trim());
  
  return {
    street: parts[0] || '',
    city: parts.length > 1 ? parts[1] : '',
    state: parts.length > 2 ? parts[2] : '',
    country: parts.length > 3 ? parts[3] : '',
  };
};

// Re-export country-related functions for backward compatibility
export {
  getCountryNameByCode,
  getCountryCodeByName,
  getCountryOptions,
  getAdminDivisionLabel,
  isStateRequired,
  usesPostalCode,
  getPostalCodeFormat
} from './countryUtils'; 