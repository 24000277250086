import React, { useState, useRef } from 'react';
import Button from '../../../../components/ui/Button';

const RentActions = ({ product, onAddToCart }) => {
  const [selectedRentOption, setSelectedRentOption] = useState(null);
  const [showSelectionPrompt, setShowSelectionPrompt] = useState(false);
  const rentalPeriodsRef = useRef(null);

  if (!product || !product.forRent) return null;

  const {
    rentPriceAndTime = [],
    rentDeposit = 0,
  } = product;

  const formatRentalPeriod = (number, option) => {
    return `${number} ${option}${number > 1 ? 's' : ''}`;
  };

  const calculateTotal = () => {
    if (!selectedRentOption) return 0;
    return selectedRentOption.price + rentDeposit;
  };

  const total = calculateTotal();

  // Show selection prompt and scroll to rental periods
  const promptRentalSelection = () => {
    setShowSelectionPrompt(true);
    
    // Scroll to rental periods if needed
    if (rentalPeriodsRef.current) {
      rentalPeriodsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    
    // Hide prompt after a few seconds
    setTimeout(() => {
      setShowSelectionPrompt(false);
    }, 3000);
  };

  return (
    <div className="space-y-6 max-w-md">
      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <label className="block text-sm font-medium text-gray-700">
            Select Rental Period
          </label>
          {showSelectionPrompt && (
            <div className="text-orange-500 text-sm font-medium animate-pulse">
              ← Please select a rental period first
            </div>
          )}
        </div>
        <div 
          ref={rentalPeriodsRef}
          className={`grid grid-cols-1 gap-3 transition-all duration-300 ${
            showSelectionPrompt ? 'ring-2 ring-orange-500 ring-offset-2 p-2 rounded-lg bg-orange-50' : ''
          }`}
        >
          {rentPriceAndTime.map((option, index) => (
            <Button
              key={index}
              onClick={() => {
                // Toggle selection: if already selected, deselect it
                if (selectedRentOption === option) {
                  setSelectedRentOption(null);
                } else {
                  setSelectedRentOption(option);
                }
                // Clear any selection prompt when user interacts with options
                setShowSelectionPrompt(false);
              }}
              variant={selectedRentOption === option ? 'primary' : 'secondary'}
              fullWidth
              className="justify-between"
            >
              <span className="text-sm">
                {formatRentalPeriod(option.number, option.option)}
              </span>
              <span className="font-medium">${option.price.toFixed(2)}</span>
            </Button>
          ))}
        </div>
      </div>

      {/* Only show rules box if at least one rule exists */}
      {(rentDeposit > 0) && (
        <div className="bg-gray-50 p-4 rounded-lg space-y-3 text-sm">
          {rentDeposit > 0 && (
            <p className="flex items-center text-gray-600">
              • Security deposit: ${rentDeposit.toFixed(2)}
            </p>
          )}
        </div>
      )}

      {selectedRentOption && rentDeposit > 0 && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Rental fee:</span>
            <span className="font-medium">${selectedRentOption.price.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <span className="text-gray-600">Security deposit:</span>
            <span className="font-medium">${rentDeposit.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-base font-bold mt-3 pt-3 border-t">
            <span>Total:</span>
            <span>${total.toFixed(2)}</span>
          </div>
        </div>
      )}

      {/* Rent Form Actions */}
      <div className="flex flex-row gap-4 mx-auto w-full">
        <Button
          variant="orange"
          className="w-full"
          onClick={() => {
            if (selectedRentOption) {
              // Only send rental period details without specific dates
              // Dates will be determined later based on delivery/pickup
              const rentalDetails = {
                duration: selectedRentOption.option === 'day' ? selectedRentOption.number :
                          selectedRentOption.option === 'week' ? selectedRentOption.number * 7 :
                          selectedRentOption.option === 'month' ? selectedRentOption.number * 30 : 0,
                option: selectedRentOption.option,
                number: selectedRentOption.number,
                price: selectedRentOption.price
              };
              
              onAddToCart?.({
                type: 'rent',
                quantity: 1,
                rentalPeriod: rentalDetails,
                navigateToCart: true
              });
            } else {
              // Show prompt if no rental period selected
              promptRentalSelection();
            }
          }}
        >
          Rent Now
        </Button>
        <Button
          variant="teal"
          className="w-full"
          onClick={() => {
            if (selectedRentOption) {
              // Only send rental period details without specific dates
              // Dates will be determined later based on delivery/pickup
              const rentalDetails = {
                duration: selectedRentOption.option === 'day' ? selectedRentOption.number :
                          selectedRentOption.option === 'week' ? selectedRentOption.number * 7 :
                          selectedRentOption.option === 'month' ? selectedRentOption.number * 30 : 0,
                option: selectedRentOption.option,
                number: selectedRentOption.number,
                price: selectedRentOption.price
              };
              
              onAddToCart?.({
                type: 'rent',
                quantity: 1,
                rentalPeriod: rentalDetails
              });
            } else {
              // Show prompt if no rental period selected
              promptRentalSelection();
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="8" cy="21" r="1" />
            <circle cx="19" cy="21" r="1" />
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
            <line x1="12" y1="9" x2="12" y2="15" />
            <line x1="9" y1="12" x2="15" y2="12" />
          </svg>
        </Button>
        <Button
          variant="offer"
          className="w-full"
        >
          Offer
        </Button>
      </div>
    </div>
  );
};

export default RentActions; 