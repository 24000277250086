import { useSelector } from 'react-redux';
import { useUrlChangeHandler } from '../../hooks/useUrlChangeHandler';
import BrowseFilter from '../../components/cards/filter/BrowseFilter';
import BrowseCard from '../../components/cards/card/BrowseCard';
import CardsContainer from '../../components/cards/CardsContainer';

const BrowsePage = () => {
  const { products } = useSelector(state => state.filter);
  
  // Use the shared URL change handler
  useUrlChangeHandler();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Filter Section - responsive width */}
          <div className="w-full lg:w-1/4 xl:w-1/5">
            <div className="bg-white shadow-sm rounded-lg">
              <BrowseFilter />
            </div>
          </div>
          
          {/* Cards Section */}
          <div className="w-full lg:w-3/4 xl:w-4/5">
            <div className="bg-white shadow-sm rounded-lg p-6">
              <CardsContainer>
                {products.map(product => (
                  <BrowseCard 
                    key={product.id} 
                    product={product}
                  />
                ))}
              </CardsContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowsePage; 