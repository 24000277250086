import React, { useState } from 'react';
import Button from '../../../../components/ui/Button';

const SwapActions = ({ product, onAddToCart }) => {
  const [swapProducts, setSwapProducts] = useState([]);

  if (!product || !product.openToSwap) return null;

  return (
    <div className="space-y-6">
      <div className="bg-gray-50 p-4 rounded-lg">
        <p className="text-sm text-gray-600">
          Propose a swap with one of your items
        </p>
      </div>

      <div className="mx-auto w-full max-w-sm">
        <Button
          variant="primary"
          className="w-full"
        >
          Select Item
        </Button>

        {swapProducts.length === 0 ? (
          <div className="text-center mt-4 text-sm text-gray-500">
            No items selected for swap yet
          </div>
        ) : (
          <div className="mt-4 space-y-4">
            {/* Selected swap items would go here */}
            <div className="text-center text-sm text-gray-500">
              {swapProducts.length} items selected
            </div>
          </div>
        )}

        {swapProducts.length > 0 && (
          <div className="mt-6">
            <Button
              variant="orange"
              className="w-full"
            >
              Swap Now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SwapActions; 