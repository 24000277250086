import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../../redux/slices/listingSlice';
import FormInput from '../../ui/FormInput';
import FormCheckbox from '../../ui/FormCheckbox';
import Dropdown from '../../ui/Dropdown';
import { showError, showSuccess } from '../../../redux/slices/responseSlice';

// Define period options
const PERIOD_OPTIONS = [
  { value: 'day', label: 'Day(s)' },
  { value: 'week', label: 'Week(s)' },
  { value: 'month', label: 'Month(s)' }
];

const PricingSection = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);
  
  // Local state for price and deposit to directly control the inputs
  const [priceValue, setPriceValue] = useState('');
  const [depositValue, setDepositValue] = useState('');
  
  // Sync local state with redux when component mounts
  useEffect(() => {
    setPriceValue(formData.price !== undefined ? String(formData.price) : '');
    setDepositValue(formData.rentDeposit !== undefined ? String(formData.rentDeposit) : '');
  }, []);
  
  // Sync local state with redux when Redux state changes
  useEffect(() => {
    // Only update the local state if the formData.price or formData.rentDeposit changed
    // and if the input is not currently focused
    if (!document.activeElement || 
        (document.activeElement.name !== 'price' && 
         document.activeElement.name !== 'rentDeposit')) {
      if (formData.price !== undefined) {
        setPriceValue(formData.price === 0 ? '' : String(formData.price));
      }
      if (formData.rentDeposit !== undefined) {
        setDepositValue(formData.rentDeposit === 0 ? '' : String(formData.rentDeposit));
      }
    }
  }, [formData.price, formData.rentDeposit]);
  
  // Debug what's coming from Redux
  useEffect(() => {
    console.log('PricingSection - formData:', formData);
    console.log('PricingSection - forSell field:', formData.forSell);
    console.log('PricingSection - rentPriceAndTime field:', formData.rentPriceAndTime);
    console.log('PricingSection - price field:', formData.price);
    console.log('PricingSection - rentDeposit field:', formData.rentDeposit);
  }, [formData]);

  const handlePriceChange = (e) => {
    const value = e.target.value;
    
    // Only allow numbers and a single decimal point
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setPriceValue(value);
      
      // Update redux with the value
      const numValue = value === '' ? '' : Number(value);
      dispatch(updateFormData({
        price: numValue
      }));
      console.log('Dispatched price update:', numValue);
    }
  };
  
  const handleDepositChange = (e) => {
    const value = e.target.value;
    
    // Only allow numbers and a single decimal point
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setDepositValue(value);
      
      // Update redux with the value
      const numValue = value === '' ? '' : Number(value);
      dispatch(updateFormData({
        rentDeposit: numValue
      }));
      console.log('Dispatched deposit update:', numValue);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const processedValue = type === 'number' || name === 'price' || name === 'rentDeposit' ? 
      (value === '' ? '' : Number(value)) : 
      value;
    
    dispatch(updateFormData({
      [name]: processedValue
    }));
    
    // Debug the value being dispatched
    console.log(`Updating ${name} with value:`, processedValue);
  };

  const handleToggle = (field) => {
    // Create an object to update the Redux state
    const updateData = {
      [field]: !formData[field]
    };
    
    // Special handling for forSell toggle
    if (field === 'forSell') {
      // If turning off forSell, set price to 0
      if (formData[field]) {
        updateData.price = 0;
        setPriceValue('0');
      }
    }
    
    // Special handling for forRent toggle
    if (field === 'forRent') {
      // If turning off forRent, clear rental info
      if (formData[field]) {
        updateData.rentPriceAndTime = [];
        updateData.requireDeposit = false;
        updateData.rentDeposit = 0;
        setDepositValue('0');
      } else if (!formData[field] && (!formData.rentPriceAndTime || formData.rentPriceAndTime.length === 0)) {
        // If turning on forRent and there are no rental periods, add one default
        updateData.rentPriceAndTime = [{ number: 1, option: 'day', price: '' }];
      }
    }
    
    dispatch(updateFormData(updateData));
    
    console.log(`Toggled ${field} to:`, !formData[field]);
    if (field === 'forSell') {
      console.log('Updated price value:', updateData.price);
    }
  };

  const handleRentPriceChange = (index, field, value) => {
    // For price and number fields, only allow valid numeric input
    if (field === 'price' || field === 'number') {
      // For number fields, only allow integers
      const regex = field === 'number' ? /^[0-9]*$/ : /^[0-9]*\.?[0-9]*$/;
      
      // Skip update if input is invalid (except empty string which is allowed)
      if (value !== '' && !regex.test(value)) {
        return;
      }
    }
    
    const updatedRentPrices = [...(formData.rentPriceAndTime || [])];
    
    // Handle numeric inputs consistently
    const processedValue = field === 'price' || field === 'number' ? 
      (value === '' ? '' : Number(value)) : 
      value;
    
    updatedRentPrices[index] = {
      ...updatedRentPrices[index],
      [field]: processedValue
    };
    
    dispatch(updateFormData({ rentPriceAndTime: updatedRentPrices }));
    
    // Debug the value being dispatched
    console.log(`Updating rentPriceAndTime[${index}].${field} with value:`, processedValue);
  };
  
  const addRentPrice = () => {
    const rentPrices = [...(formData.rentPriceAndTime || []), { number: 1, option: 'day', price: '' }];
    dispatch(updateFormData({ rentPriceAndTime: rentPrices }));
  };
  
  const removeRentPrice = (index) => {
    const rentPrices = [...(formData.rentPriceAndTime || [])].filter((_, i) => i !== index);
    dispatch(updateFormData({ rentPriceAndTime: rentPrices }));
  };

  return (
    <div className="space-y-6 max-w-[1400px] mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Pricing</h2>
      
      {/* Transaction Type Section */}
      <div className="mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Transaction Type</h3>
        <div className={`space-y-4 ${fieldErrors?.transactionType ? 'border-red-300 p-3 border rounded-md bg-red-50' : ''}`}>
          <FormCheckbox
            label="For Sale"
            name="forSell"
            checked={formData?.forSell || false}
            onChange={() => handleToggle('forSell')}
          />
          <FormCheckbox
            label="For Rent"
            name="forRent"
            checked={formData?.forRent || false}
            onChange={() => handleToggle('forRent')}
          />
          <FormCheckbox
            label="Open to Swap"
            name="openToSwap"
            checked={formData?.openToSwap || false}
            onChange={() => handleToggle('openToSwap')}
          />
          {fieldErrors?.transactionType && (
            <p className="text-sm text-red-600">{fieldErrors.transactionType}</p>
          )}
        </div>
      </div>

      {/* Sale Price Section */}
      {formData?.forSell && (
        <div className="mt-6 space-y-4 bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-800">Sale Price</h3>
          <div className="space-y-4">
            <div className="max-w-xs">
              {/* Direct input for price with controlled value */}
              <div className="max-w-form-input">
                <label className="block text-label font-medium text-gray-700 mb-2">
                  Sale Price ($) {fieldErrors?.price && <span className="text-red-500 ml-1">*</span>}
                </label>
                <input
                  type="text"
                  name="price"
                  value={priceValue}
                  onChange={handlePriceChange}
                  className={`form-input w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${fieldErrors?.price ? 'border-red-300' : 'border-gray-300'}`}
                  min="0"
                  placeholder="0"
                  pattern="[0-9]*\.?[0-9]*"
                />
                {fieldErrors?.price && (
                  <p className="mt-1 text-sm text-red-600">{fieldErrors.price}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Rent Prices Section */}
      {formData?.forRent && (
        <div className="mt-6 space-y-4 bg-gray-50 rounded-lg p-6">
          <h3 className="text-lg font-medium text-gray-800">Rental Pricing</h3>
          
          {/* Rental Periods */}
          <div className="space-y-4">
            <p className="text-sm text-gray-600">Set different rental periods and prices:</p>
            
            {(formData?.rentPriceAndTime || []).map((rentPrice, index) => (
              <div key={index} className="flex flex-col space-y-2 p-4 border border-gray-200 rounded-md bg-white">
                <div className="flex items-center justify-between">
                  <h4 className="text-sm font-medium text-gray-700">
                    Rental Option {index + 1}
                    {rentPrice.price && rentPrice.number && rentPrice.option && (
                      <span className="ml-2 text-brand-teal">
                        ${rentPrice.price} for {rentPrice.number} {rentPrice.option}{rentPrice.number > 1 ? 's' : ''}
                      </span>
                    )}
                  </h4>
                  <button
                    type="button"
                    onClick={() => removeRentPrice(index)}
                    className="text-red-600 hover:text-red-800 focus:outline-none"
                  >
                    <span className="sr-only">Remove</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Price ($)</label>
                    <input
                      type="text"
                      value={rentPrice.price || ''}
                      onChange={(e) => handleRentPriceChange(index, 'price', e.target.value)}
                      className="form-input block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-teal focus:ring focus:ring-brand-teal focus:ring-opacity-50"
                      placeholder="0"
                      pattern="[0-9]*\.?[0-9]*"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Rental Period</label>
                    <div className="flex rounded-md shadow-sm">
                      <input
                        type="text"
                        value={rentPrice.number || ''}
                        onChange={(e) => handleRentPriceChange(index, 'number', e.target.value)}
                        min="1"
                        className="form-input flex-1 min-w-0 block w-1/3 rounded-none rounded-l-md border-r-0 border-gray-300 focus:ring-brand-teal focus:border-brand-teal"
                        placeholder="0"
                        pattern="[0-9]*"
                      />
                      <select
                        value={rentPrice.option || 'day'}
                        onChange={(e) => handleRentPriceChange(index, 'option', e.target.value)}
                        className="form-select flex-1 min-w-0 block w-2/3 rounded-none rounded-r-md border-gray-300 focus:ring-brand-teal focus:border-brand-teal"
                      >
                        {PERIOD_OPTIONS.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
            <button
              type="button"
              onClick={addRentPrice}
              className="flex items-center text-brand-teal hover:text-brand-teal-dark focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v5H4a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5V4a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              Add Rental Period
            </button>
            
            {fieldErrors?.rentPriceAndTime && (
              <p className="mt-1 text-sm text-red-600">{fieldErrors.rentPriceAndTime}</p>
            )}
          </div>
          
          {/* Security Deposit */}
          <div className="space-y-4">
            <div className="w-full">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData?.requireDeposit || false}
                  onChange={() => handleToggle('requireDeposit')}
                  className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                />
                <span className="ml-2 text-sm text-gray-700">Require Security Deposit</span>
              </label>
              {formData?.requireDeposit && (
                <div className="mt-2 ml-7">
                  <div className="max-w-xs">
                    {/* Direct input for deposit with controlled value */}
                    <div className="max-w-form-input">
                      <label className="block text-label font-medium text-gray-700 mb-2">
                        Deposit Amount ($) {fieldErrors?.rentDeposit && <span className="text-red-500 ml-1">*</span>}
                      </label>
                      <input
                        type="text"
                        name="rentDeposit"
                        value={depositValue}
                        onChange={handleDepositChange}
                        className={`form-input w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${fieldErrors?.rentDeposit ? 'border-red-300' : 'border-gray-300'}`}
                        min="0"
                        placeholder="0"
                        pattern="[0-9]*\.?[0-9]*"
                      />
                      {fieldErrors?.rentDeposit && (
                        <p className="mt-1 text-sm text-red-600">{fieldErrors.rentDeposit}</p>
                      )}
                    </div>
                    <p className="mt-1 text-sm text-gray-500">This amount will be refunded after the item is returned in good condition.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingSection; 