import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BaseCard from '../../ui/BaseCard';
import Like from '../../ui/Like';
import Badge from '../../ui/Badge';
import { createSelector } from '@reduxjs/toolkit';

// Memoized selector
const selectAuthState = createSelector(
  state => state.auth,
  auth => ({
    isLoggedIn: auth.isAuthenticated
  })
);

const BrowseCard = ({ product }) => {
  const history = useHistory();
  const { isLoggedIn } = useSelector(selectAuthState);

  const handleCardClick = useCallback((product) => {
    history.push('/browse/' + product.id);
  }, [history]);

  if (!product || !product.activeListing || product.stock === '0') return null;

  return (
    <BaseCard
      product={product}
      onClick={handleCardClick}
      renderTopLeftContent={(product) => (
        <Badge 
          type={product.productType}
          size="sm"
          className="bg-white/90"
        />
      )}
      renderTopRightContent={(product) => (
        <Like 
          productId={product.id}
          size="sm"
          showCount={false}
          className="bg-white rounded-full shadow-sm p-1"
        />
      )}
      renderBottomContent={(product) => (
        <>
          <div className="flex flex-wrap gap-1 mt-2">
            {product.forSell && (
              <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                ${product.price}
              </span>
            )}
            {product.forRent && product.rentPriceAndTime?.length > 0 && (
              <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                ${product.rentPriceAndTime[0].price}/{product.rentPriceAndTime[0].number}{product.rentPriceAndTime[0].option.charAt(0)}
              </span>
            )}
            {product.openToSwap && (
              <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                Swap
              </span>
            )}
          </div>
        </>
      )}
    />
  );
};

export default BrowseCard; 