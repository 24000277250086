import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../../redux/slices/listingSlice';
import FormInput from '../../ui/FormInput';
import Dropdown from '../../ui/Dropdown';
import AddressList from '../../../components/address/AddressList';
import AddressForm from '../../../components/address/AddressForm';
import { 
  useGetAddressesQuery 
} from '../../../redux/apis';
import { showError, showSuccess } from '../../../redux/slices/responseSlice';

const AvailabilitySection = () => {
  const dispatch = useDispatch();
  const formData = useSelector(state => state.listing.form.formData);
  const fieldErrors = useSelector(state => state.listing.form.fieldErrors);
  
  const { data: addresses = [] } = useGetAddressesQuery();
  
  // Local state for stock input
  const [stockValue, setStockValue] = useState('');
  
  // Check if product is virtual
  const isVirtual = formData.productType === 'virtual';
  
  // Update shipping/pickup options when product type changes
  useEffect(() => {
    if (isVirtual) {
      // For virtual products, disable physical delivery options
      dispatch(updateFormData({
        openToShipping: false,
        openToPickUp: false
      }));
    } 
    // else if (!formData.openToShipping && !formData.openToPickUp) {
      // For physical products, ensure at least one delivery option is enabled
      // dispatch(updateFormData({
      //   openToShipping: true
      // }));
    // }
  }, [dispatch, isVirtual, formData.prlistingoductType]);
  
  // Initialize stock value from Redux state
  useEffect(() => {
    setStockValue(formData.stock !== undefined ? String(formData.stock) : '');
  }, []);

  // Sync local stock value with Redux state changes
  useEffect(() => {
    if (!document.activeElement || document.activeElement.name !== 'stock') {
      if (formData.stock !== undefined) {
        setStockValue(formData.stock === 0 ? '' : String(formData.stock));
      }
    }
  }, [formData.stock]);
  
  // Debug what's coming from Redux
  useEffect(() => {
    console.log('AvailabilitySection - formData:', formData);
    console.log('AvailabilitySection - stock field:', formData.stock);
    console.log('AvailabilitySection - productType:', formData.productType);
    console.log('AvailabilitySection - isVirtual:', isVirtual);
    console.log('AvailabilitySection - openToShipping field:', formData.openToShipping);
    console.log('AvailabilitySection - openToPickUp field:', formData.openToPickUp);
    console.log('AvailabilitySection - pickUpAddress:', formData.pickUpAddress);
    console.log('AvailabilitySection - selectedAddressId:', formData.selectedAddressId);
    
    // If we have addresses but no selected address ID, try to find a match
    if (addresses.length > 0 && formData.pickUpAddress && !formData.selectedAddressId) {
      console.log('Trying to match pickup address to available addresses');
      const matchingAddress = addresses.find(addr => addr.id === formData.pickUpAddress.id);
      if (matchingAddress) {
        console.log('Found matching address:', matchingAddress);
        dispatch(updateFormData({
          selectedAddressId: matchingAddress.id
        }));
      }
    }
  }, [formData, addresses, dispatch, isVirtual]);

  // Handler for the stock input field
  const handleStockChange = (e) => {
    const value = e.target.value;
    
    // Only allow integers (whole numbers)
    if (value === '' || /^[0-9]*$/.test(value)) {
      setStockValue(value);
      
      // Update redux with the value
      const numValue = value === '' ? '' : Number(value);
      dispatch(updateFormData({
        stock: numValue
      }));
      console.log('Dispatched stock update:', numValue);
    }
  };


  const handleToggle = (field) => {
    const updateData = {
      [field]: !formData[field]
    };
    
    // Ensure at least one delivery option is selected (but only for non-virtual products)
    if (!isVirtual) {
      if (field === 'openToPickUp' && !formData[field] === false && !formData.openToShipping) {
        updateData.openToShipping = true;
      } else if (field === 'openToShipping' && !formData[field] === false && !formData.openToPickUp) {
        updateData.openToPickUp = true;
      }
    }
    
    dispatch(updateFormData(updateData));
  };

  return (
    <div className="space-y-6 max-w-[1400px] mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Availability</h2>
      
      {/* First Row: Stock and Options */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left: Stock */}
        <div>
          <div className="max-w-form-input">
            <label className="block text-label font-medium text-gray-700 mb-2">
              Stock {fieldErrors?.stock && <span className="text-red-500 ml-1">*</span>}
            </label>
            <input
              type="text"
              name="stock"
              value={stockValue}
              onChange={handleStockChange}
              className={`form-input w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input ${fieldErrors?.stock ? 'border-red-300' : 'border-gray-300'}`}
              min="0"
              placeholder="0"
              pattern="[0-9]*"
            />
            {fieldErrors?.stock && (
              <p className="mt-1 text-sm text-red-600">{fieldErrors.stock}</p>
            )}
          </div>
        </div>

        {/* Right: Delivery Options - Only show for non-virtual products */}
        {!isVirtual && (
          <div>
            <label className="block text-label font-medium text-gray-700 mb-2">
              Delivery Options {fieldErrors?.deliveryOptions && <span className="text-red-500 ml-1">*</span>}
            </label>
            <div className={`space-y-3 ${fieldErrors?.deliveryOptions ? 'p-3 border border-red-300 rounded-md bg-red-50' : ''}`}>
              <div className="flex flex-col space-y-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData?.openToShipping || false}
                    onChange={() => handleToggle('openToShipping')}
                    className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                  />
                  <span className="ml-2 text-sm text-gray-700">Allow shipping</span>
                </label>
                
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData?.openToPickUp || false}
                    onChange={() => handleToggle('openToPickUp')}
                    className="h-5 w-5 rounded border-gray-300 text-brand-teal focus:ring-brand-teal"
                  />
                  <span className="ml-2 text-sm text-gray-700">Allow in-person pickup</span>
                </label>
              </div>
              
              {fieldErrors?.deliveryOptions && (
                <p className="text-sm text-red-600">{fieldErrors.deliveryOptions}</p>
              )}
            </div>
          </div>
        )}
        
        {/* Right side when product is virtual */}
        {/* {isVirtual && (
          <div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-600">
                Delivery options are not applicable for virtual products.
              </p>
            </div>
          </div>
        )} */}
      </div>

      {/* Pickup Address Section - Full Width */}
      {!isVirtual && formData?.openToPickUp && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Pickup Address</h3>
          <div className="max-w-2xl">
            <p className="text-sm text-gray-600 mb-4">
              This location will only be shared with approved buyers/renters when they book a pickup.
            </p>

            {/* Show selected address if one is chosen */}
            {formData.pickUpAddress && formData.selectedAddressId !== 'new' ? (
              <>
                <div className="mb-4">
                  <AddressList
                    addresses={[formData.pickUpAddress]}
                    compact={true}
                    showMap={false}
                    onSelect={null}
                  />
                  <button
                    type="button"
                    onClick={() => dispatch(updateFormData({
                      selectedAddressId: '',
                      pickUpAddress: null
                    }))}
                    className="mt-2 text-sm text-brand-teal hover:text-brand-teal-dark"
                  >
                    Change pickup address
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* If no address is selected yet or "new" is selected */}
                <div className="mb-4">
                 
                  <Dropdown
                    title="Select Address"
                    value={formData?.selectedAddressId || ''}
                    onChange={(value) => {
                      const selectedId = value;
                      let pickUpAddress = null;
                      
                      if (selectedId !== 'new') {
                        pickUpAddress = addresses.find(addr => addr.id === selectedId);
                        console.log('Selected address:', pickUpAddress);
                      }
                      
                      dispatch(updateFormData({
                        selectedAddressId: selectedId,
                        pickUpAddress: pickUpAddress
                      }));
                    }}
                    options={[
                      { value: '', label: 'Select an address' },
                      ...(addresses.length > 0 ? 
                        addresses.map(address => ({
                          value: address.id,
                          label: `${address.name || 'Address'}: ${address.street}, ${address.city}, ${address.state} ${address.postalCode}`
                        })) : []),
                      { value: 'new', label: '+ Add New Address' }
                    ]}
                    error={fieldErrors?.pickUpAddress}
                    placeholder="Select an address"
                    className="w-full"
                  />
                </div>
                
                {/* Show address form for new address */}
                {formData?.selectedAddressId === 'new' && (
                  <div className="border-t pt-4 mt-4">
                    <h4 className="text-md font-medium mb-4">Add New Address</h4>
                    <AddressForm
                      isPickupAddress={true}
                      showMap={false}
                      isInline={true}
                      onSuccess={(savedAddress) => {
                        dispatch(updateFormData({
                          selectedAddressId: savedAddress.id,
                          pickUpAddress: savedAddress
                        }));
                        dispatch(showSuccess('Address saved successfully'));
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailabilitySection; 