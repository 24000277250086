import React, { useState, forwardRef, useEffect } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import FormInput from '../../../components/ui/FormInput';
import passwordValidator from 'password-validator';

// Create a schema for password validation
const createPasswordSchema = () => {
  const schema = new passwordValidator();
  
  // Add properties to the schema
  schema
    .is().min(6)                                   // Minimum length 6
    .is().max(100)                                 // Maximum length 100
    .has().uppercase()                             // Must have uppercase letters
    .has().lowercase()                             // Must have lowercase letters
    .has().digits(1)                               // Must have at least 1 digit
    .has().not().spaces()                          // Should not have spaces
    .is().not().oneOf(['password', 'Password']);   // Blacklist these values
  
  return schema;
};

const schema = createPasswordSchema();

// Custom input component with the eye icon inside
const PasswordInputWithIcon = forwardRef(({ showPassword, toggleShowPassword, ...props }, ref) => (
  <div className="relative">
    <input {...props} type={showPassword ? "text" : "password"} ref={ref} 
      className={`form-input w-full rounded-md shadow-sm focus:ring-2 focus:ring-brand-teal focus:border-brand-teal text-input pr-10 ${props.error ? 'border-red-300' : 'border-gray-300'}`} />
    <button
      type="button"
      className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
      onClick={toggleShowPassword}
      tabIndex="-1"
    >
      {showPassword ? (
        <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
      ) : (
        <EyeIcon className="h-5 w-5" aria-hidden="true" />
      )}
    </button>
  </div>
));

PasswordInputWithIcon.displayName = 'PasswordInputWithIcon';

const PasswordInput = ({ 
  label, 
  value, 
  onChange, 
  showValidation = false, 
  validationMessage,
  userLang,
  onValidationChange,
  ...props 
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [touched, setTouched] = useState(false);
  
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleChange = (e) => {
    if (!touched) {
      setTouched(true);
    }
    
    onChange(e);
    
    // Only validate if showValidation is true (for new passwords, not current ones)
    if (showValidation) {
      const validationResult = schema.validate(e.target.value, { list: true });
      setValidationErrors(validationResult);
      
      // Notify parent component about validation errors
      if (onValidationChange) {
        onValidationChange(validationResult);
      }
    }
  };
  
  const handleBlur = () => {
    if (!touched) {
      setTouched(true);
    }
  };
  
  // Report validation errors to parent component on mount and when they change
  useEffect(() => {
    if (showValidation && onValidationChange && touched) {
      if (value) {
        const validationResult = schema.validate(value, { list: true });
        setValidationErrors(validationResult);
        onValidationChange(validationResult);
      } else {
        // If no value, report validation issues for all rules
        const allErrors = ['min', 'uppercase', 'lowercase', 'digits'];
        setValidationErrors(allErrors);
        onValidationChange(allErrors);
      }
    } else if (showValidation && onValidationChange && !touched) {
      // When not touched, set empty validation errors to avoid red border
      setValidationErrors([]);
      onValidationChange([]);
    }
  }, [showValidation, onValidationChange, value, touched]);
  
  // Generate error message from validation errors
  const getValidationErrorMessage = () => {
    // Don't show validation errors until the field has been touched
    if (!touched || !showValidation || validationErrors.length === 0) return props.error;
    
    // Map validation failures to user-friendly messages
    const errorMessages = {
      min: userLang === 'zh-CN' ? '密码长度至少为6位' : 'Password must be at least 6 characters',
      max: userLang === 'zh-CN' ? '密码长度不能超过100位' : 'Password must be less than 100 characters',
      uppercase: userLang === 'zh-CN' ? '密码必须包含大写字母' : 'Password must include uppercase letters',
      lowercase: userLang === 'zh-CN' ? '密码必须包含小写字母' : 'Password must include lowercase letters',
      digits: userLang === 'zh-CN' ? '密码必须包含数字' : 'Password must include at least 1 digit',
      spaces: userLang === 'zh-CN' ? '密码不能包含空格' : 'Password cannot contain spaces',
      oneOf: userLang === 'zh-CN' ? '密码太简单' : 'Password is too common'
    };
    
    // Return the first error message or a custom message if provided
    if (validationMessage) {
      return validationMessage;
    }
    
    const firstError = validationErrors[0];
    return errorMessages[firstError] || props.error;
  };
  
  // Create a custom render function for the input to include the eye icon
  const renderInput = ({ inputProps, labelNode, errorNode }) => {
    return (
      <div className="max-w-form-input">
        {labelNode}
        <PasswordInputWithIcon
          {...inputProps}
          showPassword={showPassword}
          toggleShowPassword={toggleShowPassword}
          error={getValidationErrorMessage()}
          onBlur={handleBlur}
        />
        {errorNode}
      </div>
    );
  };
  
  return (
    <div>
      <FormInput
        label={label}
        value={value}
        onChange={handleChange}
        error={getValidationErrorMessage()}
        renderCustomInput={renderInput}
        {...props}
      />
      
      {/* Password strength indicator - only show when showValidation is true and field has been touched */}
      {showValidation && value && touched && (
        <div className="mt-1">
          {/* Password strength text */}
          <p className="text-xs text-gray-500 mb-1">
            {validationErrors.length === 0 ? 
              (userLang === 'zh-CN' ? '密码强度: 强' : 'Password strength: Strong') : 
              validationErrors.length <= 2 ? 
                (userLang === 'zh-CN' ? '密码强度: 中' : 'Password strength: Medium') : 
                (userLang === 'zh-CN' ? '密码强度: 弱' : 'Password strength: Weak')}
          </p>
          
          {/* Password strength bars */}
          <div className="flex items-center space-x-1">
            <div className={`h-1 flex-1 rounded-full ${validationErrors.length === 0 ? 'bg-green-500' : 
                              validationErrors.length <= 2 ? 'bg-yellow-500' : 'bg-red-500'}`}></div>
            <div className={`h-1 flex-1 rounded-full ${validationErrors.length === 0 ? 'bg-green-500' : 
                              validationErrors.length <= 1 ? 'bg-yellow-500' : 'bg-gray-200'}`}></div>
            <div className={`h-1 flex-1 rounded-full ${validationErrors.length === 0 ? 'bg-green-500' : 'bg-gray-200'}`}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordInput; 