/**
 * Location utility functions
 */

/**
 * Get the current user's geolocation
 * @returns {Promise<Object>} Promise resolving to coordinates object
 */
export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy
        });
      },
      (error) => {
        let errorMessage = 'Unknown error occurred while getting location';
        
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage = 'Location permission denied';
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = 'Location information is unavailable';
            break;
          case error.TIMEOUT:
            errorMessage = 'Location request timed out';
            break;
        }
        
        reject(new Error(errorMessage));
      },
      { 
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 60000 
      }
    );
  });
};

/**
 * Get location name (city, town) from coordinates using Mapbox reverse geocoding
 * @param {number} latitude - Latitude coordinate
 * @param {number} longitude - Longitude coordinate
 * @returns {Promise<Object>} Location data including place names
 */
export const getLocationNameFromCoordinates = async (latitude, longitude) => {
  // Get Mapbox token from environment variables
  const token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  
  if (!token) {
    console.warn('Mapbox token not found. Using fallback location name.');
    return {
      displayName: 'Current location',
      city: null,
      state: null,
      coordinates: { latitude, longitude }
    };
  }
  
  try {
    // Construct Mapbox reverse geocoding URL
    // Types filter for place, locality, neighborhood prioritizes city/town names
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${token}&types=place,locality,neighborhood`;
    
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`Geocoding request failed with status: ${response.status}`);
    }
    
    const data = await response.json();
    
    // Default values
    let displayName = 'Current location';
    let city = null;
    let state = null;
    
    if (data.features && data.features.length > 0) {
      // Find the most appropriate place name (city or locality)
      const placeFeature = data.features.find(f => 
        f.place_type.includes('place') || 
        f.place_type.includes('locality') ||
        f.place_type.includes('neighborhood')
      );
      
      if (placeFeature) {
        displayName = placeFeature.text;
        city = placeFeature.text;
        
        // Extract state/region from context
        if (placeFeature.context) {
          const stateData = placeFeature.context.find(c => c.id.includes('region'));
          if (stateData) {
            state = stateData.text;
          }
        }
      }
    }
    
    // Return structured location data
    return {
      displayName,
      city,
      state,
      coordinates: { latitude, longitude }
    };
  } catch (error) {
    console.error('Error getting location name:', error);
    // Return fallback with coordinates only
    return {
      displayName: 'Current location',
      city: null,
      state: null,
      coordinates: { latitude, longitude }
    };
  }
};

/**
 * Calculate distance between two geographical points using Haversine formula
 * @param {Object} point1 - First point with latitude and longitude
 * @param {Object} point2 - Second point with latitude and longitude
 * @returns {number} Distance in kilometers
 */
export const calculateDistance = (point1, point2) => {
  if (!point1 || !point2 || !point1.latitude || !point1.longitude || 
      !point2.latitude || !point2.longitude) {
    return null;
  }
  
  // Earth's radius in kilometers
  const earthRadius = 6371;
  
  const lat1 = parseFloat(point1.latitude);
  const lon1 = parseFloat(point1.longitude);
  const lat2 = parseFloat(point2.latitude);
  const lon2 = parseFloat(point2.longitude);
  
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return earthRadius * c;
};

/**
 * Convert kilometers to meters
 * @param {number} km - Distance in kilometers
 * @returns {number} Distance in meters
 */
export const kmToMeters = (km) => {
  if (typeof km !== 'number') return 0;
  return km * 1000;
};

/**
 * Convert degrees to radians
 * @param {number} deg - Angle in degrees
 * @returns {number} Angle in radians
 */
export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

/**
 * Get Google Maps URL for an address
 * @param {Object} address - Address object
 * @returns {string} Google Maps URL
 */
export const getAddressMapUrl = (address) => {
  if (!address) return '';
  
  // If we have coordinates, use them for more accurate map
  if (address.latitude && address.longitude) {
    return `https://www.google.com/maps?q=${address.latitude},${address.longitude}`;
  }
  
  // Otherwise, use the formatted address
  const addressString = [
    address.street,
    address.city,
    address.state,
    address.postalCode,
    address.country
  ].filter(Boolean).join(', ');
  
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;
};

/**
 * Get a static map URL for an address (Mapbox)
 * @param {Object} address - Address object
 * @param {number} width - Map width in pixels
 * @param {number} height - Map height in pixels
 * @param {string} accessToken - Mapbox access token
 * @returns {string} Static map URL
 */
export const getStaticMapUrl = (address, width = 600, height = 300, accessToken) => {
  if (!address || !address.latitude || !address.longitude || !accessToken) return '';
  
  return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(${address.longitude},${address.latitude})/${address.longitude},${address.latitude},14/${width}x${height}?access_token=${accessToken}`;
}; 