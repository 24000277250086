import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MapPinIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import {
  clearLocationError,
  selectLocationError,
  selectUserLocation,
  locationDetectionFailed,
  detectUserLocationWithPlaceName
} from '../../../../redux/slices/locationSlice'
import { getLocationNameFromCoordinates } from '../../../../utils/locationUtils'
import useFilter from '../useFilter'

/**
 * Location display component showing the current location and distance slider
 * Only displays when local pickup is enabled
 */
const LocationDisplay = () => {
  const dispatch = useDispatch()
  const { filters, handleLocalPickupChange } = useFilter()
  const locationError = useSelector(selectLocationError)
  const userLocation = useSelector(selectUserLocation)
  const [isLoadingLocation, setIsLoadingLocation] = useState(false)
  const [locationDisplay, setLocationDisplay] = useState('Current location')
  const [displayError, setDisplayError] = useState('')

  // Track if pickup filter is active
  const isPickupFilterActive = filters.localPickup?.enabled === true

  // Helper to check if we have valid location coordinates
  function hasValidLocation() {
    return Boolean(
      (filters.location?.latitude && filters.location?.longitude) ||
      (userLocation?.coordinates?.latitude && userLocation?.coordinates?.longitude)
    )
  }

  // Fetch location name from coordinates when filters change or component mounts
  useEffect(() => {
    const fetchLocationName = async () => {
      // Check if pickup filter is enabled and we have coordinates
      if (isPickupFilterActive && filters.location?.latitude && filters.location?.longitude) {
        try {
          setIsLoadingLocation(true)
          setDisplayError('') // Clear any previous errors
          
          const locationData = await getLocationNameFromCoordinates(
            filters.location.latitude,
            filters.location.longitude
          )
          
          if (locationData.displayName) {
            setLocationDisplay(locationData.displayName)
          }
        } catch (error) {
          console.error('Error fetching location name:', error)
          // Display the error to the user
          setDisplayError(`Failed to get location name: ${error.message || 'Unknown error'}`)
        } finally {
          setIsLoadingLocation(false)
        }
      }
    }
    
    fetchLocationName()
  }, [filters.location, isPickupFilterActive])

  // Don't render anything if pickup isn't active
  if (!isPickupFilterActive) return null

  // Handle distance slider change
  const handleDistanceChange = e => {
    const distance = parseInt(e.target.value)
    handleLocalPickupChange(true, distance)
  }

  // Handler for refreshing location
  const handleRefreshLocation = async () => {
    if (isLoadingLocation) return // Prevent multiple requests
    
    try {
      // Set local loading state
      setIsLoadingLocation(true)
      setDisplayError('') // Clear any previous errors
      
      // Clear any previous location errors
      dispatch(clearLocationError())
      
      // Use the enhanced location detection which includes place names
      const locationAction = await dispatch(detectUserLocationWithPlaceName())
      
      if (locationAction?.payload) {
        const locationData = locationAction.payload
        
        // Update display name
        if (locationData.displayName) {
          setLocationDisplay(locationData.displayName)
        }
      } else if (locationAction?.error) {
        // Handle error from the redux action
        setDisplayError(`Location detection failed: ${locationAction.error.message || 'Unknown error'}`)
      }
    } catch (error) {
      console.error('Error getting location:', error)
      setDisplayError(`Error accessing location: ${error.message || 'Unknown error'}`)
      dispatch(locationDetectionFailed(error.message))
    } finally {
      // Clear loading state
      setIsLoadingLocation(false)
    }
  }

  // Render the location button with loading state
  const renderLocationButton = () => (
    <button
      onClick={handleRefreshLocation}
      disabled={isLoadingLocation}
      className='text-brand-teal hover:text-brand-teal-dark transition-colors'
      aria-label={isLoadingLocation ? 'Loading location...' : 'Refresh location'}
    >
      {isLoadingLocation ? (
        <svg className='animate-spin h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
          <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
          <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
        </svg>
      ) : (
        <MapPinIcon className='w-5 h-5' />
      )}
    </button>
  )

  // Get the current location display from our state or fallbacks
  const displayName = locationDisplay || userLocation?.displayName || userLocation?.city || 'Current location'

  // If there's a location error from Redux or from our component
  if (locationError || displayError) {
    return (
      <div className='pl-6 space-y-2'>
        <div className='flex items-center justify-between'>
          <div className='text-xs text-brand-orange-dark'>
            <span>{displayError || locationError || 'Failure accessing location'}</span>
          </div>
          {renderLocationButton()}
        </div>
      </div>
    )
  }

  // If pickup is enabled but we don't have location yet
  if (!hasValidLocation()) {
    return (
      <div className='pl-6 space-y-2'>
        <div className='flex items-center justify-between'>
          <div className='text-xs text-gray-700'>
            Getting your location...
          </div>
          <MapPinIcon className='w-5 h-5 text-brand-teal animate-pulse' />
        </div>
      </div>
    )
  }

  // If we're still loading location data (e.g. reverse geocoding)
  if (isLoadingLocation) {
    return (
      <div className='pl-6 space-y-2'>
        <div className='flex items-center justify-between'>
          <div className='text-xs text-gray-700'>
            Loading location details...
          </div>
          <MapPinIcon className='w-5 h-5 text-brand-teal animate-pulse' />
        </div>
      </div>
    )
  }

  // Normal slider display when we have location
  return (
    <div className='pl-6 space-y-2'>
      <div className='flex items-center justify-between mb-2'>
        <div className='text-xs text-gray-700'>
          Pickup near <span className='font-medium'>{displayName}</span>
        </div>
        {renderLocationButton()}
      </div>
      <input
        type='range'
        min='0'
        max='10' // Maximum radius is 10km
        value={filters.localPickup?.distance || 5}
        onChange={handleDistanceChange}
        className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer'
      />
      <div className='text-xs text-gray-500 flex justify-between'>
        <span>0 km</span>
        <span>{filters.localPickup?.distance || 5} km</span>
        <span>10 km</span>
      </div>
    </div>
  )
}

export default LocationDisplay 