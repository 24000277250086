import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reviewModalOpen: false,
  editingReview: null,
  reviewToDelete: null,
  deleteModalOpen: false,
  currentPage: 1,
  pageSize: 10,
  sortOrder: 'desc',
  filterRating: null,
  reviewDraft: {
    rating: 5,
    comment: {
      text: '',
      pros: [],
      cons: []
    },
    pictures: [],
    orderId: null,
    merchantId: null
  }
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setReviewModalOpen: (state, action) => {
      state.reviewModalOpen = action.payload;
      if (!action.payload) {
        state.editingReview = null;
        state.reviewDraft = initialState.reviewDraft;
      }
    },
    setEditingReview: (state, action) => {
      state.editingReview = action.payload;
      state.reviewModalOpen = true;
      if (action.payload) {
        state.reviewDraft = {
          rating: action.payload.rating,
          comment: action.payload.comment || {
            text: '',
            pros: [],
            cons: []
          },
          pictures: action.payload.pictures || [],
          orderId: action.payload.orders?.[0]?.id || null,
          merchantId: action.payload.merchants?.[0]?.id || null
        };
      }
    },
    setReviewToDelete: (state, action) => {
      state.reviewToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    setDeleteModalOpen: (state, action) => {
      state.deleteModalOpen = action.payload;
      if (!action.payload) {
        state.reviewToDelete = null;
      }
    },
    updateReviewDraft: (state, action) => {
      state.reviewDraft = {
        ...state.reviewDraft,
        ...action.payload,
        comment: {
          ...state.reviewDraft.comment,
          ...(action.payload.comment || {})
        }
      };
    },
    setPagination: (state, action) => {
      const { currentPage, pageSize } = action.payload;
      state.currentPage = currentPage || state.currentPage;
      state.pageSize = pageSize || state.pageSize;
    },
    setSortOrder: (state, action) => {
      state.sortOrder = action.payload;
    },
    setFilterRating: (state, action) => {
      state.filterRating = action.payload;
    },
    resetReviewState: (state) => {
      return initialState;
    }
  }
});

export const {
  setReviewModalOpen,
  setEditingReview,
  setReviewToDelete,
  setDeleteModalOpen,
  updateReviewDraft,
  setPagination,
  setSortOrder,
  setFilterRating,
  resetReviewState
} = reviewSlice.actions;

export default reviewSlice.reducer; 