import { createBaseApi } from '../baseApi';

const authApi = createBaseApi('authApi');

export const enhancedAuthApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => '/users/me?populate=*',
      providesTags: ['Auth', 'User']
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/local',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: ['Auth']
    }),
    register: builder.mutation({
      query: (userData) => ({
        url: '/auth/local/register',
        method: 'POST',
        body: userData
      })
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST'
      }),
      invalidatesTags: ['Auth', 'User']
    }),
    requestPasswordReset: builder.mutation({
      query: (email) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: { email }
      })
    }),
    resetPassword: builder.mutation({
      query: ({ code, password, passwordConfirmation }) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: { code, password, passwordConfirmation }
      })
    }),
    verifyEmail: builder.mutation({
      query: (confirmation) => ({
        url: '/auth/email-confirmation',
        method: 'POST',
        body: { confirmation }
      }),
      invalidatesTags: ['Auth', 'User']
    })
  })
});

export const {
  useGetCurrentUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation
} = enhancedAuthApi;
