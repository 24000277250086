import { createBaseApi } from '../baseApi';

const reviewApi = createBaseApi('reviewApi');

export const enhancedReviewApi = reviewApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductReviews: builder.query({
      query: ({ productId, page = 1, pageSize = 10, sort = 'createdAt:desc' }) => ({
        url: '/comments',
        params: {
          'filters[products][id][$eq]': productId,
          'pagination[page]': page,
          'pagination[pageSize]': pageSize,
          sort,
          _publicationState: 'live'
        }
      }),
      providesTags: (result, error, { productId }) => [
        { type: 'Review', id: 'LIST' },
        { type: 'Review', id: `PRODUCT_${productId}` }
      ]
    }),
    getReviewStats: builder.query({
      query: (productId) => ({
        url: '/comments/stats',
        params: {
          'filters[products][id][$eq]': productId,
          _publicationState: 'live'
        }
      }),
      providesTags: (result, error, productId) => [
        { type: 'Review', id: `STATS_${productId}` }
      ]
    }),
    addReview: builder.mutation({
      query: ({ productId, customerId, merchantId, orderId, ...review }) => ({
        url: '/comments',
        method: 'POST',
        body: {
          ...review,
          products: { connect: [productId] },
          customers: { connect: [customerId] },
          ...(merchantId && { merchants: { connect: [merchantId] } }),
          ...(orderId && { orders: { connect: [orderId] } })
        }
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: 'Review', id: 'LIST' },
        { type: 'Review', id: `PRODUCT_${productId}` },
        { type: 'Review', id: `STATS_${productId}` }
      ]
    }),
    updateReview: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/comments/${id}`,
        method: 'PUT',
        body: patch
      }),
      invalidatesTags: (result, error, { id, productId }) => [
        { type: 'Review', id },
        { type: 'Review', id: `PRODUCT_${productId}` },
        { type: 'Review', id: `STATS_${productId}` }
      ]
    }),
    deleteReview: builder.mutation({
      query: (id) => ({
        url: `/comments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id, productId }) => [
        { type: 'Review', id },
        { type: 'Review', id: `PRODUCT_${productId}` },
        { type: 'Review', id: `STATS_${productId}` }
      ]
    }),
    getUserReviews: builder.query({
      query: ({ userId, page = 1, pageSize = 10 }) => ({
        url: '/comments',
        params: {
          'filters[customers][id][$eq]': userId,
          'pagination[page]': page,
          'pagination[pageSize]': pageSize,
          sort: 'createdAt:desc',
          _publicationState: 'live'
        }
      }),
      providesTags: (result, error, { userId }) => [
        { type: 'Review', id: `USER_${userId}` }
      ]
    })
  })
});

export const {
  useGetProductReviewsQuery,
  useGetReviewStatsQuery,
  useAddReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
  useGetUserReviewsQuery
} = enhancedReviewApi; 