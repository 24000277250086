import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCurrentUserQuery } from '../../redux/apis';
import { useUrlChangeHandler } from '../../hooks/useUrlChangeHandler';

// Dashboard Components
import Overview from './components/Overview';
import Listings from './components/Listings';
import Transactions from './components/Transactions';
import Wallet from './components/Wallet';
import Settings from './components/Settings';

const userLang = navigator.language || navigator.userLanguage;

const Dashboard = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  
  // Update this selector to use auth state
  const { isAuthenticated, user: userInfo } = useSelector(state => ({
    isAuthenticated: state.auth?.isAuthenticated ?? false,
    user: state.auth?.user ?? null
  }));
  
  const { data: user } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated
  });

  // Handle URL changes at the parent level
  useUrlChangeHandler(false);

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  const NavigationMenu = () => {
    const isActive = (path) => location.pathname === path;
    const baseUrl = '/dashboard';

    const menuItems = [
      {
        path: baseUrl,
        icon: (
          <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
        ),
        label: userLang === 'zh-CN' ? '概览' : 'Overview'
      },
      {
        path: `${baseUrl}/listings`,
        icon: (
          <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
          </svg>
        ),
        label: userLang === 'zh-CN' ? '商品列表' : 'Listings'
      },
      {
        path: `${baseUrl}/transactions`,
        icon: (
          <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
        ),
        label: userLang === 'zh-CN' ? '交易记录' : 'Transactions'
      },
      {
        path: `${baseUrl}/wallet`,
        icon: (
          <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
        ),
        label: userLang === 'zh-CN' ? '钱包' : 'Wallet'
      },
      {
        path: `${baseUrl}/settings`,
        icon: (
          <svg className="w-6 h-6 mr-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        ),
        label: userLang === 'zh-CN' ? '设置' : 'Settings'
      }
    ];

    return (
      <nav className="space-y-1">
        {menuItems.map((item) => {
          const active = isActive(item.path);
          return (
            <button
              key={item.path}
              onClick={() => history.push(item.path)}
              className={`w-full flex items-center px-4 py-3 text-sm font-medium rounded-md transition-colors ${
                active
                  ? 'bg-brand-teal text-white'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              }`}
            >
              {item.icon}
              {item.label}
            </button>
          );
        })}
      </nav>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Desktop/Tablet Menu - Only visible on md and up */}
      <div className="hidden md:flex min-h-screen">
        {/* Sidebar */}
        <div className="w-64 lg:w-80 flex-shrink-0 bg-white shadow-lg">
          <div className="h-full flex flex-col">
            <div className="p-6 border-b">
              <h2 className="text-2xl font-medium text-gray-900">
                {userLang === 'zh-CN' ? '控制面板' : 'Dashboard'}
              </h2>
              {user?.isMerchant && (
                <p className="mt-1 text-sm text-gray-500">
                  {userLang === 'zh-CN' ? '商家账户' : 'Merchant Account'}
                </p>
              )}
            </div>
            <div className="flex-1 p-6 overflow-y-auto">
              <NavigationMenu />
            </div>
          </div>
        </div>

        {/* Main Content for Desktop/Tablet */}
        <div className="flex-1 min-w-0">
          <Switch>
            <Route exact path="/dashboard" component={Overview} />
            <Route path="/dashboard/listings" component={Listings} />
            <Route path="/dashboard/transactions" component={Transactions} />
            <Route path="/dashboard/wallet" component={Wallet} />
            <Route path="/dashboard/settings" component={Settings} />
          </Switch>
        </div>
      </div>

      {/* Mobile View - Only visible below md */}
      <div className="md:hidden">
        {/* Mobile Menu Header */}
        <div className="bg-white shadow-sm sticky top-0 z-40">
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="w-full px-6 py-4 flex items-center justify-between"
          >
            <h2 className="text-xl font-medium text-gray-900">
              {userLang === 'zh-CN' ? '控制面板' : 'Dashboard'}
            </h2>
            <svg 
              className={`w-6 h-6 text-gray-600 transform transition-transform duration-200 ${mobileMenuOpen ? 'rotate-180' : ''}`}
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {/* Mobile Menu Dropdown */}
          <div 
            className={`border-t border-gray-200 overflow-hidden transition-all duration-300 ease-in-out ${
              mobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            <div className="p-4 bg-white">
              <NavigationMenu />
            </div>
          </div>
        </div>

        {/* Mobile Main Content */}
        <div className="flex-1">
          <Switch>
            <Route exact path="/dashboard" component={Overview} />
            <Route path="/dashboard/listings" component={Listings} />
            <Route path="/dashboard/transactions" component={Transactions} />
            <Route path="/dashboard/wallet" component={Wallet} />
            <Route path="/dashboard/settings" component={Settings} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 