import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from '../../hooks/useDebounce';
import { MAPBOX_CONFIG, formatMapboxResponse } from '../../config/mapbox';
import { 
  selectAddressLoading, 
  setAddressLoading, 
  setAddressError,
  setUserAddresses
} from '../../redux/slices/addressSlice';
import { selectUserCountry, detectUserCountry } from '../../redux/slices/locationSlice';
import { formatAddress } from './utils/addressUtils';

/**
 * Enhanced AddressAutocomplete component that integrates with existing Redux store
 * This is a smart component that directly updates Redux when an address is selected
 */
const AddressAutocomplete = ({ 
  onSelect = null, // Optional callback, component will update Redux regardless
  initialValue = '',
  placeholder = 'Enter an address',
  className = '',
  disabled = false,
  required = false,
  error = null,
  label = null,
  useReduxLoading = true, // Whether to use Redux loading state
  updateReduxStore = true, // Whether to update Redux store on selection
  userLang = navigator.language || navigator.userLanguage,
  countryOverride = null // Allow overriding the country filter
}) => {
  const [query, setQuery] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const containerRef = useRef(null);
  
  const dispatch = useDispatch();
  
  // Get loading state from Redux if enabled
  const reduxLoading = useSelector(selectAddressLoading);
  const userCountry = useSelector(selectUserCountry);
  const loading = useReduxLoading ? reduxLoading : localLoading;
  
  // Make sure we have country information - highest priority is override prop
  const countryToUse = countryOverride || userCountry?.code || 'FR';
  
  // Ensure we have user country information
  useEffect(() => {
    if (!userCountry?.code && !countryOverride) {
      dispatch(detectUserCountry());
    }
  }, [dispatch, userCountry, countryOverride]);
  
  // When country changes in Redux, clear suggestions to ensure
  // they'll be refreshed with the new country filter on next search
  useEffect(() => {
    setSuggestions([]);
  }, [countryToUse]);
  
  // Debounce the input to prevent excessive API calls
  const debouncedQuery = useDebounce(query, MAPBOX_CONFIG.autocompleteDebounce || 300);
  
  // Fetch suggestions when the debounced query changes
  useEffect(() => {
    if (!debouncedQuery || debouncedQuery.length < 3 || disabled) {
      setSuggestions([]);
      return;
    }
    
    const fetchSuggestions = async () => {
      if (useReduxLoading) {
        dispatch(setAddressLoading(true));
      } else {
        setLocalLoading(true);
      }
      
      try {
        // Always convert country code to lowercase for Mapbox API
        // Make sure we're actually filtering by a country
        const countryCode = countryToUse?.toLowerCase() || 'fr';
        
        // For debugging
        console.log(`Searching addresses in country: ${countryCode}`);
        
        // Bounding box for metropolitan France to exclude territories like New Caledonia
        // Format: [min longitude, min latitude, max longitude, max latitude]
        // These coordinates roughly cover mainland France
        const franceBbox = countryCode === 'fr' ? 
          '-5.0,41.0,9.5,51.5' : // Bounding box for metropolitan France
          undefined;
        
        // Use Paris coordinates as proximity center for France
        const franceProximity = countryCode === 'fr' ? 
          '2.3522,48.8566' : // Paris coordinates
          undefined;
        
        let apiUrl = `${MAPBOX_CONFIG.geocodingEndpoint}${encodeURIComponent(debouncedQuery)}.json?` +
          `access_token=${MAPBOX_CONFIG.accessToken}&` +
          `country=${countryCode}&` +
          `limit=${MAPBOX_CONFIG.limit || 5}&` +
          `types=${MAPBOX_CONFIG.types || 'address'}&` +
          `language=${userLang || 'en'}`;
          
        // Add bounding box for France to exclude territories
        if (franceBbox) {
          apiUrl += `&bbox=${franceBbox}`;
        }
        
        // Add proximity for better results
        if (franceProximity) {
          apiUrl += `&proximity=${franceProximity}`;
        }
        
        console.log('Fetching with URL:', apiUrl);
        const response = await fetch(apiUrl);
        
        if (!response.ok) {
          throw new Error(`Mapbox API error: ${response.status}`);
        }
        
        const data = await response.json();
        setSuggestions(data.features || []);
        
        // Extra filtering for France to exclude overseas territories
        if (countryCode === 'fr' && data.features && data.features.length > 0) {
          // Filter out results from overseas territories
          // by checking if the place_name mentions New Caledonia, Guadeloupe, Martinique, etc.
          const filteredFeatures = data.features.filter(feature => {
            const placeName = feature.place_name.toLowerCase();
            return !placeName.includes('new caledonia') && 
                   !placeName.includes('nouvelle-calédonie') &&
                   !placeName.includes('guadeloupe') &&
                   !placeName.includes('martinique') &&
                   !placeName.includes('guyane') &&
                   !placeName.includes('réunion') &&
                   !placeName.includes('mayotte');
          });
          
          console.log(`Filtered out ${data.features.length - filteredFeatures.length} overseas results`);
          setSuggestions(filteredFeatures);
        }
      } catch (error) {
        console.error('Error fetching address suggestions:', error);
        setSuggestions([]);
        
        if (useReduxLoading) {
          dispatch(setAddressError('Failed to load address suggestions'));
        }
      } finally {
        if (useReduxLoading) {
          dispatch(setAddressLoading(false));
        } else {
          setLocalLoading(false);
        }
      }
    };
    
    fetchSuggestions();
  }, [debouncedQuery, disabled, useReduxLoading, dispatch, countryToUse, userLang]);
  
  // Handle clicks outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Convert MapBox response to standard address format
  const mapboxToAddressFormat = (mapboxAddress) => {
    // Create a standardized address object from the MapBox response
    return {
      name: '',  // Can be filled by the user later
      street: mapboxAddress.components.street || '',
      apartment: '',  // MapBox doesn't provide this
      city: mapboxAddress.components.city || '',
      state: mapboxAddress.components.state || '',
      postalCode: mapboxAddress.components.postalCode || '',
      country: mapboxAddress.components.country || countryToUse || 'FR',
      latitude: mapboxAddress.coordinates.latitude,
      longitude: mapboxAddress.coordinates.longitude,
      formattedAddress: mapboxAddress.fullAddress,
      isDefault: false  // Default to false
    };
  };
  
  // Handle selection from dropdown
  const handleSelect = (suggestion) => {
    const mapboxFormattedAddress = formatMapboxResponse(suggestion);
    setQuery(suggestion.place_name);
    setSuggestions([]);
    setShowSuggestions(false);
    
    // Convert to our standard address format
    const standardAddress = mapboxToAddressFormat(mapboxFormattedAddress);
    setSelectedAddress(standardAddress);
    
    // Update Redux store if option is enabled
    if (updateReduxStore) {
      dispatch(setUserAddresses({ 
        address: standardAddress, 
        action: 'current' // Special action that just updates current without API call
      }));
    }
    
    // Call the onSelect callback if provided
    if (onSelect) {
      onSelect(standardAddress);
    }
  };
  
  return (
    <div className={`w-full ${className}`} ref={containerRef}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            if (e.target.value) setShowSuggestions(true);
          }}
          onFocus={() => setShowSuggestions(Boolean(query && suggestions.length > 0))}
          placeholder={placeholder}
          className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:outline-none ${
            error 
              ? 'border-red-300 focus:ring-red-400 focus:border-red-400' 
              : 'border-gray-300 focus:ring-brand-teal focus:border-brand-teal'
          }`}
          disabled={disabled}
          required={required}
          autoComplete="off"
          data-testid="address-autocomplete-input"
        />
        
        {loading && (
          <div className="absolute right-3 top-2.5">
            <svg className="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
      
      {showSuggestions && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-auto">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onClick={() => handleSelect(suggestion)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
              data-testid="address-suggestion-item"
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
      
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default AddressAutocomplete; 