import { BACKEND_URL } from '../../../utils/config';
import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Segment, Button, Message, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { showError } from '../../../redux/slices/responseSlice';

class MerchantHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      totalIncome: 0,
      ordersNum: 0,
      incomePerOrder: 0,
      clothingNum: 0,
      accessoryNum: 0,
      productsNum: 0,
      descriptionRate: 0,
      serviceRate: 0,
      logisticsRate: 0,
      stripeAccountStatus: null,
      stripeStatusLoading: false
    }
    this.getMerchantInfo = this.getMerchantInfo.bind(this)
    this.getOrderInfo = this.getOrderInfo.bind(this)
    this.handleGoVerify = this.handleGoVerify.bind(this)
    this.fetchStripeAccountStatus = this.fetchStripeAccountStatus.bind(this)
  }

  fetchStripeAccountStatus() {
    const { merchantInfo } = this.props;
    console.log('fetchStripeAccountStatus called with merchantInfo:', merchantInfo);
    if (!merchantInfo?.stripeAccount) {
      console.log('No stripeAccount found in merchantInfo');
      return;
    }

    const token = localStorage.getItem('token');
    
    this.setState({ stripeStatusLoading: true });
    console.log('Setting stripeStatusLoading to true');
    
    return axios.post(`${BACKEND_URL}/pay/stripeAccountSession`, {
      account: merchantInfo.stripeAccount
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      console.log('Stripe account session response:', response.data);
      if (response.data && response.data.client_secret) {
        console.log('Got client_secret, setting stripeAccountStatus to null');
        this.setState({ 
          stripeAccountStatus: null,
          stripeStatusLoading: false
        });
      } else if (response.data && response.data.account) {
        console.log('Got account info, setting stripeAccountStatus:', response.data.account);
        this.setState({ 
          stripeAccountStatus: response.data.account,
          stripeStatusLoading: false
        });
      }
    })
    .catch(error => {
      console.error('Error fetching Stripe account status:', error);
      this.setState({ stripeStatusLoading: false });
    });
  }

  getOrderInfo() {
    const self = this
    const token = localStorage.getItem('token')
    const userInfo = this.props.merchantInfo
    if (!userInfo || !userInfo.id) return

    axios.get(`${BACKEND_URL}/orders?merchants=${userInfo.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const data = res.data
        const ordersNum = data.length

        var totalIncome = 0
        var incomePerOrder = 0
        for (var i = 0; i < ordersNum; i++) {
          totalIncome = totalIncome + data[i].orders.data.price
        }
        incomePerOrder = totalIncome / ordersNum

        self.setState({
          orders: data,
          ordersNum: ordersNum,
          totalIncome: totalIncome,
          incomePerOrder: incomePerOrder
        })
      })
      .catch(error => {
        console.error('Error fetching orders:', error)
        this.props.showError('Failed to fetch orders', 'error')
      })
  }

  getMerchantInfo() {
    const self = this
    const token = localStorage.getItem('token')
    const userInfo = this.props.merchantInfo
    if (!userInfo || !userInfo.id) return

    axios.get(`${BACKEND_URL}/products?users_permissions_user=${userInfo.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const products = res.data;
        const clothingProducts = products.filter(p => p.type === 'clothing');
        const accessoryProducts = products.filter(p => p.type === 'accessory');
        
        self.setState({
          clothingNum: clothingProducts.length,
          accessoryNum: accessoryProducts.length,
          productsNum: products.length
        });
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        this.props.showError('Failed to fetch products', 'error');
      });
  }

  componentDidMount() {
    console.log('componentDidMount - merchantInfo:', this.props.merchantInfo);
    if (this.props.merchantInfo) {
      Promise.all([
        this.getMerchantInfo(),
        this.getOrderInfo(),
        this.fetchStripeAccountStatus()
      ]).finally(() => {
        console.log('All initial data fetched');
        this.setState({ loading: false });
      });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 700);
    }
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate');
    console.log('Previous merchantInfo:', prevProps.merchantInfo);
    console.log('Current merchantInfo:', this.props.merchantInfo);
    // If merchantInfo changes (e.g., after webhook update)
    if (prevProps.merchantInfo !== this.props.merchantInfo && this.props.merchantInfo) {
      console.log('merchantInfo changed, fetching new status');
      this.fetchStripeAccountStatus();
    }
  }

  handleGoVerify() {
    this.props.history.push('/merchantVerify/address')
  }

  getStripeAccountInfo(merchantInfo) {
    console.log('getStripeAccountInfo called');
    console.log('Current state:', {
      stripeStatusLoading: this.state.stripeStatusLoading,
      stripeAccountStatus: this.state.stripeAccountStatus
    });
    
    if (this.state.stripeStatusLoading) {
      console.log('Still loading stripe status');
      return null;
    }
    
    // First try to use the latest fetched status
    if (this.state.stripeAccountStatus) {
      console.log('Using latest fetched account status:', this.state.stripeAccountStatus);
      return this.state.stripeAccountStatus;
    }
    
    // Then try webhook data
    if (merchantInfo?.stripeWebhook?.accountDetails) {
      console.log('Using webhook account details:', merchantInfo.stripeWebhook.accountDetails);
      return merchantInfo.stripeWebhook.accountDetails;
    }
    
    // Finally fallback to initial account details
    if (merchantInfo?.stripeAccountDetail) {
      console.log('Using initial account details:', merchantInfo.stripeAccountDetail);
      return merchantInfo.stripeAccountDetail;
    }
    
    console.log('No account info found');
    return null;
  }

  handleUploadProduct = () => {
    this.props.history.push('/merchantpage/products');
  }

  renderVerificationStatus(merchantInfo) {
    console.log('Rendering verification status for:', merchantInfo);
    
    // Handle array input
    if (Array.isArray(merchantInfo)) {
      merchantInfo = merchantInfo[0];
    }
    
    // Case 1: No Stripe account setup yet (no stripeAccount ID)
    if (!merchantInfo?.stripeAccount) {
      return (
        <Segment style={{ marginBottom: '20px' }}>
          <Header as='h3'>
            <Icon name='attention' color='yellow' />
            <Header.Content>Account Verification Status</Header.Content>
          </Header>
          <Message warning>
            <Message.Header>Get Started with SHEYOU</Message.Header>
            <p>Start onboarding to receive payments and upload products on our platform.</p>
          </Message>
          <Button 
            color='orange' 
            size='small' 
            style={{ marginTop: '10px' }}
            onClick={() => this.props.history.push('/onBoardStripe')}
          >
            <Icon name='stripe' />
            Start Onboarding
          </Button>
        </Segment>
      );
    }

    // Get account info from webhook or initial details
    const accountInfo = this.getStripeAccountInfo(merchantInfo);
    if (!accountInfo) {
      return (
        <Segment style={{ marginBottom: '20px' }}>
          <Header as='h3'>
            <Icon name='attention' color='yellow' />
            <Header.Content>Account Verification Status</Header.Content>
          </Header>
          <Message warning>
            <Message.Header>Continue Verification</Message.Header>
            <p>Please complete your verification process to enable payments.</p>
          </Message>
          <Button 
            color='orange' 
            size='small' 
            style={{ marginTop: '10px' }}
            onClick={() => this.props.history.push(`/onBoardStripe?account=${merchantInfo.stripeAccount}`)}
          >
            <Icon name='stripe' />
            Continue Onboarding
          </Button>
        </Segment>
      );
    }

    // Case 2: Fully verified (payouts enabled)
    if (accountInfo.payouts_enabled) {
      const handleManageAccount = async () => {
        try {
          const response = await axios.post(
            `${BACKEND_URL}/pay/stripeLoginLink`,
            {
              accountId: merchantInfo.stripeAccount
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              }
            }
          );

          if (response.data.url) {
            window.open(response.data.url, '_blank');
          } else {
            this.props.showError('Unable to access Express dashboard', 'error');
          }
        } catch (error) {
          this.props.showError(
            error.response?.data?.error || 'Error accessing Express dashboard',
            'error'
          );
        }
      };

      return (
        <Segment style={{ marginBottom: '20px' }}>
          <Header as='h3'>
            <Icon name='check circle' color='green' />
            <Header.Content>Account Verification Status</Header.Content>
          </Header>
          <Message positive>
            <Message.Header>Account Verified</Message.Header>
            <p>Your account is fully verified. You can now start selling on our platform.</p>
          </Message>
          <div style={{ marginTop: '10px' }}>
            <Button 
              color='yellow' 
              style={{marginRight: '10px'}}
              onClick={this.handleUploadProduct}
            >
              <Icon name='upload' />
              Upload Product
            </Button>
            <Button 
              color='teal'
              onClick={handleManageAccount}
            >
              <Icon name='stripe' />
              Manage Express Dashboard
            </Button>
          </div>
        </Segment>
      );
    }

    // Case 3: Has stripeAccount but needs additional verification
    const formatRequirement = (requirement) => {
      if (requirement.includes('person_')) {
        const parts = requirement.split('.');
        const lastPart = parts[parts.length - 1];
        return `Representative's ${lastPart.replace(/_/g, ' ')}`;
      }
      return requirement
        .split('.')
        .map(part => part.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '))
        .join(' - ');
    };

    const requirements = accountInfo.requirements;
    const disabledReason = requirements?.disabled_reason;
    let statusMessage = 'Additional information is needed to enable payouts.';
    
    // Format the disabled reason message
    if (disabledReason) {
      switch (disabledReason) {
        case 'requirements.past_due':
          statusMessage = 'Your account has past due requirements that need to be addressed.';
          break;
        case 'requirements.pending_verification':
          statusMessage = 'Your account is pending verification. This typically takes 1-2 business days.';
          break;
        case 'rejected.fraud':
          statusMessage = 'Your account verification was rejected. Please contact support.';
          break;
        case 'rejected.terms_of_service':
          statusMessage = 'Your account was rejected due to Terms of Service violation.';
          break;
        case 'rejected.listed':
          statusMessage = 'Your account was rejected. Please contact support.';
          break;
        case 'rejected.other':
          statusMessage = 'Your account was rejected. Please contact support for more information.';
          break;
        default:
          if (disabledReason.startsWith('requirements.')) {
            statusMessage = 'Additional requirements need to be completed for your account.';
          }
      }
    }

    return (
      <Segment style={{ marginBottom: '20px' }}>
        <Header as='h3'>
          <Icon name='attention' color='yellow' />
          <Header.Content>Account Verification Status</Header.Content>
        </Header>

        <Message warning>
          <Message.Header>Action Required: Additional Information Needed</Message.Header>
          <Message.Content>
            <p>{statusMessage}</p>
            {requirements?.currently_due?.length > 0 && (
              <>
                <p>Please provide the following information to complete your verification:</p>
                <List bulleted>
                  {requirements.currently_due.map((requirement, index) => (
                    <List.Item key={index}>
                      {formatRequirement(requirement)}
                    </List.Item>
                  ))}
                </List>
              </>
            )}
          </Message.Content>
        </Message>

        <Button 
          color='orange' 
          size='small' 
          style={{ marginTop: '10px' }}
          onClick={() => this.props.history.push(`/onBoardStripe?account=${merchantInfo.stripeAccount}`)}
        >
          <Icon name='stripe' />
          Complete Verification
        </Button>
      </Segment>
    );
  }

  render() {
    const { merchantInfo } = this.props;
    console.log('Render - current state:', this.state);
    console.log('Render - merchantInfo:', merchantInfo);
    
    if (!merchantInfo) {
      console.log('No merchantInfo, showing loading');
      return (
        <Segment loading>
          <Header>Loading merchant information...</Header>
        </Segment>
      );
    }

    const storeName = merchantInfo.merchantInfo?.storeName || 'Your Store';
    const account = merchantInfo.username || 'Your Account';
    const accountInfo = this.getStripeAccountInfo(merchantInfo);
    console.log('Render - accountInfo:', accountInfo);
    const isVerified = accountInfo?.payouts_enabled;
    console.log('Render - isVerified:', isVerified);

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            {/* Verification Status Section */}
            {this.renderVerificationStatus(merchantInfo)}

            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={7}>
                  <Segment>
                    <br />
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          width={14}
                          style={{ paddingLeft: 50, paddingRight: 50 }}
                        >
                          <Grid stackable>
                            <Grid.Row
                              style={{ marginBottom: 15, marginTop: 12 }}
                              columns={2}
                            >
                              <Grid.Column width={14}>
                                <Header as='h2' style={{ color: '#767676' }}>
                                  {storeName}
                                </Header>
                              </Grid.Column>

                              <Grid.Column width={2}>
                                <Link to='/merchantpage/store'>
                                  <Button basic color='teal' size='small'>
                                    Edit
                                  </Button>
                                </Link>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={5}>
                          <Header as='h4'>Account</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <p style={{ color: '#767676', position: 'relative', top: -3 }}>
                            {account}
                          </p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={5}>
                          <Header as='h4'>Detail Info</Header>
                        </Grid.Column>
                        <Grid.Column width={9}>
                          <Link
                            to='/merchantpage/store'
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            {'Fill Out  '}
                            <Icon
                              name='chevron right'
                              style={{
                                color: '#767676',
                                position: 'relative',
                                top: -1
                              }}
                              color='grey'
                              size='small'
                            />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        columms={2}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Grid.Column width={5}>
                          <Header as='h4'>Rating</Header>
                        </Grid.Column>
                        <Grid.Column width={11}>
                          <Grid>
                            <Grid.Row columns={6}>
                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Description
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>
                                  {this.state.descriptionRate}
                                </Header>
                              </Grid.Column>

                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Service
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>{this.state.serviceRate}</Header>
                              </Grid.Column>

                              <Grid.Column width={8}>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Logistics
                                </p>
                              </Grid.Column>
                              <Grid.Column width={5}>
                                <Header as='h4'>
                                  {this.state.logisticsRate}
                                </Header>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Column>

                <Grid.Column width={9}>
                  <Segment>
                    <br />
                    <Grid style={{ paddingLeft: 20, paddingRight: 10 }} stackable>
                      <Grid.Row>
                        <Header>Store Data</Header>
                      </Grid.Row>

                      <Grid.Row
                        columns='equal'
                        style={{
                          height: window.innerWidth < 766 ? 200 : 92,
                          paddingTop: 20
                        }}
                      >
                        <Grid.Column>
                          <Grid
                            style={{
                              height: 80,
                              backgroundColor: 'rgb(249,250,251)',
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Total Income
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.totalIncome}</Header>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                              </p>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid
                            style={{
                              height: 80,
                              backgroundColor: 'rgb(249,250,251)',
                              marginRight: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Orders
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.ordersNum}</Header>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                              </p>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row
                        columns='equal'
                        style={{
                          height: window.innerWidth < 766 ? 200 : 92,
                          paddingBottom: 20
                        }}
                      >
                        <Grid.Column>
                          <Grid
                            style={{
                              backgroundColor: 'rgb(249,250,251)',
                              height: 80,
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Income Per Order
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>
                                {this.state.incomePerOrder}
                              </Header>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>

                        <Grid.Column>
                          <Grid
                            style={{
                              backgroundColor: 'rgb(249,250,251)',
                              height: 80,
                              marginRight: 5,
                              paddingLeft: 5
                            }}
                          >
                            <Grid.Row
                              style={{
                                paddingTop: 20,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <p
                                style={{
                                  color: '#767676'
                                }}
                              >
                                Products
                              </p>
                            </Grid.Row>
                            <Grid.Row
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginBottom: 0,
                                marginTop: 0
                              }}
                              centered
                            >
                              <Header as='h4'>{this.state.productsNum}</Header>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  merchantInfo: state.user.userInfo
});

const mapDispatchToProps = {
  showError: (message, severity) => showError(message, severity)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MerchantHome));
