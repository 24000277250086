import React, { useRef, useState, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const Dropdown = ({ 
  value, 
  onChange, 
  options,
  placeholder = 'Select...',
  title,
  error,
  required,
  className = '',
  maxWidth = 'max-w-xs',
  renderOption = null, // Custom render function for options
  renderSelected = null // Custom render function for selected value
}) => {
  // Find the selected option object
  const selectedOption = options.find(opt => opt.value === value);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const [dropdownPlacement, setDropdownPlacement] = useState('bottom');
  
  // Update button width and position when it changes
  useEffect(() => {
    if (buttonRef.current && containerRef.current) {
      const updateMeasurements = () => {
        const rect = buttonRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        
        setButtonWidth(rect.width);
        setContainerWidth(containerRect.width);
        
        // Check if showing dropdown below would go out of viewport
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - rect.bottom;
        const dropdownHeight = Math.min(options.length * 40, 240); // Estimate dropdown height (40px per item, max 240px)
        
        if (spaceBelow < dropdownHeight && rect.top > dropdownHeight) {
          // Not enough space below, but enough space above
          setDropdownPlacement('top');
          setButtonPosition({
            top: rect.top,
            left: rect.left
          });
        } else {
          // Default: show below
          setDropdownPlacement('bottom');
          setButtonPosition({
            top: rect.bottom,
            left: rect.left
          });
        }

        // Handle horizontal alignment as well
        const viewportWidth = window.innerWidth;
        if (rect.left + rect.width > viewportWidth) {
          // Adjust left position to ensure dropdown stays within viewport
          setButtonPosition(prev => ({
            ...prev,
            left: Math.max(10, viewportWidth - rect.width - 10)
          }));
        }
      };
      
      updateMeasurements();
      
      // Add event listeners for resize and scroll
      window.addEventListener('resize', updateMeasurements);
      window.addEventListener('scroll', updateMeasurements, true);
      
      return () => {
        window.removeEventListener('resize', updateMeasurements);
        window.removeEventListener('scroll', updateMeasurements, true);
      };
    }
  }, [buttonRef.current, containerRef.current, options.length]);

  // Default render functions
  const defaultRenderOption = (option, active, selected) => (
    <div className="flex items-center w-full py-1">
      <div className="flex items-center w-full max-w-full overflow-hidden">
        <span className="block truncate max-w-full">
          {option.label}
        </span>
      </div>
    </div>
  );

  const defaultRenderSelected = (option) => (
    <div className="flex items-center h-full w-full max-w-full">
      <span className={`block truncate max-w-full overflow-hidden ${!option || !option.value ? 'text-gray-500' : 'text-gray-900'}`}>
        {option ? option.label : placeholder}
      </span>
    </div>
  );

  // Use custom render functions if provided, otherwise use defaults
  const renderOptionFn = renderOption || defaultRenderOption;
  const renderSelectedFn = renderSelected || defaultRenderSelected;

  return (
    <div ref={containerRef} className={`flex flex-col ${maxWidth} ${className}`}>
      {title && (
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {title} {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      <div className="relative w-full">
        <Listbox value={value} onChange={onChange}>
          {({ open }) => (
            <div className="relative">
              <Listbox.Button
                ref={buttonRef}
                className={`relative w-full cursor-default rounded-md border ${
                  error ? 'border-red-300' : 'border-gray-300'
                } bg-white py-2 pl-3 pr-10 text-left shadow-sm
                focus:outline-none focus:ring-2 focus:ring-brand-teal focus:border-brand-teal
                h-10 flex items-center text-input overflow-hidden`}
              >
                {renderSelectedFn(selectedOption)}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              
              {/* Position dropdown manually to follow button position */}
              <Listbox.Options
                className="fixed z-50 max-h-60 overflow-auto rounded-md bg-white py-1 text-gray-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-input"
                style={{ 
                  width: buttonWidth > 0 ? `${Math.min(buttonWidth, containerWidth)}px` : 'auto',
                  maxWidth: containerWidth > 0 ? `${containerWidth}px` : '100%',
                  top: dropdownPlacement === 'top' 
                    ? `${buttonPosition.top - 5 - (open ? 240 : 0)}px` // Position above button
                    : `${buttonPosition.top + 5}px`, // Position below button
                  left: `${buttonPosition.left}px`,
                }}
              >
                {options.map((option, index) => (
                  <Listbox.Option
                    key={option.value}
                    value={option.value}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-9 overflow-hidden ${
                        active ? 'bg-brand-form-bg text-gray-900' : 
                        selected ? 'bg-brand-form-bg text-gray-900' : 'text-gray-500'
                      }`
                    }
                  >
                    {({ active, selected }) => renderOptionFn(option, active, selected)}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          )}
        </Listbox>
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default Dropdown; 