import React from 'react';
import { navigate } from '../../utils/navigation';
import Modal from '../ui/Modal';
import Button from '../ui/Button';

const AuthRequiredModal = ({ 
  isOpen, 
  onClose, 
  message = "Please log in to continue",
  actionType = "like products"
}) => {
  const handleLogin = () => {
    navigate('/login', { 
      state: { 
        returnTo: window.location.pathname,
        actionIntent: actionType 
      } 
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Login Required"
      size="sm"
      actions={
        <>
          <Button variant="orange" onClick={handleLogin}>
            Log In
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </>
      }
    >
      <div className="py-4">
        <p className="text-gray-700">
          {message}
        </p>
        <div className="mt-4 flex items-center space-x-2">
          <span className="text-sm text-gray-500">
            Don't have an account?
          </span>
          <button
            onClick={() => {
              navigate('/signup');
              onClose();
            }}
            className="text-sm text-brand-teal font-medium hover:underline"
          >
            Sign up
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AuthRequiredModal; 