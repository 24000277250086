import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedProducts } from '../../../redux/slices/listingSlice';
import { searchWithAlgolia } from '../../../redux/slices/filterSlice';
import { useBulkToggleActivationMutation } from '../../../redux/apis/endpoints/listingApi';
import { handleResponse } from '../../../redux/slices/responseSlice';
import CardButton from '../../ui/CardButton';

const ActivationButton = ({ 
  selectedProducts,
  selectedCount
}) => {
  const dispatch = useDispatch();
  const { products, searchTerm, filters, sortBy } = useSelector(state => state.filter);
  const [bulkToggleActivation, { isLoading }] = useBulkToggleActivationMutation();

  // Calculate active state
  const isActive = useMemo(() => {
    if (!products || !selectedProducts?.length) return false;
    
    const selectedItems = products.filter(product => 
      selectedProducts.includes(product.id)
    );
    
    const activeCount = selectedItems.filter(item => item.activeListing).length;
    return activeCount === selectedItems.length;
  }, [products, selectedProducts]);

  const handleBulkToggleActive = async () => {
    if (isLoading || selectedCount === 0) return;
    
    try {
      // Optimistically update UI first
      const updatedProducts = products.map(product => {
        if (selectedProducts.includes(product.id)) {
          return { ...product, activeListing: !isActive };
        }
        return product;
      });
      dispatch({ type: 'filter/optimisticUpdate', payload: updatedProducts });
      
      const result = await bulkToggleActivation({ 
        ids: selectedProducts,
        active: !isActive 
      }).unwrap();
      
      dispatch(handleResponse(result, {
        successMessage: `Successfully ${!isActive ? 'activated' : 'deactivated'} ${selectedCount} products`,
        errorMessage: 'Failed to update product status',
        onSuccess: () => {
          dispatch(clearSelectedProducts());
        }
      }));
    } catch (error) {
      // Revert optimistic update on error by triggering a fresh search
      dispatch(searchWithAlgolia({ 
        searchTerm, 
        filters, 
        sortBy, 
        offset: 0 
      }));
      dispatch(handleResponse(error, {
        errorMessage: 'Failed to update product status'
      }));
    }
  };

  return (
    <CardButton
      onClick={handleBulkToggleActive}
      disabled={isLoading}
      isLoading={isLoading}
      variant={isActive ? 'yellow' : 'green'}
      size="medium"
    >
      <span>{isActive ? 'Deactivate' : 'Activate'} ({selectedCount})</span>
    </CardButton>
  );
};

export default ActivationButton; 