import React from 'react';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import Dropdown from '../../../../ui/Dropdown';
import useFilter from '../../useFilter';

const SortDropdown = ({ className = '' }) => {
  const { sortBy, sortOptions, handleSortChange } = useFilter();

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center gap-2">
        <ArrowsUpDownIcon className="h-5 w-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">Sort By</span>
      </div>
      <Dropdown
        value={sortBy}
        onChange={handleSortChange}
        options={sortOptions}
        placeholder="Sort by"
        maxWidth="none"
        className={className}
      />
    </div>
  );
};

export default SortDropdown; 