import React, { forwardRef, useEffect } from 'react';

const Checkbox = forwardRef(({ 
  label, 
  checked, 
  onChange, 
  type = 'checkbox', 
  name, 
  className = '',
  indeterminate = false
}, ref) => {
  useEffect(() => {
    if (ref?.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <label className={`inline-flex items-center cursor-pointer whitespace-nowrap ${className}`}>
      <input
        ref={ref}
        type={type}
        checked={checked}
        onChange={onChange}
        name={name}
        className={`
          h-4 w-4 rounded border-gray-300 text-brand-teal focus:ring-brand-teal
          ${type === 'radio' ? 'rounded-full' : 'rounded'}
        `}
      />
      {label && <span className="ml-2 text-sm text-gray-700 flex-shrink-0">{label}</span>}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox; 