import React from 'react'
import { MapPinIcon, TagIcon } from '@heroicons/react/24/outline'
import MerchantInfo from './MerchantInfo'

const ProductInfo = ({ product, onMessageMerchant, className = '' }) => {
  if (!product) return null

  const {
    description,
    category,
    size,
    customSizeDetails,
    color,
    pickUpAddress,
    openToPickUp,
    users_permissions_user,
    brand,
    material,
    condition
  } = product

  // Format distance for display
  const formatDistance = distance => {
    if (!distance) return 'Distance not available'
    if (distance < 1) return 'Less than 1 km'
    return `${Math.round(distance)} km away`
  }

  // Get color background style
  const getColorStyle = (color) => {
    const colorMap = {
      'black': { backgroundColor: '#000000' },
      'white': { backgroundColor: '#FFFFFF', border: '1px solid #E5E7EB' },
      'gray': { backgroundColor: '#6B7280' },
      'beige': { backgroundColor: '#F5F5DC', border: '1px solid #E5E7EB' },
      'brown': { backgroundColor: '#964B00' },
      'navy': { backgroundColor: '#000080' },
      'red': { backgroundColor: '#EF4444' },
      'orange': { backgroundColor: '#F97316' },
      'yellow': { backgroundColor: '#F59E0B' },
      'green': { backgroundColor: '#10B981' },
      'blue': { backgroundColor: '#3B82F6' },
      'purple': { backgroundColor: '#8B5CF6' },
      'pink': { backgroundColor: '#EC4899' },
      'gold': { backgroundColor: '#FFD700', border: '1px solid #E5E7EB' },
      'silver': { backgroundColor: '#C0C0C0', border: '1px solid #E5E7EB' },
      'multicolor': { background: 'linear-gradient(90deg, #f44336, #ff9800, #ffeb3b, #4caf50, #2196f3, #9c27b0)' },
    };
    
    return colorMap[color?.toLowerCase()] || { backgroundColor: '#CBD5E1' };
  };

  // Render feature as regular text instead of badge
  const renderFeature = (value) => {
    return (
      <span className="text-sm text-gray-700">
        {value}
      </span>
    );
  };

  // Render color with color dot
  const renderColorFeature = (color) => {
    return (
      <div className="flex items-center">
        <div 
          className="w-4 h-4 rounded-full mr-2" 
          style={getColorStyle(color)}
        />
        <span className="text-sm text-gray-700">
          {color}
        </span>
      </div>
    );
  };

  // Format condition display
  const formatCondition = (condition) => {
    if (!condition) return '';
    
    const conditionMap = {
      'new_with_tags': 'New with tags',
      'new_without_tags': 'New without tags',
      'like_new': 'Like new',
      'very_good': 'Very good',
      'good': 'Good',
      'fair': 'Fair'
    };
    
    return conditionMap[condition] || condition;
  };

  return (
    <div className={`space-y-6 ${className}`}>
      {/* Description */}
      <div className="border-t border-gray-200 pt-6">
        <h3 className='text-sm font-medium text-gray-900'>Description</h3>
        <div className='mt-2 text-base text-gray-700 space-y-4'>
          {description}
        </div>
      </div>

      {/* Product Features */}
      <div className="border-t border-gray-200 pt-6">
        <h3 className='text-sm font-medium text-gray-900 mb-4'>Product Details</h3>
        
        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          {/* Category */}
          {category && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Category</h4>
              {renderFeature(category)}
            </div>
          )}
          
          {/* Brand */}
          {brand && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Brand</h4>
              {renderFeature(brand)}
            </div>
          )}
          
          {/* Size */}
          {size && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Size</h4>
              {renderFeature(size === 'custom' && customSizeDetails 
                ? `${size} (${customSizeDetails})` 
                : size)}
            </div>
          )}
          
          {/* Color */}
          {color && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Color</h4>
              {renderColorFeature(color)}
            </div>
          )}
          
          {/* Material */}
          {material && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Material</h4>
              {renderFeature(material)}
            </div>
          )}
          
          {/* Condition */}
          {condition && (
            <div>
              <h4 className="text-xs text-gray-500 mb-2">Condition</h4>
              {renderFeature(formatCondition(condition))}
            </div>
          )}
        </div>
      </div>

      {/* Merchant Info */}
      {users_permissions_user && (
        <MerchantInfo
          merchant={users_permissions_user}
          onMessageMerchant={onMessageMerchant}
        />
      )}

      {/* Pickup Location */}
      {openToPickUp && pickUpAddress && (
        <div className='pt-6'>
          <div className='flex items-center text-sm'>
            <MapPinIcon className='h-5 w-5 text-gray-400' />
            <span className='ml-2 text-gray-700'>
              {formatDistance(pickUpAddress.distance)}
            </span>
            {pickUpAddress.city && (
              <span className='ml-1 text-gray-500'>· {pickUpAddress.city}</span>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductInfo
