import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { HeartIcon } from '@heroicons/react/24/solid';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/24/outline';
import { selectCurrentUser } from '../../redux/slices/authSlice';
import { useToggleLikeMutation, useGetLikeStatusQuery } from '../../redux/apis';
import AuthRequiredModal from '../common/AuthRequiredModal';

const Like = ({ 
  productId,
  size = 'md', // sm, md, lg
  showCount = true,
  className = '',
  onUnauthorized = null
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const [optimisticLiked, setOptimisticLiked] = useState(null);
  const [optimisticCount, setOptimisticCount] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  
  const { data: likeStatus, isLoading: likeLoading, error: likeError } = useGetLikeStatusQuery(productId, {
    skip: !productId,
    refetchOnMountOrArgChange: true
  });
  
  const [toggleLike] = useToggleLikeMutation();

  const handleLikeClick = async (e) => {
    e.stopPropagation();
    if (!currentUser) {
      if (onUnauthorized) {
        onUnauthorized();
      } else {
        setShowAuthModal(true);
      }
      return;
    }

    const newLikedState = optimisticLiked !== null ? !optimisticLiked : !likeStatus?.isLiked;
    const newCount = (optimisticCount !== null ? optimisticCount : likeStatus?.likeCount || 0) + (newLikedState ? 1 : -1);
    
    setOptimisticLiked(newLikedState);
    setOptimisticCount(newCount);

    try {
      await toggleLike({ productId });
    } catch (error) {
      setOptimisticLiked(null);
      setOptimisticCount(null);
      console.error('Error toggling like:', error);
    }
  };

  const sizeClasses = {
    sm: {
      button: 'w-6 h-6',
      icon: 'w-4 h-4',
      text: 'text-xs'
    },
    md: {
      button: 'w-8 h-8',
      icon: 'w-5 h-5',
      text: 'text-sm'
    },
    lg: {
      button: 'w-10 h-10',
      icon: 'w-6 h-6',
      text: 'text-base'
    }
  }[size];

  if (!currentUser) {
    return (
      <div className={`flex items-center gap-2 ${className}`}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (onUnauthorized) {
              onUnauthorized();
            } else {
              setShowAuthModal(true);
            }
          }}
          className={`${sizeClasses.button} flex items-center justify-center rounded-full transition-colors bg-white/80 hover:bg-white/90`}
        >
          <HeartOutlineIcon className={`${sizeClasses.icon} text-gray-400`} />
          {showCount && (
            <span className={`${sizeClasses.text} text-gray-500`}>
              {likeStatus?.likeCount || 0} {likeStatus?.likeCount === 1 ? 'like' : 'likes'}
            </span>
          )}
        </button>
       
        
        <AuthRequiredModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
          message="Please log in to like this product"
          actionType="like products"
        />
      </div>
    );
  }

  const isLiked = optimisticLiked !== null ? optimisticLiked : likeStatus?.isLiked;
  const likeCount = optimisticCount !== null ? optimisticCount : (likeStatus?.likeCount || 0);

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <button
        onClick={handleLikeClick}
        disabled={likeLoading}
        className={`${sizeClasses.button} flex items-center justify-center rounded-full transition-colors bg-white/80 hover:bg-white/90 disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        {isLiked ? (
          <HeartIcon className={`${sizeClasses.icon} text-brand-orange`} />
        ) : (
          <HeartOutlineIcon className={`${sizeClasses.icon} text-gray-400`} />
        )}
         {showCount && (
        <span className={`${sizeClasses.text} text-gray-500`}>
          {likeCount}
        </span>
      )}
      </button>
     
    </div>
  );
};

export default Like; 