import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useDispatch } from 'react-redux';
import { checkAuth } from './redux/slices/authSlice';
import { Toaster } from 'react-hot-toast';
import { detectUserCountry } from './redux/slices/locationSlice';

// Core components
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import MessageDisplay from './components/ui/MessageDisplay';
import PrivateRoute from './routes/PrivateRoute';
import ScrollToTop from './ScrollToTop';

// Pages
import HomePage from './pages/home/HomePage';
import AboutPage from './pages/about/AboutPage';
import LoginPage from './pages/login';
import SignupPage from './component/RedirectPages/SignupPage';
import CheckOutPage from './component/shoppingpages/CheckOutPage';
import Message from './component/message/Message';
import ContactPage from './pages/contact/ContactPage.js';
import Dashboard from './pages/dashboard/DashboardPage';
import Cart from './pages/cart/CartPage.js';
import BrowsePage from './pages/browse/BrowsePage';
import ProductPage from './pages/product/ProductPage';

// Auth & Verification
import EmailConfirmation from './component/RedirectPages/EmailConfirmation';
import ForgetPassword from './component/RedirectPages/ForgetPassword';
import ResetPassword from './component/RedirectPages/ResetPassword';
import VerifyEmail from './component/changeEmail/VerifyEmail';

// Payment
import PaymentResult from './component/payment/Result';
import Alipay from './component/payment/Alipay';
import WechatPay from './component/payment/WechatPay';
import Paypal from './component/payment/Paypal';
import PaypalOnboard from './component/payment/PaypalOnboard';

// Merchant
import MerchantVerify from './component/merchants/merchantPage/merchantVerify/MerchantVerify';
import MerchantOpenShop from './component/merchants/MerchantOpenShop';
import MerchantPage from './component/merchants/merchantPage/MerchantPage';
import ListingForm from './components/listings/ListingForm';
import Favorites from './components/favorites/Favorites';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Check auth state on app load
    dispatch(checkAuth());

    // Set up interval to periodically check auth
    const interval = setInterval(() => {
      dispatch(checkAuth());
    }, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval);
  }, [dispatch]);

  // Detect user's country on app load
  useEffect(() => {
    dispatch(detectUserCountry());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <ParallaxProvider>
        <div className="min-h-screen flex flex-col">
          {/* Responsive Navigation */}
          <Nav className="sticky top-0 z-40" />
          
          {/* Message Display */}
          <div className="fixed inset-0 pointer-events-none z-50">
            <MessageDisplay />
          </div>
          
          {/* Toast Notifications */}
          <Toaster 
            position="top-right" 
            toastOptions={{
              duration: 3000,
              style: {
                background: '#fff',
                color: '#333',
                fontSize: '14px',
                borderRadius: '8px',
                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
              },
              success: {
                style: {
                  borderLeft: '4px solid #10B981',
                },
                iconTheme: {
                  primary: '#10B981',
                  secondary: '#fff',
                },
              },
              error: {
                style: {
                  borderLeft: '4px solid #EF4444',
                },
                iconTheme: {
                  primary: '#EF4444',
                  secondary: '#fff',
                },
              },
            }}
          />

          {/* Main Content */}
          <main className="flex-grow">
            <Switch>
              {/* Public Routes */}
              <Route exact path="/" component={HomePage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/signup" component={SignupPage} />
              <Route path="/product" component={ProductPage} />
              
              {/* Auth Routes */}
              <Route path="/forgot-password" component={ForgetPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/verify-email" component={VerifyEmail} />
              <Route path="/email-confirmation" component={EmailConfirmation} />
              
              {/* Protected Routes */}
              <PrivateRoute path="/favorites" component={Favorites} />
              <PrivateRoute path="/cart" component={Cart} />
              <PrivateRoute path="/checkout" component={CheckOutPage} />
              <PrivateRoute path="/messages" component={Message} />
              
              {/* Dashboard Routes */}
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/listing/upload" component={ListingForm} />
              <PrivateRoute path="/listing/edit/:id" component={ListingForm} />
              
              {/* Merchant Routes */}
              <PrivateRoute path="/merchant/verify" component={MerchantVerify} />
              <PrivateRoute path="/merchant/open-shop" component={MerchantOpenShop} />
              
              {/* Payment Routes */}
              <PrivateRoute path="/payment/complete" component={PaymentResult} />
              <PrivateRoute path="/payment/alipay" component={Alipay} />
              <PrivateRoute path="/payment/wechat" component={WechatPay} />
              <PrivateRoute path="/payment/paypal" component={Paypal} />
              <PrivateRoute path="/payment/paypal/onboard" component={PaypalOnboard} />
              
              {/* Product Routes */}
              <Route exact path="/browse" component={BrowsePage} />
              <Route path="/browse/:id" component={ProductPage} />
              <Route path="/product/:id" component={ProductPage} />
              
              {/* Redirect old product routes */}
              <Redirect from="/products" to="/browse" />
              <Redirect from="/allProducts" to="/browse" />
              
              {/* Redirect old routes to new ones */}
              <Redirect from="/mypage" to="/dashboard" />
              <Redirect from="/merchantpage" to="/dashboard" />
              <Redirect from="/profile" to="/dashboard" />
              <Redirect from="/merchantsLog" to="/login?type=merchant" />
              <Redirect from="/signin" to="/login" />
              
              {/* 404 Route */}
              <Route path="*">
                <div className="min-h-screen flex items-center justify-center">
                  <div className="text-center">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">404</h1>
                    <p className="text-gray-600">Page not found</p>
                  </div>
                </div>
              </Route>
            </Switch>
          </main>

          {/* Footer */}
          <Footer />
        </div>
      </ParallaxProvider>
    </Router>
  );
}

export default App; 