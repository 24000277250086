import React from 'react';

const MenuItem = ({ 
  children,
  className = ''
}) => {
  return (
    <div className={`
      flex-none
      w-44
      mb-3
      flex items-center
      ${className}
    `} style={{ position: 'relative' }}>
      <div className="w-full flex items-center">
        {children}
      </div>
    </div>
  );
};

export default MenuItem; 