import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDeleteModalOpen } from '../../../redux/slices/listingSlice';
import CardButton from '../../ui/CardButton';
import DeleteModal from './DeleteModal';

const DeleteButton = ({ 
  selectedCount
}) => {
  const dispatch = useDispatch();
  const { selectedProducts, deleteModalOpen } = useSelector(state => state.listing);

  const handleOpenModal = () => {
    dispatch(setDeleteModalOpen(true));
  };

  return (
    <>
      <CardButton
        onClick={handleOpenModal}
        variant="red"
        size="medium"
      >
        <span>Delete ({selectedCount})</span>
      </CardButton>

      {/* Single DeleteModal outside of the component tree */}
      <DeleteModal
        isOpen={deleteModalOpen}
        selectedProducts={selectedProducts}
        itemCount={selectedCount}
      />
    </>
  );
};

export default DeleteButton; 