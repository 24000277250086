import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
// import Section from './components/Section';
import HeroSection from './components/sections/HeroSection';
import SharingSection from './components/sections/SharingSection';
import OccasionSection from './components/sections/OccasionSection';
import MapSection from './components/sections/MapSection';
import SustainSection from './components/sections/SustainSection';
import MerchantSection from './components/sections/MerchantSection';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const HomePage = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    // <ParallaxProvider>
      <div className="min-h-screen">
        <HeroSection />
        {/* <SharingSection /> */}
        <OccasionSection />
        <MapSection />
        <SustainSection />
        <MerchantSection />
      </div>
    // </ParallaxProvider>
  );
};

export default HomePage; 