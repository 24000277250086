import { createBaseApi } from '../baseApi';

const productApi = createBaseApi('productApi');

export const enhancedProductApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (id) => {
        if (!id) {
          throw new Error('Product ID is required');
        }
        return {
          url: `/products/${id}`,
          credentials: 'omit',  // Don't send cookies or authorization headers
          headers: { 
            // Explicitly clear Authorization header for this endpoint
            Authorization: ''
          }
        };
      },
      transformResponse: (response) => {
        // Transform photos URLs if they're relative
        if (response.photos) {
          response.photos = response.photos.map(photo => ({
            ...photo,
            url: photo.url.startsWith('http') ? photo.url : `${process.env.REACT_APP_BACKEND_URL}${photo.url}`,
            formats: {
              ...photo.formats,
              thumbnail: photo.formats?.thumbnail ? {
                ...photo.formats.thumbnail,
                url: photo.formats.thumbnail.url.startsWith('http') 
                  ? photo.formats.thumbnail.url 
                  : `${process.env.REACT_APP_BACKEND_URL}${photo.formats.thumbnail.url}`
              } : null
            }
          }));
        }
        return response;
      },
      providesTags: (result, error, id) => [{ type: 'Product', id }]
    }),
    toggleLike: builder.mutation({
      query: ({ productId }) => ({
        url: `/products/${productId}/like`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { productId }) => [
        { type: 'Product', id: productId },
        { type: 'User', id: 'CURRENT' }
      ]
    }),
    getLikeStatus: builder.query({
      query: (productId) => `/products/${productId}/like-status`,
      providesTags: (result, error, productId) => [
        { type: 'Product', id: productId },
        { type: 'User', id: 'CURRENT' }
      ]
    })
  })
});

export const {
  useGetProductQuery,
  useToggleLikeMutation,
  useGetLikeStatusQuery
} = enhancedProductApi;
