import React from 'react'
import BaseFilter from './BaseFilter'
import PickUpCheckbox from './filter_components/checkboxes/PickUpCheckbox'
import ShippingCheckbox from './filter_components/checkboxes/ShippingCheckbox'
import LocationDisplay from './filter_components/LocationDisplay'

/**
 * Browse filter component showing delivery method options
 * and location-based filtering for pickup
 */
const BrowseFilter = () => {
  return (
    <BaseFilter layout='vertical'>
      <div className='space-y-3'>
        <div className='flex space-x-4'>
          <ShippingCheckbox />
          <PickUpCheckbox />
        </div>

        <div style={{ maxWidth: '300px' }}>
          <LocationDisplay />
        </div>
      </div>
    </BaseFilter>
  )
}

export default BrowseFilter
