import React from 'react';

const MenuGrid = ({ 
  children, 
  layout = 'vertical',
  topSection,
  filterSection,
  expandedFilterSection,
  bottomSection,
  className = ''
}) => {
  const gridClasses = {
    wrapper: "space-y-4",
    // Main filters section (search, sort, product types)
    mainSection: `
      overflow-x-auto
      -mx-4
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent
      ${layout === 'vertical' ? 'lg:overflow-visible lg:mx-0' : ''}
    `,
    mainInner: `
      inline-flex items-center
      px-4
      space-x-4 whitespace-nowrap
      ${layout === 'vertical' ? 'lg:flex lg:flex-col lg:space-x-0 lg:space-y-4' : ''}
    `,
    // Expanded filters section (only shown in horizontal mode when more filters are active)
    expandedSection: `
      overflow-x-auto
      -mx-4
      scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent
      mt-4
      ${layout === 'vertical' ? 'lg:hidden' : ''}
    `,
    expandedInner: `
      inline-flex items-center
      px-4
      space-x-4 whitespace-nowrap
    `,
    // Bottom section (checkboxes, clear all)
    bottomSection: `
      flex flex-col sm:flex-row items-start sm:items-center justify-between
      px-4 gap-2
      ${layout === 'vertical' ? 'lg:flex-col lg:items-stretch lg:gap-5' : ''}
    `
  };

  return (
    <div className={`bg-white rounded-lg shadow-sm p-4 ${gridClasses.wrapper} ${className}`}>
      {/* Main filters section */}
      <div className={gridClasses.mainSection} style={{ position: 'relative' }}>
        <div className={gridClasses.mainInner}>
          {topSection}
          {filterSection}
        </div>
      </div>

      {/* Expanded filters section */}
      {expandedFilterSection && (
        <div className={gridClasses.expandedSection}>
          <div className={gridClasses.expandedInner}>
            {expandedFilterSection}
          </div>
        </div>
      )}

      {/* Bottom section */}
      <div className={gridClasses.bottomSection}>
        {bottomSection}
      </div>
    </div>
  );
};

export default MenuGrid; 