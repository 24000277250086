import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  formatAddress, 
  isValidAddress
} from '../utils/addressUtils';
import { 
  getCountryOptions,
  getCountryNameByCode,
  getAdminDivisionLabel,
  isStateRequired,
  usesPostalCode
} from '../utils/countryUtils';
import { AddressAutocomplete } from '..';
import { formatMapboxResponse } from '../../../config/mapbox';
import FormInput from '../../ui/FormInput';
import Dropdown from '../../ui/Dropdown';
import { selectUserCountry, updateUserCountry, selectIsDetectingCountry } from '../../../redux/slices/locationSlice';

const AddressInput = ({ 
  label = 'Address',
  description,
  onAddressChange,
  required = false,
  error,
  initialAddress = null,
  showMap = false
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState(formatAddress(initialAddress || {}));
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  
  // Get user's country from Redux (but don't set a default - AddressForm handles that)
  const userCountry = useSelector(selectUserCountry);
  const isDetectingCountry = useSelector(selectIsDetectingCountry);
  
  // Get country-specific address format info
  const adminDivisionLabel = getAdminDivisionLabel(address.country, 'Region');
  const stateRequired = required && isStateRequired(address.country);
  const postalCodeRequired = required && usesPostalCode(address.country);

  // Set country from Redux on first render if not already set in initial address
  useEffect(() => {
    if (!address.country && userCountry?.code && !initialAddress?.country) {
      handleChange('country', userCountry.code);
    }
  }, [userCountry?.code]);

  // Load country options
  useEffect(() => {
    // Format country options for the Dropdown component
    const options = getCountryOptions().map(country => ({
      value: country.value,
      label: country.text
    }));
    setCountryOptions(options);
  }, []);

  // Update address if initialAddress changes
  useEffect(() => {
    if (initialAddress) {
      setAddress(formatAddress(initialAddress));
      
      // Set autocomplete value based on address
      if (initialAddress.street) {
        const addressString = [
          initialAddress.street,
          initialAddress.city,
          initialAddress.state,
          initialAddress.postalCode
        ].filter(Boolean).join(', ');
        
        setAutocompleteValue(addressString);
      }
    }
  }, [initialAddress]);

  // Handle manual field changes
  const handleChange = (field, value) => {
    const newAddress = {
      ...address,
      [field]: value
    };
    setAddress(newAddress);
    onAddressChange({ [field]: value });
  };

  // Handle input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  // Handle country dropdown change
  const handleCountryChange = (countryCode) => {
    if (!countryCode) return;
    
    // Update the address locally
    handleChange('country', countryCode);
    
    // Get the country name to update Redux
    const countryName = getCountryNameByCode(countryCode);
    
    if (countryName) {
      // Log for debugging
      console.log(`Country selected: ${countryCode} (${countryName})`);
      
      // Update Redux so other components (like AddressAutocomplete) can use it
      dispatch(updateUserCountry({ 
        countryCode: countryCode,
        countryName: countryName
      }));
    }
  };

  // Handle selection from autocomplete
  const handleAutocompleteSelect = (selectedAddress) => {
    // The new AddressAutocomplete now returns a fully formatted address object
    // Make sure we preserve any existing data from the current address not in the selected address
    const newAddress = {
      ...address,
      ...selectedAddress,
      // Keep the existing name if we have one
      name: address.name || selectedAddress.name || '',
      // Keep existing apartment data if we have it
      apartment: address.apartment || selectedAddress.apartment || '',
      // Keep instructions if we have them
      instructions: address.instructions || selectedAddress.instructions || ''
    };
    
    setAddress(newAddress);
    onAddressChange(newAddress);
  };
  
  // Determine if a specific field has an error
  const getFieldError = (field) => {
    if (!error) return null;
    
    // If error is an object with field-specific errors
    if (typeof error === 'object' && error[field]) {
      return error[field];
    }
    
    // For simple field validation when error is a boolean
    if (typeof error === 'boolean' && error) {
      // Basic required field validation
      if (!address[field] && required) {
        if (field === 'country' || ['street', 'city', 'country'].includes(field)) {
          return `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        }
        
        // Special handling for state/region
        if (field === 'state' && stateRequired) {
          return `${adminDivisionLabel} is required`;
        }
        
        // Special handling for postal code
        if (field === 'postalCode' && postalCodeRequired) {
          return 'Postal code is required';
        }
      }
    }
    
    return null;
  };

  return (
    <div className="space-y-4">
      {description && (
        <p className="text-sm text-gray-500 mb-3">{description}</p>
      )}

      {/* Address Autocomplete */}
      <div className="mb-4">
        <label className="block text-label font-medium text-gray-700 mb-2">
          Find Address
        </label>
        <AddressAutocomplete 
          initialValue={autocompleteValue}
          onSelect={handleAutocompleteSelect}
          placeholder="Search for your address"
          useReduxLoading={true}
          updateReduxStore={true}
        />
        <p className="mt-1 text-xs text-gray-500">
          Search for your address above or fill in the fields below manually.
        </p>
      </div>

      {/* Address Form */}
      <div className="space-y-4">
        <FormInput
          as="input"
          label="Name"
          name="name"
          value={address.name || ''}
          onChange={handleInputChange}
          placeholder="Home, Office, etc."
          error={getFieldError('name')}
        />

        <FormInput
          as="input"
          label="Street Address"
          name="street"
          value={address.street || ''}
          onChange={handleInputChange}
          placeholder="Street address"
          required={required}
          error={getFieldError('street')}
        />

        <FormInput
          as="input"
          label="Apartment, Suite, etc."
          name="apartment"
          value={address.apartment || ''}
          onChange={handleInputChange}
          placeholder="Apartment, suite, unit, building, floor, etc."
          error={getFieldError('apartment')}
        />

        <div className="grid grid-cols-2 gap-4">
          <FormInput
            as="input"
            label="City"
            name="city"
            value={address.city || ''}
            onChange={handleInputChange}
            placeholder="City"
            required={required}
            error={getFieldError('city')}
          />
          
          <FormInput
            as="input"
            label={adminDivisionLabel}
            name="state"
            value={address.state || ''}
            onChange={handleInputChange}
            placeholder={adminDivisionLabel}
            required={stateRequired}
            error={getFieldError('state')}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          {usesPostalCode(address.country) && (
            <FormInput
              as="input"
              label="Postal Code"
              name="postalCode"
              value={address.postalCode || ''}
              onChange={handleInputChange}
              placeholder="Postal code"
              required={postalCodeRequired}
              error={getFieldError('postalCode')}
            />
          )}
          
          <Dropdown
            title="Country"
            value={address.country || ''}
            onChange={handleCountryChange}
            options={countryOptions}
            placeholder={isDetectingCountry ? "Detecting country..." : "Select Country"}
            error={getFieldError('country')}
            required={required}
            maxWidth="w-full"
            disabled={isDetectingCountry}
          />
        </div>

        <FormInput
          as="input"
          type="tel"
          label="Phone Number"
          name="phoneNumber"
          value={address.phoneNumber || ''}
          onChange={handleInputChange}
          placeholder="Phone number for this address"
          required={required}
          error={getFieldError('phoneNumber')}
        />
        
        <FormInput
          as="textarea"
          label="Special Instructions"
          name="instructions"
          value={address.instructions || ''}
          onChange={handleInputChange}
          placeholder="Delivery instructions, gate codes, etc."
          rows={3}
          error={getFieldError('instructions')}
        />
      </div>

      {error && typeof error === 'string' && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
};

export default AddressInput; 