import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';

const Rating = ({ 
  rating, 
  totalRatings,
  showCount = true,
  size = 'base', // 'sm', 'base', or 'lg'
  className = '' 
}) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  const sizeClasses = {
    sm: 'h-4 w-4',
    base: 'h-5 w-5',
    lg: 'h-6 w-6'
  };

  const textSizes = {
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg'
  };

  return (
    <div className={`flex items-center gap-1 ${className}`}>
      <div className="flex">
        {/* Full stars */}
        {[...Array(fullStars)].map((_, i) => (
          <StarIcon
            key={`full-${i}`}
            className={`${sizeClasses[size]} text-yellow-400`}
          />
        ))}

        {/* Empty stars */}
        {[...Array(emptyStars)].map((_, i) => (
          <StarOutlineIcon
            key={`empty-${i}`}
            className={`${sizeClasses[size]} text-gray-300`}
          />
        ))}
      </div>

      {showCount && totalRatings > 0 && (
        <span className={`${textSizes[size]} text-gray-600`}>
          ({totalRatings})
        </span>
      )}
    </div>
  );
};

export default Rating; 