import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetProductQuery, useAddCartItemMutation, useReplaceRentalPeriodMutation } from '../../redux/apis';
import { selectCurrentUser } from '../../redux/slices/authSlice';
import ProductGallery from './components/ProductGallery';
import ProductInfo from './components/ProductInfo';
import ProductActions from './components/ProductActions';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import ErrorMessage from '../../components/common/ErrorMessage';
import BackButton from '../../components/common/BackButton';
import { redirectToCart, redirectToLogin } from '../../utils/navigation';
import toast from 'react-hot-toast';
import Modal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';

const ProductPage = () => {
  const { id } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const [addCartItem] = useAddCartItemMutation();
  const [replaceRentalPeriod] = useReplaceRentalPeriodMutation();
  
  // State for rental period replacement modal
  const [showRentalModal, setShowRentalModal] = useState(false);
  const [rentalChoice, setRentalChoice] = useState(null);
  
  const { data: product, isLoading: productLoading, error: productError } = useGetProductQuery(id, {
    refetchOnMountOrArgChange: false,
    skip: !id
  });

  const handleMessageMerchant = () => {
    // TODO: Implement messaging
    console.log('Message merchant clicked');
  };

  // Format rental period for display
  const formatRentalPeriod = (period) => {
    if (!period) return 'Unknown period';
    
    const number = period.number || 1;
    const option = period.option || 'day';
    
    return `${number} ${option}${number > 1 ? 's' : ''}`;
  };

  const handleAddToCart = async (cartData) => {
    if (!currentUser) {
      toast.error('Please log in to add items to your cart');
      redirectToLogin();
      return;
    }

    try {
      // Validate product stock before adding to cart
      const requestedQuantity = cartData.quantity || 1;
      const availableStock = parseInt(product.stock);
      
      if (availableStock === 0) {
        toast.error(`Sorry, ${product.name} is out of stock`);
        return;
      }
      
      // Check if this would exceed the available stock
      if (availableStock < requestedQuantity) {
        toast.error(`Sorry, only ${availableStock} units available`);
        return;
      }

      const cartItem = {
        product: product.id,
        quantity: requestedQuantity,
        type: cartData.type || 'buy',
      };

      // Add rental period data if it's a rental
      if (cartData.type === 'rent' && cartData.rentalPeriod) {
        cartItem.rentalPeriod = cartData.rentalPeriod;
      }

      // If this product has a merchant, add it to cart item
      if (product.users_permissions_user) {
        cartItem.merchant = product.users_permissions_user.id;
      }

      // Add any product options that were selected
      if (cartData.productOptions && cartData.productOptions.length > 0) {
        cartItem.productOptions = cartData.productOptions;
      }

      // Add custom note if provided
      if (cartData.customNote) {
        cartItem.customNote = cartData.customNote;
      }

      // Add to cart
      const result = await addCartItem(cartItem).unwrap();
      
      // Handle the case where the same rental period is already in cart
      if (result && result.status === 'already_in_cart') {
        toast.success(result.message || `${product.name} already in your cart.`);
        
        // Optionally navigate to cart
        if (cartData.navigateToCart) {
          redirectToCart(cartData.type === 'rent');
        }
        return;
      }
      
      // Handle the case where the user already has this product with a different rental period
      if (result && result.status === 'choice_required') {
        // Store choice data for the modal
        setRentalChoice({
          options: result.options,
          itemIndex: result.itemIndex,
          newItem: result.newItem,
          navigateToCart: cartData.navigateToCart
        });
        
        // Show the modal
        setShowRentalModal(true);
        return;
      }
      
      // Normal success case
      if (result) {
        const message = `${product.name} added to your cart!`;
        toast.success(message);
      }
      
      // Optionally navigate to cart
      if (cartData.navigateToCart) {
        redirectToCart(cartData.type === 'rent');
      }
    } catch (error) {
      // Handle specific error messages from the server
      if (error.data && error.data.message) {
        if (error.data.message.includes('Not enough quantity available')) {
          toast.error(`Sorry, not enough stock available for ${product.name}`);
        } else {
          toast.error(error.data.message);
        }
      } else {
        toast.error('Failed to add item to cart. Please try again.');
        console.error('Add to cart error:', error);
      }
    }
  };

  // Handle rental period replacement choice
  const handleRentalChoice = async (action) => {
    try {
      // Only handle replace action now
      await replaceRentalPeriod({
        itemIndex: rentalChoice.itemIndex,
        rentalPeriod: rentalChoice.newItem.rentalPeriod
      }).unwrap();
      
      toast.success(`Rental period updated for ${product.name}`);
      
      // Close the modal
      setShowRentalModal(false);
      
      // Navigate to cart if that was the original intent
      if (rentalChoice.navigateToCart) {
        redirectToCart(true);
      }
    } catch (error) {
      console.error('Error handling rental choice:', error);
      toast.error('Failed to update cart. Please try again.');
    }
  };

  if (productLoading) return <LoadingSpinner />;
  if (productError) {
    console.error('Product fetch error:', productError);
    return <ErrorMessage message="We couldn't load this product. Please try again later." />;
  }
  if (!product) return <ErrorMessage message="Product not found" />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <BackButton className="mb-6" />
      <div className="md:grid md:grid-cols-2 md:gap-x-8 md:items-start">
        {/* Left column */}
        <ProductGallery 
          images={product.photos} 
          isLoading={productLoading}
          className="md:sticky md:top-24"
        />

        {/* Right column */}
        <div className="mt-10 px-4 sm:px-0 sm:mt-16 md:mt-0">
          <ProductActions
            product={product}
            onAddToCart={handleAddToCart}
            className="mb-8"
          />
          <ProductInfo
            product={product}
            onMessageMerchant={handleMessageMerchant}
          />
        </div>
      </div>
      
      {/* Rental Choice Modal */}
      <Modal
        title="Same Item, Different Rental Period"
        isOpen={showRentalModal}
        onClose={() => setShowRentalModal(false)}
      >
        <div className="space-y-4">
          <p className="text-gray-600">
            You already have this item with a different rental period in your cart.
          </p>
          
          {rentalChoice && (
            <div className="bg-gray-50 p-4 rounded-md space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-gray-600">Existing rental:</span>
                <span className="font-medium">
                  {rentalChoice.options && rentalChoice.options[0] && 
                   formatRentalPeriod(rentalChoice.options[0].existingPeriod)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-gray-600">New rental:</span>
                <span className="font-medium">
                  {rentalChoice.options && rentalChoice.options[0] && 
                   formatRentalPeriod(rentalChoice.options[0].newPeriod)}
                </span>
              </div>
            </div>
          )}
          
          <div className="flex justify-center mt-4">
            <Button 
              variant="primary"
              className="w-full max-w-sm"
              onClick={() => handleRentalChoice('replace')}
            >
              Replace Rental Period
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductPage;