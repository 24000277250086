// Common colors for product listing
export const COMMON_COLORS = [
  { value: 'black', label: 'Black', bgClass: { backgroundColor: '#000000', color: 'white' } },
  { value: 'white', label: 'White', bgClass: { backgroundColor: '#FFFFFF', border: '2px solid #E5E7EB' } },
  { value: 'gray', label: 'Gray', bgClass: { backgroundColor: '#6B7280', color: 'white' } },
  { value: 'beige', label: 'Beige', bgClass: { backgroundColor: '#F5F5DC', border: '1px solid #E5E7EB' } },
  { value: 'brown', label: 'Brown', bgClass: { backgroundColor: '#964B00', color: 'white' } },
  { value: 'navy', label: 'Navy', bgClass: { backgroundColor: '#000080', color: 'white' } },
  { value: 'blue', label: 'Blue', bgClass: { backgroundColor: '#3B82F6', color: 'white' } },
  { value: 'red', label: 'Red', bgClass: { backgroundColor: '#EF4444', color: 'white' } },
  { value: 'pink', label: 'Pink', bgClass: { backgroundColor: '#EC4899', color: 'white' } },
  { value: 'purple', label: 'Purple', bgClass: { backgroundColor: '#8B5CF6', color: 'white' } },
  { value: 'green', label: 'Green', bgClass: { backgroundColor: '#10B981', color: 'white' } },
  { value: 'yellow', label: 'Yellow', bgClass: { backgroundColor: '#FBBF24', border: '1px solid #E5E7EB' } },
  { value: 'orange', label: 'Orange', bgClass: { backgroundColor: '#F97316', color: 'white' } },
  { value: 'gold', label: 'Gold', bgClass: { backgroundColor: '#FFD700', border: '1px solid #E5E7EB' } },
  { value: 'silver', label: 'Silver', bgClass: { backgroundColor: '#C0C0C0', border: '1px solid #E5E7EB' } },
  { value: 'multicolor', label: 'Multicolor', bgClass: { background: 'linear-gradient(to right, #EC4899, #FBBF24, #3B82F6)', color: 'white' } }
]; 