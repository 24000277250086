import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import Rating from '../../../components/ui/Rating';

const MerchantInfo = ({ 
  merchant,
  onMessageMerchant,
  className = ''
}) => {
  if (!merchant) return null;

  const hasProfilePic = merchant.profilePic?.[0]?.url;
  
  // Calculate average rating from comments
  const averageRating = merchant.commentsFromCustomers?.length 
    ? merchant.commentsFromCustomers.reduce((sum, comment) => sum + (comment.rating || 0), 0) / merchant.commentsFromCustomers.length
    : 0;
  
  // Get total number of ratings
  const totalRatings = merchant.commentsFromCustomers?.length || 0;

  return (
    <div className={`border-t border-gray-200 pt-6 ${className}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="h-12 w-12 rounded-full overflow-hidden">
            {hasProfilePic ? (
              <img 
                src={merchant.profilePic[0].url}
                alt={merchant.username}
                className="h-full w-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-brand-teal flex items-center justify-center text-white text-xl font-bold">
                {merchant.username?.[0]?.toUpperCase() || 'U'}
              </div>
            )}
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-900">
              {merchant.username}
            </h3>
            <div className="mt-1 flex items-center">
              <Rating 
                rating={averageRating || 0} 
                totalRatings={totalRatings}
                size="sm"
              />
            </div>
          </div>
        </div>
        <button
          onClick={onMessageMerchant}
          className="ml-4 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-teal"
        >
          Message
        </button>
      </div>
    </div>
  );
};

export default MerchantInfo; 