import React from 'react';
import { useGetProfileQuery } from '../../../redux/apis/endpoints/profileApi';
import ProfileBackground from '../../../components/overview/ProfileBackground';
import ProfilePhoto from '../../../components/overview/ProfilePhoto';
import AboutMe from '../../../components/overview/AboutMe';
import ProfileStats from '../../../components/overview/ProfileStats';
import LoadingState from '../../../components/overview/LoadingState';

/**
 * Overview component displays the user profile including:
 * - Profile background image
 * - Profile photo
 * - About me section with social links
 * - Statistics (products, transactions, followers, following)
 * 
 * Uses the profileApi.js to fetch profile data
 */
const Overview = ({ userId, profileData }) => {
  // Use the profileApi getProfile query instead of the user API
  const { data: currentUser, isLoading: isCurrentUserLoading, error } = useGetProfileQuery();
  
  // Determine which user's profile we're showing
  const user = profileData || currentUser;
  const isLoading = !profileData && isCurrentUserLoading;

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">Error loading profile: {error.message}</div>;
  }

  if (!user) {
    return <div className="p-6 text-center text-gray-500">User not found</div>;
  }

  const socialLinks = user?.socialLinkHandle || {};
  
  // If userId is provided and doesn't match the current user's ID,
  // then we're viewing someone else's profile
  const isCurrentUser = !userId || (currentUser && userId === currentUser.id);

  return (
    <div className="space-y-6">
      {/* Background Image */}
      <ProfileBackground user={user} isCurrentUser={isCurrentUser} />

      {/* Profile Section with Photo and About Me */}
      <div className="relative px-6">
        <ProfilePhoto user={user} isCurrentUser={isCurrentUser} />
        <AboutMe user={user} socialLinks={socialLinks} />
      </div>

      {/* Stats Grid */}
      <ProfileStats user={user} />
    </div>
  );
};

export default Overview; 